<div class="ncpdp-pharmacy-add" [class.ncpdp-pharmacy-add--white-bg]="whiteBackground">
  <app-text-control
    class="ncpdp-pharmacy-add__input"
    label="Enter Pharmacy NCPDP"
    [(value)]="ncpdp"
    labelModifier="small-bottom-margin"
    (keyUpEnter)="findPharmacy()"
    [focus]="focusNcpdpField"
    data-cy="addPharmacyField"
  >
  </app-text-control>

  <div class="ncpdp-pharmacy-add__add-btn">
    <button class="button button--primary" (click)="findPharmacy()" [disabled]="isLoading" data-cy="addPharmacyBtn">ADD</button>
    <app-loading-spinner [isLoading]="isLoading" modifier="button-120-px"> </app-loading-spinner>
  </div>
</div>
