import { isObject } from 'lodash';

export type SelectListItem<T = any> = {
  value: T;
  name: string;
  group?: string;
  description?: string;
  badge?: string;
  badgeClass?: string;
};

export function isSelectListItem(item: unknown): item is SelectListItem {
  return isObject(item) && 'name' in item && 'value' in item;
}
