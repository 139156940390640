import { TableHeaderFilterType } from '@shared/models/table-header.model';

export enum DataTransformationStrategyType {
  PassThrough = 'PassThrough',
  PassThroughTableValue = 'PassThroughTableValue',
  StringArrayToCommaSeparatedString = 'StringArrayToCommaSeparatedString',
  StringArrayToCommaSeparatedStringTableValue = 'StringArrayToCommaSeparatedStringTableValue',
  NextEligibleDateWithReadyTextAndIconTableValues = 'NextEligibleDateWithReadyTextAndIconTableValues',
  RequestCodesButtonWithDisableTableValue = 'RequestCodesButtonWithDisableTableValue',
}

export enum DataTransformationParameterSelectionStrategyType {
  PassThrough = 'PassThrough',
  ProductNames = 'ProductNames',
  FirstArrayElementHowSupplied = 'FirstArrayElementHowSupplied',
  FirstArrayElementNdc = 'FirstArrayElementNdc',
  TenantName = 'TenantName',
}

export interface DataTransformationStrategy {
  transform: (parameters?: Record<DataSourceType, any>) => DataTransformationReturn;
}

export enum DataSourceType {
  Program = 'Program',
  ProgramCodeAvailability = 'ProgramCodeAvailability',
}

export interface DataSource {
  type: DataSourceType;
  field?: string;
  parameterSelectionStrategyType: DataTransformationParameterSelectionStrategyType;
}

export interface DataSourceFilled extends DataSource {
  data: any;
}

export interface DataTransformationReturn {
  types?: DataTransformationReturnType[];
  value: any;
}

export enum DataTransformationReturnType {
  ButtonTableValue = 'ButtonTableValue',
  TextTableValue = 'TextTableValue',
  TextWithIcon = 'TextWithIcon',
  Dispense = 'Dispense',
  Disabled = 'Disabled',
}

export type DataTransformationParameterSelectionStrategy = (data: any) => any;

export interface TableColumn {
  name: string;
  columnStyles?: Record<string, string | number>;
  headerStyles?: Record<string, string | number>;
  filterType?: TableHeaderFilterType;
  tableValueStyles?: Array<{
    tranformationReturnType?: DataTransformationReturnType;
    valueType?: string;
    styles: Record<string, string | number>;
  }>;
  tableValueStyleClasses?: Array<{
    tranformationReturnType?: DataTransformationReturnType;
    valueType?: string;
    classes: string[];
  }>;
  dataSources?: DataSource[];
  dataTransformationStrategyType?: DataTransformationStrategyType;
}
