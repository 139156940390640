import { Injectable } from '@angular/core';
import { IProgramGroup } from '@shared/models/program-group.model';
import { ProgramGroupStateService } from '@services/program-group-state.service';
import Utils from '@shared/providers/utils';
import { ProgramStateService } from '@services/program-state.service';
import { Program } from '@shared/models/program.model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { TenantService } from '@services/tenant.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramGroupUtilityService {
  constructor(
    private programGroupStateService: ProgramGroupStateService,
    private programStateService: ProgramStateService,
    private tenantService: TenantService,
  ) {}

  private get allProgramGroupsForTenant() {
    return this.programGroupStateService.allProgramGroupsForTenant.getValue();
  }

  getProgramGroupFromIdAndTenantId(programGroupId: string, tenant: TenantIdType): IProgramGroup | undefined {
    if (!this.hasProgramGroupForTenantId(tenant)) {
      return undefined;
    }
    return this.allProgramGroupsForTenant.find(group => {
      return group.tenant === tenant && group.id === programGroupId;
    });
  }

  get hasProgramGroups(): boolean {
    return Utils.isNonEmptyArray(this.allProgramGroupsForTenant);
  }

  hasProgramGroupForTenantId(tenantId: TenantIdType): boolean {
    return this.hasProgramGroups && this.allProgramGroupsForTenant.some(group => group.tenant === tenantId);
  }

  getProgramGroupFromId(programGroupId: string): IProgramGroup | undefined {
    const tenant = this.tenantService.currentTenant;
    if (!tenant) {
      return undefined;
    }
    return this.getProgramGroupFromIdAndTenantId(programGroupId, tenant.id);
  }

  getProgramIdsFromProgramGroup(programGroup: IProgramGroup): string[] {
    if (programGroup && Utils.isNonEmptyArray(programGroup.default_programs)) {
      const programIds = programGroup.default_programs.map(defaultProgram => defaultProgram.program_id);
      return programIds;
    }

    return [];
  }

  getProgramsFromProgramGroup(programGroup: IProgramGroup): Program[] {
    if (programGroup && Utils.isNonEmptyArray(programGroup.default_programs)) {
      const allPrograms = this.programStateService.allProgramsForTenant.getValue();
      const programs = programGroup.default_programs.map(defaultProgram => {
        if (allPrograms.some(program => defaultProgram.program_id === program.copay_program_id)) {
          return allPrograms.find(program => defaultProgram.program_id === program.copay_program_id);
        }
      });
      return programs as Program[];
    }
    return [];
  }
}
