import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-input-group',
  template: `
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        [ngClass]="{ 'is-invalid': !formControl.valid && formControl.touched && !field.props?.disabled }"
        [formControl]="formControl"
        [attr.disabled]="field.props?.disabled ? true : null"
        [attr.readonly]="field.props?.readonly ? true : null"
        [autocomplete]="field.props?.autocomplete"
        [formlyAttributes]="field"
      />
      <button type="button" [ngClass]="field.props?.btnClass" [disabled]="field.props?.actionDisabled ?? false" (click)="onClick($event)">
        <i [ngClass]="field.props?.btnIcon"></i>
        <span>{{ field.props?.btnText ?? 'Missing text' }}</span>
      </button>
    </div>
  `,
})
export class FormlyFieldInputGroupComponent extends FieldType<FieldTypeConfig> {
  onClick(event: MouseEvent) {
    if (this.field.props?.onClick) {
      this.field.props.onClick(event);
    }
  }
}
