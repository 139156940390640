import gql from 'graphql-tag';

export const GetPharmacyPaymentInfoDownloadAttachmentUrlQuery = gql`
  query GetPharmacyPaymentInfoDownloadAttachmentUrl($ncpdp: String!, $attachmentName: String!) {
    pharmacy(ncpdp: $ncpdp) {
      paymentInfo {
        authorizationAttachmentUrls(forDownload: true, attachmentName: $attachmentName) {
          name
          url
        }
      }
    }
  }
`;
