import { Component, Input, ContentChildren, QueryList, ContentChild, TemplateRef } from '@angular/core';
import { CardSectionDirective } from '@shared/components/dark-card-with-rounded-borders/card-section/card-section.directive';
import { CardAsideDirective } from '@shared/components/dark-card-with-rounded-borders/card-aside/card-aside.directive';
import { CardHeaderDirective } from '@shared/components/dark-card-with-rounded-borders/card-header/card-header.directive';
import Utils from '@shared/providers/utils';

export type Modifier = 'padded-bottom' | 'no-padding' | 'gradient-bottom-to-top' | 'shadow';

@Component({
  selector: 'app-dark-card-with-rounded-borders',
  templateUrl: './dark-card-with-rounded-borders.component.html',
  styleUrls: ['./dark-card-with-rounded-borders.component.scss'],
})
export class DarkCardWithRoundedBordersComponent {
  @Input() headerText: string;
  @Input() set modifiers(modifiers: Modifier[]) {
    this.onModifiers(modifiers);
  }

  @ContentChild(CardAsideDirective, { read: TemplateRef, static: false }) cardAside: TemplateRef<CardAsideDirective>;
  @ContentChild(CardHeaderDirective, { read: TemplateRef, static: false }) cardHeader: TemplateRef<CardHeaderDirective>;
  @ContentChildren(CardSectionDirective) cardSections: QueryList<CardSectionDirective>;

  _modifiers: string;

  onModifiers(modifiers: Modifier[]): void {
    this._modifiers = Utils.getModifiedStringArrayAsString(modifiers, 'dark-card--', 'prepend');
  }
}
