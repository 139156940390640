import { Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'chitin-icon',
  templateUrl: './chitin-icon.component.html',
  styleUrls: ['./chitin-icon.component.scss'],
})
export class ChitinIconComponent {
  @Input()
  icon: string;

  @Input()
  styles?: NgStyle;

  @Input()
  clickable?: boolean;
}
