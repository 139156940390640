import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TimelineStep } from '@shared/components/timeline/timeline-step';

interface TimelineComponentStep extends TimelineStep {
  icon: string;
}

@Component({
  selector: 'app-shared-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements AfterViewInit, OnDestroy {
  @Input() showDuration = true;
  @ViewChildren('row')
  rows: QueryList<ElementRef<HTMLElement>>;
  lineBottom = '0px';
  lineBottomActive = 'calc(100% - 0px)';
  timelineSteps: TimelineComponentStep[] = [];
  private $rowsChanges: Subscription;

  constructor(private changeDetector: ChangeDetectorRef) {}

  get steps(): TimelineStep[] {
    return this.timelineSteps;
  }

  @Input()
  set steps(value: TimelineStep[]) {
    this.timelineSteps = value.map(step => {
      return {
        ...step,
        icon: step.isDone ? 'check' : 'close',
      };
    });
  }

  ngAfterViewInit() {
    this.$rowsChanges = this.rows.changes.subscribe(() => this.calculate());
    setTimeout(() => {
      this.calculate();
    });
  }

  ngOnDestroy() {
    if (this.$rowsChanges) {
      this.$rowsChanges.unsubscribe();
    }
  }

  private calculate() {
    const rowElements = this.rows.toArray().map(item => item.nativeElement);

    const lastStepRow = rowElements[rowElements.length - 1];
    if (lastStepRow) {
      this.lineBottom = `calc(100% - ${lastStepRow.offsetTop}px)`;
    }

    const finalStepIndex = this.steps.findIndex(item => item.isLastDone) || 0;
    const finalStepRow = rowElements[finalStepIndex];
    if (finalStepRow) {
      this.lineBottomActive = `calc(100% - ${finalStepRow.offsetTop}px)`;
    }

    this.changeDetector.markForCheck();
  }
}
