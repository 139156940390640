import { Component, OnDestroy, OnInit } from '@angular/core';
import { WindowService } from '@services/window.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-almirall-seysara-terms-conditions',
  templateUrl: './almirall-seysara-terms-conditions.component.html',
  styleUrls: ['./almirall-seysara-terms-conditions.component.scss'],
})
export class AlmirallSeysaraTermsConditionsComponent implements OnInit, OnDestroy {
  isTablet = false;
  isMobile = false;

  unsubscribe = new Subject<void>();

  constructor(private windowService: WindowService) {}

  ngOnInit() {
    this.checkDeviceType();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private checkDeviceType(): void {
    this.windowService.isTablet.pipe(takeUntil(this.unsubscribe)).subscribe(isTablet => (this.isTablet = isTablet));

    this.windowService.isMobile.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => (this.isMobile = isMobile));
  }
}
