<div class="text-control" [ngClass]="_modifiers">
  <label *ngIf="label" [for]="id || randomId"> {{ label }} </label>
  <span *ngIf="tooltip && isTooltipLocationTop && !useTooltipIcon" class="text-control__tooltip"> {{ tooltip }} </span>
  <app-tooltip *ngIf="tooltipIconText && useTooltipIcon" [text]="tooltipIconText"> </app-tooltip>

  <div class="text-control__input-and-edit" [class.text-control__input-and-edit--text-value]="_displayAsText">
    <div
      class="text-control__input-container"
      [class.text-control__input-container--text-value]="_displayAsText"
      [style.maxWidth.%]="maxWidthPercent"
    >
      <span *ngIf="isCurrencyType" class="text-control__dollar-sign"> $ </span>

      <input
        #inputField
        *ngIf="!_displayAsText"
        [type]="_type"
        [minLength]="minLength || ' '"
        [maxLength]="maxLength || ' '"
        [ngModel]="_value"
        [id]="id || randomId"
        [disabled]="disabled || noValue || _doNotKnow"
        [placeholder]="placeholder"
        (blur)="blur.emit()"
        [min]="minValue"
        [max]="maxValue"
        (keyup.enter)="keyUpEnter.emit()"
        (input)="emitChange(inputField.value, $event)"
        class="form-control text-control__input"
        [class.form-control--currency]="isCurrencyType"
        [ngClass]="controlCssClass"
        data-cy="input"
      />

      <span *ngIf="_displayAsText && isPhoneType" class="text-control__input-value">
        {{ _doNotKnow ? "DON'T KNOW" : (_value | phone) }}{{ isPreferred ? '*' : '' }}
      </span>
      <span *ngIf="_displayAsText && !isPhoneType" class="text-control__input-value">
        {{ _doNotKnow ? "DON'T KNOW" : _value }}{{ isPreferred ? '*' : '' }}
      </span>

      <span *ngIf="clearable && _value" class="text-control__clear" data-cy="textControlClear" (click)="clearAndEmit()"> x </span>

      <app-loading-spinner [isLoading]="isLoading" modifier="small-and-relative"> </app-loading-spinner>
    </div>

    <div *ngIf="_editable && _displayAsText && !_doNotKnow" class="text-control__edit-container">
      <img class="text-control__edit-icon" src="assets/icons/edit.svg" (click)="_displayAsText = false" />
      <span class="text-control__edit-text" (click)="_displayAsText = false"> Edit </span>
    </div>

    <div *ngIf="_allowNone" class="text-control__allow-none-container">
      <app-square-checkmark [(value)]="noValue" [label]="allowNoneText" (valueChange)="onAllowNoneClick()" [modifiers]="['rounded']">
      </app-square-checkmark>
    </div>

    <div *ngIf="showToolTipOnRight" class="text-control__tooltip-container">
      <span class="text-control__left-arrow"> </span>
      <span class="text-control__tooltip--pink"> {{ tooltip }} </span>
    </div>
  </div>

  <div *ngIf="includeDoNotKnow && !_displayAsText && !_doNotKnow" class="text-control__do-not-know-container">
    <span class="text-control__do-not-know" (click)="onDoNotKnow()"> Don't Know? </span>
  </div>

  <div *ngIf="tooltip && isTooltipLocationBottom" class="text-control__tooltip-container text-control__tooltip-container--bottom">
    <span class="text-control__tooltip text-control__tooltip--bottom"> {{ tooltip }} </span>
  </div>
</div>
