import gql from 'graphql-tag';

export const CreatePharmacyPaymentInfoMutation = gql`
  mutation CreatePharmacyPaymentInfo($data: CreatePharmacyPaymentInfoInput!) {
    createPharmacyPaymentInfo(data: $data) {
      pharmacyNpi
      authorizationAttachmentUrls(forDownload: false) {
        name
        url
      }
    }
  }
`;
