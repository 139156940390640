import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { ApiBoolean } from '@shared/models/api-boolean.type';
import { IconPath } from '@shared/models/icon-path.enum';
import Utils from '@shared/providers/utils';

type Modifier = '' | 'rounded' | 'rounded-3' | 'w-20' | 'h-18' | 'margin-20' | 'shadow';

@Component({
  selector: 'app-square-checkmark',
  templateUrl: './square-checkmark.component.html',
  styleUrls: ['./square-checkmark.component.scss'],
})
export class SquareCheckmarkComponent implements OnInit {
  @Input() set value(value: undefined | boolean | ApiBoolean) {
    this.onSetValue(value);
  }
  @Input() useApiBooleanType = false;
  @Input() label = '';
  @Input() id = `square-checkmark-${Math.random()}`;
  @Input() disabled = false;
  @Input() set modifiers(modifiers: Modifier[]) {
    this.onModifiers(modifiers);
  }

  @Output() valueChange = new EventEmitter<any>();

  isTokenLogin = false;

  IconPath = IconPath;

  _value = false;
  _modifiers: string;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.isTokenLogin = this.authService.isTokenizedLogin();
  }

  onModifiers(modifiers: Modifier[]): void {
    this._modifiers = Utils.getModifiedStringArrayAsString(modifiers, 'square-checkbox--', 'prepend');
  }

  emitChange(): void {
    const valueToEmit = this.useApiBooleanType ? Utils.convertBoolToApiBool(this._value) : Utils.castToBool(this._value);
    this.valueChange.emit(valueToEmit);
  }

  toggleValue(): void {
    this.value = !this.value;
  }

  onSetValue(newValue: undefined | boolean | ApiBoolean): void {
    this._value = Utils.castToBool(newValue);
  }
}
