import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChange, ViewChild } from '@angular/core';
import { NgChanges } from '@shared/ng-changes';
import { Chart } from 'chart.js/auto';
import { ChartConfiguration } from 'chart.js/dist/types';
import chartTrendline from 'chartjs-plugin-trendline';

@Component({
  selector: 'shared-charts-base',
  templateUrl: './charts-base.component.html',
  styleUrls: ['./charts-base.component.scss'],
})
export class ChartsBaseComponent implements OnChanges, AfterViewInit {
  @ViewChild('container')
  container: ElementRef<HTMLCanvasElement>;
  @Input()
  config?: ChartConfiguration;
  @Input()
  customLegendColours?: string[];
  public chart?: Chart;

  public constructor() {
    Chart.register(...[chartTrendline]);
  }

  ngAfterViewInit() {
    if (this.config) {
      this.createChart(this.config);
    }
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.config && this.container) {
      this.createChart(changes.config.currentValue);
    }
  }

  createChart(config: ChartConfiguration) {
    this.chart?.destroy();
    this.chart = new Chart(this.container.nativeElement, config);
  }

  datasetVisibilityToggle(labelId: number) {
    if (!this.chart) return;
    this.chart.setDatasetVisibility(labelId, this.chart.getDatasetMeta(labelId).hidden);
    this.chart.update();
  }
}
