import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements AfterViewInit {
  @ViewChild('overflowScrollWrapper') overflowScrollWrapper: ElementRef;
  moveRightBtnVisible = false;
  moveLeftBtnVisible = false;

  ngAfterViewInit() {
    setTimeout(() => {
      this.onNavScrolled();
    });
  }

  onNavClicked(direction: 'right' | 'left') {
    const scrollValue = (this.overflowScrollWrapper.nativeElement.firstElementChild.offsetWidth + 16) * (direction === 'right' ? 1 : -1);
    this.overflowScrollWrapper.nativeElement.scrollBy({
      left: scrollValue,
      behaviour: 'smooth',
    });
  }

  onNavScrolled() {
    const target = this.overflowScrollWrapper.nativeElement;
    if (this.moveRightBtnVisible && target.scrollLeft >= target.scrollWidth - target.offsetWidth) {
      this.moveRightBtnVisible = false;
    } else if (!this.moveRightBtnVisible && (target.scrollLeft < target.scrollWidth - target.offsetWidth || target.scrollWidth === 0)) {
      this.moveRightBtnVisible = true;
    }
    if (!this.moveLeftBtnVisible && target.scrollLeft > 10) {
      this.moveLeftBtnVisible = true;
    } else if (this.moveLeftBtnVisible && target.scrollLeft <= 10) {
      this.moveLeftBtnVisible = false;
    }
  }
}
