<div class="modal-header">
  <h3 class="modal-title">Confirmation Dialog</h3>
</div>

<div class="modal-body" data-cy="confirmationDialog">
  <h5 ngbAutofocus class="text-center">{{ question }}</h5>
</div>

<div class="modal-footer">
  <div class="row">
    <div class="col text-right">
      <chitin-button (clicked)="yes()" [outline]="true" label="Yes" data-cy="confirmationDialogYesBtn" />
    </div>
    <div class="col">
      <chitin-button (clicked)="no()" [outline]="true" label="No" data-cy="confirmationDialogNoBtn" />
    </div>
  </div>
</div>
