import { Component, Output, EventEmitter, Input } from '@angular/core';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { PharmacyApiService } from '@services/pharmacy-api.service';
import { finalize } from 'rxjs/operators';
import { TenantIdType } from '@shared/models/tenant-id.type';

export interface PharmacyAndNcpdp {
  pharmacy: IPharmacy;
  ncpdp: string;
}

@Component({
  selector: 'app-add-pharmacy-from-ncpdp',
  templateUrl: './add-pharmacy-from-ncpdp.component.html',
  styleUrls: ['./add-pharmacy-from-ncpdp.component.scss'],
})
export class AddPharmacyFromNcpdpComponent {
  @Input() whiteBackground = false;
  @Input() tenants: TenantIdType[] = [];
  @Output() pharmacyAdded = new EventEmitter<PharmacyAndNcpdp>();

  ncpdp: string;
  isLoading = false;

  focusNcpdpField = true;

  constructor(private pharmacyApiService: PharmacyApiService) {}

  findPharmacy(): void {
    if (!this.ncpdp?.trim()) {
      return;
    }
    this.isLoading = true;
    this.focusNcpdpField = false;
    this.getPharmacy()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(pharmacy => {
        if (pharmacy) {
          this.pharmacyAdded.emit({
            pharmacy: pharmacy,
            ncpdp: this.ncpdp.trim(),
          });
          this.reset();
        } else {
          this.pharmacyAdded.emit({
            pharmacy: undefined as any,
            ncpdp: this.ncpdp.trim(),
          });
        }
      });
  }

  private getPharmacy() {
    return this.pharmacyApiService.getPharmacyByNcpdp(this.ncpdp.trim(), false);
  }

  private reset(): void {
    this.ncpdp = '';
    this.focusNcpdpField = true;
  }
}
