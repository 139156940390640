import { ManufacturerAboutSection } from '@shared/models/manufacturer-about-section.model';
import { Tenant } from '@shared/models/tenant.model';

export class Manufacturer {
  name: string;
  id: string;
  imageFileName?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  about?: ManufacturerAboutSection[];
  pharmacistInstructions?: string[];
  termsAndConditions?: string;

  readonly MARKDOWN_LINK_REGEX = /(\[[\w|.|@]+\]\([^\s]+\))/; // Example match: [Click Here](www.example.com)
  readonly MARKDOWN_LINK_TEXT_REGEX = /\[(.+)\]/; // Example match: [Click Here]
  readonly MARKDOWN_LINK_ADDRESS_REGEX = /\((.+)\)/; // Example match: (www.example.com)
  readonly MARKDOWN_LINK_TEXT_STARTING_CHARACTER = '[';

  constructor(name?: string, imageFileName?: string) {
    this.name = name ? name : '';
    if (imageFileName) {
      this.imageFileName = imageFileName;
    }
  }

  getCorrectedImageLocation(imageName: string): string {
    return '/assets/images/' + imageName;
  }

  setInfoFromTenant(tenant: Partial<Tenant>): void {
    if (!tenant) {
      return;
    }
    if (tenant.name) {
      this.name = tenant.name;
    }
    if (tenant.id) {
      this.id = tenant.id;
    }
  }

  private convertPlainTextToManufacturerAboutText(text: string): ManufacturerAboutSection[] {
    const aboutText: ManufacturerAboutSection[] = [];

    text.split(this.MARKDOWN_LINK_REGEX).forEach(match => {
      if (this.isRegexMatchALink(match)) {
        aboutText.push({
          type: 'link',
          content: this.getAboutTextLinkContent(match),
          link: this.getAboutTextLinkAddress(match),
        });
      } else {
        aboutText.push({
          type: 'text',
          content: match,
        });
      }
    });

    return aboutText;
  }

  private isRegexMatchALink(match: string): boolean {
    return match.startsWith(this.MARKDOWN_LINK_TEXT_STARTING_CHARACTER);
  }

  private getAboutTextLinkContent(link: string): string {
    return this.MARKDOWN_LINK_TEXT_REGEX.exec(link)?.[1] ?? '';
  }

  private getAboutTextLinkAddress(link: string): string {
    return this.MARKDOWN_LINK_ADDRESS_REGEX.exec(link)?.[1] ?? '';
  }
}
