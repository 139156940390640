import gql from 'graphql-tag';

export const UpdatePharmacyPaymentInfoMutation = gql`
  mutation UpdatePharmacyPaymentInfo($data: UpdatePharmacyPaymentInfoInput!) {
    updatePharmacyPaymentInfo(data: $data) {
      pharmacyNpi
      authorizationAttachmentUrls(forDownload: false) {
        name
        url
      }
    }
  }
`;
