import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-product-manufacturer-search',
  templateUrl: './product-manufacturer-search.component.html',
  styleUrls: ['./product-manufacturer-search.component.scss'],
})
export class ProductManufacturerSearchComponent {
  @Output() searched = new EventEmitter<void>();

  searchField: string;

  searchBtnClicked = false;

  constructor() {}

  search() {
    if (this.searchField && this.searchField.length > 0) {
      this.searchBtnClicked = true;
      this.searched.emit();
    }
  }

  updateSearch(newValue: string): void {
    this.searchField = newValue;
  }
}
