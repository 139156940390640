import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-button',
  template: ` <button type="button" [ngClass]="field.props?.btnClass" [disabled]="field.props?.disabled ?? false" (click)="onClick($event)">
    <i [ngClass]="field.props?.icon"></i>
    <span>{{ field.props?.text ?? 'Missing text' }}</span>
  </button>`,
})
export class FormlyFieldButtonComponent extends FieldType<FieldTypeConfig> {
  onClick(event: MouseEvent) {
    if (this.field.props?.onClick) {
      this.field.props.onClick(event, this.field);
    }
  }
}
