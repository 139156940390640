import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@shared/components/base-component';
import { View } from '@shared/components/brightview/brightview-types';
import { IUser } from '@shared/models/user.model';
import MurmurHash3 from 'imurmurhash';

@Component({
  selector: 'app-brightview-save-view',
  templateUrl: './brightview-save-view.component.html',
  styleUrls: ['./brightview-save-view.component.scss'],
})
export class BrightViewSaveViewComponent<T> extends BaseComponent {
  @Input() editedView: View.UserView<T> | View.DefaultView<T>;
  @Input() isViewEditable: boolean;
  @Input() existingLabels: string[];
  @Input() currentUser: IUser;
  _newViewLabel: any;
  set newViewLabel(label: string | null | undefined) {
    this._newViewLabel = label ?? undefined;
    this.testLabelValidity(label);
  }
  get newViewLabel() {
    return this._newViewLabel;
  }
  labelError?: string;
  newViewMode?: 'update' | 'create';

  constructor(protected activeModal: NgbActiveModal) {
    super();
  }

  changeViewMode(mode: 'update' | 'create' | undefined) {
    if (mode === 'update') {
      this.newViewLabel = this.editedView.label;
    } else {
      this.newViewLabel = undefined;
    }
    this.newViewMode = mode;
  }

  onUpdateClicked() {
    this.activeModal.close({
      ...this.editedView,
      label: this.newViewLabel,
      oldLabel: this.editedView.label,
      isCustom: true,
      isPinned: true,
      unsavedChanges: false,
      mode: 'update',
    });
  }

  testLabelValidity(label: string | null | undefined) {
    const isNotUndefined = !!label;
    const isUnique = !this.existingLabels.includes(label ?? '');
    if (this.newViewMode === 'create' && !isUnique) {
      this.labelError = 'This name is already used by another view. Please choose another one.';
    } else {
      this.labelError = undefined;
    }
    return isNotUndefined && (this.newViewMode === 'update' || isUnique);
  }

  onCreateClicked() {
    if (!this.testLabelValidity(this.newViewLabel)) {
      return;
    }
    this.activeModal.close({
      ...this.editedView,
      id: new MurmurHash3(`${this.newViewLabel}_${this.currentUser}}`).result(),
      label: this.newViewLabel,
      oldLabel: this.editedView.label,
      isCustom: true,
      isPinned: true,
      unsavedChanges: false,
      mode: 'create',
      basedOnViewId: this.editedView.id,
    });
  }
}
