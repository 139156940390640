import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNaN } from 'lodash';
import { Observable, Subscription } from 'rxjs';

export type NumberRange = {
  from?: number;
  to?: number;
};

@Component({
  selector: 'chitin-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => ChitinRangeInputComponent) }],
})
export class ChitinRangeInputComponent {
  items: any[] = [];
  isLoading = false;
  fieldOptions?: any[] | Observable<any[]>;
  fieldSubscription?: Subscription;
  optionsSubscription?: Subscription;
  private onChange?: Function;
  private onTouched?: Function;

  @Input()
  inputId?: string;
  @Input()
  name?: string;
  @Input()
  isDisabled: boolean = false;
  @Input()
  isMinDisabled: boolean = false;
  @Input()
  isMaxDisabled: boolean = false;
  @Input()
  readonly: boolean = false;
  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';
  @Input()
  mode: 'compact' | 'fullsize' = 'compact';
  @Input()
  prefix?: string;
  @Input()
  suffix?: string;
  @Input()
  placeholder?: { from: string; to: string };
  @Input()
  value: NumberRange = { from: undefined, to: undefined };
  @Input()
  clearable?: boolean;

  @Output()
  valueChange = new EventEmitter<NumberRange>();

  @ViewChild('inputFieldToFocus')
  private inputFieldToFocus: { focus: () => {} };

  focus() {
    setTimeout(() => {
      this.inputFieldToFocus.focus();
    });
  }

  onFromChange(from?: unknown) {
    if (isNaN(from)) return;
    if (typeof from !== 'number') {
      from = Number(from);
    }
    this.value = {
      ...this.value,
      from: from ? (from as number) : undefined,
    };
    this.onValuesChanged();
  }
  onToChange(to?: unknown) {
    if (isNaN(to)) return;
    if (typeof to !== 'number') {
      to = Number(to);
    }
    this.value = {
      ...this.value,
      to: to ? (to as number) : undefined,
    };
    this.onValuesChanged();
  }
  private onValuesChanged() {
    this.valueChange.next(this.value);
    if (this.onChange) this.onChange(this.value);
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(value: NumberRange) {
    this.value = value;
  }

  onBlur() {
    this.onTouched ? this.onTouched() : null;
  }
}
