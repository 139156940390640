import gql from 'graphql-tag';

export const GetAllProductsQuery = gql`
  query GetAllProducts($tenantIds: [String!]!) {
    tenants(ids: $tenantIds) {
      id
      products {
        edges {
          cursor
          node {
            _id
            brand
            dosage
            howSupplied
            name
            ndc
            ndc9
            wac
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`;
