export const REJECT_REASONS: Record<string, string> = {
  'Unknown error': '000',
  'Invalid data': '001',
  'Invalid data type': '002',
  'Invalid data format': '003',

  // configuration errors
  'Invalid NDC': '010',
  'Missing pharmacy configuration': '011',
  'Missing PCN and group configuration': '012',
  'Missing PCN and group': '013',
  'Missing accepted OCC settings': '014',
  'Invalid PCN and group configuration': '015',
  'Invalid NDC access': '016',

  // required fields
  'Missing required field': '020',
  'Missing group': '021',
  'Missing PCN': '022',
  'Missing BIN': '023',
  'Missing copay amount': '024',
  'OCC3 missing reject code': '025',
  'OCC8 missing responsibility amount': '026',

  // common errors
  'Invalid date of service': '050',
  'Exceeds daily limit': '051',
  'Exceeds weekly limit': '052',
  'Invalid OCC': '053',
  'Invalid BIN format': '054',
  'Same PCN and BIN': '055',
  'Same PCN and patient name': '056',
  'Same group and patient name': '057',

  // consignment matching errors
  'Unauthorized consignment matching': '070',
  'Unknown consignment ID': '061',
  'Invalid consignment ID': '062',
  'Invalid consignment ID for NDC': '063',
  'Invalid consignment ID for RX': '064',

  // workflow errors
  'Resubmission required': '100',
  'Invalid insurance': '111',
  'Ineligible reject code': '112',
  'Copay over limit': '113',
  'Days supplied out of range': '114',
  'Pharmacy state excluded': '115',
  'Patient state excluded': '116',
  'Quantity dispensed out of range': '117',
  'Ineligible age': '118',
};
