import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-business-rules-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  question = 'Are you sure?';

  constructor(public modal: NgbActiveModal) {}

  yes() {
    this.modal.close(true);
  }

  no() {
    this.modal.close(false);
  }
}
