import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneWithSpaces',
})
export class PhoneWithSpacesPipe implements PipeTransform {
  transform(value = ''): any {
    value = value.trim().replace(/\D/g, '');

    let formattedNumber = value.toString();

    if (!value || formattedNumber.length > 10 || formattedNumber.length < 7) {
      return value;
    }

    if (value.length === 10) {
      formattedNumber = value.substr(0, 3) + ' ' + value.substr(3, 3) + ' ' + value.substr(6, 4);
    } else if (value.length === 7) {
      formattedNumber = value.substr(3, 3) + ' ' + value.substr(6, 4);
    }

    return formattedNumber;
  }
}
