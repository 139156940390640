import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateService } from '@services/date.service';
import { UrlService } from '@services/url.service';
import { EtlMapping } from '@shared/models/etl-mapping.model';
import { EtlRoute } from '@shared/models/etl-route.model';
import { EtlSearchTable } from '@shared/models/etl-search-table.model';
import { EtlValidationRule } from '@shared/models/etl-validation-rule.model';
import { EtlWorkflow } from '@shared/models/etl-workflow.model';
import { InventoryFilesResponse } from '@shared/models/etl/inventory-files.response';
import { LoadSequenceScorecardResponse } from '@shared/models/etl/load-sequence-scorecard.response';
import { FtpAccount } from '@shared/models/ftp-account.model';
import { LoadSequence } from '@shared/models/load-sequence.model';
import { ScheduleModel } from '@shared/models/schedule-model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { FromTo } from 'moment';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';

type UrlType =
  | 'routes'
  | 'mappings'
  | 'validation_rules'
  | 'get_sequence'
  | 'get_scorecard'
  | 'search_sequence'
  | 'errors'
  | 'workflows'
  | 'etlSearch'
  | 'search_schedules'
  | 'inventory';
type SearchSequenceOptions = 'filename' | 'sender' | 'FROM_created' | 'TO_created' | 'offset_from_utc';

const DEFAULT_ROUTE_FILTER = {
  partner_type: 'pharmacy',
  IS_IN_record_type: ['Dispense File', 'Consignment File'],
};

@Injectable({
  providedIn: 'root',
})
export class EtlService {
  URLS: Record<UrlType, string> = {
    routes: 'routes/{tenant}',
    mappings: 'mappings/{tenant}',
    validation_rules: 'validation_rules/{tenant}',
    get_sequence: 'load_sequences/{tenant}',
    get_scorecard: 'load_sequences/{tenant}/scorecard',
    search_sequence: 'load_sequences/{tenant}/search',
    errors: 'load_sequences/{tenant}/errors',
    workflows: 'workflows/{tenant}',
    etlSearch: 'etl_search/{tenant}',
    search_schedules: 'schedules/{tenant}',
    inventory: 'inventory/{tenant}/scorecard',
  };

  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private dateService: DateService,
  ) {}

  private getUrl(urlType: UrlType): string {
    const baseAddr = this.urlService.ETL_URL;
    return baseAddr + this.URLS[urlType];
  }

  etlSearch(table: EtlSearchTable, options: Record<string, any> = {}, tenantId?: TenantIdType): Observable<ScheduleModel[]> {
    let url = this.getUrl('etlSearch');
    if (tenantId) {
      url = url.replace('{tenant}', tenantId);
    }
    return this.http.post<ScheduleModel[]>(`${url}/${table}`, options);
  }

  searchSchedules(search: Partial<ScheduleModel>, tenantId?: TenantIdType): Observable<any> {
    let url = this.getUrl('search_schedules');
    if (tenantId) {
      url = url.replace('{tenant}', tenantId);
    }
    return this.http.post(url, search);
  }

  getRoute(route_id: string, tenantId?: TenantIdType): Observable<EtlRoute> {
    let url = this.getUrl('routes');
    if (tenantId) {
      url = url.replace('{tenant}', tenantId);
    }

    return this.http.get<EtlRoute>(`${url}/${route_id}`);
  }

  getRoutes(tenantId: TenantIdType): Observable<EtlRoute[]> {
    let url = this.getUrl('routes');
    if (tenantId) {
      url = url.replace('{tenant}', tenantId);
    }
    return this.http.post<EtlRoute[]>(url, {});
  }

  saveRoute(route: EtlRoute): Observable<any> {
    route['partner-action-record_type'] = route.partner + '-' + route.partner_action + '-' + route.record_type;
    return this.http.put(this.getUrl('routes'), route);
  }

  getValidationRules(): Observable<EtlValidationRule[]> {
    return this.http.post<EtlValidationRule[]>(this.getUrl('validation_rules'), {});
  }

  saveValidationRule(rule: EtlValidationRule): Observable<any> {
    return this.http.put(this.getUrl('validation_rules'), rule);
  }

  getMaps(): Observable<EtlMapping[]> {
    return this.http.post<EtlMapping[]>(this.getUrl('mappings'), {});
  }

  saveMap(map: EtlMapping): Observable<any> {
    return this.http.put(this.getUrl('mappings'), map);
  }

  getLoadSequences(): Observable<LoadSequence[]> {
    return this.http.get<LoadSequence[]>(this.getUrl('get_sequence'));
  }

  getActiveErrors(): Observable<LoadSequence[]> {
    return this.http.get<LoadSequence[]>(this.getUrl('errors'));
  }

  getLoadSequenceScorecard(
    loadSequenceFilter: Record<string, any>,
    routeFilter: Record<string, any> = DEFAULT_ROUTE_FILTER,
  ): Observable<LoadSequenceScorecardResponse> {
    const search_criteria: Record<string, any> = {};
    search_criteria['offset_from_utc'] = moment().utcOffset();
    search_criteria['load_sequence_search_criteria'] = loadSequenceFilter;
    search_criteria['route_search_criteria'] = routeFilter;
    return this.http.post<any>(this.getUrl('get_scorecard'), JSON.stringify(search_criteria));
  }

  searchSequences(options: { [index in SearchSequenceOptions]: string }, tenantId?: TenantIdType): Observable<LoadSequence[]> {
    if ('FROM_created' in options || 'TO_created' in options) {
      options['offset_from_utc'] = this.dateService.getUtcOffset();
    }

    let url = this.getUrl('search_sequence');
    if (tenantId) {
      url = url.replace('{tenant}', tenantId);
    }

    return this.http.post<any>(url, options);
  }

  getWorkflows(): Observable<EtlWorkflow[]> {
    return this.http.post<EtlWorkflow[]>(this.getUrl('workflows'), {});
  }

  saveWorkflow(workflow: EtlWorkflow): Observable<any> {
    const workflowCopy = this.cleanWorkflowData(workflow);

    return this.http.put(this.getUrl('workflows'), workflowCopy);
  }

  private cleanWorkflowData(workflow: EtlWorkflow): EtlWorkflow {
    const workflowCopy: Partial<EtlWorkflow> = new EtlWorkflow(workflow);

    if (workflowCopy.parameters === '') {
      delete workflowCopy.parameters;
    } else {
      workflowCopy.parameters = JSON.stringify(workflowCopy.parameters);
    }

    return workflowCopy as EtlWorkflow;
  }

  getFtpAccounts(): Observable<FtpAccount[]> {
    return this.http.post<FtpAccount[]>(this.urlService.FTP_ACCOUNTS_URL, {});
  }

  saveFtpAccount(account: FtpAccount): Observable<any> {
    return this.http.put(this.urlService.FTP_ACCOUNTS_URL, account);
  }

  getInventoryFiles(week: FromTo): Observable<InventoryFilesResponse> {
    return this.http.post<InventoryFilesResponse>(this.getUrl('inventory'), week);
  }
}
