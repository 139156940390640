<div [ngClass]="{ 'row align-items-center': layout === 'inline' }">
  <label *ngIf="label" [attr.for]="inputId" class="form-label" [ngClass]="{ 'col-form-label col-auto text-sm-right': layout === 'inline' }">
    {{ label }}
  </label>
  <div [ngClass]="{ 'col-auto': layout === 'inline' && label }">
    <ng-select
      #select
      data-cy="chitinSelect"
      [ngClass]="{
        'form-control chitin-select': displayType === 'input',
        disabled: isDisabled,
        'btn btn-secondary buttonSelect': displayType === 'button',
        noArrow: alwaysOpen,
      }"
      [class]="(displayType === 'button' ? 'btn-' : 'form-control-') + size"
      [(ngModel)]="selectedItems"
      [items]="options"
      [bindLabel]="displayExpr"
      [bindValue]="valueExpr"
      [appendTo]="displayType === 'button' ? null : $any(appendTo)"
      [multiple]="multiple"
      (change)="emitValueChange($event)"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [disabled]="isDisabled || isLoading"
      [labelForId]="inputId"
      [searchable]="searchable === 'smart' ? optionsCount >= 3 : searchable"
      [clearable]="displayType === 'button' ? false : clearable"
      [closeOnSelect]="!multiple"
      [loading]="isLoading"
      [groupBy]="groupItems ? 'group' : $any(undefined)"
      [selectableGroup]="groupItems && multiple ? true : $any(undefined)"
      [selectableGroupAsModel]="groupItems && multiple ? false : $any(undefined)"
      [maxSelectedItems]="maxNumOfOptions ?? $any(undefined)"
      [dropdownPosition]="dropdownPosition"
      (open)="onOpen(select)"
      (close)="onClose()"
      [isOpen]="alwaysOpen ? true : $any(undefined)"
      [keyDownFn]="onKeyDown"
      [compareWith]="compareFn ? compareFn : $any(undefined)"
    >
      <ng-template *ngIf="multiple && (displayNumberOfSelected || selectionDisplay)" ng-multi-label-tmp let-items="items">
        <ng-container *ngIf="!displayNumberOfSelected && selectionDisplay">
          <div>{{ selectionDisplay }}</div>
        </ng-container>
        <ng-container *ngIf="displayNumberOfSelected">
          <div class="ng-value" *ngIf="items.length">
            <span class="ng-value-label">{{ items.length }} selected</span>
          </div>
        </ng-container>
      </ng-template>
      <ng-template *ngIf="valueType !== 'item'" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <ng-container *ngIf="multiple && showSelectAll && index === 0">
          <ng-template [ngTemplateOutlet]="massSelectButtons" />
        </ng-container>
        <ng-container *ngIf="!item.group || groupsExpanded[item.group]; else itemHidden">
          <input
            id="item-{{ index }}"
            *ngIf="checkboxView && item.value !== SELECT_ALL_VALUE"
            type="checkbox"
            [ngModel]="item.value === SELECT_ALL_VALUE ? false : item$.selected"
            [ngClass]="{ 'ms-3': item.group }"
            style="pointer-events: none"
          />
          <span class="ms-2" [style]="item.description ? 'font-size: 0.85rem' : ''">{{ item[displayExpr] }}</span>
          <div
            *ngIf="item.description"
            class="ms-4 pe-4"
            style="max-width: 100%; white-space: normal !important; opacity: 0.7; font-size: 0.75rem"
          >
            {{ item.description }}
          </div>
          @if (item.badge) {
            <span class="badge badge-pill rounded ms-2" [ngClass]="item.badgeClass">{{ item.badge }}</span>
          }
        </ng-container>
        <ng-template #itemHidden>
          <div class="itemHidden"></div>
        </ng-template>
      </ng-template>
      <ng-template *ngIf="checkboxView" ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <ng-container *ngIf="multiple && showSelectAll && index === 0">
          <ng-template [ngTemplateOutlet]="massSelectButtons" />
        </ng-container>
        <chitin-icon
          [icon]="'fa-solid ' + (groupsExpanded[item.group] ? 'fa-chevron-down' : 'fa-chevron-right')"
          class="px-2"
          style="margin-left: -0.5rem"
          (click)="onItemExpanded(item, $event)"
        />
        <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" style="pointer-events: none" /> {{ item.group }}
      </ng-template>
      <ng-template *ngIf="!checkboxView" ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <ng-container *ngIf="multiple && showSelectAll && index === 0">
          <ng-template [ngTemplateOutlet]="massSelectButtons" />
        </ng-container>
        <div (click)="onItemExpanded(item, $event)">
          <chitin-icon
            [icon]="'fa-solid ' + (groupsExpanded[item.group] ? 'fa-chevron-down' : 'fa-chevron-right')"
            class="px-2"
            style="margin-left: -0.5rem"
          />
          <span>{{ item.group }}</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>

<ng-template #massSelectButtons>
  <div class="massSelectButtons">
    <a (click)="onSelectAllClicked(false, $event)">Select all</a>
    <a (click)="onSelectAllClicked(true, $event)">Deselect all</a>
  </div>
</ng-template>
