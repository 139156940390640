import gql from 'graphql-tag';

export const GetPharmacyForTerminalQuery = gql`
  query GetPharmacyForTerminal($ncpdp: String!) {
    pharmacy(ncpdp: $ncpdp) {
      name
      npi
      ncpdp
      address {
        city
        state
        zip
        lineOne
        lineTwo
      }
      contact {
        phoneNumber
      }
      enrollments {
        engagements {
          priorAuthorization {
            isBrightclockEnabled
          }
        }
      }
    }
  }
`;
