import { Component, Input } from '@angular/core';

@Component({
  selector: 'chitin-spinner',
  templateUrl: './chitin-spinner.component.html',
  styleUrls: ['./chitin-spinner.component.scss'],
})
export class ChitinSpinnerComponent {
  @Input()
  size: 'small' | 'default' = 'default';

  @Input()
  isLoading: boolean = false;
}
