<div class="modal-header">
  <p class="main-scrn-title elementCenter pt-15" id="modal-basic-title" data-cy="signOutTitle">Sign out?</p>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()" data-cy="close"></button>
</div>
<div class="modal-body pb-3rem">
  <div class="pt-2 spaced-buttons" style="margin-top: 15px; text-align: center">
    <button type="button" class="button button--default" (click)="modal.close()">CANCEL</button>
    <button type="submit" class="button button--primary" (click)="onLogout()">SIGN OUT</button>
  </div>
</div>
