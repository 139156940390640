import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-horizontal-scroll',
  standalone: true,
  templateUrl: './horizontal-scroll.component.html',
})
export class HorizontalScrollComponent implements OnDestroy {
  scrollInterval?: unknown;

  ngOnDestroy(): void {
    this.stopScroll();
  }

  startScroll(filtersEl: HTMLElement, scrollUp: boolean) {
    this.scrollInterval = setInterval(() => {
      scrollUp ? (filtersEl.scrollLeft += 25) : (filtersEl.scrollLeft -= 25);
    }, 50);
  }

  stopScroll() {
    this.scrollInterval && clearInterval(this.scrollInterval as number);
  }
}
