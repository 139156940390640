<div class="dropdown-toggle">
  <div class="dropdown-toggle__label-caret-container" (click)="toggle()">
    <label class="dropdown-toggle__label"> {{ activeOptionAsLabel ? activeOptionLabel : label }} </label>

    <app-up-down-arrow [up]="isToggled"> </app-up-down-arrow>
  </div>

  <div *ngIf="isToggled" class="dropdown-toggle__content">
    <ng-content> </ng-content>
  </div>
</div>
