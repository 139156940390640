<div class="app-table__pagination-container">
  <div class="app-table__number-of-items-being-shown" data-cy="consignmentNumberPaginator">
    <ng-content></ng-content>
    <span *ngIf="totalItems && totalItems > totalLimit">
      Retrieved {{ totalItems | number }} items. Listing the first {{ totalLimit | number }} |
    </span>
    <span *ngIf="length > 0">Showing {{ startIndex + 1 }} to {{ endIndex }} of {{ length | number }}</span>
    <span *ngIf="length < 1">Showing 0</span>
  </div>

  <div class="app-table__pagination-controls-container">
    <div class="app-table__rows-per-page">
      <div class="select-control select-control--horizontal">
        <span class="select-control__label select-control__label--horizontal">Rows per page:</span>
        <div class="select-control__control-container select-control__control-container--horizontal">
          <ng-select
            class="select-control__control ng-select-transparent-background"
            data-cy="rowsPerPageSelect"
            dropdownPosition="top"
            [(ngModel)]="pageSize"
            [items]="pageSizeOptions"
            [clearable]="false"
            [searchable]="false"
            [disabled]="loading"
            (change)="onPageSizeChange($event)"
          >
          </ng-select>
        </div>
      </div>
    </div>

    <div class="app-table__pagination-controls">
      <ngb-pagination
        [class]="'ngb-pagination-' + color"
        [disabled]="loading"
        [collectionSize]="length"
        [page]="pageIndex + 1"
        [pageSize]="pageSize"
        [maxSize]="5"
        (pageChange)="goToPage($event)"
      ></ngb-pagination>
    </div>
  </div>
</div>
