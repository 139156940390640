export enum UserRole {
  ProgramAdminALM = 'ProgramAdminALM',
  Patient = 'Patient',
  Administrator = 'Administrator',
  ProgramAdminRead = 'ProgramAdminRead',
  ApiRetailPharmacy = 'ApiRetailPharmacy',
  RetailPharmacy = 'RetailPharmacy',
  PharmacyOwner = 'PharmacyOwner',
  PaAdmin = 'PaAdmin',
  DemoUser = 'DemoUser',
  FieldAdmin = 'FieldAdmin',
  Agent = 'Agent',
  ProgramAdmin = 'ProgramAdmin',
  ApiPharmacy = 'ApiPharmacy',
  DataScienceUser = 'DataScienceUser',
  Kiosk = 'Kiosk',
}
