<div class="verify-account">
  <div class="loader spin-me" *ngIf="isLoading"></div>

  <div class="verify-account__header">
    <h2 class="verify-account__header-text" data-cy="verifyAccountTitle">Please verify your account information</h2>

    <span class="verify-account__exit" (click)="closePopup()" data-cy="verifyAccExit"> &times; </span>
  </div>

  <div *ngIf="errorMessage" class="verify-account__error-container">
    <p class="message-red my-0">{{ errorMessage }}</p>
  </div>

  <div class="verify-account__content">
    <div class="verify-account__field-container">
      <label class="verify-account__field-label" for="firstNameField"> FIRST NAME: </label>
      <input
        type="text"
        name="firstNameField"
        [(ngModel)]="currentUser.first_name"
        class="form-control verify-account__field test-first-name-edit-input"
        [disabled]="isFirstNameFieldDisabled"
      />
      <span class="verify-account__edit-btn test-first-name-edit-btn" (click)="toggleFirstNameFieldDisable()">
        Edit
        <img class="verify-account__edit-icon" src="assets/icons/edit.png" />
      </span>
    </div>

    <div class="verify-account__field-container">
      <label class="verify-account__field-label" for="lastNameField"> LAST NAME: </label>
      <input
        type="text"
        name="lastNameField"
        [(ngModel)]="currentUser.last_name"
        class="form-control verify-account__field test-last-name-edit-input"
        [disabled]="isLastNameFieldDisabled"
      />
      <span class="verify-account__edit-btn test-last-name-edit-btn" (click)="toggleLastNameFieldDisable()">
        Edit
        <img class="verify-account__edit-icon" src="assets/icons/edit.png" />
      </span>
    </div>

    <div class="verify-account__field-container">
      <label class="verify-account__field-label"> EMAIL: </label>
      <input type="text" name="fname" [(ngModel)]="currentUser.email" class="form-control verify-account__field" disabled />
    </div>
  </div>

  <div class="verify-account__confirm-container">
    <button type="button" class="button button--primary" (click)="confirm()">CONFIRM</button>
  </div>
</div>
s
