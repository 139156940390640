import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'app-square-radio-button',
  templateUrl: './square-radio-button.component.html',
  styleUrls: ['./square-radio-button.component.scss'],
})
export class SquareRadioButtonComponent implements OnInit, OnChanges {
  @Input() squareCheckmarkModel: any;
  @Input() value: any;
  @Input() name: string;
  @Input() disabled = false;

  @Output() squareCheckmarkModelChange = new EventEmitter<any>();

  id = `square-checkmark-${Math.random()}`;

  isChecked = false;

  constructor() {}

  ngOnInit() {
    this.checkIsChecked();
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes && changes['squareCheckmarkModel']) {
      this.checkIsChecked();
    }
  }

  checkIsChecked(): void {
    this.isChecked = this.squareCheckmarkModel === this.value;
  }

  emitChange(resetModel = false): void {
    if (resetModel) {
      this.squareCheckmarkModelChange.emit(undefined);
    } else {
      this.squareCheckmarkModelChange.emit(this.value);
    }
  }

  onContentClicked(): void {
    if (!this.disabled) {
      this.emitChange();
    }
  }
}
