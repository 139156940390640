import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from '@shared/models/user-role.model';

const roleToString: Record<UserRole, string> = {
  ProgramAdminALM: 'Program Admin ALM',
  Patient: 'Patient',
  Administrator: 'Administrator',
  ProgramAdminRead: 'Program Admin Read',
  ApiRetailPharmacy: 'API Retail Pharmacy',
  RetailPharmacy: 'Retail Pharmacy',
  PharmacyOwner: 'Pharmacy Owner',
  PaAdmin: 'PA Admin',
  DemoUser: 'Demo User',
  FieldAdmin: 'Field Admin',
  Agent: 'Agent',
  ProgramAdmin: 'Program Admin',
  ApiPharmacy: 'API Pharmacy',
  DataScienceUser: 'Data Science User',
  Kiosk: 'Kiosk User',
};

@Pipe({
  name: 'userRole',
})
export class UserRolePipe implements PipeTransform {
  transform(value: UserRole | string = ''): string {
    if (value in roleToString) {
      return roleToString[value as keyof typeof roleToString];
    }
    console.error(`Unknown role ${value}`);
    return value;
  }
}
