import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const EXPORT_LIFETIME_IN_MS = 5 * 60 * 1000;

@Component({
  selector: 'app-export-result-modal',
  templateUrl: './export-result-modal.component.html',
})
export class ExportResultModalComponent extends BaseComponent implements OnInit {
  data: { fileUrl: string };
  exportFailed: boolean = false;
  exportExpired: boolean = false;

  constructor(protected activeModal: NgbActiveModal) {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      this.exportExpired = true;
    }, EXPORT_LIFETIME_IN_MS);
  }
}
