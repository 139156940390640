import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import moment from 'moment/moment';
import { MomentInput } from 'moment';
import { ChitinButtonComponent } from '@shared/components/chitin/atoms/button/button.component';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'chitin-button-date-picker',
  templateUrl: './button-date-picker.component.html',
  styleUrls: ['./button-date-picker.component.scss'],
})
export class ButtonDatePickerComponent<TValue> extends ChitinButtonComponent implements OnChanges {
  @Input()
  label = 'Select date';

  @Input()
  value: TValue;

  @Input()
  maxDate?: Date;

  @Input()
  minDate?: Date;

  @Input()
  color: 'primary' | 'danger' | 'secondary' = 'primary';

  @Output()
  valueChange = new EventEmitter<TValue>();

  displayLabel?: string;

  onValueChange(value: TValue) {
    this.emitChange(value);
    this.updateDisplayLabel();
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.value) {
      this.updateDisplayLabel();
    }
  }

  private emitChange(value: TValue) {
    this.valueChange.next(value);
  }

  private updateDisplayLabel() {
    const isToday = moment(this.value as MomentInput).isSame(moment(), 'day');

    this.displayLabel = isToday ? 'Today' : moment(this.value as MomentInput).format('MM/DD/YYYY');
  }
}
