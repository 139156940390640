import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { DateService } from '@services/date.service';
import { GraphqlConnection } from '@services/graphql/generic/generic';
import { LogService } from '@services/log.service';
import { LookupsService } from '@services/lookups.service';
import { TenantService } from '@services/tenant.service';
import { UrlService } from '@services/url.service';
import { GraphqlClient, MutateResult, QueryResult } from '@shared/providers/graphql-client';
import { first } from 'lodash';

@Injectable()
export class GraphqlService {
  constructor(
    protected authService: AuthService,
    protected dateService: DateService,
    protected graphqlClient: GraphqlClient,
    protected http: HttpClient,
    protected url: UrlService,
    protected logger: LogService,
    protected lookupsService: LookupsService,
    protected tenantService: TenantService,
  ) {}

  getEmptyConnection(result?: QueryResult<{}>): GraphqlConnection<any> {
    return {
      edges: [],
      pageInfo: {},
      totalCount: 0,
      error: first(result?.errors)?.message,
    };
  }

  protected processGraphqlQueryResult(result: QueryResult<any>) {
    if (result.errors?.length) {
      this.logger.error(result.errors[0]);
    }
  }

  protected processGraphqlMutationResult(result: MutateResult<any>) {
    if (result.errors?.length) {
      this.logger.error(result.errors[0]);
    }
  }

  protected sanitizeFilter(variables: { filter?: object | null }) {
    if (variables.filter && Object.keys(variables.filter).length === 0) {
      delete variables.filter;
    }
  }

  protected sanitizeInputType(input: Record<string, any>) {
    for (const [key, value] of Object.entries(input)) {
      if (value === '') {
        input[key] = null;
      }
      if (value && typeof value === 'object') {
        this.sanitizeInputType(value);
      }
    }
  }
}
