<div class="dark-theme-border">
  <div class="dark-theme-border__side dark-theme-border__side--left">
    <img class="dark-theme-border__side-image dark-theme-border__side-image--left" [src]="IconPath.LeftCurvedGradientBorder" />
  </div>

  <div class="dark-theme-border__middle"></div>

  <div class="dark-theme-border__side dark-theme-border__side--right">
    <img class="dark-theme-border__side-image dark-theme-border__side-image--right" [src]="IconPath.RightCurvedGradientBorder" />
  </div>

  <hr class="dark-theme-border__border" />
</div>
