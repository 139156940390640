import { Component, OnInit, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';
import { ManufacturerService } from '@services/manufacturer.service';
import { Manufacturer } from '@shared/models/manufacturer.model';
import { ProductService } from '@services/product.service';
import { IProduct } from '@shared/models/product.model';
import { Router } from '@angular/router';

type SearchResultClick = 'product' | 'manufacturer';

@Component({
  selector: 'app-search-with-suggestions',
  templateUrl: './search-with-suggestions.component.html',
  styleUrls: ['./search-with-suggestions.component.scss'],
})
export class SearchWithSuggestionsComponent implements OnInit {
  @Output() valueChanged = new EventEmitter<string>();

  manufacturers: Manufacturer[] = [];
  drugs: IProduct[] = [];

  searchResults: any[];
  showResults = false;

  search: string;

  component: Record<string, any> = this;

  @HostListener('document:click', ['$event', '$event.target'])
  handleClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.element.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showResults = false;
    } else if (this.hasSearchResults()) {
      this.showResults = true;
    }
  }

  constructor(
    private manufacturerService: ManufacturerService,
    private productService: ProductService,
    private element: ElementRef,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getManufacturers();
    this.getDrugs();
  }

  private getManufacturers(): void {
    this.manufacturerService.getManufacturers().subscribe(newManufacturers => {
      this.manufacturers = newManufacturers;
    });
  }

  private getDrugs(): void {
    this.productService.getProducts().subscribe(newProducts => (this.drugs = newProducts));
  }

  hasSearchResults(): boolean {
    return this.searchResults && this.searchResults.length > 0;
  }

  updateResults(): void {
    if (this.search && this.search.length > 2) {
      this.setDummyData();
      this.showResults = true;
    } else {
      this.resetData();
      this.showResults = false;
    }

    this.emitUpdate();
  }

  private setDummyData(): void {
    this.searchResults = [
      {
        label: 'Drug Name',
        data: this.drugs,
        clickHandler: 'productClicked',
      },
      {
        label: 'Manufacturer Name',
        data: this.manufacturers,
        clickHandler: 'manufacturerClicked',
      },
    ];
  }

  private resetData(): void {
    this.searchResults = [];
  }

  productClicked(product: IProduct): void {
    // do nothing
  }

  goToManufacturersProfile(nameOfManufacturer: string): void {
    const navigationExtras = {
      state: {
        nameOfManufacturer: nameOfManufacturer,
      },
    };

    this.router.navigateByUrl('/retail/manufacturer-profile', navigationExtras);
  }

  manufacturerClicked(manufacturer: Manufacturer): void {
    this.goToManufacturersProfile(manufacturer.name);
  }

  private emitUpdate(): void {
    this.valueChanged.emit(this.search);
  }
}
