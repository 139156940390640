<div
  *ngIf="useScrollbar"
  class="scrollbar-container"
  [class.scrollbar-container--full-height]="isDefaultHeader"
  [class.scrollbar-container--transparent-bg]="isDefaultHeader"
>
  <perfect-scrollbar>
    <ng-container [ngTemplateOutlet]="pageContent"> </ng-container>
  </perfect-scrollbar>
</div>

<ng-container *ngIf="!useScrollbar" [ngTemplateOutlet]="pageContent"> </ng-container>

<ng-template #pageContent>
  <div
    class="page-content page-content--{{ themeModifier }}"
    [class.page-content--include-back]="includeBackButton"
    [class.page-content--full-height]="isDefaultHeader"
    [class.page-content--transparent-bg]="isDefaultHeader"
    [class.page-content--tokenized-login]="isTokenLogin"
  >
    <div class="row pb-3 pt-3">
      <div *ngIf="includeBackButton" class="col-12 mb-4">
        <button class="page-content__back-btn" (click)="goBack()">
          <img class="page-content__back-icon" src="assets/icons/arrow-back.svg" />
          Return to {{ backPage.name }}
        </button>
      </div>
      <div class="col-12 d-sm-flex d-block align-items-center">
        <h1 class="page-content__title page-content__title--{{ themeModifier }}" *ngIf="title !== ''" data-cy="pageTitle">{{ title }}</h1>
        <div class="page-content__add-button" *ngIf="includeAddButton">
          <app-icon-with-text [addObject]="addObject"> </app-icon-with-text>
        </div>
      </div>
    </div>
    <div [class.page-content__content]="showFooter">
      <ng-content> </ng-content>
    </div>

    <app-footer *ngIf="showFooter" class="mt-auto"> </app-footer>
  </div>
</ng-template>
