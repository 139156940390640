<i *ngIf="isStatusGood" class="material-icons status-icon__status-image status-icon__status-image--good"> check_circle </i>

<i *ngIf="isStatusBad" class="material-icons status-icon__status-image status-icon__status-image--bad"> cancel </i>

<svg *ngIf="isStatusMaybeGood" xmlns="http://www.w3.org/2000/svg" version="1.1" class="status-icon">
  <circle class="status-icon__shape status-icon__shape--success" />
</svg>

<svg *ngIf="isStatusMaybeBad" xmlns="http://www.w3.org/2000/svg" version="1.1" class="status-icon">
  <circle class="status-icon__shape status-icon__shape--failure" />
</svg>
