export const PROJECTION_INDEXES = {
  PATIENT_ASSISTANCE_CLAIM_GENERAL_VIEW: 'patient_assistance_claim_general_view',
  PATIENT_ASSISTANCE_PAID_CLAIM_GENERAL_VIEW: 'patient_assistance_paid_claim_general_view',
  PATIENT_ASSISTANCE_PAID_CLAIM_NEW_INFRASTRUCTURE_TESTING_VIEW:
    'patient_assistance_paid_claim_new_infrastructure_testing_view',
  PATIENT_ASSISTANCE_REQUEST_GENERAL_VIEW: 'patient_assistance_request_general_view',
  PHARMACY_GENERAL_VIEW: 'pharmacy_general_view',
  PRIOR_AUTHORIZATION_REQUEST_GENERAL_VIEW: 'prior_authorization_request_general_view',
  PHARMACY_PAYMENT_STATEMENT_GENERAL_VIEW: 'pharmacy_payment_statement_general_view',
} as const;

export type ProjectionIndex = (typeof PROJECTION_INDEXES)[keyof typeof PROJECTION_INDEXES];
