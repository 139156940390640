import { chainStatementFragment } from '@services/graphql/pharmacy/chain/chain-statement.fragment';
import { pharmacyChainFragment } from '@services/graphql/pharmacy/chain/pharmacy-chain.fragment';
import { earlyPaymentProductFragment } from '@services/graphql/pharmacy/early-payment-product.fragment';
import gql from 'graphql-tag';

export const GetChainStatementQuery = gql`
  ${earlyPaymentProductFragment}
  ${chainStatementFragment}
  ${pharmacyChainFragment}
  query GetChainStatement($npi: String!, $periodEnd: AWSDateTime!) {
    pharmacyChain(npi: $npi) {
      ...PharmacyChain
      paymentStatement(periodEnd: $periodEnd) {
        ...ChainStatement
        reportFileNames
        transmittedAtHistory {
          changedAt
          transmittedAt
        }
        earlyPayment {
          acceptedAt
          declinedAt
          dueDate
          requestId
          requestedAt
          status
          product {
            ...EarlyPaymentProduct
          }
          products {
            ...EarlyPaymentProduct
          }
        }
      }
    }
  }
`;
