import { Injectable, inject } from '@angular/core';
import {
  ApolloClient,
  ApolloClientOptions,
  DefaultContext,
  GraphQLRequest,
  InMemoryCache,
  OperationVariables,
  createHttpLink,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import config from '@config';
import { Query } from '@generated/graphql';
import { AuthService } from '@services/auth.service';
import { DocumentNode, GraphQLError } from 'graphql';
import { EMPTY, Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export type QueryResult<T> = {
  data: T | null;
  errors?: GraphQLError[];
};

export type MutateResult<T> = {
  data: T | null;
  errors?: GraphQLError[];
};

@Injectable({ providedIn: 'root' })
export class GraphqlClient {
  private readonly authService = inject(AuthService);
  private readonly client = this.createClient();

  query<T = Query, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode,
    variables?: TVariables,
  ): Observable<QueryResult<T>> {
    return this.loadJwtToken().pipe(
      switchMap(token => {
        if (!this.checkJwtToken(token)) return EMPTY;
        return from(this.client.query<T, TVariables>({ query, variables, context: { token } }) as Promise<QueryResult<T>>);
      }),
    );
  }

  mutate<T, TVariables extends OperationVariables = OperationVariables>(
    mutation: DocumentNode,
    variables?: TVariables,
  ): Observable<MutateResult<T>> {
    return this.loadJwtToken().pipe(
      switchMap(token => {
        if (!this.checkJwtToken(token)) return EMPTY;
        return from(this.client.mutate<T, TVariables>({ mutation, variables, context: { token } })) as Observable<MutateResult<T>>;
      }),
    );
  }

  subscribe<T, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode,
    variables?: TVariables,
  ): Observable<MutateResult<T>> {
    return this.loadJwtToken().pipe(
      switchMap(token => {
        if (!this.checkJwtToken(token)) return EMPTY;
        return from(this.client.subscribe<T, TVariables>({ query, variables, context: { token } })) as Observable<MutateResult<T>>;
      }),
    );
  }

  private loadJwtToken() {
    return from(this.authService.fetchAccessToken());
  }

  private checkJwtToken(token: string | null) {
    if (!token) {
      this.authService.logout('GraphqlClientAccessToken');
    }
    return !!token;
  }

  private createClient() {
    const httpLink = createHttpLink({ uri: config.graphql.endpoint });
    const authLink = setContext((request: GraphQLRequest, context: DefaultContext) => {
      const token: string = context.token ?? 'Unknown';
      const headers = context.header ?? {};
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });
    const options: ApolloClientOptions<{}> = {
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: { errorPolicy: 'all', fetchPolicy: 'no-cache' },
        query: { errorPolicy: 'all', fetchPolicy: 'no-cache' },
        watchQuery: { errorPolicy: 'all', fetchPolicy: 'no-cache' },
      },
    };
    return new ApolloClient(options);
  }
}
