import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DateService } from '@services/date.service';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'app-top-page-datepicker',
  templateUrl: './top-page-datepicker.component.html',
  styleUrls: ['./top-page-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopPageDatepickerComponent implements OnInit, OnChanges {
  @Input() date: NgbDate;
  @Output() dateChange = new EventEmitter<NgbDate>();
  fullDateString: string;
  maxDate: NgbDate;
  active = false;

  constructor(private dateService: DateService) {
    this.maxDate = this.dateService.dateToNgbDate(new Date());
  }

  ngOnInit() {
    this.updateDateString();
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.date) {
      this.updateDateString();
    }
  }

  updateDateString(): void {
    const momentDate = this.dateService.ngbDateToMomentDate(this.date || this.maxDate);
    this.fullDateString = momentDate.format('MMMM D, YYYY');
  }

  open(dp: NgbInputDatepicker) {
    dp.toggle();
    this.active = dp.isOpen();
  }

  onClose() {
    this.active = false;
  }

  onDateSelect(date: NgbDate) {
    this.dateChange.emit(date);
  }
}
