import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabInformation } from '@shared/models/tab-information.model';

export type TabMenuAppearance = 'primary' | 'secondary' | 'tertiary';

@Component({
  selector: 'app-shared-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
})
export class TabMenuComponent {
  @Input()
  appearance: TabMenuAppearance = 'primary';

  private items: TabInformation[] = [];

  get tabs(): TabInformation[] {
    return this.items;
  }

  @Input()
  set tabs(value: TabInformation[]) {
    this.items = value;
    this.changeTab(0);
  }

  @Input()
  selectedIndex = 0;

  @Output()
  selectedIndexChange = new EventEmitter<number>();

  changeTab(index: number) {
    const tab = this.tabs[index];
    if (tab && tab.disabled === true) {
      return;
    }

    this.selectedIndex = index;
    this.selectedIndexChange.emit(this.selectedIndex);
  }
}
