<div #wrapperEl class="d-flex">
  <div class="prependEl">
    <ng-content select="prepend"></ng-content>
  </div>
  <button
    class="btn shadow-none"
    [hidden]="contentEl.scrollWidth < wrapperEl.offsetWidth"
    (mousedown)="startScroll(contentEl, false)"
    (mouseup)="stopScroll()"
    (touchstart)="startScroll(contentEl, false)"
    (touchend)="stopScroll()"
  >
    <i class="fa fa-chevron-left"></i>
  </button>
  <div #contentEl class="contentEl">
    <ng-content></ng-content>
  </div>
  <button
    class="btn shadow-none"
    [hidden]="contentEl.scrollWidth < wrapperEl.offsetWidth"
    (mousedown)="startScroll(contentEl, true)"
    (mouseup)="stopScroll()"
    (touchstart)="startScroll(contentEl, true)"
    (touchend)="stopScroll()"
  >
    <i class="fa fa-chevron-right"></i>
  </button>
  <div class="appendEl">
    <ng-content select="append"></ng-content>
  </div>
</div>
