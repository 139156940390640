<div data-cy="squareCheckbox" class="square-checkbox" [class.square-checkbox--tokenized-login]="isTokenLogin" [ngClass]="_modifiers">
  <input
    type="checkbox"
    [hidden]="_value && isTokenLogin"
    class="square-checkbox__input"
    data-cy="squareCheckboxInput"
    [(ngModel)]="_value"
    (ngModelChange)="emitChange()"
    [id]="id"
    [attr.disabled]="disabled ? 'disabled' : null"
  />
  <label [for]="id" class="square-checkbox__label" [class.selected]="_value && isTokenLogin"> {{ label }} </label>
</div>
