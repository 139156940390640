import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() text: string[] | string;

  hovered = false;

  setHovered(value: boolean): void {
    this.hovered = value;
  }
}
