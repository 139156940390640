import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-pharmacy-dot',
  templateUrl: './pharmacy-dot.component.html',
  styleUrls: ['./pharmacy-dot.component.scss'],
})
export class PharmacyDotComponent {
  @HostBinding('class') class = 'idle';

  @Input() set status(value: 'busy' | 'idle') {
    this.class = value;
  }
}
