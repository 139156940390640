<div class="add-button" [class.add-button--disabled]="!isActive" (click)="handleClick()">
  <img class="add-button__image" [src]="imageAddress" *ngIf="isIconLocationOnLeft()" />
  <span
    class="add-button__label add-button__label--{{ labelModifier }}"
    [class.ml-0]="addObject.iconLocation === 'right'"
    [class.mr-0]="addObject.iconLocation === 'left'"
  >
    {{ addObject?.name }}
  </span>
  <img class="add-button__image" [src]="imageAddress" *ngIf="isIconLocationOnRight()" />
</div>
