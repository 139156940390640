import { Component, DestroyRef, OnInit, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { isString } from 'lodash';

@Component({
  selector: 'app-formly-field-input',
  template: `
    @if (showFileInput()) {
      <input
        [formControl]="formControl"
        type="file"
        [attr.name]="fieldName()"
        [disabled]="field.props?.disabled ?? false"
        [readonly]="field.props?.readonly ?? false"
        [attr.accept]="field.props?.acceptFileTypes"
        [attr.multiple]="field.props?.multipleFiles ?? false"
        [attr.placeholder]="field.props?.placeholder"
        [attr.data-cy]="'input'"
        class="form-control"
        [formlyAttributes]="field"
      />
    }

    @for (fileInfo of filesInfo(); track $index) {
      <div class="d-flex flex-row justify-content-between me-2" [ngClass]="{ 'mt-2': isMultipleUpload() }">
        <div>
          <chitin-icon [clickable]="false" [icon]="fileInfo.iconClass"></chitin-icon>
          <a class="ms-2 text-muted text-link" (click)="handleDownloadClick(fileInfo.name)">{{ fileInfo.name }}</a>
        </div>
        <div>
          <chitin-icon [clickable]="true" icon="fa-lg fa-solid fa-trash" (click)="handleRemoveClick(fileInfo.name)"></chitin-icon>
        </div>
      </div>
    }
  `,
})
export class FormlyFieldFileComponent extends FieldType<FieldTypeConfig> implements OnInit {
  fieldName = signal('');
  isMultipleUpload = signal(true);
  showFileInput = computed(() => {
    return this.isMultipleUpload() ? true : this.filesInfo().length === 0;
  });
  filesInfo = signal<
    Array<{
      name: string;
      iconClass: string;
    }>
  >([]);
  private destroyRef = inject(DestroyRef);

  handleDownloadClick(fileNameToDownload: string) {
    if (this.field.props?.handleDownloadClick) {
      this.field.props.handleDownloadClick(this.field, fileNameToDownload);
    }
  }

  handleRemoveClick(fileNameToRemove: string) {
    if (this.isMultipleUpload()) {
      this.filesInfo.update(filesInfo => {
        return filesInfo.filter(fileInfo => fileInfo.name !== fileNameToRemove);
      });

      this.formControl.setValue(this.formControl.value.filter((fileName: string) => fileName !== fileNameToRemove));
    } else {
      this.formControl.setValue(null);
      this.filesInfo.set([]);
    }

    if (this.field.props?.handleRemoveClick) {
      this.field.props.handleRemoveClick(this.field, fileNameToRemove);
    }
  }

  ngOnInit() {
    this.init();
    this.setInputFields();

    if (this.field.options?.fieldChanges)
      this.field.options.fieldChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.setInputFields();
      });
  }

  private setInputFields() {
    this.fieldName.set(isString(this.field.key) ? this.field.key : '');
    this.isMultipleUpload.set(this.field.props?.multipleFiles ?? false);
  }

  private init() {
    const formValue = this.formControl.value;

    if (Array.isArray(formValue)) {
      this.filesInfo.set(
        formValue.map(fileName => {
          return {
            iconClass: `fa-lg ${this.getIconClassFromFileName(fileName)}`,
            name: fileName,
          };
        }),
      );
    } else if (typeof formValue === 'string') {
      this.filesInfo.set([
        {
          iconClass: `fa-lg ${this.getIconClassFromFileName(formValue)}`,
          name: formValue,
        },
      ]);
    }
  }

  private getIconClassFromFileName(fileName: string): string {
    const fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1);

    switch (fileExtension) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        return 'fa-regular fa-file-image';
      case 'pdf':
        return 'fa-regular fa-file-pdf';
      case 'docx':
        return 'fa-regular fa-file-doc';
      case 'pptx':
        return 'fa-regular fa-file-powerpoint';
      default:
        return 'fa-regular fa-file';
    }
  }
}
