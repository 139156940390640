import { Injectable } from '@angular/core';
import { CodeRequestResultType } from '@shared/models/code-request-result.enum';

@Injectable({
  providedIn: 'root',
})
export class CopayApiService {
  getResultOptions() {
    return [
      CodeRequestResultType.Incomplete,
      CodeRequestResultType.Consignment,
      CodeRequestResultType.InProgress,
      CodeRequestResultType.Paid,
      CodeRequestResultType.PaidEligible,
      CodeRequestResultType.Rejected,
      CodeRequestResultType.Reversed,
      CodeRequestResultType.PaApproved,
      CodeRequestResultType.PaPending,
      CodeRequestResultType.PaReady,
    ];
  }
}
