import { BaseComponent } from '@shared/components/base-component';
import { Observable, of } from 'rxjs';

export type SelfServeLoaderType = '' | 'verify' | 'validate';

export interface SelfServeStepInfo {
  componentClass: any;
  headerText: string;
  displayContinueButton: boolean;
  displayContinueButtonTooltip: boolean;
  continueButtonTooltip: string;
  disableContinueButton: boolean;
  continueButtonText: string;
  displayStepNumber: boolean;
  estimatedTimeToCompleteInSeconds: number;
  numStepsToMoveOnContinue: number;
  includeInNumberOfSteps: boolean;
  continueText: string;
  loading: boolean;
  loaderType: SelfServeLoaderType;
  showContinueSectionSeparator: boolean;
  showHeaderSectionSeparator: boolean;
  hideContinueButtonSection: boolean;
}

export abstract class SelfServeStep extends BaseComponent implements SelfServeStepInfo {
  componentClass: any;
  headerText: string;
  displayContinueButton: boolean;
  displayContinueButtonTooltip: boolean;
  continueButtonTooltip: string;
  disableContinueButton: boolean;
  continueButtonText: string;
  displayStepNumber: boolean;
  estimatedTimeToCompleteInSeconds: number;
  numStepsToMoveOnContinue: number;
  includeInNumberOfSteps: boolean;
  continueText: string;
  loading: boolean;
  loaderType: SelfServeLoaderType;
  showContinueSectionSeparator: boolean;
  showHeaderSectionSeparator: boolean;
  hideContinueButtonSection: boolean;
  showHeader: boolean;

  abstract onContinue(): Observable<any>;

  finalize(): Observable<Boolean> {
    const stepCompleted = true;
    return of(stepCompleted);
  }

  canStepContinue(): boolean {
    return true;
  }
}
