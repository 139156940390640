export const paymentReportColumns = {
  column_names: ['Pharmacy NCPDP', 'Pharmacy Name', 'Rx Number', 'Secondary BIN', 'NDC', 'Product', 'Date of Service', 'Approved Payment'],
  column_source: ['ncpdp', 'pharmacy_name', 'rx_number', 'secondary_bin', 'ndc', 'product_label', 'date_of_service', 'benefit_amount'],
  data: [],
  header: [],
};

export const extraPaymentReportColumns = {
  column_names: [
    'Pharmacy NCPDP',
    'Pharmacy Name',
    'Rx Number',
    'Secondary BIN',
    'NDC',
    'Product',
    'Date of Service',
    'Additional Payment',
  ],
  column_source: [
    'ncpdp',
    'pharmacy_name',
    'rx_number',
    'secondary_bin',
    'ndc',
    'product_label',
    'date_of_service',
    'extra_benefit_amount',
  ],
  data: [],
  header: [],
};

export const paidClaimsSearchCriteria = [
  {
    tenant: 'GLD',
  },
  {
    tenant: 'INCY',
  },
  {
    tenant: 'ALM',
    IS_IN_occ: ['02', '08'],
    NOT_EXISTS_extra_benefit_amount: 1,
  },
  {
    tenant: 'ALM',
    IS_IN_occ: ['02', '08'],
    extra_benefit_amount: '0.00',
  },
];

export const extraPaidClaimsSearchCriteria = [
  {
    tenant: 'ALM',
    IS_IN_occ: ['02', '08'],
    EXISTS_extra_benefit_amount: 1,
    NOT_EQUAL_extra_benefit_amount: '0.00',
  },
];
