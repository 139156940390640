<canvas #container></canvas>
<div class="labels" *ngIf="config?.options?.plugins?.legend?.display === false">
  <div
    class="label"
    *ngFor="let item of chart?.legend?.legendItems; let i = index"
    [ngClass]="{ hidden: !this.chart?.isDatasetVisible(item.datasetIndex ?? i) }"
  >
    <div
      class="labelIndicator"
      [style]="{
        backgroundColor:
          customLegendColours && customLegendColours.length > (item.datasetIndex ?? i)
            ? customLegendColours[item.datasetIndex ?? i]
            : item.fillStyle,
      }"
    ></div>
    <div class="labelText" [style]="{ color: item.fontColor }">{{ item.text }}</div>
  </div>
</div>
