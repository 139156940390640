import { Component, Input } from '@angular/core';

export type CirclePercentColor = 'default' | 'success' | 'warning' | 'danger';

@Component({
  selector: 'app-shared-circle-percent',
  templateUrl: './circle-percent.component.html',
  styleUrls: ['./circle-percent.component.scss'],
})
export class CirclePercentComponent {
  @Input()
  color: CirclePercentColor = 'default';

  @Input()
  value = 0;
}
