<div class="square-radio" (click)="onContentClicked()" data-cy="squareRadio">
  <input
    type="radio"
    [(ngModel)]="squareCheckmarkModel"
    (ngModelChange)="emitChange()"
    [value]="value"
    [id]="id"
    [attr.name]="name"
    [checked]="isChecked"
    [disabled]="disabled"
  />
  <label [for]="id"> </label>

  <div class="square-radio__content-container">
    <ng-content> </ng-content>
  </div>
</div>
