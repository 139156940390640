import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { RadioItem } from '@shared/models/radio-item.model';

@Component({
  selector: 'app-radio-buttons',
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss'],
})
export class RadioButtonsComponent {
  static idCounter = 0;

  @Input() name: string;
  @Input() disabled = false;
  @Input() verticalLayout = false;
  @Input() items: RadioItem[] = [];
  @Input() valueType: 'radio item' | 'value' = 'value';
  @Input() set value(value: any) {
    this.onValue(value);
  }

  @Output() valueChange = new EventEmitter<any>();

  id: string;
  selectedRadioItem?: RadioItem;
  isTokenLogin = false;

  constructor(private authService: AuthService) {
    this.id = `C${RadioButtonsComponent.idCounter++}I`;
    this.isTokenLogin = this.authService.isTokenizedLogin();
  }

  onValue(value: any): void {
    if (value) {
      this.selectedRadioItem =
        this.valueType === 'value' ? this.items.find(item => item.value === value) : this.items.find(item => item.value === value.value);
    }
  }

  emitChange(): void {
    this.valueChange.emit(this.valueType === 'value' ? this.selectedRadioItem?.value : this.selectedRadioItem);
  }
}
