<section class="need-help" classToExclude="need-help" [class.need-help--active]="!steps.inactive.active">
  <div class="need-help__contact-container" (click)="activate()">
    <div *ngIf="steps.helpTopic.active">
      <ol class="need-help__help-topics">
        <li
          class="need-help__help-topic"
          data-cy="needHelpTopic"
          *ngFor="let helpTopic of helpTopics"
          (click)="onHelpTopicSelected(helpTopic)"
          [class.selected]="helpTopic === selectedHelpTopic"
        >
          <div class="need-help__radio-container">
            <input
              type="radio"
              id="{{ helpTopic }}"
              name="{{ helpTopic }}"
              class="need-help__select-radio"
              [checked]="helpTopic === selectedHelpTopic"
            />
          </div>
          {{ helpTopic }}
        </li>
      </ol>
    </div>

    <div *ngIf="steps.needHelp.active" data-cy="contactMethod">
      <ol class="need-help__confirmation-form">
        <li>
          <label>Pharmacy Name:</label>
          <span data-cy="pharmacyName">{{ pharmacy?.name }}</span>
        </li>
        <li>
          <label>NCPDP #:</label>
          <span data-cy="ncpdp">{{ pharmacy?.ncpdp }}</span>
        </li>
        <li>
          <label>Topic:</label>
          <span [title]="selectedHelpTopic" data-cy="selectedHelpTopic">{{ selectedHelpTopic }}</span>
        </li>
        <li *ngIf="tenants.length > 1">
          <label>Sponsor:</label>
          <div class="sponsor-dropdown-container">
            <app-dropdown
              [(dropdownModel)]="sponsor"
              [dropdownOptions]="tenants"
              [useSelectListItemValue]="true"
              [useThinArrow]="true"
              [height]="25"
            >
            </app-dropdown>
          </div>
        </li>
        <li>
          <label data-cy="phoneLabel">Contact Phone Number:</label>
          <div class="need-help__phone-number">
            <input
              #areaCodeInput
              type="text"
              digitOnly
              maxlength="3"
              class="form-control need-help__form-control"
              data-cy="phoneInput"
              [(ngModel)]="phoneNumber.areaCode.value"
              (keypress)="preventNonNumericInput($event)"
              (input)="onPhoneNumberInput(areaCodeInput.value, 'areaCode')"
            />

            <input
              #centralOfficeCodeInput
              type="text"
              digitOnly
              maxlength="3"
              class="form-control need-help__form-control need-help__form-control--margin"
              data-cy="phoneInput"
              [(ngModel)]="phoneNumber.centralOfficeCode.value"
              (keypress)="preventNonNumericInput($event)"
              (input)="onPhoneNumberInput(centralOfficeCodeInput.value, 'centralOfficeCode')"
            />

            <input
              #lineNumberInput
              type="text"
              digitOnly
              maxlength="4"
              class="form-control need-help__form-control need-help__form-control--margin"
              data-cy="phoneInput"
              [(ngModel)]="phoneNumber.lineNumber.value"
              (keypress)="preventNonNumericInput($event)"
              (input)="onPhoneNumberInput(lineNumberInput.value, 'lineNumber')"
            />
          </div>
          <div class="mt-2">
            <app-retail-message *ngIf="phoneInvalid" message="Please enter a valid phone number" [modifiers]="['extra-small']">
            </app-retail-message>
          </div>
        </li>
        <li>
          <label data-cy="emailLabel">Contact Email</label>
          <input
            type="email"
            class="form-control need-help__form-control need-help__form-control--full-width"
            data-cy="emailInput"
            [(ngModel)]="email"
            (keydown.space)="$event.preventDefault()"
          />
          <div class="mt-2">
            <app-retail-message
              *ngIf="emailInvalid"
              message="Please enter a valid email address without spaces."
              [modifiers]="['extra-small']"
            >
            </app-retail-message>
          </div>
        </li>
        <li>
          <label data-cy="firstNameLabel">First Name</label>
          <input
            type="text"
            data-cy="firstNameInput"
            class="form-control need-help__form-control need-help__form-control--full-width"
            [(ngModel)]="firstName"
            (keydown.space)="$event.preventDefault()"
          />
          <div class="mt-2">
            <app-retail-message *ngIf="firstNameInvalid" message="First Name is required." [modifiers]="['extra-small']">
            </app-retail-message>
          </div>
        </li>
        <li>
          <label data-cy="lastInitialLabel">Last Initial</label>
          <input
            type="text"
            data-cy="lastInitialInput"
            class="form-control need-help__form-control"
            maxlength="4"
            [(ngModel)]="lastNameInitial"
            (keydown.space)="$event.preventDefault()"
          />
          <div class="mt-2">
            <app-retail-message *ngIf="lastNameInitialInvalid" message="Last Initial is required." [modifiers]="['extra-small']">
            </app-retail-message>
          </div>
        </li>
      </ol>
    </div>

    <div *ngIf="steps.thankYou.active" class="need-help__request-received-container">
      <img class="need-help__request-received-icon" src="assets/icons/red-check.svg" data-cy="needHelpReceivedImg" />
      <p class="need-help__request-received-text">We've received your request and will contact you shortly</p>
    </div>
  </div>

  <div *ngIf="!steps.inactive.active" class="need-help__24-7" [class.need-help__24-7--justify-end]="steps.thankYou.active">
    <button *ngIf="!steps.thankYou.active" class="button need-help__submit" data-cy="submitBtn" (click)="submit()">Submit</button>
    <a *ngIf="steps.thankYou.active" class="need-help__close-button" (click)="onCloseClicked()">Close</a>
  </div>
</section>
