import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent {
  @Input() active = false;
  @Input() disabled = false;
  @Output() toggled = new EventEmitter<boolean>();

  constructor() {}

  toggle() {
    if (!this.disabled) {
      this.active = !this.active;
      this.toggled.emit(this.active);
    }
  }
}
