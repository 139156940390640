<div class="agreement">
  <div class="agreement__error">
    <div *ngIf="error" class="alert alert-danger mt-3">{{ error }}</div>
  </div>

  <app-connected-circle-steps
    *ngIf="showSteps && !isLoadingAgreements && !isMarkingAgreed"
    [numberOfSteps]="numberOfAgreements"
    [activeStep]="agreementStep"
  >
  </app-connected-circle-steps>

  <div class="loader spin-me" *ngIf="isLoadingAgreements"></div>

  <div *ngFor="let agreement of agreementsToShow">
    <ng-container [ngTemplateOutlet]="agreementTemplate" [ngTemplateOutletContext]="{ agreement: agreement }"> </ng-container>
  </div>

  <div class="agreement__btn-container">
    <button class="button button--default agreement__decline-btn" (click)="onDecline()" [disabled]="isLoadingAgreements || isMarkingAgreed">
      DECLINE
    </button>
    <button class="button button--primary" (click)="onAgree()" [disabled]="!isAgree || isLoadingAgreements || isMarkingAgreed">
      {{ isMarkingAgreed ? 'SAVING...' : 'AGREE' }}
    </button>
  </div>
</div>

<!-- Templates -->

<ng-template #agreementTemplate let-agreement="agreement">
  <ng-container *ngIf="isCurrentAgreement(agreement)">
    <div class="row">
      <div class="agreement__header-container">
        <span class="agreement__pharmacy-info">{{ pharmacy?.name }} - NCPDP #{{ pharmacy?.ncpdp }}</span>
        <h1 class="agreement__header" [class.agreement__header--small]="agreement.headerText">
          {{ agreement.headerText ? agreement.headerText : agreement.name }}
        </h1>
      </div>
    </div>
    <div #agreementDocument id="agreement" class="agreement__document">
      <ng-template [appAgreement]="agreement.id" [showHeader]="false"></ng-template>
    </div>
    <div class="row agreement__accept-toolbar">
      <div class="col-md-7 col-sm-12 agreement__accept-container">
        <label class="agreement__accept-label" (mouseover)="onAgreeLabelMouseOver()" (mouseout)="onAgreeLabelMouseOut()">
          I have read the agreement and accept the terms.
          <input class="agreement__accept" type="checkbox" [checked]="isAgree" (change)="isAgree = !isAgree" />
          <span
            class="agreement__accept-shown"
            [ngClass]="{ 'agreement__accept-shown--display': isAgree, 'agreement__accept-shown--hover': agreeLabelHover }"
          ></span>
        </label>
      </div>
      <div class="col agreement__toolbar-container">
        <div class="agreement__toolbar">
          <a href="assets/pdf/{{ agreement.file }}" target="_blank" class="agreement__toolbar-link">
            <img
              #saveIcon
              class="agreement__toolbar-img"
              src="assets/icons/save.png"
              (mouseover)="saveIcon.src = 'assets/icons/save-rollover.png'"
              (mouseout)="saveIcon.src = 'assets/icons/save.png'"
            />
          </a>

          <a class="agreement__toolbar-link" (click)="onPrint()">
            <img
              #printIcon
              class="agreement__toolbar-img"
              src="assets/icons/print.png"
              (mouseover)="printIcon.src = 'assets/icons/print-rollover.png'"
              (mouseout)="printIcon.src = 'assets/icons/print.png'"
            />
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
