import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PA_DENIALS } from '@app/retail/models/pa-denials';
import { BaseComponent } from '@shared/components/base-component';
import {
  PrescriberFormFields,
  PrescriberFormModel,
  PrescriberFormModelEntry,
} from '@shared/components/prescriber-form/prescriber-form.form';
import { FormOption } from '@app/shared/components/prescriber-form/form-input/form-input.component';
import moment from 'moment';

@Component({
  selector: 'prescriber-form',
  templateUrl: 'prescriber-form.component.html',
  styleUrls: ['./prescriber-form.component.scss'],
})
export class PrescriberFormComponent extends BaseComponent {
  readonly reasons = PA_DENIALS.map(item => item.type).filter(item => item !== 'Unknown to Pharmacy');
  @Input()
  model: PrescriberFormModel = PrescriberFormFields;
  @Output()
  send = new EventEmitter<PrescriberFormModel>();
  @Input()
  isSubmitting: boolean = false;
  timeOptions: FormOption[] = [
    { label: '8 am-12 pm', value: 'time1' },
    { label: '12-4 pm', value: 'time2' },
    { label: '4-8 pm', value: 'time3' },
  ];
  icd10Options: FormOption[] = [
    { label: 'L20 Atopic Dermatitis', value: 'l20' },
    { label: 'L80 Vitiligo', value: 'l80' },
  ];

  onSubmit(): typeof PrescriberFormFields | undefined {
    if (!this.model) return;
    if (!this.validateForm()) return;
    this.isSubmitting = true;
    const processedModel: PrescriberFormModel = {
      ...this.model,
      pa_denial_reason: {
        ...this.model.pa_denial_reason,
        value: this.model.pa_denial_reason.value === 'Other' ? this.model.pa_denial_reason.valueCustom : this.model.pa_denial_reason.value,
      },
      signature_date: {
        ...this.model.signature_date,
        value: this.model.signature_date.value ?? moment().format('YYYY-MM-DD'),
      },
      declaration: {
        value: this.model.declaration.value ?? this.model.l20_declaration.value ?? this.model.l80_declaration.value,
      },
    };
    this.send.emit(processedModel);
  }

  private validateForm() {
    let errorFound = false;
    for (const key of Object.keys(this.model)) {
      const field: PrescriberFormModelEntry<string> = (this.model as Record<string, any>)[key];
      field.error = undefined;
      if (field.value && field.validator && !field.validator(field.value, this.model)) {
        field.error = 'Invalid format';
        errorFound = true;
      }
      if (field.optional) continue;
      if (field.value !== 'Other' && field.value) continue;
      if (field.value === 'Other' && field.valueCustom) continue;
      field.error = 'This field is required!';
      errorFound = true;
    }
    return !errorFound;
  }

  onIcd10Selected(newIcd10: string | undefined) {
    this.model.icd10.error = undefined;
    this.model.declaration.validator = undefined;
    this.model.l20_declaration.value = undefined;
    this.model.l20_declaration.error = undefined;
    this.model.l80_declaration.value = undefined;
    this.model.l80_declaration.error = undefined;
    if (newIcd10 === 'l20') {
      this.model.l80_declaration.optional = true;
      this.model.l20_declaration.optional = false;
      this.model.declaration.value = undefined;
    } else if (newIcd10 === 'l80') {
      this.model.l80_declaration.optional = false;
      this.model.l20_declaration.optional = true;
      this.model.declaration.value = undefined;
    } else {
      this.model.declaration.value = newIcd10;
      this.model.l20_declaration.optional = true;
      this.model.l80_declaration.optional = true;
      this.model.declaration.validator = (_value: string, model: PrescriberFormModel) => {
        return !!model.l20_declaration.value || !!model.l80_declaration.value;
      };
    }
  }

  onSignatureChanged(newSignature: string | undefined) {
    this.model.signature_base64.error = undefined;
    this.model.signature_base64.value = newSignature;
  }
}
