import { DateRange } from '@shared/components/chitin/molecules/daterange-picker/date-range';
import { DateRangePreset } from '@shared/models/date-range-preset';
import moment from 'moment';

export function dateRangePresets(): Record<DateRangePreset, DateRange> {
  return {
    Today: {
      from: moment().startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
    },
    'This Week': {
      from: moment().startOf('week').toDate(),
      to: moment().endOf('week').toDate(),
    },
    'This Month': {
      from: moment().startOf('month').toDate(),
      to: moment().endOf('month').toDate(),
    },
    'This Year': {
      from: moment().startOf('year').toDate(),
      to: moment().endOf('year').toDate(),
    },
    YTD: {
      from: moment().startOf('year').toDate(),
      to: moment().endOf('day').toDate(),
    },
    Yesterday: {
      from: moment().subtract(1, 'day').startOf('day').toDate(),
      to: moment().subtract(1, 'day').endOf('day').toDate(),
    },
    'Last Week': {
      from: moment().subtract(1, 'week').startOf('week').toDate(),
      to: moment().subtract(1, 'week').endOf('week').toDate(),
    },
    'Last Month': {
      from: moment().subtract(1, 'month').startOf('month').toDate(),
      to: moment().subtract(1, 'month').endOf('month').toDate(),
    },
    'Last Year': {
      from: moment().subtract(1, 'year').startOf('year').toDate(),
      to: moment().subtract(1, 'year').endOf('year').toDate(),
    },
    // Scorecard
    Monthly: {
      from: moment().subtract(1, 'month').startOf('month').toDate(),
      to: moment().subtract(1, 'month').endOf('month').toDate(),
    },
    'Trailing 3 Months': {
      from: moment().subtract(3, 'month').startOf('month').toDate(),
      to: moment().subtract(1, 'month').endOf('month').toDate(),
    },
    'Trailing 6 Months': {
      from: moment().subtract(6, 'month').startOf('month').toDate(),
      to: moment().subtract(1, 'month').endOf('month').toDate(),
    },
  };
}
