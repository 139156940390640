import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { isString } from 'lodash';

@Component({
  selector: 'app-formly-error',
  template: ` <div *ngIf="!!model[fieldKey]" class="invalid-feedback d-block">{{ field.props?.message }}</div> `,
})
export class FormlyFieldErrorComponent extends FieldType<FieldTypeConfig> {
  get fieldKey(): string {
    return isString(this.field.key) ? this.field.key : '';
  }
}
