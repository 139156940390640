<div class="pharmacy-select {{ class }}" clickOutside (clickOutside)="closeDropdown()" [class.d-none]="hide()">
  <div class="pharmacy-select__header" aria-haspopup="true" (click)="toggleDropdown()">
    <span class="pharmacy-select__label" *ngIf="showErrorMessage()"> {{ errorMessage }} </span>

    <span class="pharmacy-select__label" *ngIf="showLabel()"> {{ label }} </span>
    <span class="pharmacy-select__pharmacy-info" data-cy="pharmacyDd" *ngIf="showPharmacyInfo()"> {{ getPharmacyInfo() }} </span>
    <span class="pharmacy-select__arrow">
      <app-up-down-arrow *ngIf="!showErrorMessage() && !isLoading" [up]="showDropdown"> </app-up-down-arrow>
    </span>

    <app-loading-spinner [isLoading]="isLoading" modifier="small-and-centered"> </app-loading-spinner>
  </div>

  <div @openCollapse *ngIf="showDropdown" class="pharmacy-select__dropdown" aria-label="submenu">
    <p *ngIf="displayTextInsteadOfOptions" class="pharmacy-select__menu-option mb-0">{{ displayTextInsteadOfOptions }}</p>
    <ul *ngIf="!displayTextInsteadOfOptions" class="pharmacy-select__menu">
      <li
        *ngFor="let pharmacy of availablePharmacies"
        class="pharmacy-select__menu-option"
        data-cy="selectOption"
        (click)="changeCurrentPharmacy(pharmacy)"
      >
        {{ getPharmacyInfo(pharmacy) }}
      </li>
    </ul>
  </div>
</div>
