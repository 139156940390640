<div class="modal-header" data-cy="saveViewScreen">
  <h5 class="modal-title">Save view</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<ng-container *ngIf="!newViewMode">
  <div *ngIf="isViewEditable" class="modal-body m-3">
    <p class="mb-0">Do you want to update {{ editedView.label }} or create a new one?</p>
  </div>
  <div *ngIf="!isViewEditable" class="modal-body m-3">
    <p class="mb-0">Do you want to create a new view based on {{ editedView.label }}?</p>
  </div>
  <div class="modal-footer text-center">
    <chitin-button *ngIf="isViewEditable" (clicked)="changeViewMode('update')" [label]="'Update ' + editedView.label" />
    <chitin-button (clicked)="changeViewMode('create')" label="Create new" />
  </div>
</ng-container>
<ng-container *ngIf="newViewMode">
  <div class="modal-body m-3">
    <p class="mb-0">Please enter a name for this view</p>
    <chitin-form-input type="text" [value]="newViewLabel" (valueChange)="newViewLabel = $event" />
    <div *ngIf="labelError" class="text-danger mt-1" style="font-size: 75%">{{ labelError }}</div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <chitin-button (clicked)="changeViewMode(undefined)" [outline]="true" label="Back" />
    <chitin-button *ngIf="newViewMode === 'create' && newViewLabel" (clicked)="onCreateClicked()" [label]="'Save as ' + newViewLabel" />
    <chitin-button *ngIf="newViewMode === 'update' && newViewLabel" (clicked)="onUpdateClicked()" [label]="'Update ' + newViewLabel" />
  </div>
</ng-container>
