import { KeyValue } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  DateRange as MaterialDateRange,
} from '@angular/material/datepicker';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DateRange } from '@shared/components/chitin/molecules/daterange-picker/date-range';
import { dateRangePresets } from '@shared/components/chitin/molecules/daterange-picker/date-range-presets';
import { DateRangePreset } from '@shared/models/date-range-preset';
import { SelectListItem } from '@shared/models/select-list-item';
import { NgChanges } from '@shared/ng-changes';
import { getTimeZones } from '@vvo/tzdb';
import { cloneDeep, isEqual, merge, pick } from 'lodash';
import moment from 'moment';

@Component({
  selector: 'chitin-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ChitinDateRangePickerComponent),
    },
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class ChitinDateRangePickerComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  inputId?: string;

  @Input()
  name?: string;

  @Input()
  isDisabled: boolean = false;

  @Input()
  readonly: boolean = false;

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  type: 'button' | 'input' = 'button';

  @Input()
  color: 'primary' | 'secondary' = 'primary';

  @Input()
  placeholder?: string;

  @Input()
  set value(value: DateRange | undefined) {
    this._value = value;
    this.lastValidValue = cloneDeep(value);
  }

  @Input()
  selectedTimezone: string | undefined;

  @Input()
  maxDate?: Date;

  @Input()
  minDate?: Date;

  @Input()
  fullwidth?: boolean = false;

  @Input()
  visibleDateRangePresets: DateRangePreset[] = [
    'Today',
    'This Week',
    'This Month',
    'This Year',
    'Yesterday',
    'Last Week',
    'Last Month',
    'Last Year',
  ];

  @Input()
  hideDateRangePresets: DateRangePreset[] = [];

  @Input()
  addDateRangePresets: DateRangePreset[] = [];

  @Input()
  showLabelContextInfo: boolean = false;

  @Input()
  showPresetInSelection?: boolean = false;

  @Input()
  showTimezoneSelector?: boolean = false;

  @Input()
  dateDisplayFormat: string = 'MMM D, YYYY';

  @Input()
  allHistory?: DateRange;

  @Input()
  appendTo?: 'body';

  @Input()
  onlyApplyOnValueChange?: boolean = false;

  @Output()
  valueChange = new EventEmitter<DateRange>();

  @Output()
  selectedTimezoneChange = new EventEmitter<string>();

  @ViewChild('popover')
  popoverRef: NgbPopover;

  dateRangePresets: Record<string, DateRange> = {};
  lastValidValue?: DateRange;

  displayLabel?: string;
  startDate?: Date;
  endDate?: Date;

  timezoneOptions: SelectListItem[];
  defaultTimezoneOption: string;

  selectedDateRange: MaterialDateRange<Date> | null;

  private _value?: DateRange;

  private onChange?: Function;
  private onTouched?: Function;

  get value() {
    return this._value;
  }

  private setStartDate(date: Date | undefined) {
    this.startDate = moment(date).startOf('day').toDate();
    this.endDate = undefined;
    this._value = merge(this.value, {
      from: moment(date).startOf('day').toDate(),
    });
    if (this.onChange) this.onChange(this.value);
    this.updateDisplayLabel();
  }

  private setEndDate(date: Date | undefined) {
    this.endDate = moment(date).endOf('day').toDate();
    this._value = merge(this.value, {
      to: moment(date).endOf('day').toDate(),
    });
    if (this.onChange) this.onChange(this.value);
  }

  private setPreset() {
    const presetKey = this.getSelectedPreset();
    this._value = merge(this.value, {
      preset: presetKey,
      presetDisplayValue: this.showLabelContextInfo && presetKey ? this.getLabelWithContextInfo() : presetKey,
    });
  }

  ngOnInit() {
    this.initDateRangePresets();
    this.initTimezoneOptions();
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.value) {
      if (this.value) {
        if (typeof this.value?.from === 'string') {
          this.startDate = new Date(this.value?.from);
        } else {
          this.startDate = this.value?.from;
        }
        if (typeof this.value?.to === 'string') {
          this.endDate = new Date(this.value?.to);
        } else {
          this.endDate = this.value?.to;
        }
      }
      this.updateDisplayLabel();
      this.selectedDateRange = new MaterialDateRange<Date>(this.startDate ?? null, this.endDate ?? null);
      if (this.onChange) this.onChange(this.value);
    }
    if (changes.selectedTimezone && !this.selectedTimezone) {
      this.selectedTimezone = this.defaultTimezoneOption;
    }
  }

  initTimezoneOptions() {
    const timezones = getTimeZones({ includeUtc: true });
    const timezoneOptions = [];
    for (const timezone of timezones) {
      timezoneOptions.push({
        name: `GMT${timezone.rawFormat}`,
        value: timezone.name,
      });
    }
    this.timezoneOptions = timezoneOptions;
    const currentTimezone = moment.tz.guess();
    this.defaultTimezoneOption = currentTimezone;
    this.selectedTimezone = this.selectedTimezone ?? this.defaultTimezoneOption;
  }

  onSelectedTimezoneChanged(newTimezone?: string | null) {
    this.selectedTimezone = newTimezone ?? this.defaultTimezoneOption;
    this.selectedTimezoneChange.next(newTimezone ?? this.defaultTimezoneOption);
  }

  checkCleared(value: unknown) {
    if (value === null) {
      this._value = undefined;
      this.selectedDateRange = null;
      this.applySelection();
      this.popoverRef.close();
    }
  }

  private updateDisplayLabel() {
    if (!this.value) {
      this.displayLabel = undefined;
      return;
    }
    if (this.showPresetInSelection) {
      const presetKey = this.getSelectedPreset();
      if (presetKey) {
        this.displayLabel = this.showLabelContextInfo ? this.getLabelWithContextInfo() : presetKey;
        return;
      }
    }

    const startDateFormatted = moment(this.startDate).format(this.dateDisplayFormat);
    const endDateFormatted = moment(this.endDate).format(this.dateDisplayFormat);
    if (startDateFormatted === endDateFormatted) {
      this.displayLabel = startDateFormatted;
      return;
    }
    if (this.startDate && !this.endDate) {
      this.displayLabel = `${startDateFormatted} - Select End Date`;
      return;
    }
    this.displayLabel = `${startDateFormatted} - ${endDateFormatted}`;
  }

  // Preserve original property order
  protected originalOrder = (_a: KeyValue<string, DateRange>, _b: KeyValue<string, DateRange>): number => {
    return 0;
  };

  protected onPresetClicked(presetKey: string) {
    this._value = merge(this.value, {
      preset: presetKey as DateRangePreset,
    });
    this.setStartDate(this.dateRangePresets[presetKey as DateRangePreset].from);
    this.setEndDate(this.dateRangePresets[presetKey as DateRangePreset].to);
    this.setPreset();
    this.selectedDateRange = new MaterialDateRange<Date>(this.startDate ?? null, this.endDate ?? null);
    this.updateDisplayLabel();
    if (this.onTouched) this.onTouched();
    this.popoverRef.close();
    this.valueChange.emit(this.value);
  }

  protected closeClicked() {
    if (this.onTouched) this.onTouched();
  }

  protected applySelection() {
    if (this.onTouched) this.onTouched();

    if (!this.onlyApplyOnValueChange || !isEqual(this.value, this.lastValidValue)) {
      if (isNaN(this.endDate?.getTime() ?? NaN)) {
        this._value = this.lastValidValue;
      }
      this.setPreset();
      this.updateDisplayLabel();
      this.valueChange.emit(this.value);
      this.lastValidValue = cloneDeep(this.value);
    }

    this.popoverRef.close();
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(value: DateRange) {
    this._value = value;
  }

  onClose() {
    this.onTouched ? this.onTouched() : null;
  }

  private initDateRangePresets() {
    const dateRangePresetsKeys = this.visibleDateRangePresets
      .concat(this.addDateRangePresets)
      .filter((preset: DateRangePreset) => !this.hideDateRangePresets.includes(preset));
    this.dateRangePresets = pick(dateRangePresets(), dateRangePresetsKeys);
    if (this.allHistory) this.dateRangePresets['All History'] = this.allHistory;
  }

  private getLabelWithContextInfo(): string {
    const presetKey = this.getSelectedPreset();
    let date = '';
    const startDate = moment(this.startDate);
    const endDate = moment(this.endDate);
    const isSameDay = startDate.get('day') === endDate.get('day');
    const isSameMonth = startDate.get('month') === endDate.get('month');
    const isSameYear = startDate.get('year') === endDate.get('year');

    if (isSameDay && isSameMonth && isSameYear) {
      date = `${startDate.format('MM')}/${endDate.format('DD')}`;
    } else if (!isSameDay && isSameYear) {
      date = `${startDate.format('MM')}/${startDate.format('DD')}-${endDate.format('MM')}/${endDate.format('DD')}`;
    } else {
      date = `${startDate.format('MM')}/${startDate.format('DD')}/${startDate.format('YYYY')}-${endDate.format('MM')}/${endDate.format(
        'DD',
      )}/${endDate.format('YYYY')}`;
    }

    return `${presetKey} (${date})`;
  }

  private getSelectedPreset(): DateRangePreset | undefined {
    let selectedPreset = undefined;

    for (const [presetKey, presetValue] of Object.entries(this.dateRangePresets)) {
      if (this.startDate?.getTime() === presetValue.from?.getTime() && this.endDate?.getTime() === presetValue.to?.getTime()) {
        selectedPreset = cloneDeep(presetKey) as DateRangePreset;
      }
    }

    return selectedPreset;
  }

  onDateRangeSelect(date: Date) {
    if (!this.startDate) {
      this.startDate = date;
    } else if (!this.endDate) {
      if (this.startDate > date) {
        this.endDate = moment(this.startDate).endOf('day').toDate();
        this.startDate = date;
      } else {
        this.endDate = moment(date).endOf('day').toDate();
      }
    } else {
      this.startDate = date;
      this.endDate = undefined;
    }
    this.updateDisplayLabel();
    this.selectedDateRange = new MaterialDateRange(this.startDate ?? null, this.endDate ?? null);
    if (this.startDate && this.endDate) {
      this._value = { from: this.startDate, to: this.endDate };
      this.applySelection();
    }
  }
}
