import { Component, Input } from '@angular/core';
import { ButtonModel } from '@shared/models/button.model';

@Component({
  selector: 'app-sidebar-cta',
  templateUrl: './sidebar-cta.component.html',
  styleUrls: ['./sidebar-cta.component.scss'],
})
export class SidebarCtaComponent {
  @Input()
  title?: string;

  @Input()
  text?: string;

  @Input()
  button?: ButtonModel;
}
