import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import config from '@config';
import { AuthService } from '@services/auth.service';
import { UIAnalyticsService } from '@services/ui-analytics.service';
import { ROLES_WITH_ALL_TENANT_ACCESS } from '@services/user.service';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { SelectListItem } from '@shared/models/select-list-item';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { SelfServeHomepageTabs, Tenant } from '@shared/models/tenant.model';
import { IUser } from '@shared/models/user.model';
import { sortBy } from 'lodash';
import { Subject } from 'rxjs';

export type Package = 'Claim Endpoint Detection' | 'Demand Enablement' | 'Demand Conversion';

@Injectable({ providedIn: 'root' })
export class TenantService {
  static readonly TENANT_TEST_ID = 'da_test';
  package = signal<Package>('Demand Conversion');
  usersTenants: Tenant[] = [];
  tenantsChanged: Subject<boolean> = new Subject<boolean>();
  activeTenantChanged: Subject<boolean> = new Subject<boolean>();

  currentTenant?: Tenant;

  tenantSpecificPaths: string[] = ['agent-call', 'support', 'faq'];

  private tenantData: Tenant[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private uiAnalyticsService: UIAnalyticsService,
  ) {
    this.initTenantData();
    this.setupSubscriptions();
    this.loadTenants();
  }

  getActiveTenants(): Tenant[] {
    return this.usersTenants.filter(tenant => tenant.id.toString() !== 'HZN');
  }

  reset() {
    this.currentTenant = undefined;
    this.clearTenants();
    this.loadTenants();
  }

  getCurrentTenantData(): Tenant | undefined {
    if (this.currentTenant) {
      return this.currentTenant;
    }
    const user = this.authService.getCurrentUser();
    if (user && user.currentTenant) {
      // Needed for first time users
      return this.getTenantById(user.currentTenant);
    }
  }

  getCurrentTenantId(): TenantIdType {
    if (!this.currentTenant) {
      throw new Error('No current tenant selected!');
    }
    return this.currentTenant.id;
  }

  getTenantById(id: TenantIdType): Tenant | undefined {
    return id ? this.tenantData.find(t => t.id.toLowerCase() === id.toLowerCase()) : undefined;
  }

  getTenantByName(name: string): Tenant | undefined {
    return name ? this.tenantData.find(tenant => tenant.name.toLowerCase() === name.toLowerCase()) : undefined;
  }

  getAllTenants(): Tenant[] {
    return sortBy(this.tenantData, 'name');
  }

  getTenantsDropdown(): Array<SelectListItem<string>> {
    return this.getAllTenants().map(tenant => ({ name: tenant.name, value: tenant.id }));
  }

  changeCurrentTenant(tenant: Tenant) {
    if (this.currentTenant?.id === tenant.id) return;
    this.resetPathsForTenant();

    const currentUrl: string = this.router.url.split('/').pop() ?? '';
    if (this.tenantSpecificPaths.includes(currentUrl)) {
      this.reloadPage(currentUrl);
    }

    this.currentTenant = tenant;
    this.activeTenantChanged.next(true);
    this.uiAnalyticsService.trackTenantSwitch(tenant.id);
  }

  changeCurrentTenantById(id: TenantIdType) {
    const tenant = this.getTenantById(id);
    if (tenant) {
      this.changeCurrentTenant(tenant);
    }
  }

  isTenant(tenantId: string): boolean {
    return this.currentTenant?.id === tenantId;
  }

  doesTenantUseReferralCheck(): boolean {
    return this.currentTenant?.id === 'ALM';
  }

  doesTenantHaveSelfServeTab(tab: SelfServeHomepageTabs, tenantId?: TenantIdType): boolean {
    let tenant = this.currentTenant;

    if (tenantId) {
      tenant = this.getTenantById(tenantId);
    }

    const tabs: SelfServeHomepageTabs[] = [];
    return tabs.includes(tab);
  }

  doesTenantHavePharmacy(pharmacy: IPharmacy, tenantId?: TenantIdType): boolean {
    tenantId = tenantId ?? this.getCurrentTenantId();
    const tenantFields: Record<string, any> = pharmacy.tenant_fields ?? {};
    return !!tenantFields[tenantId];
  }

  private initTenantData(): void {
    this.tenantData = Object.values(config.tenants) as Tenant[];
  }

  private setupSubscriptions(): void {
    this.authService.authStatusChanged.subscribe(isAuthorized => {
      isAuthorized ? this.loadTenants() : this.reset();
    });
  }

  private loadTenants(): void {
    if (!this.authService.isUserAuthenticated()) {
      this.clearTenants();
      return;
    }

    const currentUser = this.authService.getCurrentUser();

    if (ROLES_WITH_ALL_TENANT_ACCESS.includes(currentUser.role)) {
      this.usersTenants = this.getAllTenants();
    } else {
      this.usersTenants = this.getTenantsByUsersTenants(currentUser);
    }

    this.usersTenants = sortBy(this.usersTenants, 'name');
    this.changeCurrentTenant(this.usersTenants[0]);
    this.tenantsChanged.next(true);
  }

  getTenantsByUsersTenants(currentUser?: IUser): Tenant[] {
    const tmpTenants: Tenant[] = [];

    if (currentUser?.tenants) {
      currentUser.tenants.forEach(tenantId => {
        const tenant = this.getTenantById(tenantId);
        if (tenant) {
          tmpTenants.push(tenant);
        }
      });
    }

    return tmpTenants;
  }

  private clearTenants(): void {
    this.usersTenants = [];
    this.tenantsChanged.next(true);
  }

  private resetPathsForTenant(): void {
    this.removeCurrentPaths();
    this.router.resetConfig(this.router.config);
  }

  private removeCurrentPaths(): void {
    this.tenantSpecificPaths.forEach(path => {
      const routeIndex = this.router.config.findIndex(p => p.path === path);
      if (routeIndex > 0) {
        this.router.config.splice(routeIndex, 1);
      }
    });
  }

  private reloadPage(urlToReload: string): void {
    this.router.navigateByUrl(urlToReload);
  }

  getCurrentTenantsDefaultGroupId(): string {
    return '1';
  }
}
