import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RetailStateService } from '@app/retail/providers/retailState.service';
import { AuthService } from '@services/auth.service';
import { EtlService } from '@services/etl.service';
import { ProgramStateService } from '@services/program-state.service';
import { ProgramUtilityService } from '@services/program-utility.service';
import { TenantService } from '@services/tenant.service';
import { UserStoreService } from '@services/user-store.service';
import { EtlRoute } from '@shared/models/etl-route.model';
import { IPharmacy } from '@shared/models/pharmacy.model';
import Utils from '@shared/providers/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pharmacy-select',
  templateUrl: './pharmacy-select.component.html',
  styleUrls: ['./pharmacy-select.component.scss'],
  animations: [
    trigger('openCollapse', [
      transition(':enter', [style({ height: '0px' }), animate('0.150s ease-in-out', style({ height: '*' }))]),
      transition(':leave', [style({ height: '*' }), animate('0.150s ease-in-out', style({ height: '0px' }))]),
    ]),
  ],
})
export class PharmacySelectComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() class: string;
  @Input() pharmacyInfoPrefix: string;
  @Input() placeNameFirst = false;
  @Input() hideWhenOnePharmacy = false;
  @Input() onlyUsePharmaciesInWeeklyBatchProgram = false;
  @Input() onlyUsePharmaciesWithEtlRoute = false;
  @Input() addDefaultListItem = false;
  @Input() displayTextInsteadOfOptions: string;

  currentPharmacy?: IPharmacy;
  defaultPharmacy: IPharmacy;
  availablePharmacies: IPharmacy[];
  showDropdown = false;
  isLoading = false;
  errorMessage?: string;

  private unsubscribe = new Subject<void>();

  constructor(
    private userStoreService: UserStoreService,
    private retailStateService: RetailStateService,
    private programUtilityService: ProgramUtilityService,
    private programStateService: ProgramStateService,
    private etlService: EtlService,
    private tenantService: TenantService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.defaultPharmacy = this.createDefaultPharmacy();
    this.getAvailablePharmacies();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  reset() {
    const defaultPharmacy = this.addDefaultListItem ? this.defaultPharmacy : undefined;
    this.changeCurrentPharmacy(defaultPharmacy);
  }

  closeDropdown() {
    this.showDropdown = false;
  }

  private getAvailablePharmacies(): void {
    this.resetErrorMessage();

    this.isLoading = true;
    this.userStoreService
      .getPharmacies()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(pharmacies => {
        this.retailStateService.resetPharmacy();
        this.initPharmacies(pharmacies);
      });
  }

  private initPharmacies(pharmacies: IPharmacy[]): void {
    if (this.onlyUsePharmaciesInWeeklyBatchProgram) {
      this.setPharmaciesInWeeklyBatchProgram(pharmacies);
    } else if (this.onlyUsePharmaciesWithEtlRoute) {
      this.setPharmaciesWithEtlRoute(pharmacies);
    } else {
      this.setAllUserPharmacies(pharmacies);
    }
  }

  private setAllUserPharmacies(pharmacies: IPharmacy[]) {
    this.availablePharmacies = pharmacies;
    this.initPharmacyFromAvailablePharmacies();
  }

  private setPharmaciesInWeeklyBatchProgram(pharmacies: IPharmacy[]) {
    const user = this.authService.getCurrentUser();
    this.programStateService
      .getAllProgramsForTenants(user?.tenants ?? [])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(allPrograms => {
        if (Utils.isNonEmptyArray(allPrograms)) {
          this.availablePharmacies = this.getPharmaciesEnrolledInAWeeklyBatchProgram(pharmacies);
          this.initPharmacyFromAvailablePharmacies();
        } else {
          this.reset();
        }
      });
  }

  private setPharmaciesWithEtlRoute(pharmacies: IPharmacy[]) {
    this.etlService
      .getRoutes(this.tenantService.getCurrentTenantId())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(routes => {
        this.availablePharmacies = pharmacies.filter(pharmacy => this.pharmacyHasEtlRoutes(pharmacy, routes));
        this.initPharmacyFromAvailablePharmacies();
      });
  }

  private hasDefaultPharmacyBeenAdded() {
    return this.availablePharmacies.find(pharmacy => pharmacy.name === this.defaultPharmacy.name) !== undefined;
  }

  private createDefaultPharmacy() {
    const defaultPharmacy = new IPharmacy();
    defaultPharmacy.name = '--SELECT STORE--';
    return defaultPharmacy;
  }

  private pharmacyHasEtlRoutes(pharmacy: IPharmacy, routes: EtlRoute[]) {
    return routes.some(route => {
      if (route.partner_type === 'pharmacy') {
        return route.partner === pharmacy.ncpdp;
      } else if (route.partner_type === 'tenant') {
        const currentTenantName = this.tenantService.currentTenant?.name;
        return route.partner.toLowerCase() === currentTenantName?.toLowerCase();
      }
      return false;
    });
  }

  private getPharmaciesEnrolledInAWeeklyBatchProgram(pharmacies: IPharmacy[]): IPharmacy[] {
    return pharmacies.filter(pharmacy => {
      if (this.isDefaultPharmacy(pharmacy)) {
        return false;
      }
      const enrolledPrograms = this.programUtilityService.getEnrolledProgramsFromPharmacy(pharmacy);
      return enrolledPrograms.some(enrolledProgram => {
        return this.programStateService.allProgramsForTenant
          .getValue()
          .find(program => program.copay_program_id === enrolledProgram.copay_program_id)?.is_weekly_batch_program;
      });
    });
  }

  private resetErrorMessage(): void {
    this.errorMessage = undefined;
  }

  private isDefaultPharmacy(pharmacy: IPharmacy) {
    return pharmacy.name === this.defaultPharmacy.name;
  }

  private initPharmacyFromAvailablePharmacies(): void {
    this.isLoading = false;

    if (this.addDefaultListItem && !this.hasDefaultPharmacyBeenAdded()) {
      this.availablePharmacies.unshift(this.defaultPharmacy);
    }

    this.currentPharmacy = this.retailStateService.pharmacy.getValue();
    if (this.currentPharmacy && !this.isDefaultPharmacy(this.currentPharmacy)) {
      return;
    }
    const userPharmacies = this.availablePharmacies.filter(pharmacy => !this.isDefaultPharmacy(pharmacy));
    let selectedPharmacy: IPharmacy | undefined;
    if (userPharmacies.length === 1) {
      selectedPharmacy = userPharmacies[0];
    } else if (this.addDefaultListItem) {
      selectedPharmacy = this.defaultPharmacy;
    } else if (this.availablePharmacies.length > 0) {
      selectedPharmacy = this.availablePharmacies[0];
    }
    this.currentPharmacy = selectedPharmacy;
    this.retailStateService.updatePharmacy(this.currentPharmacy);
  }

  getPharmacyInfo(pharmacyToGetInfoFor?: IPharmacy): string {
    const pharmacy = pharmacyToGetInfoFor ? pharmacyToGetInfoFor : this.currentPharmacy;
    if (!pharmacy || this.isDefaultPharmacy(pharmacy)) {
      return this.defaultPharmacy.name;
    }
    if (!pharmacy || !pharmacy.name || !pharmacy.ncpdp) {
      return '';
    }

    const prefix = this.showPharmacyInfoPrefix() ? this.pharmacyInfoPrefix : '';
    let pharmacyInfo = '';

    if (this.placeNameFirst) {
      pharmacyInfo += pharmacy.name + ' (' + pharmacy.ncpdp + ')';
    } else {
      pharmacyInfo += pharmacy.ncpdp + ' (' + pharmacy.name + ')';
    }

    return prefix + pharmacyInfo;
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  changeCurrentPharmacy(newPharmacy?: IPharmacy): void {
    this.currentPharmacy = newPharmacy;
    this.retailStateService.updatePharmacy(this.currentPharmacy);
    this.showDropdown = false;
  }

  showErrorMessage(): boolean {
    return this.errorMessage !== undefined;
  }

  showLabel(): boolean {
    return !this.showErrorMessage() && this.label !== undefined;
  }

  showPharmacyInfoPrefix(): boolean {
    return !this.showErrorMessage() && this.pharmacyInfoPrefix !== undefined;
  }

  showPharmacyInfo(): boolean {
    return !this.showErrorMessage() && this.currentPharmacy !== undefined;
  }

  hide(): boolean {
    return this.hideWhenOnePharmacy && Utils.isNonEmptyArray(this.availablePharmacies) && this.availablePharmacies.length === 1;
  }
}
