import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';
import { DataViewConfigService } from '@services/dataview-config.service';
import { BaseComponent } from '@shared/components/base-component';
import { ChitinFormInputComponent } from '@shared/components/chitin/atoms/form-input/form-input.component';
import { Column, Filter } from '@shared/components/data-view/data-view-types';
import { sum } from 'lodash';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-data-view-filter-gallery',
  templateUrl: './data-view-filter-gallery.component.html',
  styleUrls: ['./data-view-filter-gallery.component.scss'],
})
export class DataViewFilterGalleryComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() pageName?: string;
  @Input() header?: string;
  appliedFilters: Filter.AppliedDataViewFilter[] = [];
  newlyAppliedFilters: Filter.AppliedDataViewFilter[] = [];
  newlyRemovedFilterIds: string[] = [];
  filterCategories: Column.DataViewColumnGalleryCategory[];
  filters: Record<string, Filter.DataViewFilter[]> = {};
  searchQuery: string | null;
  @ViewChild('filterSearch') filterSearch: ChitinFormInputComponent<string>;

  constructor(
    protected activeOffcanvas: NgbActiveOffcanvas,
    private dataViewConfigService: DataViewConfigService,
    private authService: AuthService,
  ) {
    super();
  }

  get filtersCount() {
    return sum(Object.values(this.filters).map(filters => filters.length));
  }

  ngOnInit() {
    if (this.pageName?.length) {
      this.setupFilters(this.pageName);
    }
  }

  ngAfterViewInit() {
    this.filterSearch?.focus();
  }

  private setupFilters(pageName: string) {
    this.subscribe(
      forkJoin({
        filterCategories: this.dataViewConfigService.getColumnCategories(pageName),
        filters: this.dataViewConfigService.getAvailableFilters(pageName, this.authService.getCurrentUser()),
      }),
      result => {
        this.setFilters(result.filters, result.filterCategories);
      },
    );
  }

  setFilters(filters: Filter.DataViewFilter[], filterCategories: Column.DataViewColumnGalleryCategory[]) {
    for (const filter of filters) {
      if (this.appliedFilters.filter(appliedFilter => appliedFilter.field === filter.field).length > 0) {
        filter.disabled = true;
      } else {
        filter.disabled = false;
      }
      if (!(filter.categoryId in this.filters)) {
        this.filters[filter.categoryId] = [];
      }
      this.filters[filter.categoryId].push(filter);
    }

    this.filterCategories = filterCategories;
  }

  onFilterSelected(filter: Filter.DataViewFilter) {
    const alreadyAppliedIndex = this.appliedFilters.findIndex(appliedFilter => appliedFilter.id === filter.id);
    const newlyAppliedIndex = this.newlyAppliedFilters.findIndex(newlyAppliedFilter => newlyAppliedFilter.id === filter.id);
    const newlyRemovedIndex = this.newlyRemovedFilterIds.findIndex(newlyRemovedFilterId => newlyRemovedFilterId === filter.id);

    if (alreadyAppliedIndex !== -1) {
      // Filter has already been added
      if (newlyRemovedIndex !== -1) {
        this.newlyRemovedFilterIds.splice(newlyRemovedIndex, 1);
        filter.disabled = true;
      } else {
        this.newlyRemovedFilterIds.push(filter.id);
        filter.disabled = false;
      }
    } else {
      // Filter has not yet been added
      if (newlyAppliedIndex !== -1) {
        this.newlyAppliedFilters.splice(newlyAppliedIndex, 1);
        filter.disabled = false;
      } else {
        this.newlyAppliedFilters.push({
          ...filter,
          type: filter.allowedTypes[0],
          value: undefined,
        });
        filter.disabled = true;
      }
    }
  }

  getActiveFilters() {
    const appliedFilters = this.appliedFilters.filter(appliedFilter => !this.newlyRemovedFilterIds.includes(appliedFilter.id));
    return [...appliedFilters, ...this.newlyAppliedFilters];
  }
}
