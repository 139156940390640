import { Component, EventEmitter, Input, Output } from '@angular/core';

let ChitinCheckInputGroupComponentCounter = 0;

@Component({
  selector: 'chitin-check-input-group',
  templateUrl: './check-input-group.component.html',
})
export class ChitinCheckInputGroupComponent<TItem, TValue> {
  @Input() set inputId(value: string | undefined) {
    this.id = value ?? this._automaticGeneratedId;
  }

  @Input() set inputName(value: string | undefined) {
    this.name = value ?? this._automaticGeneratedId;
  }

  @Input() set inputValue(value: TValue) {
    this.onValue(value);
  }

  @Input()
  label?: string;

  @Input()
  allowMultiple: boolean = false;

  @Input()
  items: Array<Record<string, TItem>> = [];

  @Input()
  layout: 'block' | 'inline' = 'block';

  @Input()
  direction: 'horizontal' | 'vertical' = 'horizontal';

  @Input()
  valueExpr: string = 'value';

  @Input()
  displayExpr: string = 'label';

  @Input()
  valueType: 'item' | 'value' = 'value';

  @Input()
  disabled: boolean = false;

  @Output()
  valueChange = new EventEmitter<TValue | null>();

  public id: string;
  public name: string;
  public selectedItems: Array<Record<string, TItem>> = [];
  private readonly _automaticGeneratedId: string;

  constructor() {
    this._automaticGeneratedId = `ChI${ChitinCheckInputGroupComponentCounter++}G`;
    this.id = this._automaticGeneratedId;
    this.name = this._automaticGeneratedId;
  }

  public onNgModelChange(selectedItem: Record<string, TItem>): void {
    if (this.allowMultiple) {
      const index = this.selectedItems.findIndex((item: Record<string, TItem>) => {
        return item[this.valueExpr] === selectedItem[this.valueExpr];
      });
      if (index === -1) {
        this.selectedItems.push(selectedItem);
      } else {
        this.selectedItems.splice(index, 1);
      }
    } else {
      this.selectedItems[0] = selectedItem;
    }

    this.emitValue();
  }

  public isChecked(item: Record<string, TItem>): boolean {
    return !!this.selectedItems.find((row: Record<string, TItem>) => row[this.valueExpr] === item[this.valueExpr]);
  }

  private onValue(value: TValue): void {
    if (!value) {
      this.selectedItems = [];
      return;
    }

    this.selectedItems =
      this.valueType === 'value'
        ? this.items.filter((item: Record<string, TItem>) => {
            return Array.isArray(value) ? value.includes(item[this.valueExpr]) : item[this.valueExpr] === value;
          })
        : this.items.filter((item: Record<string, TItem>) => {
            return Array.isArray(value)
              ? !!value.find((row: Record<string, TItem>) => row[this.valueExpr] === item[this.valueExpr])
              : item[this.valueExpr] === (value as Record<string, TItem>)[this.valueExpr];
          });
  }

  private emitValue(): void {
    if (this.selectedItems.length === 0) {
      this.valueChange.emit(null);
      return;
    }

    const selectedItems =
      this.valueType === 'value' ? this.selectedItems.map((row: Record<string, TItem>) => row[this.valueExpr]) : this.selectedItems;
    this.valueChange.emit((this.allowMultiple ? selectedItems : selectedItems[0]) as TValue);
  }
}
