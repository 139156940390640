import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  forwardRef,
  viewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent } from '@shared/components/base-component';
import { SelectListItem } from '@shared/models/select-list-item';

type OptionGroup = {
  open: boolean;
  selected: boolean;
  items: SelectListItem[];
};

@Component({
  selector: 'chitin-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => ChitinSelectComponent) }],
})
export class ChitinSelectComponent<TValue, TItem extends TValue | TValue[] | undefined>
  extends BaseComponent
  implements AfterViewInit, OnChanges
{
  @Input() value: TItem;
  @Input() options: Array<SelectListItem<TValue>> = [];
  @Input() disabled = false;
  @Input() searchable = false;
  @Input() placeholder = 'Select option';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() groupItems: boolean = false;
  @Input() openGroupsByDefault: boolean = false;

  @Output() valueChange = new EventEmitter<TItem>();
  @Output() selectedItemChange = new EventEmitter<SelectListItem<TValue>>();

  selectDropdown = viewChild<ElementRef>('#selectDropdown');
  multiple = false;
  searchTerm?: string;
  visibleOptions: Array<SelectListItem<TValue>> = [];
  selectedItems: Array<SelectListItem<TValue>> = [];
  groupedItems: Record<string, OptionGroup>;
  displayText: string = '';

  ngAfterViewInit() {
    this.updateVisibleItems();
    this.updateDisplayText();
    this.updateSelectedItems();
    if (this.groupItems) {
      this.updateGroupedItems();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.multiple = Array.isArray(changes.value.currentValue);
      this.updateSelectedItems();
      this.updateDisplayText();
    }
    if (changes.options) {
      this.updateVisibleItems();
      if (this.groupItems) {
        this.updateGroupedItems();
      }
    }
  }

  focus() {
    this.selectDropdown()?.nativeElement?.focus();
  }

  onSearchTermChanged(searchTerm?: string) {
    this.searchTerm = searchTerm;
    this.updateVisibleItems();
  }

  selectItem(item: SelectListItem<TValue>) {
    if (Array.isArray(this.value)) {
      this.value.push(item.value);
      const existingIndex = this.selectedItems.findIndex(i => i.value === item.value);
      if (existingIndex !== -1) {
        this.selectedItems.splice(existingIndex, 1);
      } else {
        this.selectedItems.push(item);
      }
    } else {
      this.value = item.value as TItem;
      this.selectedItems = [item];
    }
    this.updateDisplayText();
    this.valueChange.next(this.value);
  }

  updateVisibleItems() {
    this.visibleOptions = this.searchTerm ? this.options.filter(item => item.name.includes(this.searchTerm!)) : this.options;
    if (this.groupItems) {
      this.updateGroupedItems();
    }
  }

  updateDisplayText() {
    if (this.selectedItems.length > 1) {
      this.displayText = `${this.selectedItems.length} selected`;
    } else if (this.selectedItems.length > 0) {
      this.displayText = this.selectedItems[0].name;
    } else {
      this.displayText = this.placeholder;
    }
  }

  updateSelectedItems() {
    this.selectedItems = [];
    if (Array.isArray(this.value)) {
      for (const value of this.value) {
        const item = this.options.find(option => option.value === value);
        if (item) this.selectedItems.push(item);
      }
    } else {
      const item = this.options.find(option => option.value === this.value);
      if (item) this.selectedItems = [item];
    }
  }

  updateGroupedItems() {
    this.groupedItems = {};
    for (const option of this.options) {
      if (option.group) {
        if (Object.keys(this.groupedItems).includes(option.group)) {
          this.groupedItems[option.group].items.push(option);
        } else {
          this.groupedItems[option.group] = { selected: false, open: this.openGroupsByDefault, items: [option] };
        }
      } else {
        if (Object.keys(this.groupedItems).includes('Other')) {
          this.groupedItems['Other'].items.push(option);
        } else {
          this.groupedItems['Other'] = { selected: false, open: this.openGroupsByDefault, items: [option] };
        }
      }
    }
  }
}
