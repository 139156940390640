<div class="position-relative d-grid" [ngClass]="{ 'd-block': !fullwidth }">
  <chitin-form-input
    *ngIf="type === 'input'"
    [value]="displayLabel ?? null"
    (valueChange)="checkCleared($event)"
    [placeholder]="placeholder ?? 'Select date period'"
    [isDisabled]="isDisabled || readonly"
    [size]="size"
    [ngbPopover]="datePickerPopoverContent"
    #popover="ngbPopover"
    placement="bottom"
    triggers="manual"
    (focused)="popover.open()"
    (hidden)="applySelection()"
    [autoClose]="'outside'"
    popoverClass="datePickerContainer"
    [container]="$any(appendTo)"
  />
  <chitin-button
    *ngIf="type === 'button'"
    [color]="color"
    icon="fa-solid fa-calendar-alt"
    [label]="displayLabel ?? placeholder ?? 'Select date period'"
    [fullwidth]="fullwidth"
    [disabled]="isDisabled || readonly"
    [size]="size"
    [ngbPopover]="datePickerPopoverContent"
    #popover="ngbPopover"
    placement="bottom"
    (clicked)="popover.open()"
    (hidden)="applySelection()"
    [autoClose]="'outside'"
    popoverClass="datePickerContainer"
    [container]="$any(appendTo)"
  />
</div>

<ng-template #datePickerPopoverContent>
  <mat-calendar [(selected)]="selectedDateRange" (selectedChange)="onDateRangeSelect($event)" />
  <div class="presets mb-2">
    <chitin-button
      *ngFor="let preset of dateRangePresets | keyvalue: originalOrder"
      size="xs"
      color="primary"
      [outline]="true"
      [label]="preset.key"
      class="d-grid"
      (clicked)="onPresetClicked(preset.key)"
    />
  </div>
  <chitin-select-old
    *ngIf="showTimezoneSelector"
    size="sm"
    (valueChange)="onSelectedTimezoneChanged($event)"
    [items]="timezoneOptions"
    displayExpr="name"
    valueExpr="value"
    [value]="selectedTimezone"
    [appendTo]="undefined"
    [clearable]="false"
  />
</ng-template>
