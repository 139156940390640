import gql from 'graphql-tag';

export const earlyPaymentProductFragment = gql`
  fragment EarlyPaymentProduct on PatientAssistancePharmacyPaymentStatementEarlyPaymentProduct {
    id
    isAvailable
    name
    fee
    description
    paymentStatementPayout
    unavailabilityReason
    dueInBusinessDays
    dueDate
  }
`;
