<div *ngIf="useBackdrop" (click)="hide()" class="sidepanel__backdrop" title="Close Sidepanel"></div>

<div class="sidepanel__container" [@showHide]="isHidden ? 'hide' : 'show'">
  <app-dark-theme-border class="sidepanel__top-border"></app-dark-theme-border>
  <div class="sidepanel__bg"></div>
  <div class="sidepanel__container-header">
    <span class="sidepanel__container-header-close-btn">
      <button utility-btn color="primary" (click)="hide()">
        <app-shared-material-icon icon="close"></app-shared-material-icon>
      </button>
    </span>
  </div>
  <div class="sidepanel__container-content">
    <perfect-scrollbar class="dark">
      <ng-content></ng-content>
    </perfect-scrollbar>
  </div>
</div>
