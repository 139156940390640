import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

@Pipe({
  name: 'timeDuration',
  pure: true,
})
export class TimeDurationPipe implements PipeTransform {
  transform(value?: number): string {
    if (!isNumber(value)) return '';
    const seconds = isNaN(value) ? 0 : value;
    const hours = seconds / 3600;
    const minutes = seconds / 60;
    let pre: number, by: number, unit: string;

    if (hours > 1) {
      pre = Math.floor(hours);
      by = Math.round(minutes - pre * 60);
      unit = 'hr';
    } else if (minutes > 1) {
      pre = Math.floor(minutes);
      by = Math.round(seconds - pre * 60);
      unit = 'min';
    } else {
      pre = 0;
      by = Math.round(seconds);
      unit = 'sec';
    }

    return `${pre}:${by < 10 ? '0' : ''}${by} ${unit}`;
  }
}
