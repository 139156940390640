<div class="select-control" [class.select-control--horizontal]="isLabelOnLeft" [ngClass]="classModifiers">
  <label
    *ngIf="label"
    [for]="randomId"
    class="select-control__label select-control__label--{{ classModifiers }}"
    [class.select-control__label--horizontal]="isLabelOnLeft"
  >
    {{ label }}
  </label>
  <div
    *ngIf="!hideControl"
    class="select-control__control-container select-control__control-container--{{ classModifiers }}"
    [class.select-control__control-container--horizontal]="isLabelOnLeft"
  >
    <ng-select
      class="select-control__control dropdown-overflow"
      data-cy="tableSelectDropDown"
      [ngClass]="{
        'ng-select--shadow': includeShadow,
        'ng-select--narrow-arrow': includeShadow || useNarrowArrow || isTableFilter,
        'ng-select--border-radius-5': includeShadow,
        'ng-select--no-border': includeShadow,
        'ng-select--height-40': includeShadow,
        'ng-select--no-row-height': includeShadow,
        'ng-select--fw-normal': includeShadow,
        'ng-select--fs-small': includeShadow,
        'ng-select--height-25': isTableFilter,
        'ng-select--padding-top-10': isForPaginator,
        'ng-select--auto': autoWidth,
        'ng-select--menu-right': menuPosition === 'right',
        'ng-select--dark': useDarkTheme,
      }"
      [class.ng-select-transparent-background]="useTransparentBackground"
      [(ngModel)]="value"
      [items]="valueOptions"
      bindLabel="name"
      bindValue="value"
      [appendTo]="container"
      [multiple]="allowMultipleValues"
      (change)="!allowMultipleValues && emitValueChange($event)"
      (add)="onItemAdded($event)"
      (remove)="onItemRemoved()"
      (clear)="emitValueChange($event)"
      [placeholder]="displayNumOfSelected ?? placeholder"
      [disabled]="disabled"
      [labelForId]="randomId"
      [searchable]="searchable"
      [clearable]="clearable"
    >
    </ng-select>

    <app-loading-spinner [isLoading]="isLoading" modifier="small-and-relative"> </app-loading-spinner>
  </div>
</div>
