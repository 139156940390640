import gql from 'graphql-tag';

export const GetPharmacyEngagementsQuery = gql`
  query GetPharmacyEngagements($ncpdp: String!, $tenantIds: [String!]!) {
    pharmacy(ncpdp: $ncpdp) {
      name
      ncpdp
      npi
      enrollments(tenantIds: $tenantIds) {
        engagements {
          lossToFollow {
            isSubscribed
          }
          pba {
            isApprovedResponseEnabled
            responseSettings {
              matchingCapturedResponseOccList
              paidCapturedResponseOccList
            }
          }
          priorAuthorization {
            arePayorApprovedEmailNotificationsEnabled
            arePayorApprovedFaxNotificationsEnabled
            arePayorDeniedEmailNotificationsEnabled
            arePayorDeniedFaxNotificationsEnabled
            arePrescriberNonResponseEmailNotificationsEnabled
            arePrescriberNonResponseFaxNotificationsEnabled
            isBrightclockEnabled
            isParticipant
            isSkipHoldAvailable
            isSubscribed
            notificationEmails
          }
          brightscrip {
            isScorecardEnabled
          }
          rxLogistics {
            isParticipant
          }
        }
        isConsignmentHub
        isPortalRequestBlocked
        isVisibleToRam
        tenant {
          id
          name
        }
      }
    }
  }
`;
