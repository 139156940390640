import { Component, Input } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component';
import { Navlink } from '@shared/models/navlink.model';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent extends BaseComponent {
  @Input() resourceName: string = 'Page';
  @Input() backlink?: Navlink;
}
