<div class="pm-search-results">
  <div class="pm-search-results__header">
    <h2 class="pm-search-results__header-label">Search Results</h2>

    <span class="pm-search-results__header-number" *ngIf="hasNumberOfPrograms()"> ({{ numberOfPrograms }}) </span>
  </div>

  <div class="pm-search-results__content">
    <div class="pm-search-results__table">
      <app-table [data]="tableData" [isLoading]="isLoadingTableData" (action)="onAction()"> </app-table>
    </div>
  </div>
</div>
