import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { TableData } from '@shared/models/table-data.model';
import { TableValue } from '@shared/models/table-value.model';
import { TableRow } from '@shared/models/table-row.model';
import { trigger, style, transition, animate, group } from '@angular/animations';
import { TableAction } from '@shared/models/table-action.model';
import Utils from '@shared/providers/utils';
import { IPaginationModel } from '@shared/models/pagination.model';
import { SelectListItem } from '@shared/models/select-list-item';
import { TableEntry } from '@shared/models/table-entry.model';
import { TableService } from '@services/table.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconPath } from '@shared/models/icon-path.enum';
import { CursorPaginatorComponent } from '@shared/components/data-table/cursor-paginator/cursor-paginator.component';
import { TableHeaderFilterType } from '@shared/models/table-header.model';
import { NgChanges } from '@shared/ng-changes';

type RowModifier = '' | 'short';
interface Filter {
  indexOfColumnToApplyTo: number;
  filter: string;
}

export type Modifier =
  | 'white-header-row'
  | 'tall'
  | 'header-end'
  | 'pb-10'
  | 'first-header-pl-0'
  | 'row-height-35'
  | 'filter-py-5'
  | 'filter-pr-20'
  | 'filter-height-80'
  | 'filter-flex-end'
  | 'filter-no-bottom-border'
  | 'scrollbar-h-100';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('openCollapse', [
      transition(':enter', [style({ height: '0px' }), animate('0.200s ease-in-out', style({ height: '*' }))]),
      transition(':leave', [style({ height: '*' }), animate('0s', style({ height: '0px' }))]),
    ]),
    trigger('visibleHidden', [
      transition(':enter', [style({ overflow: 'hidden' }), group([animate('0s 1s', style({ overflow: 'visible' }))])]),
      transition(':leave', [style({ overflow: 'visible' }), animate('0s', style({ overflow: 'hidden' }))]),
    ]),
  ],
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  static readonly TOO_MANY_GROUP_INDICES_ERROR = 'A TableRow can only use 1 TableEntry as a group';
  static readonly DEFAULT_NUMBER_ITEMS_PER_PAGE = 10;
  readonly DEFAULT_GROUP = 'default';
  readonly EMPTY_TABLE_ICON = IconPath.NoSearchResults;
  readonly TableHeaderFilterType = TableHeaderFilterType;

  @Input() data: TableData = {} as TableData;
  @Input() isEditable = false;
  @Input() editButtonLabel = 'Edit';
  @Input() isSavable = true;
  @Input() isLoading = false;
  @Input() isRowClickable = false;
  @Input() useShortColumnLabels = false;
  @Input() groupBy = '';
  @Input() rowModifier: RowModifier = '';
  @Input() usePagination = false;
  @Input() startingNumberItemsPerPage = TableComponent.DEFAULT_NUMBER_ITEMS_PER_PAGE;
  @Input() allowFiltering = false;
  @Input() handleFilteringOutsideTable = false;
  @Input() showClearButton = false;
  @Input() makeColumnOverflowEllipsis = false;
  @Input() showEmptyDataIcon = false;
  @Input() emptyDataText: string;
  @Input() bodyTemplate: TemplateRef<any> | null = null;
  @Input() initialDataText: string;
  @Input() showInitialDataText = false;
  @Input() reverseBackgroundColor = false;
  @Input() useApiPagination = false;
  @Input() set modifiers(modifiers: Modifier[]) {
    this.setModifiers(modifiers);
  }

  @Output() edit = new EventEmitter<TableRow>();
  @Output() save = new EventEmitter<TableRow>();
  @Output() close = new EventEmitter<TableRow>();
  @Output() action = new EventEmitter<TableAction>();
  @Output() rowClick = new EventEmitter<TableRow>();
  @Output() filterUpdated = new EventEmitter<Record<string, string>>();
  @Output() apiPaginator = new EventEmitter<CursorPaginatorComponent>();

  tableClassModifiers = '';

  isEditing = false;
  rowBeingEditted: number;
  currentGroup = '';
  tableEntryIndexToGroupBy = 0;
  pagination: IPaginationModel;
  itemsPerPageOptions: SelectListItem[];
  showPageControls = true;

  filteredRows: TableRow[];
  filters: Filter[] = [];

  numberOfFilterResets = 0;
  readonly Utils = Utils;

  private componentDestroyed = new Subject<void>();

  @ViewChild('apiPaginator', { static: false }) paginator: CursorPaginatorComponent;

  constructor(private tableService: TableService) {
    this.itemsPerPageOptions = this.defaultItemsPerPageOptions;
    this.setPagination();
  }

  setModifiers(modifiers: Modifier[]): void {
    this.tableClassModifiers = Utils.getModifiedStringArrayAsString(modifiers, 'app-table--', 'prepend');
  }

  ngOnInit() {
    this.setupGroupForData();
    this.filterRows();
    this.trackCollapseRowsFromService();
    this.trackRefreshFromService();
  }

  ngOnChanges(changes: NgChanges<this>) {
    this.setupGroupForData();
    this.filterRows(true);
    if (changes && (changes.data || changes.usePagination)) {
      this.setPagination(this.startingNumberItemsPerPage);
    }
  }

  ngAfterViewInit() {
    this.apiPaginator.next(this.paginator);
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  trackCollapseRowsFromService(): void {
    this.tableService.collapseAllRows.pipe(takeUntil(this.componentDestroyed)).subscribe(() => this.closeEdit());
  }

  trackRefreshFromService(): void {
    this.tableService.refresh.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
      this.filterRows(true);
      this.setPagination(this.startingNumberItemsPerPage);
    });
  }

  get defaultItemsPerPageOptions(): SelectListItem[] {
    const values = [TableComponent.DEFAULT_NUMBER_ITEMS_PER_PAGE, 20, 50, 100];
    return values.map(value => ({ name: value.toString(), value }));
  }

  setupGroupForData(): void {
    if (!this.tableEntryIndexToGroupBy && Utils.isNonEmptyArray(this.data.groups) && Utils.isNonEmptyArray(this.data.rows)) {
      this.tableEntryIndexToGroupBy = this.data.rows[0].entries.findIndex(entry => this.isTableEntryTheGroup(entry));
      const numberOfGroups = this.data.rows[0].entries.filter(entry => this.isTableEntryTheGroup(entry)).length;
      if (numberOfGroups > 1) {
        throw new Error(TableComponent.TOO_MANY_GROUP_INDICES_ERROR);
      }
    } else {
      this.setDataGroupsToDefault();
    }
  }

  isTableEntryTheGroup(entry: TableEntry): boolean {
    return !Array.isArray(entry.values) && !!entry.values.isGroup;
  }

  private setDataGroupsToDefault(): void {
    this.data.groups = [{ value: this.DEFAULT_GROUP }];
  }

  setPagination(itemsPerPage = TableComponent.DEFAULT_NUMBER_ITEMS_PER_PAGE, currentPage = 1): void {
    this.pagination = {
      itemsPerPage: this.usePagination ? itemsPerPage : this.lengthOfData,
      currentPage: currentPage,
      numberOfFirstItemBeingShown: this.getNumberOfFirstItemBeingShown(itemsPerPage, currentPage),
      numberOfLastItemBeingShown: this.getNumberOfLastItemBeingShown(itemsPerPage, currentPage),
    };

    this.showPageControls = this.filteredRows && this.filteredRows.length > itemsPerPage;
  }

  get lengthOfData(): number {
    return this.data && this.data.rows ? this.data.rows.length : 0;
  }

  getNumberOfFirstItemBeingShown(itemsPerPage: number, currentPage: number): number {
    if (!Utils.isNonEmptyArray(this.filteredRows)) {
      return 0;
    }
    return currentPage * itemsPerPage - itemsPerPage + 1;
  }

  getNumberOfLastItemBeingShown(itemsPerPage: number, currentPage: number): number {
    if (!Utils.isNonEmptyArray(this.filteredRows)) {
      return 0;
    }

    const lastItemNumber = currentPage * itemsPerPage;
    return lastItemNumber > this.filteredRows.length ? this.filteredRows.length : currentPage * itemsPerPage;
  }

  filterRows(reset = false): void {
    this.closeEdit();
    if (!this.data || !Utils.isNonEmptyArray(this.data.rows)) {
      this.filteredRows = [];
    } else if (reset) {
      this.filteredRows = this.data.rows;
      if (!this.handleFilteringOutsideTable) {
        this.resetFilters();
      }
    } else {
      this.filteredRows = this.data.rows.filter(row => this.doesRowPassFilters(row));
    }

    this.setPagination(this.pagination.itemsPerPage, this.pagination.currentPage);
  }

  resetFilters(): void {
    this.filters = [];
    this.numberOfFilterResets += 1;
  }

  doesRowPassFilters(row: TableRow): boolean {
    if (this.filters.length === 0) {
      return true;
    }

    return this.filters.every(_filter => {
      return this.doesEntryContainString(row.entries[_filter.indexOfColumnToApplyTo], _filter.filter);
    });
  }

  doesEntryContainString(entry: TableEntry, testString: string): boolean {
    let values: string[] = [];
    if (!entry || !entry.values) {
      return false;
    } else if (!Array.isArray(entry.values) && entry.values.value) {
      values = [(entry.values as TableValue).value.toString().toLowerCase()];
    } else if (Array.isArray(entry.values)) {
      values = entry.values.map(_value => _value.value.toString().toLowerCase());
    }

    return values.some(_value => _value.includes(testString.toLowerCase()));
  }

  onRowsPerPageChange(newNumberRowsPerPage: number): void {
    this.pagination.itemsPerPage = newNumberRowsPerPage;
    this.setPagination(this.pagination.itemsPerPage, this.pagination.currentPage);
  }

  filterRowsByGroup(rows: TableRow[], groupName: string): TableRow[] {
    if (groupName === this.DEFAULT_GROUP || groupName === '') {
      return rows;
    }
    return rows.filter(row => this.getRowGroupValue(row) === groupName);
  }

  setCurrentGroup(groupName: string): void {
    if (this.currentGroup === groupName) {
      this.currentGroup = '';
    } else {
      this.currentGroup = groupName;
    }
  }

  showDataRow(row: TableRow): boolean {
    if (this.data.groups.length === 0 || this.currentGroup === 'default' || this.data.groups[0].value === 'default') {
      return true;
    }

    if (this.data.groups.length > 0 && this.currentGroup === '') {
      return false;
    }

    if (this.data.groups.length > 0 && this.currentGroup !== '') {
      return this.getRowGroupValue(row) === this.currentGroup;
    }

    return true;
  }

  getRowGroupValue(row: TableRow): string {
    const entry = row.entries[this.tableEntryIndexToGroupBy];
    if (entry && !Array.isArray(entry.values)) {
      return entry.values.value.toString();
    }
    return '';
  }

  isValuesArray(values: TableValue | TableValue[]): values is TableValue[] {
    return values.constructor.name === 'Array';
  }

  isGroupHeader(entry: any): boolean {
    if (this.groupBy === null) {
      return false;
    }
    return true;
  }

  editRow(row: TableRow, rowIndex: number): void {
    this.updateRowIndex(row, rowIndex);

    // Case: User hits edit on row they are already editing
    if (this.handleRowAlreadyEditing(row)) {
      return;
    }

    // Case: User hits edit on a different row than the one they are already
    // editing
    if (this.handleOtherRowEditing(row)) {
      return;
    }

    // Case: User hits edit and they aren't currently editing any rows
    if (this.handleNoRowsEditing(row)) {
      return;
    }
  }

  private updateRowIndex(row: TableRow, rowIndex: number): void {
    row.rowIndex = rowIndex;
  }

  private handleRowAlreadyEditing(row: TableRow): boolean {
    if (this.isEditing && this.showEdit(row.rowIndex!)) {
      this.closeEdit();
      return true;
    }

    return false;
  }

  private handleOtherRowEditing(row: TableRow): boolean {
    if (this.isEditing && !this.showEdit(row.rowIndex!)) {
      this.rowBeingEditted = row.rowIndex!;
      this.edit.next(row);
      return true;
    }

    return false;
  }

  private handleNoRowsEditing(row: TableRow): boolean {
    if (!this.isEditing) {
      this.edit.next(row);
      this.rowBeingEditted = row.rowIndex!;
      this.isEditing = true;
      return true;
    }

    return false;
  }

  isValueImageType(value: TableValue): boolean {
    return value.valueType === 'image';
  }

  isValueTextType(value: TableValue): boolean {
    if (value && value.valueType && value.valueType === 'text') {
      return true;
    } else if (!value || !value.valueType) {
      return true;
    }

    return false;
  }

  isValueButtonType(value: TableValue): boolean {
    return value.valueType === 'button';
  }

  isValueCheckmarkType(value: TableValue): boolean {
    return value.valueType === 'checkmark';
  }

  isValueTextFormType(value: TableValue): boolean {
    return value.valueType === 'textForm';
  }

  isValueNumberFormType(value: TableValue): boolean {
    return value.valueType === 'numberForm';
  }

  isValueTemplateType(value: TableValue): boolean {
    return value.valueType === 'templateRef';
  }

  showEdit(rowIndex: number): boolean {
    return this.isEditing && rowIndex === this.rowBeingEditted;
  }

  closeEdit(row?: TableRow): void {
    this.isEditing = false;
    if (row) {
      this.close.emit(row);
    }
    this.rowBeingEditted = 0;
  }

  saveRow(row: TableRow): void {
    this.save.next(row);
  }

  emitAction(actionName: string, row: TableRow): void {
    const action: TableAction = {
      name: actionName,
      rowContainingAction: row,
    };

    this.action.emit(action);
  }

  emitRowClick(row: TableRow): void {
    if (this.isRowClickable) {
      this.rowClick.emit(row);
    }
  }

  onPageChange(newPage: number): void {
    this.closeEdit();
    this.setPagination(this.pagination.itemsPerPage, newPage);
  }

  onFilterChange(filter: string, columnToFilterOn: number): void {
    if (this.handleFilteringOutsideTable) {
      const header = this.data.headers[columnToFilterOn];
      const filterRecord: Record<string, any> = {};
      filterRecord[header.name] = filter;
      this.filterUpdated.emit(filterRecord);
    } else {
      this.updateFilters(filter, columnToFilterOn);
      this.filterRows();
    }
  }

  updateFilters(filter: string, columnToFilterOn: number): void {
    const filterToUpdate = this.filters.find(_filter => _filter.indexOfColumnToApplyTo === columnToFilterOn);

    if (filter === '') {
      this.removeFilter(filterToUpdate);
      return;
    }

    if (filterToUpdate) {
      filterToUpdate.filter = filter;
    } else {
      this.filters.push({ filter: filter, indexOfColumnToApplyTo: columnToFilterOn });
    }
  }

  removeFilter(filterToRemove?: Filter): void {
    const filterIndex = this.filters.findIndex(filter => filter.indexOfColumnToApplyTo === filterToRemove?.indexOfColumnToApplyTo);
    this.filters.splice(filterIndex, 1);
  }
}
