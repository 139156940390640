<div class="input-group" [ngClass]="{ 'input-group-sm': size === 'sm', 'input-group-lg': size === 'lg' }">
  <input
    ngbDatepicker
    #dp="ngbDatepicker"
    (closed)="close()"
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="2"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [startDate]="fromDate!"
    [dayTemplate]="dayTemplate"
    [footerTemplate]="footerTemplate"
    [container]="container"
    autoClose="outside"
    outsideDays="hidden"
    class="hidden-control"
  />
  <input readonly [value]="value" class="form-control" />
  <button class="btn btn-outline-light" type="button" [disabled]="disabled" (click)="dp.toggle()">
    <i class="material-icons md-inline">calendar_today</i>
  </button>
</div>

<ng-template #dayTemplate let-date let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>

<ng-template #footerTemplate>
  <button class="btn btn-link btn-sm m-2 float-left" (click)="dp.toggle(); reset()">Reset</button>
  <button class="btn btn-link btn-sm m-2 float-right" (click)="dp.toggle(); ok()">OK</button>
</ng-template>
