import gql from 'graphql-tag';

export const GetPharmacyForInventoryQuery = gql`
  query GetPharmacyForInventory($ncpdp: String!) {
    pharmacy(ncpdp: $ncpdp) {
      name
      npi
      ncpdp
      enrollments {
        tenant {
          id
        }
        enrolledPrograms {
          id
        }
      }
    }
  }
`;
