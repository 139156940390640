import { Component, inject, OnInit } from '@angular/core';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { DateService } from '@services/date.service';
import { isString } from 'lodash';
import moment from 'moment';

class NgbDateISOAdapter implements NgbDateAdapter<string> {
  fromModel(value: string | null): NgbDateStruct | null {
    const date = moment(value);
    return { year: date.year(), month: date.month() + 1, day: date.date() };
  }

  toModel(date: NgbDateStruct | null): string | null {
    if (date) {
      return moment({ day: date.day, month: date.month - 1, year: date.year }).format('yyyy-MM-DD') + 'T12:00:00Z';
    }
    return null;
  }
}

@Component({
  selector: 'app-formly-field-date',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateISOAdapter }],
  template: ` <div class="app-formly-field-date-wrapper">
    <input
      type="text"
      class="form-control"
      ngbDatepicker
      #dp="ngbDatepicker"
      [ngClass]="{ 'is-invalid': !formControl.valid && formControl.touched && !field.props?.disabled }"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [attr.disabled]="field.props?.disabled"
      [readonly]="field.props?.readonly"
      [maxDate]="field.props?.maxDate"
      [minDate]="field.props?.minDate"
      [autoClose]="true"
      (click)="dp.toggle()"
    />
    <span class="icon material-icons" (click)="dp.toggle()">calendar_month</span>
  </div>`,
  styles: [
    `
      .app-formly-field-date-wrapper {
        position: relative;
      }

      .app-formly-field-date-wrapper input {
        padding-left: 36px !important;
      }

      .app-formly-field-date-wrapper .icon {
        position: absolute;
        top: 6px;
        left: 6px;
        cursor: pointer;
      }
    `,
  ],
})
export class FormlyFieldDateComponent extends FieldType<FieldTypeConfig> implements OnInit {
  private readonly dateService = inject(DateService);

  ngOnInit() {
    if (this.field.props) {
      if (isString(this.field.props.maxDate)) {
        this.field.props.maxDate = this.dateService.parseNgbDate(this.field.props.maxDate);
      }
      if (isString(this.field.props.minDate)) {
        this.field.props.minDate = this.dateService.parseNgbDate(this.field.props.minDate);
      }
    }
  }
}
