import { Injectable } from '@angular/core';
import { BaseStoreService } from '@services/base-store.service';
import { PharmacyApiService } from '@services/pharmacy-api.service';
import { ProgramApiService } from '@services/program-api.service';
import { UserService } from '@services/user.service';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { Program } from '@shared/models/program.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserStoreService extends BaseStoreService {
  constructor(
    private userService: UserService,
    private pharmacyApiService: PharmacyApiService,
    private programApiService: ProgramApiService,
  ) {
    super();
  }

  getPharmacies(): Observable<IPharmacy[]> {
    return this.get('pharmacies', this.pharmacyApiService.getUserPharmacyList());
  }

  getPrograms(): Observable<Program[]> {
    const tenants = this.userService.getCurrentUser()?.tenants ?? [];
    return this.get('programs', this.programApiService.getAllProgramsForTenants(tenants));
  }
}
