import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from '@shared/components/base-component';

@Component({
  selector: 'chitin-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
})
export class ChitinChipsInputComponent extends BaseComponent implements AfterViewInit {
  @Input()
  inputId?: string;

  @Input()
  name?: string;

  @Input()
  isDisabled: boolean = false;

  @Input()
  readonly: boolean = false;

  @Input()
  layout: 'block' | 'inline' = 'block';

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  placeholder?: string;

  @Input()
  label?: string;

  @Input()
  clearable: boolean = true;

  @Input()
  emissionDelayInMs = 0;

  @Input()
  emitValueTrigger: Observable<void>;

  @Input()
  set value(value: string[] | undefined) {
    this._chips = [];
    this._htmlValue = undefined;
    if (value) {
      this.setHtmlValue(value.join(','), true);
    }
  }

  set htmlValue(htmlValue: string | undefined) {
    this.setHtmlValue(htmlValue);
  }

  @Output()
  blur = new EventEmitter<void>();

  @Output()
  valueChange = new EventEmitter<string[]>();

  @Output()
  emitValueTriggerFinished = new EventEmitter<string[]>();

  @ViewChild('inputFieldToFocus')
  private inputFieldToFocus: ElementRef;

  private _chips: string[] = [];
  get chips() {
    return this._chips;
  }
  private _htmlValue?: string;
  get htmlValue() {
    return this._htmlValue;
  }

  private setHtmlValue(htmlValue: string | undefined, includeLastChip = false, emitTriggerFinished = false) {
    if (htmlValue) {
      const splitHtmlValue = htmlValue.split(',');
      if (splitHtmlValue.length > 0) {
        if (includeLastChip && splitHtmlValue[splitHtmlValue.length - 1].length > 0) {
          this._chips.push(...splitHtmlValue);
        } else {
          this._chips.push(...splitHtmlValue.slice(0, -1));
          this._htmlValue = splitHtmlValue[splitHtmlValue.length - 1];
        }
        this.valueChange.next(this._chips);
      }
    }
    if (emitTriggerFinished) {
      this.emitValueTriggerFinished.next(this._chips);
    }
  }

  ngAfterViewInit() {
    this.subscribe(this.emitValueTrigger, () => {
      this.setHtmlValue(this.htmlValue, true, true);
    });
  }

  focus() {
    setTimeout(() => {
      this.inputFieldToFocus.nativeElement.focus();
    });
  }

  protected removeChip(chipIndex: number) {
    this._chips.splice(chipIndex, 1);
  }

  protected clearChips() {
    this._chips = [];
    this.valueChange.next([]);
  }

  protected onBlur() {
    this.blur.next();
  }
}
