import { Component } from '@angular/core';
import config from '@config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  year = new Date().getFullYear();
  version = config.version;
  releaseEnv = config.name;
}
