import { Component } from '@angular/core';
import { SelfServeStep } from '@shared/models/self-serve-step.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CopayCodeRequestStateService } from '@services/copay-code-request-state.service';
import { switchMap } from 'rxjs/operators';
import { ICopayCodeRequest } from '@shared/models/copay-code-request.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-how-did-we-do',
  templateUrl: './how-did-we-do.component.html',
  styleUrls: ['./how-did-we-do.component.scss'],
})
export class HowDidWeDoComponent extends SelfServeStep {
  static readonly COMPONENT_NAME = 'WeeklyBatchRequestCodesComponent';
  private feedback = 0;

  constructor(
    private statefulCopayCodeRequestService: CopayCodeRequestStateService,
    private router: Router,
    private location: Location,
  ) {
    super();
  }

  updateValue(newVal: number): void {
    this.feedback = newVal;
  }

  onContinue(): Observable<any> {
    let codeRequestFieldsToUpdate = {} as Partial<ICopayCodeRequest>;

    if (this.wasTransactionPassedToThisComponent()) {
      codeRequestFieldsToUpdate = this.codeRequestPassedToThisComponent;
    }

    codeRequestFieldsToUpdate.user_feedback = this.feedback;
    codeRequestFieldsToUpdate.current_component = HowDidWeDoComponent.COMPONENT_NAME;

    return this.statefulCopayCodeRequestService.update(codeRequestFieldsToUpdate).pipe(switchMap(_ => this.router.navigate(['retail'])));
  }

  private wasTransactionPassedToThisComponent(): boolean {
    return !!this.location.getState() && !!this.codeRequestPassedToThisComponent;
  }

  get codeRequestPassedToThisComponent(): ICopayCodeRequest {
    const state: Record<string, any> = this.location.getState() as Record<string, any>;
    return state['codeRequestToUpdate'];
  }
}
