import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Navlink } from '@shared/models/navlink.model';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnChanges {
  @Input()
  public navLinks: Navlink[];
  @Input()
  public pharmacies?: Navlink[];
  @Input()
  public bottomLinks?: Navlink[];
  @Input()
  public isLoading = false;
  @Input()
  public isClosed = false;
  @Input()
  public loadingCount = 0;

  pharmacySearch = '';
  filteredPharmacies: Navlink[] = [];
  pharmacySearchEnabled = false;

  constructor(protected activeRoute: ActivatedRoute) {}

  ngOnChanges(changes: NgChanges<this>) {
    if (!changes.pharmacies) return;
    if (changes.pharmacies.currentValue.length > 10) {
      this.pharmacySearchEnabled = true;
    } else {
      this.pharmacySearchEnabled = false;
    }
    this.filteredPharmacies = changes.pharmacies.currentValue;
  }

  pharmacySearchChanged() {
    if (!this.pharmacies) return;
    this.filteredPharmacies = this.pharmacies.filter(value => value.label.toLowerCase().includes(this.pharmacySearch.toLowerCase()));
  }
}
