import { Injectable } from '@angular/core';
import { ExtendedProgramCodeAvailability } from '@app/retail/components/user-programs-listing/user-programs-listing.component';
import { IconPath } from '@shared/models/icon-path.enum';
import { TableValue } from '@shared/models/table-value.model';
import {
  DataSourceType,
  DataTransformationReturn,
  DataTransformationReturnType,
  DataTransformationStrategy,
} from '@shared/providers/data-transformation/data-transformation.model';
import { isObject } from 'lodash';

@Injectable()
export class NextEligibleDateWithReadyTextAndIconTableValuesStrategy implements DataTransformationStrategy {
  transform(parameters?: Record<DataSourceType, any>): DataTransformationReturn {
    const transformation = {} as DataTransformationReturn;
    transformation.value = [] as TableValue[];
    const availabilityForProgram: ExtendedProgramCodeAvailability = parameters && parameters[DataSourceType.ProgramCodeAvailability];

    if (isObject(availabilityForProgram)) {
      transformation.value.push({
        value: availabilityForProgram.canDispense ? 'READY' : this.getFormattedTimeUntilNextDispense(availabilityForProgram),
        valueType: 'text',
        styles: {
          display: 'inline',
        },
      });

      transformation.types = [DataTransformationReturnType.TextWithIcon];

      if (availabilityForProgram.canDispense) {
        transformation.value.push({
          value: IconPath.GreenCheck,
          valueType: 'image',
          styles: {
            display: 'inline',
          },
        });

        transformation.types.push(DataTransformationReturnType.Dispense);
      }
    }

    if (transformation.value.length === 0) {
      transformation.value.push({ value: '' });
    }

    return transformation;
  }

  private getFormattedTimeUntilNextDispense(availabilityForProgram: ExtendedProgramCodeAvailability): string {
    return Math.abs(Math.floor(availabilityForProgram.timeUntilNextDispense)) + ' ' + availabilityForProgram.timeUnit;
  }
}
