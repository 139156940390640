<div ngbDropdown class="dropdown" [style.height.px]="height ? height : null">
  <button ngbDropdownToggle [style.height.px]="height ? height : null" class="dropdown__toggle" data-cy="dropdownBtn" [disabled]="disabled">
    <span data-cy="selectedOption"> {{ dropdownModel ? getSelectedValue() : label }} </span>
    <i *ngIf="!useThinArrow" class="material-icons"> arrow_drop_down </i>
    <div *ngIf="useThinArrow" class="dropdown__thin-arrow"></div>
  </button>
  <div ngbDropdownMenu>
    <button
      *ngFor="let option of dropdownOptions"
      ngbDropdownItem
      class="dropdown__option"
      [disabled]="disabled"
      (click)="emitModelChange(option)"
      data-cy="dropdownOption"
    >
      {{ option.name }}
    </button>
  </div>
</div>
