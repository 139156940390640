import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'chitin-checkbox',
  templateUrl: './checkbox.component.html',
})
export class ChitinCheckboxComponent {
  @Input()
  inputId?: string;

  @Input()
  name?: string;

  @Input()
  mode: 'checkbox' | 'switch' = 'checkbox';

  @Input()
  size: 'medium' | 'large' = 'medium';

  @Input()
  disabled: boolean = false;

  @Input()
  reverse: boolean = false;

  @Input()
  label?: string;

  @Input()
  value?: boolean = false;

  @Input()
  clickableLabel?: boolean = false;

  @Output()
  valueChange = new EventEmitter<boolean>();

  protected labelClicked() {
    if (this.clickableLabel) {
      this.valueChange.emit(!this.value);
    }
  }
}
