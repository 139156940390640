<perfect-scrollbar *ngIf="!isMobile && !isTablet">
  <ng-container [ngTemplateOutlet]="agreement"></ng-container>
</perfect-scrollbar>

<ng-container *ngIf="isMobile || isTablet" [ngTemplateOutlet]="agreement"></ng-container>

<!-- Templates -->

<ng-template #agreement>
  <div class="eagle-copay">
    <h1 *ngIf="shouldShowHeader" class="eagle-copay__header">Eagle Co-Pay Assistance Program</h1>

    <p [class.eagle-copay__first-paragraph]="shouldShowHeader">
      Offer: Commercial Patient will pay $10 for prescription if their primary insurance returns an NDC block and $0 for High Copay/High
      Rebate prescriptions.
    </p>

    <p>Please utilize the following information when submitting claims to the co-pay program:</p>

    <div class="eagle-copay__info">
      <h2 class="eagle-copay__info-title">Co-Pay</h2>

      <div>
        <div><span class="eagle-copay__info-category">BIN #:</span>004682</div>
        <div><span class="eagle-copay__info-category">PCN #:</span>CN</div>
        <div><span class="eagle-copay__info-category">GROUP #:</span>ECHORIZON8 ($10) & ECHORIZON9 ($0)</div>
        <div>
          <span class="eagle-copay__info-category">ID #:</span><span class="u-font-weight-500">See accompanying Member ID File</span>
        </div>
      </div>
    </div>

    <p>
      <span class="u-font-weight-500"
        >Pharmacist Instructions for a Patient with an Eligible Third Party with an NDC block or Prior Auth:</span
      >
      Submit the claim to the primary Third Party Payer first, then submit the balance due to
      <span class="u-font-weight-500">Preferred Network</span> as a Secondary Payer COB [coordination of benefits] with patient
      responsibility amount and a valid Other Coverage Code, <span class="u-font-weight-500">(e.g. 3)</span>. Patient will pay $10 for a 30
      days supply. Reimbursement will be received from <span class="u-font-weight-500">Preferred Network</span>.
    </p>

    <p class="eagle-copay__page-separator">
      Valid Other Coverage Code required. For any questions regarding <span class="u-font-weight-500">Preferred Network</span> online
      processing, please call the Help Desk at 1-800-433-4893
    </p>

    <p>
      <span class="u-font-weight-500">Pharmacist:</span> In order to redeem this offer, you must have a valid commercial prescription. The
      offer may not be redeemed for CASH. By using this offer, you are certifying that the patient meets the eligibility criteria
      (commercial beneficiary) and you will comply with the terms and conditions described below.
    </p>

    <p>
      <span class="u-font-weight-500">*Terms and Conditions:</span> Offer cannot be combined with any other rebate or coupon, free trial, or
      similar offer for the specified prescription.
      <span class="eagle-copay__bold-underline"
        >Not valid for prescriptions reimbursed in whole or in part by Medicaid, Medicare, VA, DOD, TriCare or other federal or state
        programs (including state prescription drug programs)</span
      >. Offer good only in the United States at participating retail pharmacies for eligible Horizon Pharma products. Offer not valid where
      otherwise prohibited by law, for example by applicable state law prohibiting co-pay cards. Horizon Pharma reserves the right to
      rescind, revoke, or amend the offer without notice. The selling, purchasing, trading, or counterfeiting of this offer is prohibited by
      law. This card is not insurance and is not intended to substitute for insurance. Participating patients and pharmacists understand and
      agree to comply with all Terms and Conditions of this offer.
    </p>
  </div>
</ng-template>
