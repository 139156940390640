import gql from 'graphql-tag';

const pharmacyChainBankFragment = gql`
  fragment PharmacyChainBank on PharmacyChainBank {
    accountNumber
    routingNumber
  }
`;

const pharmacyChainContact = gql`
  fragment PharmacyChainContact on PharmacyChainContact {
    email
    name
  }
`;

const pharmacyChainAddressFragment = gql`
  fragment PharmacyChainAddress on PharmacyChainAddress {
    lineOne
    city
    state
    zip
  }
`;

export const pharmacyChainFragment = gql`
  ${pharmacyChainBankFragment}
  ${pharmacyChainContact}
  ${pharmacyChainAddressFragment}
  fragment PharmacyChain on PharmacyChain {
    _id
    name
    npi
    taxId
    bank {
      ...PharmacyChainBank
    }
    contact {
      ...PharmacyChainContact
    }
    address {
      ...PharmacyChainAddress
    }
    updatedAt
    destinationId
    remittanceAdviceStartDate
    authorizationAttachments
  }
`;
