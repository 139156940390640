import { Directive, ContentChild, Input } from '@angular/core';
import { TabContentDirective } from '@shared/components/tabs/tab-content.directive';

@Directive({
  selector: 'app-tab',
})
export class TabDirective {
  @Input() title = '';
  @Input() flexDirection: 'column' | 'row' = 'column';

  @ContentChild(TabContentDirective, { static: false }) content: TabContentDirective;

  constructor() {}
}
