import { pharmacyChainFragment } from '@services/graphql/pharmacy/chain/pharmacy-chain.fragment';
import gql from 'graphql-tag';

export const CreatePharmacyChainMutation = gql`
  ${pharmacyChainFragment}
  mutation CreatePharmacyChain($data: CreatePharmacyChainInput!) {
    createPharmacyChain(data: $data) {
      ...PharmacyChain
      authorizationAttachmentUrls(forDownload: false) {
        name
        url
      }
    }
  }
`;
