import gql from 'graphql-tag';

export const GetPharmacyEnrollmentsQuery = gql`
  query GetPharmacyEnrollments($ncpdp: String!) {
    pharmacy(ncpdp: $ncpdp) {
      enrollments {
        engagements {
          lossToFollow {
            isSubscribed
          }
          pba {
            isApprovedResponseEnabled
            responseSettings {
              matchingCapturedResponseOccList
              paidCapturedResponseOccList
            }
          }
          priorAuthorization {
            arePayorApprovedEmailNotificationsEnabled
            arePayorApprovedFaxNotificationsEnabled
            arePayorDeniedEmailNotificationsEnabled
            arePayorDeniedFaxNotificationsEnabled
            arePrescriberNonResponseEmailNotificationsEnabled
            arePrescriberNonResponseFaxNotificationsEnabled
            isSkipHoldAvailable
            isSubscribed
            notificationEmails
            isParticipant
            isBrightclockEnabled
          }
          brightscrip {
            isScorecardEnabled
          }
          rxLogistics {
            isParticipant
          }
        }
        fieldAdmin
        isConsignmentHub
        isPortalRequestBlocked
        isVisibleToRam
        networkGroup {
          id
          name
        }
        preferredTransferMethod
        programGroup {
          id
          name
          isBlocked
        }
        tenant {
          id
          name
        }
      }
      name
      ncpdp
      npi
    }
  }
`;
