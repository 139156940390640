export const ROLE_ADMINISTRATOR = 'Administrator';
export const ROLE_AGENT = 'Agent';
export const ROLE_BUSINESS_EXEC = 'BusinessExec';
export const ROLE_FIELD_ADMIN = 'FieldAdmin';
export const ROLE_PHARMACY = 'Pharmacy';
export const ROLE_PHARMACY_UPLOADER = 'PharmacyUploader';
export const ROLE_PROGRAM_ADMIN = 'ProgramAdmin';
export const ROLE_PROGRAM_ADMIN_ALM = 'ProgramAdminALM';
export const ROLE_PROGRAM_ADMIN_READ = 'ProgramAdminRead';
export const ROLE_RETAIL_PHARMACY = 'RetailPharmacy';
export const ROLE_RX_CONNECT_ADMIN_READ = 'RxConnectAdminRead';
export const ROLE_PHARMACY_OWNER = 'PharmacyOwner';
export const ROLE_DATA_SCIENCE_USER = 'DataScienceUser';
export const ROLE_FINANCE_ADMIN = 'FinanceAdmin';
export const ROLE_PA_ADMIN = 'PaAdmin';
export const ROLE_API_PHARMACY = 'ApiPharmacy';
export const ROLE_PATIENT = 'Patient';
export const ROLE_KIOSK = 'Kiosk';
export const ROLE_TOKEN_PHARMACY = 'TokenPharmacy';
export const ROLE_TOKEN_PATIENT = 'TokenPatient';
export const ROLE_RECON_PARTNERS = 'ReconPartners';

const sharedMasterDataUserRolesAsConst = [
  ROLE_ADMINISTRATOR,
  ROLE_AGENT,
  ROLE_BUSINESS_EXEC,
  ROLE_FIELD_ADMIN,
  ROLE_PHARMACY,
  ROLE_PHARMACY_UPLOADER,
  ROLE_PROGRAM_ADMIN,
  ROLE_PROGRAM_ADMIN_ALM,
  ROLE_PROGRAM_ADMIN_READ,
  ROLE_RETAIL_PHARMACY,
  ROLE_RX_CONNECT_ADMIN_READ,
  ROLE_PHARMACY_OWNER,
  ROLE_DATA_SCIENCE_USER,
  ROLE_FINANCE_ADMIN,
  ROLE_PA_ADMIN,
  ROLE_API_PHARMACY,
  ROLE_PATIENT,
  ROLE_KIOSK,
  ROLE_TOKEN_PHARMACY,
  ROLE_TOKEN_PATIENT,
  ROLE_RECON_PARTNERS,
] as const;

export const SHARED_MASTER_DATA_USER_ROLES = [...sharedMasterDataUserRolesAsConst];
export type SharedMasterDataUserRoleType = (typeof sharedMasterDataUserRolesAsConst)[number];
