import { Injectable } from '@angular/core';
import { TenantService } from '@services/tenant.service';
import { Product } from '@shared/models/product.model';
import { DataTransformationParameterSelectionStrategy } from '@shared/providers/data-transformation/data-transformation.model';
import Utils from '@shared/providers/utils';

@Injectable()
export class ParameterSelectionStrategies {
  constructor(private tenantService: TenantService) {}

  passThroughStrategy: DataTransformationParameterSelectionStrategy = data => data;

  productNamesStrategy: DataTransformationParameterSelectionStrategy = (products: Product[]) => {
    if (Utils.isNonEmptyArray(products)) {
      return products.map(product => product.name);
    }
    return [];
  };

  firstElementHowSuppliedStrategy: DataTransformationParameterSelectionStrategy = (products: Product[]) => {
    if (Utils.isNonEmptyArray(products) && products[0].howSupplied) {
      return products[0].howSupplied;
    }
    return '';
  };

  firstElementNdcStrategy: DataTransformationParameterSelectionStrategy = (products: Product[]) => {
    if (Utils.isNonEmptyArray(products) && products[0].ndc) {
      return products[0].ndc;
    }
    return '';
  };

  tenantNameStrategy: DataTransformationParameterSelectionStrategy = (tenantId: string) => {
    if (tenantId) {
      const tenant = this.tenantService.getAllTenants().find(_tenant => _tenant.id === tenantId);
      if (tenant) {
        return tenant.name;
      }
    }
    return '';
  };
}
