import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { RoutesExplorer } from '@app/router-explorer';
import { FeatureFlagsService } from '@services/feature-flags.service';
import { Guard } from '@services/guards/guard';
import { UserService } from '@services/user.service';
import { FeatureFlagContextAttributes } from '@shared/models/feature-flag-context-attributes';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements Guard {
  protected readonly router = inject(Router);
  protected readonly userService = inject(UserService);
  protected readonly featureFlagsService = inject(FeatureFlagsService);

  canActivate(route: ActivatedRouteSnapshot) {
    const componentName: string = this.normalizeCmpName(route.data.componentName ?? route.component?.name ?? '');
    const featureFlagKey: string = route.data.featureFlagKey ?? '';
    const params = RoutesExplorer.getParams(RoutesExplorer.getRouteUrl(route));
    return this.isAccessGranted(componentName, featureFlagKey, params, true);
  }

  canShow(route: Route, params: Record<string, string>) {
    const componentName: string = this.normalizeCmpName(route.data?.componentName ?? route.component?.name ?? '');
    const featureFlagKey: string = route.data?.featureFlagKey ?? '';
    return this.isAccessGranted(componentName, featureFlagKey, params);
  }

  async isAccessGranted(componentName: string, featureFlagKey: string, params: Record<string, string>, log = false) {
    if (!this.userService.isUserAuthenticated()) return false;
    let isAccessGranted = this.userService.isComponentAccessGranted(componentName);
    if (!componentName) isAccessGranted = true;
    if (!isAccessGranted && log) console.warn('AuthGuard', componentName);

    if (isAccessGranted && featureFlagKey.length && this.featureFlagExists(featureFlagKey)) {
      isAccessGranted = this.isFeatureFlagOn(featureFlagKey, this.getContext(params));
      if (!isAccessGranted && log) console.warn('AuthGuard', featureFlagKey);
    }

    return isAccessGranted;
  }

  protected normalizeCmpName(name: string) {
    return name.startsWith('_') ? name.substring(1) : name;
  }

  protected featureFlagExists(featureFlagKey: string): boolean {
    featureFlagKey = featureFlagKey.replace(/\//gi, '|');
    return this.featureFlagsService.exists(featureFlagKey);
  }

  protected isFeatureFlagOn(featureFlagKey: string, context: FeatureFlagContextAttributes): boolean {
    featureFlagKey = featureFlagKey.replace(/\//gi, '|');
    return this.featureFlagsService.isOnWithContext(featureFlagKey, context);
  }

  protected getContext(params: Record<string, string>): FeatureFlagContextAttributes {
    const context: FeatureFlagContextAttributes = {
      ncpdps: [],
      programGroups: [],
      regions: [],
      tenants: [],
    };

    if (params.ncpdp) context.ncpdps?.push(params.ncpdp);
    if (params.programGroup) context.programGroups?.push(params.programGroup);
    if (params.region) context.regions?.push(params.region);
    if (params.tenantId) context.tenants?.push(params.tenantId);

    return context;
  }
}
