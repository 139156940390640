<perfect-scrollbar *ngIf="!isMobile && !isTablet">
  <ng-container [ngTemplateOutlet]="agreement"></ng-container>
</perfect-scrollbar>

<ng-container *ngIf="isMobile || isTablet" [ngTemplateOutlet]="agreement"></ng-container>

<!-- Templates -->

<ng-template #agreement>
  <div class="alm-tc">
    <div class="alm-tc__image-container">
      <img class="alm-tc__image" src="assets/images/almirall-seysara.png" />
    </div>

    <h2 class="alm-tc__header">Easy, affordable access to Almirall’s SEYSARA® (sarecycline) tablets</h2>

    <p class="alm-tc__info">
      <span class="u-font-weight-700">To the Patient: </span>
      Almirall, LLC, is committed to making its products affordable for patients. This card may be used to reduce your out-of-pocket costs
      for SEYSARA. To participate, present this card to the pharmacist, along with a valid prescription and information about your private
      commercial insurance coverage. If you are enrolled in a medical or prescription drug insurance plan under a federal- or state-funded
      program, such as Medicaid, Medicare (including Medicare Advantage and Medicare Part D), Medigap, VA, DOD, CHAMPUS, TRICARE, or any
      other federal or state health program (e.g., medical assistance program), you are not eligible to use or benefit from this offer. By
      using this offer, you are certifying that you meet the eligibility criteria and will comply with the terms and conditions described in
      the Restrictions section below. Eligible patients who do not have coverage for SEYSARA may pay as little as $60 per fill when using
      this card at a participating pharmacy within Almirall’s pharmacy network, or as little as $125 per fill if using this card at a retail
      pharmacy. Additional savings may apply for eligible patients who have coverage for SEYSARA under their commercial insurance plan.
      However, eligible patients may receive no more than a maximum benefit amount per person, per year. Maximum benefits apply depending on
      the terms of your commercial insurance plan. This offer is not valid when the entire cost of your prescription drug is eligible to be
      reimbursed by your commercial insurance plan or other health or pharmacy benefit programs. If you are a patient with questions about
      the Almirall Advantage program or this offer, you may call:
      <span class="u-font-weight-700">1-888-591-9860</span>.
    </p>

    <p class="alm-tc__info">
      <span class="u-font-weight-700">To the Pharmacist: </span>
      This card may be used for savings on SEYSARA only. Cash discounts, cash equivalents and cash discount cards may not be used in
      combination with this offer. Almirall, LLC, is taking active measures to ensure the appropriate use of this program by pharmacies.
      When you apply this offer, you are certifying that you will comply with the terms and conditions described in the Restrictions section
      below.
    </p>

    <p class="alm-tc__info">
      <span class="u-font-weight-700">Pharmacist Instructions for Eligible Patients with Private Commercial Insurance: </span>
      Submit the claim to the primary Third-Party Payer first, then submit the balance due to CHANGE HEALTHCARE as a Secondary Payer COB
      (coordination of benefits), with patient responsibility amount and a valid Other Coverage Code (e.g., 8 or 3). Maximum reimbursement
      limits apply, as described in the patient instructions above. Reimbursement will be received from CHANGE HEALTHCARE. For any questions
      regarding CHANGE HEALTHCARE online processing, please call the Help Desk at 1-800-433-4893.
    </p>

    <p class="alm-tc__info">
      <span class="u-font-weight-700">RESTRICTIONS: </span>
      This offer is valid only in the United States. No other purchase is necessary. Program is applicable only for commercially insured
      patients. Cash discounts, cash equivalents or cash discount cards cannot be used in combination with this offer. This card is not
      health insurance. Offer is not valid for patients who are enrolled in a federal-or state-funded healthcare program, including
      Medicaid, a Medicare drug benefit plan (including Medicare Advantage and Parts A, B and D plans), Medigap, VA, DOD, CHAMPUS, or
      TRICARE or other federal or state health programs (such as medical assistance programs). If the patient is eligible for benefits under
      any such program, the patient cannot use this offer. By using this offer, the patient certifies that he or she will comply with any
      terms of his or her health insurance contract requiring notification to his or her payer of the existence and/or value of this offer.
      Patient, guardian, pharmacist, and prescriber agree not to seek reimbursement for all or any part of the benefit received by the
      recipient through this offer. It is illegal to (or to offer to) sell, purchase, or trade this offer. This offer is not transferable
      and has no cash value. It cannot be combined with any other rebate/coupon, free trial, or similar offer for the specified
      prescription(s). Not valid if reproduced. Void where prohibited by law. Program managed by ConnectiveRx, on behalf of Almirall, LLC.
      Almirall, LLC, reserves the right to rescind, revoke, amend or terminate this offer without written notice at any time.
    </p>

    <p class="alm-tc__footer alm-tc__footer--first">©2019 Almirall, LLC, Exton PA 19341. www.almirall.us. All Rights Reserved.</p>

    <p class="alm-tc__footer">US-SEY-1900013 12-2019</p>
  </div>
</ng-template>
