export const PAYOR_PLAN_TYPES = [
  'Commercial',
  'Medicare',
  'Mix',
  'HIX',
  'Non-Benefit',
  'FFS Medicaid',
  'CHIP',
  'Cash',
  'Workers Comp',
  'Managed Medicaid',
];
