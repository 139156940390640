<footer class="footer">
  <div class="py-3">
    <div class="text-muted">
      <!-- <div class="col-6 text-start">
        <ul class="list-inline">
          <li class="list-inline-item">
            <a class="text-muted" href="#">Support</a>
          </li>
          <li class="list-inline-item">
            <a class="text-muted" href="#">Help Center</a>
          </li>
          <li class="list-inline-item">
            <a class="text-muted" routerLink="/admin/privacy">Privacy</a>
          </li>
          <li class="list-inline-item">
            <a class="text-muted" href="#">Terms of Service</a>
          </li>
        </ul>
      </div> -->
      <div class="text-center">
        <p class="mb-0">
          brightscrip is made with ❤️ at
          <a href="https://www.monarchsp.com/" class="text-muted" target="_blank">Monarch Specialty Group</a>
          &copy; {{ year }}
        </p>
      </div>
    </div>
  </div>
</footer>
