<div class="chitinSelect" ngbDropdown #selectDropdown="ngbDropdown">
  <input
    class="form-control"
    [ngClass]="[disabled ? 'disabled' : '', 'form-control-' + this.size, value !== undefined && value !== '' ? 'has-value' : '']"
    [placeholder]="displayText"
    [disabled]="disabled"
    [readonly]="!searchable"
    [(ngModel)]="searchTerm"
    (ngModelChange)="onSearchTermChanged($event)"
    ngbDropdownAnchor
    (click)="selectDropdown.toggle()"
    (keyup.enter)="selectDropdown.toggle()"
  />
  <div ngbDropdownMenu>
    @if (groupItems) {
      @for (group of groupedItems | keyvalue; track group) {
        <button class="dropdown-group" [ngClass]="{ active: group.value.selected }" (click)="group.value.open = !group.value.open">
          <span class="me-2">{{ group.key }}</span>
          <span style="font-size: 80%">
            <chitin-icon [icon]="group.value.open ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'" />
          </span>
        </button>
        @if (group.value.open) {
          @for (option of group.value.items; track option) {
            <button
              [ngClass]="{ active: option.value === value || (!!value && $any(value).includes(option)) }"
              ngbDropdownItem
              style="padding-inline-start: 2rem !important"
              (click)="selectItem(option)"
            >
              {{ option.name }}
            </button>
          }
        }
      }
    } @else {
      <button
        *ngFor="let option of visibleOptions"
        [ngClass]="{ active: selectedItems.includes(option) }"
        ngbDropdownItem
        (click)="selectItem(option)"
      >
        {{ option.name }}
      </button>
    }
  </div>
</div>
