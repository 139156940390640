import { Component, Input } from '@angular/core';
import { IconPath } from '@shared/models/icon-path.enum';

@Component({
  selector: 'app-up-down-arrow',
  templateUrl: './up-down-arrow.component.html',
  styleUrls: ['./up-down-arrow.component.scss'],
})
export class UpDownArrowComponent {
  @Input() up = false;

  IconPath = IconPath;
}
