import { inject, Injectable } from '@angular/core';
import { SettingsService } from '@services/settings.service';
import { StorageType, Store } from '@services/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SettingsStore extends Store<any> {
  protected static storageType: StorageType = 'session';
  private readonly service = inject(SettingsService);

  protected getDataSource(key: string): Observable<any> {
    const [subject, attribute] = key.split('#');
    if (attribute?.length) {
      return this.service.get(subject, attribute);
    }
    return this.service.getList(subject);
  }

  getSettings<TSetting>(subject: string, attribute?: string): Observable<TSetting | undefined> {
    return this.get(`${subject}#${attribute ?? ''}`);
  }
}
