import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlService } from '@services/url.service';
import { INetworkGroup } from '@shared/models/network-group-model';

@Injectable({
  providedIn: 'root',
})
export class NetworkGroupApiService {
  constructor(
    private http: HttpClient,
    private urlService: UrlService,
  ) {}

  getAllNetworkGroups(): Observable<INetworkGroup[]> {
    return this.http.get<INetworkGroup[]>(this.urlService.NETWORK_GROUP_URL);
  }

  getAllNetworkGroupsForTenant(tenant: string): Observable<INetworkGroup[]> {
    return this.http.get<INetworkGroup[]>(this.urlService.NETWORK_GROUP_URL.replace('{tenant}', tenant));
  }

  getAllNetworkGroupsForTenants(tenants: string[]): Observable<Record<string, INetworkGroup[]>> {
    const url = this.urlService.NETWORK_GROUP_URL;
    const tenantGroupsRequests: Record<string, Observable<unknown>> = {};
    for (const tenant of tenants) {
      tenantGroupsRequests[tenant] = this.http.get<INetworkGroup[]>(url.replace('{tenant}', tenant));
    }
    return forkJoin(tenantGroupsRequests).pipe(map(request => request as Record<string, INetworkGroup[]>));
  }
}
