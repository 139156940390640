<div class="program-selector">
  <div class="program-selector__programs-container">
    <ul class="program-selector__program-list">
      <li class="program-selector__program-list-option" *ngFor="let program of enrolledPrograms">
        <app-square-radio-button
          [(squareCheckmarkModel)]="selectedProgram"
          [value]="program"
          name="programs-selection"
          [disabled]="hasSelectedProgramBeenEmitted"
        >
          <span class="program-selector__program-name"> {{ program.program_name }} </span>
          <span class="program-selector__products"> {{ getProgramsProductsFormatted(program) }} </span>
        </app-square-radio-button>
      </li>
    </ul>
  </div>

  <div class="program-selector__button-container">
    <button
      *ngIf="showSelectProgramButtonAfterSelection || !hasSelectedProgramBeenEmitted"
      type="button"
      class="button button--primary"
      [disabled]="disableSelect"
      (click)="onProgramSelected()"
      data-cy="selectProgramBtn"
    >
      Select Program
    </button>
  </div>
</div>
