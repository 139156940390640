<div #container class="sws">
  <div class="sws__search-area" [class.sws__search-area--results-open]="showResults" (click)="field.focus()">
    <img src="assets/icons/magnifying-glass.svg" class="sws__image" />
    <input #field type="text" class="sws__field" autocomplete="off" autofocus [(ngModel)]="search" (ngModelChange)="updateResults()" />
  </div>

  <div class="sws__results-container" *ngIf="showResults">
    <ng-container *ngFor="let result of searchResults">
      <h4 class="sws__results-header">{{ result.label }}</h4>
      <ul class="m-0 p-0">
        <li class="sws__result" *ngFor="let item of result.data" (click)="component[result.clickHandler](item)">{{ item.name }}</li>
      </ul>
    </ng-container>
  </div>
</div>
