<ul class="secondary-tabs" [class.secondary-tabs--horizontal]="horizontal" role="navigation">
  <li
    *ngFor="let tab of internalTabs"
    class="secondary-tabs__tab"
    [class.secondary-tabs__tab--active]="tab.active"
    [class.secondary-tabs__tab--disabled]="tab.disabled"
    [class.secondary-tabs__tab--horizontal]="horizontal"
    (click)="emitTab(tab)"
  >
    <img
      *ngIf="tab.iconPath"
      class="secondary-tabs__icon"
      [class.secondary-tabs__icon--disabled]="tab.disabled"
      [class.secondary-tabs__icon--active]="tab.active"
      [src]="tab.iconPath"
    />

    <div class="secondary-tabs__text">
      <p class="secondary-tabs__value" [class.secondary-tabs__value--disabled]="tab.disabled">{{ tab?.value }}</p>

      <p *ngIf="tab.description" class="secondary-tabs__description" [class.secondary-tabs__description--disabled]="tab.disabled">
        {{ tab.description }}
      </p>
    </div>
  </li>
</ul>
