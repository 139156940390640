import gql from 'graphql-tag';

export const instantPaymentRequestDetailFragment = gql`
  fragment InstantPaymentRequestDetail on PatientAssistancePharmacyPaymentStatement {
    _id
    id
    presumedDateOfPayment
    earlyPayment {
      acceptedAt
      declinedAt
      status
      requestId
      product {
        dueDate
        paymentStatementPayout
      }
    }
  }
`;
