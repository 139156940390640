import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@shared/components/base-component';
import { AvailableType, Filter, TypeOptionsMap } from '@shared/components/data-view/data-view-types';
import { SelectListItem } from '@shared/models/select-list-item';
import { NgChanges } from '@shared/ng-changes';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-data-view-add-filter',
  templateUrl: './data-view-add-filter.component.html',
  styleUrls: ['./data-view-add-filter.component.scss'],
})
export class DataViewAddFilterComponent extends BaseComponent implements AfterViewInit, OnChanges {
  @Input() viewMode: 'popover' | 'row' = 'popover';
  private userClickedInside = true; // First click opens popover
  private _model: Filter.AppliedDataViewFilter;
  typeOptions: SelectListItem[];
  modelHasGroupedItems = false;
  chipsForceEmitTrigger = new Subject<void>();
  editedValue: unknown;
  @ViewChild('focusableInputField') focusableInputField: { focus: () => void };

  @Input() activePopover?: NgbPopover;
  @Input() set model(model: Filter.AppliedDataViewFilter) {
    this._model = model;
    this.editedValue = model.value;
    if (model.options) {
      this.modelHasGroupedItems = model.options.findIndex(option => option.group) !== -1;
    }
  }
  get model() {
    return this._model;
  }
  @Output() filterChanged = new EventEmitter<Filter.AppliedDataViewFilter>();

  @HostListener('click')
  clickIn() {
    if (this.activePopover) this.userClickedInside = true;
  }
  @HostListener('document:click')
  click() {
    setTimeout(() => {
      if (this.activePopover && !this.userClickedInside && document.getElementsByClassName('cdk-overlay-pane').length <= 0) {
        this.activePopover.close();
      }
      this.userClickedInside = false;
    });
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.okClicked();
      event.stopImmediatePropagation();
    }
  }

  ngAfterViewInit() {
    this.focusableInputField?.focus();
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.model) {
      this.typeOptions = this.model.allowedTypes.map(type => ({
        name: TypeOptionsMap[type],
        value: type,
      }));
    }
  }

  onTypeChanged(type: AvailableType | null) {
    const filterHadTypeAndValue = !!this.model.type && !!this.model.value;
    if (type) {
      this.model.type = type;
    }
    this.model.value = ['exists', 'nexists'].includes(type ?? '') ? true : undefined;
    if (filterHadTypeAndValue || ['exists', 'nexists'].includes(type ?? '') || this.viewMode === 'row') this.onFilterChanged();
  }
  onValueChanged(value: unknown) {
    if (this.model.maxNumOfOptions && Array.isArray(value)) {
      if (value.length > this.model.maxNumOfOptions) {
        value = value.slice(1);
      }
    }
    this.editedValue = value;
    if (this.viewMode === 'row') {
      this.model.value = this.editedValue;
    }
  }
  onTimezoneChanged(timezone: string) {
    this.model.timezone = timezone;
    this.onFilterChanged();
  }
  private onFilterChanged() {
    this.filterChanged.next(this.model);
  }

  clearClicked() {
    this.onValueChanged(null);
    if (['isOneOf', 'nisOneOf'].includes(this.model.type)) {
      this.chipsForceEmitTrigger.next();
    } else {
      this.model.value = this.editedValue;
      this.onFilterChanged();
    }
  }

  okClicked() {
    if (['isOneOf', 'nisOneOf'].includes(this.model.type)) {
      this.chipsForceEmitTrigger.next();
    } else {
      this.model.value = this.editedValue;
      this.onFilterChanged();
      if (this.activePopover) this.activePopover.close();
    }
  }

  forceEmitFinished(value: string[]) {
    this.model.value = value;
    this.onFilterChanged();
    if (this.activePopover) this.activePopover.close();
  }
}
