import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject, range } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WindowService } from '@services/window.service';

interface Circle {
  centerX: number;
  centerY: number;
  radius: number;
  isActive: boolean;
}

interface Text {
  x: number;
  y: number;
  value: string;
}

interface Line {
  leftX: number;
  leftY: number;
  rightX: number;
  rightY: number;
  isActive: boolean;
}

@Component({
  selector: 'app-connected-circle-steps',
  templateUrl: './connected-circle-steps.component.html',
  styleUrls: ['./connected-circle-steps.component.scss'],
})
export class ConnectedCircleStepsComponent implements OnInit, OnDestroy {
  @Input() set numberOfSteps(number: number) {
    this._numberOfSteps = number;
    this.numberOfLines = number - 1;
    this.setupSvgElements();
  }

  @Input() set activeStep(stepNumber: number) {
    // Internally active step will start at 0, while
    // externally it will start at 1
    this._activeStep = stepNumber - 1;
    this.updateSvgElementsActiveAttribute();
  }

  _numberOfSteps: number;
  numberOfLines: number;
  _activeStep: number;

  circles: Circle[] = [];
  numbers: Text[] = [];
  lines: Line[] = [];

  private circleRadius = 15;
  private stepImgWidth = 600;
  stepImgInnerWidth: number;

  readonly CIRCLE_OFFSET = 15;
  readonly CIRCLE_MOBILE_OFFSET = 7.5;
  readonly NUMBER_HORIZONTAL_OFFSET = 10;
  readonly NUMBER_VERTICAL_OFFSET = 20;
  readonly LINE_HORIZONTAL_OFFSET = 30;
  readonly LINE_VERTICAL_OFFSET = 15;

  isMobile = false;

  private unsubscribe = new Subject<void>();

  constructor(private windowService: WindowService) {}

  ngOnInit() {
    this.trackDeviceType();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private trackDeviceType(): void {
    this.windowService.isMobile.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => this.setIsMobile(isMobile));
  }

  setIsMobile(isMobile: boolean): void {
    this.isMobile = isMobile;
    this.stepImgWidth = isMobile ? 300 : 600;
    this.circleRadius = isMobile ? 7.5 : 15;
    this.setupSvgElements();
  }

  private setupSvgElements(): void {
    this.setStepImgInnerWidth();
    this.setupCircles();
    this.setupNumbers();
    this.setupLines();
  }

  private setStepImgInnerWidth(): void {
    this.stepImgInnerWidth = this.stepImgWidth - this.circleRadius * 2;
  }

  private setupCircles(): void {
    this.circles = [];

    range(0, this._numberOfSteps).subscribe(stepNumber => {
      this.circles.push({
        centerX: this.getX(stepNumber, this.CIRCLE_OFFSET),
        centerY: this.getCircleCenterY(),
        radius: this.getCircleRadius(),
        isActive: this.isStepActive(stepNumber),
      });
    });
  }

  private setupNumbers(): void {
    this.numbers = [];

    range(0, this._numberOfSteps).subscribe(stepNumber => {
      this.numbers.push({
        x: this.getX(stepNumber, this.NUMBER_HORIZONTAL_OFFSET),
        y: this.getY(this.NUMBER_VERTICAL_OFFSET),
        value: (stepNumber + 1).toString(),
      });
    });
  }

  private setupLines(): void {
    this.lines = [];

    range(0, this.numberOfLines).subscribe(stepNumber => {
      this.lines.push({
        leftX: this.getX(stepNumber, this.LINE_HORIZONTAL_OFFSET),
        leftY: this.getY(this.LINE_VERTICAL_OFFSET),
        rightX: this.getX(stepNumber + 1),
        rightY: this.getY(this.LINE_VERTICAL_OFFSET),
        isActive: this.isStepActive(stepNumber + 1),
      });
    });
  }

  private updateSvgElementsActiveAttribute(): void {
    this.circles.forEach((circle, stepNumber) => (circle.isActive = this.isStepActive(stepNumber)));
    this.lines.forEach((line, stepNumber) => (line.isActive = this.isStepActive(stepNumber + 1)));
  }

  getDistanceBetweenSteps(): number {
    return this.stepImgInnerWidth / this.numberOfLines;
  }

  getX(step: number, offset = 0): number {
    if (this.isMobile) {
      offset /= 2;
    }

    return this.getDistanceBetweenSteps() * step + offset;
  }

  getY(offset = 0): number {
    return this.isMobile ? offset / 2 : offset;
  }

  getCircleRadius(): number {
    return this.circleRadius;
  }

  getCircleCenterY(): number {
    return this.isMobile ? this.CIRCLE_MOBILE_OFFSET : this.CIRCLE_OFFSET;
  }

  isStepActive(stepNumber: number): boolean {
    return this._activeStep >= stepNumber;
  }
}
