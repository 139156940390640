<div class="d-flex justify-content-center">
  @if (flagsCount()) {
    @if (claim().flagsAmountsValue) {
      <span class="claim-flag bg-gray text-black" [ngClass]="{ 'claim-flag-small': size() === 'small' }">
        <chitin-icon icon="far fa-dollar-sign" class="d-block" style="margin: 3px 7px" />
        <span class="claim-flag-badge" [ngClass]="{ 'claim-flag-badge-small': size() === 'small' }">{{ flagsFillsRemaning() }}</span>
      </span>
    } @else {
      <span class="claim-flag bg-gray text-black" [ngClass]="{ 'claim-flag-small': size() === 'small' }">
        <chitin-icon icon="far fa-flag" class="d-block" style="margin: 3px 7px" />
        <span class="claim-flag-badge" [ngClass]="{ 'claim-flag-badge-small': size() === 'small' }">{{ flagsFillsRemaning() }}</span>
      </span>
    }
  }
</div>
