import { Injectable } from '@angular/core';
import { IPharmacy, isNcpdpValid, isNpiValid } from '@shared/models/pharmacy.model';
import Utils from '@shared/providers/utils';
import { Program } from '@shared/models/program.model';
import { ProgramGroupUtilityService } from '@services/program-group-utility.service';
import { NewPharmacy } from '@shared/models/new-pharmacy.model';
import { EtlRoute } from '@shared/models/etl-route.model';
import { TenantService } from '@services/tenant.service';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { IProgramGroup } from '@shared/models/program-group.model';
import { cloneDeep, find, has, set } from 'lodash';

interface FieldValidation {
  fieldName: string;
  method: any;
  errorMessage?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PharmacyUtilityService {
  static readonly FIELD_VALIDATION_LOOKUP: FieldValidation[] = [
    {
      fieldName: 'ncpdp',
      method: isNcpdpValid,
      errorMessage: 'This NCPDP is not valid. It must be at least 7 digits long.',
    },
    {
      fieldName: 'email',
      method: Utils.isValidEmail,
      errorMessage: 'This email is not a valid format.',
    },
    {
      fieldName: 'contact_email',
      method: Utils.isValidEmail,
      errorMessage: 'Contact Email is not a valid format.',
    },
    {
      fieldName: 'ach_remittance_email',
      method: Utils.isValidEmail,
      errorMessage: 'ACH Remittance Email is not a valid format.',
    },
    {
      fieldName: 'phone',
      method: Utils.isPhoneNumberValid,
      errorMessage: 'Phone is not a valid format.',
    },
    {
      fieldName: 'contact_phone',
      method: Utils.isPhoneNumberValid,
      errorMessage: 'Contact Phone is not a valid format.',
    },
    {
      fieldName: 'sms_contact_number',
      method: Utils.isPhoneNumberValid,
      errorMessage: 'SMS Contact Number is not a valid format.',
    },
    {
      fieldName: 'network_group',
      method: Utils.isNonEmptyString,
    },
    {
      fieldName: 'system_vendor',
      method: Utils.isNonEmptyString,
      errorMessage: 'Pharmacy Software is required if PBA is enabled.',
    },
    {
      fieldName: 'npi',
      method: isNpiValid,
      errorMessage: 'This NPI is not valid. It must be 10 digits long.',
    },
    {
      fieldName: 'program_group',
      method: Utils.isNonEmptyString,
    },
  ];
  static readonly DEFAULT_VALIDATION_METHOD = Utils.isNonEmptyString;
  readonly pharmacies = new Map<string, IPharmacy>();

  constructor(
    private programGroupUtilityService: ProgramGroupUtilityService,
    private tenantService: TenantService,
  ) {}

  isPharmacyBlocked(pharmacy?: IPharmacy, tenantId?: TenantIdType): boolean {
    if (!pharmacy || !pharmacy.ncpdp) {
      return true;
    }

    let pharmacyProgramGroup: IProgramGroup | undefined;
    if (
      tenantId &&
      pharmacy.tenant_fields &&
      pharmacy.tenant_fields[tenantId] &&
      pharmacy.tenant_fields[tenantId].program_group != undefined
    ) {
      const programGroupId = pharmacy.tenant_fields[tenantId].program_group ?? '';
      pharmacyProgramGroup = this.programGroupUtilityService.getProgramGroupFromIdAndTenantId(programGroupId, tenantId);
    } else if (tenantId) {
      pharmacyProgramGroup = this.programGroupUtilityService.getProgramGroupFromIdAndTenantId(pharmacy.program_group, tenantId);
    } else {
      pharmacyProgramGroup = this.programGroupUtilityService.getProgramGroupFromId(pharmacy.program_group);
    }

    return !pharmacyProgramGroup || Utils.castToBool(pharmacyProgramGroup.is_blocked);
  }

  isBlocked(pharmacy: IPharmacy, programGroups: IProgramGroup[], tenantId?: TenantIdType): boolean {
    const tenant = tenantId ?? this.tenantService.getCurrentTenantId();
    let programGroup: IProgramGroup | undefined;

    if (has(pharmacy, `tenant_fields.${tenant}.program_group`)) {
      const programGroupId = pharmacy.tenant_fields?.[tenant].program_group;
      programGroup = find(programGroups, { id: programGroupId, tenant });
    } else {
      programGroup = find(programGroups, { id: pharmacy.program_group, tenant });
    }

    return !programGroup || !!programGroup.is_blocked;
  }

  isPharmacyNew(pharmacy?: IPharmacy | NewPharmacy): boolean {
    return !!pharmacy && 'is_new' in pharmacy && (pharmacy as NewPharmacy).is_new;
  }

  isPharmacyEnrolledInProgram(pharmacy: IPharmacy, program: Program): boolean {
    if (pharmacy && Utils.isNonEmptyArray(pharmacy.enrolled_programs)) {
      return pharmacy.enrolled_programs.some(
        enrolledProgram => enrolledProgram.program_id === program.copay_program_id && enrolledProgram.tenant === program.tenant,
      );
    }

    return false;
  }

  getTenantWithPortalRequestAccessByProgramGroup(pharmacy: IPharmacy, tenants: TenantIdType[]): TenantIdType[] {
    let result: TenantIdType[] = [];

    let pharmacyProgramGroup: IProgramGroup | undefined;
    if (pharmacy && pharmacy.tenant_fields) {
      const tenant_fields = pharmacy.tenant_fields;
      tenants.forEach(tenant => {
        const programGroupId = tenant_fields[tenant]?.program_group ?? '';
        pharmacyProgramGroup = this.programGroupUtilityService.getProgramGroupFromIdAndTenantId(programGroupId, tenant);
        if (pharmacyProgramGroup && !Utils.castToBool(pharmacyProgramGroup.is_portal_request_blocked)) {
          result.push(tenant);
        }
      });
    } else if (pharmacy) {
      pharmacyProgramGroup = this.programGroupUtilityService.getProgramGroupFromId(pharmacy.program_group);
      if (pharmacyProgramGroup && !Utils.castToBool(pharmacyProgramGroup.is_portal_request_blocked)) {
        result = tenants;
      }
    }
    return result;
  }

  getTenantWithPortalRequestAccess(pharmacy: IPharmacy, tenants: TenantIdType[]): TenantIdType[] {
    let result: TenantIdType[] = [];
    const filteredTenants = this.getTenantWithPortalRequestAccessByProgramGroup(pharmacy, tenants);

    if (pharmacy && pharmacy.tenant_fields) {
      const tenant_fields = pharmacy.tenant_fields;
      result = filteredTenants.filter(tenant => !Utils.castToBool(tenant_fields[tenant]?.is_portal_request_blocked));
    } else if (pharmacy && !Utils.castToBool(pharmacy.is_portal_request_blocked)) {
      result = filteredTenants;
    }
    return result;
  }

  getPharmacyEnrolledProgramDailyLimit(pharmacy: IPharmacy, program: Program): number {
    if (!pharmacy || !program || !Utils.isNonEmptyArray(pharmacy.enrolled_programs)) {
      return 0;
    }

    const pharmacyProgram = pharmacy.enrolled_programs.find(
      enrolledProgram => enrolledProgram.program_id === program.copay_program_id && enrolledProgram.tenant === program.tenant,
    );
    return pharmacyProgram?.daily_limit ?? 0;
  }

  getPharmacyEnrolledProgramWeeklyLimit(pharmacy: IPharmacy, program: Program): number {
    if (!pharmacy || !program || !Utils.isNonEmptyArray(pharmacy.enrolled_programs)) {
      return 0;
    }

    const pharmacyProgram = pharmacy.enrolled_programs.find(
      enrolledProgram => enrolledProgram.program_id === program.copay_program_id && enrolledProgram.tenant === program.tenant,
    );
    return pharmacyProgram?.weekly_limit ?? 0;
  }

  pharmacyHasEtlRoutes(pharmacy: IPharmacy, routes: EtlRoute[], tenantIds: TenantIdType[]) {
    return routes.some(route => {
      if (route.partner_type === 'pharmacy') {
        return route.partner === pharmacy.ncpdp;
      } else if (route.partner_type === 'tenant') {
        if (Utils.isNonEmptyArray(tenantIds)) {
          const currentTenantNames = tenantIds.map(id => this.tenantService.getTenantById(id)?.name.toLowerCase());
          return currentTenantNames.includes(route.partner.toLowerCase());
        } else {
          const currentTenantName = this.tenantService.currentTenant?.name;
          return route.partner.toLowerCase() === currentTenantName?.toLowerCase();
        }
      }
      return false;
    });
  }

  isFieldValidForPharmacy(fieldName: keyof IPharmacy, pharmacy: IPharmacy): [boolean, string | undefined] {
    let isValid = false;
    let errorMessage = '';

    const FIELD_VALIDATION = PharmacyUtilityService.FIELD_VALIDATION_LOOKUP.find(validation => validation.fieldName === fieldName);
    console.log(fieldName);
    console.log(FIELD_VALIDATION);
    if (FIELD_VALIDATION) {
      isValid = FIELD_VALIDATION.method(pharmacy[fieldName]);
      errorMessage = !isValid && FIELD_VALIDATION.errorMessage ? FIELD_VALIDATION.errorMessage : '';
    } else {
      isValid = PharmacyUtilityService.DEFAULT_VALIDATION_METHOD(pharmacy[fieldName] as string);
    }

    return [isValid, errorMessage];
  }

  getTenantPharmacy(pharmacy: IPharmacy, tenantId: TenantIdType): IPharmacy {
    const key = `${tenantId}_${pharmacy.ncpdp}`;
    const originalPharmacy = this.pharmacies.get(key);
    if (originalPharmacy) {
      // Copy any new properties the cached pharmacy doesn't have.
      for (const [key, value] of Object.entries(pharmacy)) {
        const hasProperty = key in originalPharmacy;
        if (!hasProperty) {
          set(originalPharmacy, key, value);
        }
      }
      pharmacy = cloneDeep(originalPharmacy);
    } else {
      this.pharmacies.set(key, cloneDeep(pharmacy));
    }

    const tenantFields: Record<string, any> = pharmacy.tenant_fields?.[tenantId] ?? {};
    for (const field of Object.keys(tenantFields)) {
      set(pharmacy, field, tenantFields[field]);
    }

    return pharmacy;
  }
}
