import { Injectable } from '@angular/core';
import { Manufacturer } from '@shared/models/manufacturer.model';
import { TenantService } from '@services/tenant.service';
import { Observable, of } from 'rxjs';
import { Tenant } from '@shared/models/tenant.model';

@Injectable({
  providedIn: 'root',
})
export class ManufacturerService {
  constructor(private tenantService: TenantService) {}

  getManufacturers(): Observable<Manufacturer[]> {
    const dummyData: Manufacturer[] = [];
    this.tenantService.usersTenants.forEach(tenant => {
      const manufacturer = new Manufacturer();
      manufacturer.setInfoFromTenant(tenant);
      dummyData.push(manufacturer);
    });

    return of(dummyData);
  }
}
