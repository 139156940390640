import { EnrolledProgram } from '@shared/models/enrolled-program.model';
import { RxInfo } from '@shared/models/rx-info.model';
import { ApiBoolean } from '@shared/models/api-boolean.type';
import { ReducedAgreement } from '@shared/models/reduced-agreement.model';
import { TenantIdType } from '@shared/models/tenant-id.type';

export class IPharmacy {
  ncpdp: string;
  program_group: string;
  primary_wholesaler?: string;
  secondary_wholesaler?: string;
  enrolled_programs?: EnrolledProgram[];
  wednesday_close?: number;
  zip?: string;
  npi?: string;
  monday_close?: number;
  email?: string;
  owner_first_name?: string;
  owner_last_name?: string;
  pic_first_name?: string;
  pic_last_name?: string;
  name: string;
  saturday_close?: number;
  state?: string;
  city?: string;
  monday_open?: number;
  thursday_open?: number;
  open_24_hours?: string;
  phone?: string;
  fax_number?: string;
  addressone?: string;
  addresstwo?: string;
  saturday_open?: number;
  tuesday_close?: number;
  tuesday_open?: number;
  lon?: number;
  lat?: number;
  thursday_close?: number;
  parent_organization_name?: string;
  wednesday_open?: number;
  chain_name?: string;
  friday_close?: number;
  friday_open?: number;
  pharmacy_affiliation_code?: number;
  field_admin?: string;
  primary_bin?: string;
  primary_pcn?: string;
  primary_group?: string;
  rx_info: RxInfo;
  is_visible_to_ram?: ApiBoolean;
  is_consignment_hub?: ApiBoolean;
  network_group?: string;
  preferred_transfer_method?: string;
  username?: string;
  accepted_agreements?: ReducedAgreement[] = [];
  date_joined?: string;
  is_pa_program_participant: ApiBoolean;
  is_portal_request_blocked: ApiBoolean;
  enrollment_engagements_prior_authorization_are_fax_notifications_enabled: ApiBoolean;
  enrollment_engagements_prior_authorization_are_email_notifications_enabled: ApiBoolean;
  enrollment_engagements_prior_authorization_notification_emails: string[] = [];
  system_vendor?: string;
  enable_pba: ApiBoolean;
  pba_pcn_group_fields: any;
  pba_ignored_member_id_value?: string;
  ach_remittance_email?: string;
  sms_contact_number?: string;
  dba_name?: string;
  legal_name?: string;
  contact_first_name?: string;
  contact_last_name?: string;
  contact_title?: string;
  contact_phone?: string;
  contact_phone_ext?: string;
  contact_email?: string;
  store_number?: string;
  phone_ext?: string;
  county?: string;
  msa?: string;
  pmsa?: string;
  open_date?: string;
  closure_date?: string;
  mailing_addressone?: string;
  mailing_addresstwo?: string;
  mailing_city?: string;
  mailing_state?: string;
  mailing_zip?: string;
  contact_middle_initial?: string;
  dea_registration_id?: string;
  dea_expiration_id?: string;
  fed_tax_id?: string;
  state_tax_id?: string;
  enrollment_engagements_prior_authorization_are_payor_approved_email_notifications_enabled?: boolean;
  enrollment_engagements_prior_authorization_are_payor_approved_fax_notifications_enabled?: boolean;
  enrollment_engagements_prior_authorization_are_payor_denied_email_notifications_enabled?: boolean;
  enrollment_engagements_prior_authorization_are_payor_denied_fax_notifications_enabled?: boolean;
  enrollment_engagements_prior_authorization_are_prescriber_non_response_email_notifications_enabled?: boolean;
  enrollment_engagements_prior_authorization_are_prescriber_non_response_fax_notifications_enabled?: boolean;
  pharmacy_software_instructions_last_sent_date?: string;
  // Example:
  // tenant_fields: {
  //   HZN: {
  //     program_group: "0"
  //   }
  // }
  tenant_fields?: Record<TenantIdType, Partial<IPharmacy>>;
}

export function isNcpdpValid(ncpdp: string): boolean {
  // Acceptable NCPDP format: 1234567
  if (!ncpdp) {
    return false;
  }

  const is7Characters = ncpdp.length === 7;
  if (!is7Characters) {
    return false;
  }

  const areAllCharactersDigits = !isNaN(+ncpdp);
  if (!areAllCharactersDigits) {
    return false;
  }

  return true;
}

export function isNpiValid(npi: string): boolean {
  // Acceptable NPI format: 1234567890
  if (!npi) {
    return false;
  }

  const is10Characters = npi.length === 10;
  const isANumber = !isNaN(+npi);

  return is10Characters && isANumber;
}
