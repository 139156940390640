import { Component, OnInit } from '@angular/core';
import { Program } from '@shared/models/program.model';
import { TableData } from '@shared/models/table-data.model';
import { ProgramApiService } from '@services/program-api.service';
import { TableRow } from '@shared/models/table-row.model';
import { TenantService } from '@services/tenant.service';
import { TableValue } from '@shared/models/table-value.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-manufacturer-search-results',
  templateUrl: './product-manufacturer-search-results.component.html',
  styleUrls: ['./product-manufacturer-search-results.component.scss'],
})
export class ProductManufacturerSearchResultsComponent implements OnInit {
  programs: Program[];
  numberOfPrograms: number;

  tableData: TableData = {} as TableData;

  isLoadingTableData = false;

  constructor(
    private programService: ProgramApiService,
    private tenantService: TenantService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getProgramsForCurrentTenant();
  }

  private getProgramsForCurrentTenant(): void {
    this.isLoadingTableData = true;
    this.programService.getAllPrograms().subscribe(allPrograms => {
      if (allPrograms) {
        this.filterOutBlockedPrograms(allPrograms);
        this.addSponsors();
        this.setNumberOfPrograms();
        this.setupTableData();
      }
    });
  }

  private filterOutBlockedPrograms(programs: Program[]): void {
    // TODO: Fix in separate PR
    // this.programs = programs.filter((program) => !program.is_block_program);
  }

  private addSponsors(): void {
    const currentTenant = this.tenantService.currentTenant;
    // TODO: Fix in separate PR
    // this.programs.forEach((program) => program.sponsor = currentTenant.name);
  }

  private setNumberOfPrograms(): void {
    this.numberOfPrograms = this.programs ? this.programs.length : 0;
  }

  hasNumberOfPrograms(): boolean {
    return this.numberOfPrograms !== undefined;
  }

  private setupTableData(): void {
    this.setupTableHeaders();
    this.setupTableRows();
    this.isLoadingTableData = false;
  }

  private setupTableHeaders(): void {
    this.tableData.headers = [
      {
        name: 'Program Name',
        styles: {
          minWidth: '200px',
          flex: '3 0',
        },
      },
      {
        name: 'Program Sponsor',
        styles: {
          minWidth: '150px',
          flex: '2 0',
        },
      },
      {
        name: 'Last Updated',
        styles: {
          minWidth: '175px',
          flex: '1 0',
        },
      },
      {
        name: 'Status',
        styles: {
          color: 'transparent',
          minWidth: '225px',
          flex: '2 0',
        },
      },
      {
        name: 'Fill out application',
        styles: {
          color: 'transparent',
          minWidth: '225px',
          flex: '0 0',
        },
      },
    ];
  }

  private setupTableRows(): void {
    this.tableData.rows = [];

    this.programs.forEach((program, index) => {
      this.tableData.rows.push({
        entries: [
          {
            // Program Name column
            values: {
              value: program.program_name,
            },
            styles: {
              minWidth: '200px',
              flex: '3 0',
            },
          },
          {
            // Program Sponsor column
            values: {
              value: program.tenant ?? '',
            },
            styles: {
              minWidth: '150px',
              flex: '2 0',
            },
          },
          {
            // Last Updated column
            values: {
              value: '03/10/19',
            },
            styles: {
              minWidth: '175px',
              flex: '1 0',
            },
          },
          {
            // Status column
            values: {
              value: this.getStatus(index),
              styleClasses: [this.getStatusCircleStyleClass(index)],
              styles: {
                textTransform: 'uppercase',
                color: this.getStatusColor(index),
                fontWeight: '600',
                letterSpacing: '1px',
                fontSize: '12px',
              },
            },
            styles: {
              minWidth: '225px',
              flex: '2 0',
            },
          },
          {
            // Fill out application / blocked column
            values: {
              ...this.getFillOutApplicationTableValue(index),
            },
            styles: {
              minWidth: '225px',
              flex: '0 0',
            },
          },
        ],
      });
    });
  }

  private getStatus(rowIndex: number): string {
    return rowIndex % 2 ? 'Recently Updated' : 'Active';
  }

  private getStatusColor(rowIndex: number): string {
    return rowIndex % 2 ? '#AAE995' : '#C2C2C2';
  }

  private getStatusCircleStyleClass(rowIndex: number): string {
    return rowIndex % 2 ? 'u-prepend-green-circle' : 'u-prepend-grey-circle';
  }

  private getFillOutApplicationTableValue(rowIndex: number): TableValue {
    const styleClasses = ['button', 'button--retail-small'];
    if (rowIndex % 2) {
      styleClasses.push('button--disabled');
    }

    return {
      value: 'Fill out application',
      valueType: 'button',
      styleClasses: styleClasses,
      styles: {
        width: '180px',
      },
    };
  }

  onAction(): void {
    this.router.navigate(['/retail/application']);
  }
}
