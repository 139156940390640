export interface PaDenial {
  eligible: boolean;
  type: string;
  message: string;
}

export const PA_DENIALS: PaDenial[] = [
  {
    eligible: true,
    type: `Not on Formulary`,
    message: `This patient may be eligible for Incyte patient support. To proceed, additional information is required from the HCP.`,
  },
  {
    eligible: true,
    type: `Denied, but Patient meets UM`,
    message: `This patient may be eligible for Incyte patient support. To proceed, additional information is required from the HCP.`,
  },
  {
    eligible: false,
    type: `Step edits not met - Patient did not try and fail alternatives per plan's criteria`,
    message: `This patient does not meet the eligibility criteria for Incyte patient support. In 
    order to qualify, the patient must receive a denial after meeting the plan's coverage 
    criteria. If provider has not submitted complete information to the plan, provider must 
    resubmit the PA with the patient's treatment history.`,
  },
  {
    eligible: false,
    type: `BSA Exceeds % in Prescribing Information`,
    message: `This patient does not meet eligibility criteria for Incyte patient support. In order to qualify, patients must be treated for a BSA % as indicated in the prescribing information.`,
  },
  {
    eligible: false,
    type: `Combination use with biologics, other jak inhibitors, potent immunosuppressants, and/or other branded medication`,
    message: `This patient does not meet eligibility criteria for Incyte patient support. Combination use is outside of the plan's coverage criteria for Opzelura. If the patient does meet the plan's requirement, the provider must resubmit the PA with updated information on the patient's treatment history.`,
  },
  {
    eligible: false,
    type: `Missing chart notes, incorrect paperwork, needed or other administrative error`,
    message: `This patient does not meet the eligibility criteria for Incyte patient support. A prior authorization with complete information must be resubmitted addressing the plan’s requirements.`,
  },
  {
    eligible: false,
    type: `Incorrect Indication`,
    message: `Patients with a diagnosis outside of the Prescribing Information are not eligible for Incyte patient support.`,
  },
  {
    eligible: false,
    type: `Diagnosis –Severe AD or Segmental Vitiligo`,
    message: `Patients with a diagnosis outside of the Prescribing Information are not eligible for Incyte patient support.`,
  },
  {
    eligible: true,
    type: `Other`,
    message: `This patient may be eligible for Incyte patient support. To proceed, additional information is required from the HCP.`,
  },
  {
    eligible: true,
    type: `Cosmetic Purposes – Vitiligo`,
    message: `This patient may be eligible for Incyte patient support. To proceed, additional information is required from the HCP.`,
  },
  {
    eligible: true,
    type: `Unknown to Pharmacy`,
    message: `This patient may be eligible for Incyte patient support. To proceed, additional information is required from the HCP.`,
  },
];
