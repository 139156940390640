import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ContentChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { TabDirective } from '@shared/components/tabs/tab.directive';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  animations: [
    trigger('activeInactive', [
      state(
        'active',
        style({
          color: '#fe7183',
          boxShadow: 'inset 0px -7px #fe7183',
        }),
      ),
      state(
        'inactive',
        style({
          color: 'black',
          boxShadow: 'none',
        }),
      ),
      transition('inactive => active', [animate('0.200s 0s ease-in')]),
      transition('active => inactive', [animate('0.010s')]),
    ]),
    trigger('showHide', [
      state(
        'hide',
        style({
          visibility: 'hidden',
          opacity: '0.0',
        }),
      ),
      state(
        'show',
        style({
          visibility: 'visible',
          opacity: '1.0',
        }),
      ),
      transition('hide => show', [animate('0.15s ease-in-out')]),
      transition('show => hide', [animate('0.15s ease-in-out')]),
    ]),
  ],
})
export class TabsComponent {
  @Output() tabChange = new EventEmitter<string>();

  @ContentChildren(TabDirective) tabs: QueryList<TabDirective>;

  activeTab = 0;

  constructor() {}

  isActiveTab(tabIndex: number): boolean {
    return this.activeTab === tabIndex;
  }

  setTab(tabIndex: number): void {
    this.activeTab = tabIndex;
    this.tabChange.next(this.tabs.toArray()[tabIndex].title);
  }
}
