import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenantService } from '@services/tenant.service';
import { UrlService } from '@services/url.service';
import { ApiBoolean } from '@shared/models/api-boolean.type';
import { IPage } from '@shared/models/page.model';
import { PharmacyList } from '@shared/models/pharmacy.list.model';
import { IPharmacy, isNcpdpValid, isNpiValid } from '@shared/models/pharmacy.model';
import { Program } from '@shared/models/program.model';
import { SystemVendorConfig } from '@shared/models/system-vendor-config.model';
import Utils from '@shared/providers/utils';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PharmacyApiService {
  pharmacies: IPharmacy[];
  pharmacy: IPharmacy;

  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private tenantService: TenantService,
  ) {}

  getHubNetworkPharmacies() {
    return this.http.post<PharmacyList>(this.urlService.PHARMACY_URL, { hub_network: '1' });
  }

  searchPharmacyList(
    name: string,
    program_group: string,
    state: string,
    field_admin?: string,
    network_group?: string,
    last?: IPharmacy,
  ): Observable<any> {
    const isNameNcpdp = isNcpdpValid(name);
    const isNameNpi = isNpiValid(name);
    const isNameAPharmacyName = !isNameNcpdp && !isNameNpi;
    if (isNameNcpdp) {
      return this.http.get<IPharmacy>(this.urlService.PHARMACY_URL + '/' + name);
    } else {
      const page = new IPage();
      page.state = state;
      page.last_evaluated_key = last;
      page.rows_per_page = 25;
      if (isNameAPharmacyName) {
        page.name = name;
      } else if (isNameNpi) {
        page.npi = name;
      }
      if (program_group) {
        page.program_group_ids = program_group === '-1' ? undefined : program_group;
      }
      if (field_admin && field_admin !== '-1') {
        page.field_admin = field_admin;
      }
      if (network_group && network_group !== '-1') {
        page.network_groups = [network_group];
      }
      return this.http.post<PharmacyList>(this.urlService.PHARMACY_URL, page);
    }
  }

  searchPharmacyByNpi(npi: string, forKnownTenant = true): Observable<IPharmacy> {
    if (forKnownTenant) {
      return this.getPharmacyByNcpdp(npi);
    }
    return this.getPharmacyByNcpdpForUnknownTenant(npi);
  }

  searchPharmaciesByFieldAdmin(fieldAdminUsername: string, isVisibleToRam: ApiBoolean = 0): Observable<any> {
    const body = {
      field_admin: fieldAdminUsername,
      is_visible_to_ram: isVisibleToRam,
    };
    return this.http.post(this.urlService.PHARMACY_URL, body);
  }

  emailPharmacySoftwareInstructions(ncpdp: string): Observable<any> {
    const url = `${this.urlService.PHARMACY_EMAIL_INSTRUCTIONS_URL}/${ncpdp}`;
    return this.http.post(url, {});
  }

  private getBasePharmacyUrl(getProgramInfo?: boolean, tenant = '') {
    const TENANT_URL_PATH_PARAMETER = '{tenant}';
    let url = getProgramInfo ? this.urlService.PHARMACY_PROGRAM_URL : this.urlService.PHARMACY_URL;
    if (tenant) {
      url = url.replace(TENANT_URL_PATH_PARAMETER, tenant);
    }
    return url;
  }

  getPharmacyUrl(ncpdp: string, getProgramInfo?: boolean, tenant = '') {
    const url = this.getBasePharmacyUrl(getProgramInfo, tenant);
    return url + '/' + ncpdp;
  }

  getProgramForPharmacy(ncpdp: string): Observable<Program> {
    const url = this.urlService.PROGRAM_NO_AUTH_URL + '/' + ncpdp;
    return this.http.get<Program>(url);
  }

  getPharmacyByNcpdp(ncpdp: string, getProgramInfo = false, tenant = ''): Observable<IPharmacy> {
    const url = this.getPharmacyUrl(ncpdp, getProgramInfo, tenant);
    const pharmacy = this.http.get<IPharmacy>(url);

    return pharmacy;
  }

  getUserPharmacyList(sortByName = true): Observable<IPharmacy[]> {
    const userPharmacyUrl = this.urlService.PHARMACY_URL.replace('/{tenant}', '');
    return this.http
      .get<IPharmacy[]>(userPharmacyUrl)
      .pipe(map(pharmacies => (sortByName ? this.sortPharmacies(pharmacies, 'name') : pharmacies)));
  }

  getPharmacyByNcpdpForUnknownTenant(ncpdp: string, getProgramInfo = false): Observable<IPharmacy> {
    const UNKNOWN_TENANT_ID = 'UNKNOWN';
    const TENANT_URL_PATH_PARAMETER = '{tenant}';

    const url = this.getPharmacyUrl(ncpdp, getProgramInfo).replace(TENANT_URL_PATH_PARAMETER, UNKNOWN_TENANT_ID);
    const pharmacy = this.http.get<IPharmacy>(url);

    return pharmacy;
  }

  getPharmacyBatchByNcpdp(ncpdps: string[], tenant = ''): Observable<PharmacyList> {
    const body: Record<string, any> = {};
    if (Utils.isNonEmptyArray(ncpdps)) {
      body['ncpdp_list'] = ncpdps;
    }
    const pharmacyUrl = this.getBasePharmacyUrl(false, tenant);
    return this.http.post<PharmacyList>(pharmacyUrl, body);
  }

  savePharmacy(pharmacy: IPharmacy): Observable<object> {
    const pharmacyCopy = Utils.copyObject(pharmacy);
    this.removeUnnecessaryFieldsFromPharmacy(pharmacyCopy);
    return this.http.put(this.urlService.PHARMACY_URL, pharmacyCopy);
  }

  private removeUnnecessaryFieldsFromPharmacy(pharmacy: IPharmacy): void {
    const pharmacyRecord: Record<string, any> = pharmacy;
    if ('is_block_program' in pharmacyRecord) {
      delete pharmacyRecord['is_block_program'];
    }
    if ('is_weekly_batch_program' in pharmacyRecord) {
      delete pharmacyRecord['is_weekly_batch_program'];
    }
    if ('is_new' in pharmacyRecord) {
      delete pharmacyRecord['is_new'];
    }
    if ('enrolled_programs' in pharmacy && pharmacy.enrolled_programs) {
      const currentTenantId = this.tenantService.getCurrentTenantData()?.id;
      pharmacy.enrolled_programs = pharmacy.enrolled_programs.filter(program => program.tenant === currentTenantId);
    }
  }

  uploadPharmacyCodeLimitFile(s3FileName: string) {
    const body = {
      code_filename: s3FileName,
    };

    const postOptions = {
      responseType: 'text' as const,
    };
    return this.http.put(this.urlService.CODES_LIMITS_URL, body, postOptions);
  }

  sortPharmacies(pharmacies: IPharmacy[], sortField: keyof IPharmacy) {
    return sortBy(pharmacies, sortField);
  }

  getPharmacySystemVendors(): Observable<SystemVendorConfig[]> {
    const URL = this.urlService.MASTER_DATA_URL + '/system-vendor-configs/';
    return this.http.get<SystemVendorConfig[]>(URL);
  }
}
