import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, Optional, ViewEncapsulation } from '@angular/core';
import { SortDirection, TableSortDirective } from '@shared/components/table-sort/table-sort.directive';

@Component({
  selector: '[tableSortColumn]',
  exportAs: 'app-table-sort-column',
  templateUrl: './table-sort-column.component.html',
  styleUrls: ['./table-sort-column.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSortColumnComponent {
  @Input()
  tableSortColumn: string;

  @Input()
  sortField: string;

  @Input()
  sortDirection: SortDirection = 'asc';

  @HostBinding('class')
  hostClass = 'table-sort-column';

  constructor(@Optional() public tableSort: TableSortDirective) {}

  @HostListener('click')
  click() {
    if (!this.sortField) {
      this.sortField = this.tableSortColumn;
    }
    if (this.tableSort) {
      if (this.sortField !== this.tableSort.sortField) {
        this.sortDirection = 'asc';
      }
      this.tableSort.sort({ field: this.sortField, direction: this.sortDirection });
      this.sortDirection = this.tableSort.sortDirection;
    }
  }
}
