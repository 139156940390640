import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabInformation } from '@shared/models/tab-information.model';

@Component({
  selector: 'app-primary-tabs',
  templateUrl: './primary-tabs.component.html',
  styleUrls: ['./primary-tabs.component.scss'],
})
export class PrimaryTabsComponent {
  @Input() set tabs(tabs: TabInformation[]) {
    this.internalTabs = tabs;
  }

  @Output() tabsChange = new EventEmitter<TabInformation[]>();

  internalTabs: TabInformation[] = [];

  emitTab(tab: TabInformation): void {
    if (!tab.disabled) {
      this.setInternalTabActive(tab);
      this.tabsChange.emit(this.internalTabs);
    }
  }

  setInternalTabActive(tab: TabInformation): void {
    this.internalTabs.forEach(internalTab => (internalTab.active = internalTab.value === tab.value));
  }
}
