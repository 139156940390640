import { AuthGuard } from '@services/guards/auth-guard';
import { AuthInventoryGuard } from '@services/guards/auth-inventory-guard';
import { AuthPharmacyGuard } from '@services/guards/auth-pharmacy-guard';
import { AuthScorecardGuard } from '@services/guards/auth-scorecard-guard';

export const GUARDS = {
  Auth: AuthGuard,
  Pharmacy: AuthPharmacyGuard,
  Scorecard: AuthScorecardGuard,
  Inventory: AuthInventoryGuard,
} as const;

export type GuardType = keyof typeof GUARDS;
