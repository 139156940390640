<div class="timeline">
  <div class="timeline__line" [ngStyle]="{ bottom: lineBottom }"></div>
  <div class="timeline__line timeline__line--active" [ngStyle]="{ bottom: lineBottomActive }"></div>

  <div class="rows">
    <div *ngFor="let step of timelineSteps" #row class="row">
      <div class="col-auto">
        <span class="timeline__icon__border" [ngClass]="{ 'timeline__icon__border--active': step.isLastDone }"></span>
        <span class="timeline__icon" [ngClass]="{ 'timeline__icon--active': step.isLastDone }">
          <app-shared-material-icon [icon]="step.icon"></app-shared-material-icon>
        </span>
      </div>

      <div class="col-3">{{ step.name }}</div>

      <div class="col-auto timeline__col-context">
        <div *ngFor="let field of step.requestContext">
          <span data-cy="timelineStatusLabel" *ngIf="field.label">{{ field.label }}: </span>
          <span data-cy="timelineStatus">{{ field.value }}</span>
          <span *ngIf="field.description" [ngbTooltip]="field.description" class="timeline__col-context-description">
            <app-shared-material-icon icon="help_outline"></app-shared-material-icon>
          </span>
        </div>
      </div>

      <div class="col-auto" *ngIf="showDuration">{{ step.duration | timeDuration }}</div>
    </div>
  </div>
</div>
