import { inject, Injectable } from '@angular/core';
import { PharmacyViewModel } from '@services/models/pharmacy-view.model';
import { PharmaciesSearchService } from '@services/pharmacies.search.service';
import { StorageType, Store } from '@services/store';
import { OpenSearchQueryContainer } from '@shared/providers/opensearch-api';
import { intersection, sortBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PharmaciesSearchStore extends Store<PharmacyViewModel> {
  protected static storageType: StorageType = 'session';
  private readonly searchService = inject(PharmaciesSearchService);

  protected getDataSource(key: string): Observable<any> {
    return this.searchService.get(key);
  }

  getPharmacies(ncpdps: string[]): Observable<PharmacyViewModel[]> {
    const compare = intersection(ncpdps, this.keys());
    if (compare.length === ncpdps.length) {
      return of(ncpdps).pipe(
        map(items => items.map(item => this.store[item])),
        map(items => sortBy(items, 'name')),
      );
    }
    const query: OpenSearchQueryContainer[] = [{ terms: { ncpdp: ncpdps } }];
    return this.searchService.searchData(query, { from: 0, size: 1000 }, { name: 'asc' }).pipe(
      map(data => data.items),
      tap(items => items.forEach(item => this.set(item.ncpdp, item))),
    );
  }
}
