import { Component, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import copyTextToClipboard from 'copy-text-to-clipboard';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-copy-to-clipboard-button',
  templateUrl: './copy-to-clipboard-button.component.html',
})
export class CopyToClipboardButtonComponent {
  valueToCopy = input<string>();
  copied = signal(false);
  private _copied = new Subject<boolean>();

  constructor() {
    this._copied.pipe(delay(5000), takeUntilDestroyed()).subscribe({
      next: () => {
        this.copied.set(false);
      },
    });
  }

  copyToClipboard() {
    const successCopy = copyTextToClipboard(this.valueToCopy() ?? '');
    if (successCopy) {
      this.copied.set(successCopy);
      this._copied.next(successCopy);
    }
  }
}
