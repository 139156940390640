import { Injectable } from '@angular/core';
import config from '@config';
import * as Sentry from '@sentry/angular-ivy';
import { AuthService } from '@services/auth.service';
import { LogOptions } from '@shared/models/log-options.model';
import { LogUser } from '@shared/models/log-user.model';
import Utils from '@shared/providers/utils';

enum LogType {
  Message = 'message',
  Exception = 'exception',
}

@Injectable()
export class LogService {
  DEFAULT_ERROR_SEVERITY: Sentry.SeverityLevel = 'error';
  DEFAULT_MESSAGE_SEVERITY: Sentry.SeverityLevel = 'info';

  _hasBeenInitiated = false;
  _userHasBeenSet = false;

  constructor(private authService: AuthService) {
    this._initLogger();
    this._setupChangeUserSubscription();
  }

  private _initLogger(): void {
    Sentry.init({
      dsn: config.sentry.dsn,
      environment: config.name,
      release: config.version,
      enabled: config.sentry.enabled,
      ignoreErrors: ['Non-Error exception captured'],
      integrations: [new Sentry.BrowserTracing({ routingInstrumentation: Sentry.routingInstrumentation })],
      allowUrls: [/^https:\/\/\S*brightscrip\.com\S*$/],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });

    this._hasBeenInitiated = true;
  }

  private _setupChangeUserSubscription(): void {
    this.authService.authStatusChanged.subscribe(isUserAuthenticated => {
      if (isUserAuthenticated) {
        this._ensureUserIsSet();
        this._userHasBeenSet = true;
      } else {
        this._userHasBeenSet = false;
      }
    });
  }

  private _ensureUserIsSet(): void {
    if (config.sentry.enabled) {
      Sentry.configureScope(scope => {
        const currentUser = this.authService.getCurrentUser();

        if (currentUser) {
          const user: LogUser = {
            email: currentUser.email ? currentUser.email : '',
            username: currentUser.username ? currentUser.username : '',
            role: currentUser.role ? currentUser.role : '',
          };

          scope.setUser(user);

          this._userHasBeenSet = true;
        }
      });
    }
  }

  messageWithAction(message: string, action: string, ...options: LogOptions[]): void {
    const OPTIONS = this.getOptionsWithActionAdded(action, this.reduceLogOptions(...options));
    this.message(message, OPTIONS);
  }

  reduceLogOptions(...options: LogOptions[]): LogOptions {
    if (Utils.isNonEmptyArray(options)) {
      return options.reduce((accumlatedOptions, currentOption) => {
        if (currentOption.extraInfo) {
          accumlatedOptions.extraInfo = accumlatedOptions.extraInfo || {};
          const extraInfoKey = Object.keys(currentOption.extraInfo)[0];
          accumlatedOptions.extraInfo[extraInfoKey] = currentOption.extraInfo[extraInfoKey];
        }
        if (currentOption.tags) {
          accumlatedOptions.tags = accumlatedOptions.tags || {};
          const tagKey = Object.keys(currentOption.tags)[0];
          accumlatedOptions.tags[tagKey] = currentOption.tags[tagKey];
        }
        return accumlatedOptions;
      }, {});
    }

    return {};
  }

  getOptionsWithActionAdded(action: string, options?: LogOptions): LogOptions {
    let _options: LogOptions;

    if (options) {
      _options = Utils.copyObject(options);

      _options.tags = _options.tags ? _options.tags : {};

      _options.tags!.action = action;
    } else {
      _options = {
        tags: {
          action: action,
        },
      };
    }

    return _options;
  }

  message(message: string, options?: LogOptions): void {
    this._log(message, LogType.Message, options);
  }

  errorWithAction(message: string, action: string, ...options: LogOptions[]): void {
    const OPTIONS = this.getOptionsWithActionAdded(action, this.reduceLogOptions(...options));
    this.error(new Error(message), OPTIONS);
  }

  error(error: Error, options?: LogOptions): void {
    this._log(error, LogType.Exception, options);
  }

  private _log(logItem: unknown, logType: LogType, options?: LogOptions): void {
    this._logToConsole(logItem, logType, options);
    const defaultSeverity: Sentry.SeverityLevel = logType === LogType.Message ? this.DEFAULT_MESSAGE_SEVERITY : this.DEFAULT_ERROR_SEVERITY;
    const severity = options?.severity ? options.severity : defaultSeverity;

    if (!config.sentry.enabled) {
      return;
    }

    if (!this._hasBeenInitiated) {
      this._initLogger();
    }
    if (!this._userHasBeenSet) {
      this._ensureUserIsSet();
    }

    Sentry.withScope(scope => {
      scope.setLevel(severity);

      if (options?.extraInfo) {
        for (const prop in options.extraInfo) {
          if (prop in options.extraInfo) {
            scope.setExtra(prop, options.extraInfo[prop]);
          }
        }
      }

      if (options?.tags) {
        for (const prop in options.tags) {
          if (prop in options.tags) {
            scope.setTag(prop, options.tags[prop]);
          }
        }
      }

      if (logType === LogType.Message) {
        Sentry.captureMessage(logItem as string);
      } else if (logType === LogType.Exception) {
        Sentry.captureException(logItem);
      }
    });
  }

  private _logToConsole(logItem: unknown, logType: LogType, options?: LogOptions): void {
    if (logType === LogType.Message) {
      console.log(logItem, options);
    } else {
      console.log(options);
      console.error(logItem);
    }
  }
}
