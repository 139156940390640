import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'chitin-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ChitinColorPickerComponent {
  @Input()
  value?: string | null;

  @Input()
  presetColors: string[] = [
    '#264653',
    '#2a9d8f',
    '#e9c46a',
    '#f4a261',
    '#e76f51',
    '#d62828',
    '#023e8a',
    '#0077b6',
    '#0096c7',
    '#00b4d8',
    '#48cae4',
  ];

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();
}
