<section class="dark-card" [class.dark-card--with-aside]="cardAside" [ngClass]="_modifiers">
  <div *ngIf="cardAside" class="dark-card__aside">
    <ng-template [ngTemplateOutlet]="cardAside"> </ng-template>
  </div>

  <div class="dark-card__main" [class.dark-card__main--round-all-borders]="!cardAside">
    <h1 *ngIf="headerText" class="dark-card__header-text">{{ headerText }}</h1>

    <div *ngIf="cardHeader" class="dark-card__header-text">
      <ng-template [ngTemplateOutlet]="cardHeader"> </ng-template>
    </div>

    <div *ngFor="let section of cardSections" class="dark-card__section">
      <ng-template [ngTemplateOutlet]="section.templateRef"> </ng-template>
    </div>
  </div>
</section>
