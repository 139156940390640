import { Injectable } from '@angular/core';
import { TableValue } from '@shared/models/table-value.model';
import {
  DataSourceType,
  DataTransformationReturn,
  DataTransformationStrategy,
} from '@shared/providers/data-transformation/data-transformation.model';
import { PassThroughStrategy } from '@shared/providers/data-transformation/pass-through-strategy';

@Injectable()
export class PassThroughTableValueStrategy implements DataTransformationStrategy {
  constructor(private passThroughStrategy: PassThroughStrategy) {}

  transform(parameters?: Record<DataSourceType, any>): DataTransformationReturn {
    if (parameters && Object.keys(parameters).length > 0) {
      return {
        value: {
          value: this.passThroughStrategy.transform(parameters).value as TableValue,
        },
      };
    }
    return {
      value: { value: '' } as TableValue,
    };
  }
}
