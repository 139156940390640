import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DropdownService } from '@services/dropdown.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-toggle',
  templateUrl: './dropdown-toggle.component.html',
  styleUrls: ['./dropdown-toggle.component.scss'],
})
export class DropdownToggleComponent implements OnInit, OnDestroy {
  @Input() public label: string;
  @Input() public loading = false;
  @Input() public activeOptionAsLabel = false;

  public isToggled = false;
  public activeOptionLabel: string;

  private componentDestroyed = new Subject<void>();

  public toggle(): void {
    this.isToggled = !this.isToggled;
  }

  constructor(private dropdownService: DropdownService) {}

  ngOnInit() {
    this.trackActionOptionLabel();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  private trackActionOptionLabel() {
    this.dropdownService.activeOptionLabelsCombined
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(combinedLabels => (this.activeOptionLabel = combinedLabels));
  }
}
