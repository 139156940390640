import { Injectable } from '@angular/core';
import { CopayCodeRequestApiService } from '@services/copay-code-request-api.service';
import { DateService } from '@services/date.service';
import { PharmacyUtilityService } from '@services/pharmacy-utility.service';
import { ProgramStateService } from '@services/program-state.service';
import { ApiList } from '@shared/models/api-list.model';
import { CopayCodeRequestSearch } from '@shared/models/copay-code-request-search.model';
import { ICopayCodeRequest } from '@shared/models/copay-code-request.model';
import { EnrolledProgram } from '@shared/models/enrolled-program.model';
import { PharmacyProgram } from '@shared/models/pharmacy-program.model';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { ProgramCodeAvailability } from '@shared/models/program-code-availability.model';
import { Program } from '@shared/models/program.model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import Utils from '@shared/providers/utils';
import { merge } from 'lodash';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProgramUtilityService {
  static readonly BLOCKED_PROGRAM_ID = 'BLKD';
  static readonly UNDEFINED_PROGRAM_ID = 'UNDEF';

  constructor(
    private programStateService: ProgramStateService,
    private copayCodeRequestApiService: CopayCodeRequestApiService,
    private dateService: DateService,
    private pharmacyUtilityService: PharmacyUtilityService,
  ) {}

  getEnrolledProgramsFromPharmacy(pharmacy?: IPharmacy): Program[] {
    const programs = [];

    if (pharmacy && Utils.isNonEmptyArray(pharmacy.enrolled_programs)) {
      const enrolledPrograms = this.programStateService.allProgramsForTenant.getValue().filter(program =>
        pharmacy.enrolled_programs?.some(enrolledProgram => {
          return this.areSameProgram(enrolledProgram, program);
        }),
      );
      if (enrolledPrograms) {
        programs.push(...enrolledPrograms);
      }
    }

    return programs;
  }

  areSameProgram(program1: EnrolledProgram | Program, program2: EnrolledProgram | Program): boolean {
    const defaultIdFieldName = 'program_id';
    const alternativeIdFieldName = 'copay_program_id';

    let program1IdFieldName = defaultIdFieldName;
    let program2IdFieldName = defaultIdFieldName;

    if (alternativeIdFieldName in program1) {
      program1IdFieldName = alternativeIdFieldName;
    }
    if (alternativeIdFieldName in program2) {
      program2IdFieldName = alternativeIdFieldName;
    }

    return (
      (program1 as Record<string, any>)[program1IdFieldName] === (program2 as Record<string, any>)[program2IdFieldName] &&
      program1.tenant === program2.tenant
    );
  }

  getProgramsForPharmacy(pharmacy: IPharmacy): PharmacyProgram[] {
    const allPrograms = this.programStateService.allProgramsForTenant.value;
    const enrolledPrograms = pharmacy.enrolled_programs ?? [];
    const pharmacyPrograms = enrolledPrograms.map(value => {
      const program = allPrograms.find(item => item.copay_program_id === value.program_id && item.tenant === value.tenant);
      return merge({}, value, program);
    });
    return pharmacyPrograms;
  }

  getTenantProgramsForPharmacy(pharmacy: IPharmacy, tenants: TenantIdType[]): PharmacyProgram[] {
    const validTenants = this.pharmacyUtilityService.getTenantWithPortalRequestAccess(pharmacy, tenants);
    return this.getProgramsForPharmacy(pharmacy).filter(program => validTenants.includes(program.tenant as TenantIdType));
  }

  getProgramsWithAvailableLimits(programs: PharmacyProgram[]): PharmacyProgram[] {
    return programs.filter(program => this.isProgramLimitAvailable(program));
  }

  private isProgramLimitAvailable(program: EnrolledProgram): boolean {
    return program.weekly_limit > 0;
  }

  getUniqueProductNames(programs: Program[]): string[] {
    if (!Utils.isNonEmptyArray(programs)) {
      return [];
    }

    const programNames: string[] = [];

    programs.forEach(program => {
      if (program.products) {
        program.products.forEach(product => {
          if (!programNames.includes(product.name)) {
            programNames.push(product.name);
          }
        });
      }
    });

    return programNames;
  }

  getHowSuppliedForProgramFirstProduct(program: Program): string {
    if (!program || !Utils.isNonEmptyArray(program.products) || !program.products[0].howSupplied) {
      return '';
    }
    return program.products[0].howSupplied;
  }

  getUniqueProgramName(program: Program): string {
    if (!program) {
      return '';
    }

    let howSupplied = this.getHowSuppliedForProgramFirstProduct(program);

    if (howSupplied) {
      howSupplied = ' ' + howSupplied;
    }

    return `${program.program_name}${howSupplied}`;
  }

  doesProgramHaveProductWithName(program: Program, productName: string): boolean {
    return program.products?.some(product => product.name === productName) ?? false;
  }

  getLastCodeRequestForPharmacyAndProgram(pharmacy: IPharmacy, program: Program): Observable<ICopayCodeRequest | undefined> {
    const beginningOfBrightscrip = moment(DateService.BEGINNING_OF_BRIGHTSCRIP_DATE).format(DateService.ISO_DATE_FORMAT).toString();
    return this.searchCodeRequests(pharmacy.ncpdp, program.copay_program_id, beginningOfBrightscrip).pipe(
      map(results => {
        for (let i = 0; i < results.items.length; i++) {
          const codeRequest = results.items[i];
          if (!codeRequest.extra_dispense) {
            return codeRequest;
          }
        }
        return undefined;
      }),
    );
  }

  getNumberOfCodesAvailableToDispenseForPharmacyAndProgram(pharmacy: IPharmacy, program: Program): Observable<ProgramCodeAvailability> {
    const startOfWeek = this.dateService.getStartOfIsoWeek().toISOString();
    const endOfWeek = this.dateService.getEndOfIsoWeek().toISOString();
    return this.searchCodeRequests(pharmacy.ncpdp, program.copay_program_id, startOfWeek, endOfWeek, program.tenant).pipe(
      switchMap(codeRequestsFromTheWeek => {
        return new Observable<ProgramCodeAvailability>(observer => {
          const availableCodes = this.countNumberOfCodes(pharmacy, program, codeRequestsFromTheWeek.items);
          observer.next({
            codesLeftToday: availableCodes[0],
            codesLeftThisWeek: availableCodes[1],
          });
          observer.complete();
        });
      }),
    );
  }

  private searchCodeRequests(
    ncpdp: string,
    programId: string,
    fromDate: string,
    toDate?: string,
    tenant?: TenantIdType,
  ): Observable<ApiList<ICopayCodeRequest>> {
    const search: Partial<CopayCodeRequestSearch> = {};
    search.ncpdp = ncpdp;
    search.from_date = fromDate;
    search.to_date = toDate;
    search.consistent_read = 'true';
    search.with_codes_dispensed = 'true';
    search.program_ids = programId;
    return this.copayCodeRequestApiService.searchCodeRequests(search, true, tenant);
  }

  private countNumberOfCodes(pharmacy: IPharmacy, program: Program, codeRequestsFromTheWeek: ICopayCodeRequest[]): [number, number] {
    let codesDispensedThisWeek = 0;
    let codesDispensedToday = 0;

    const nextDayDispenseDate = this.dateService.getTomorrowWithOffsetAdjustment();
    const useNextDayDispenseDate = moment().isSameOrAfter(nextDayDispenseDate);
    const startOfToday = useNextDayDispenseDate ? nextDayDispenseDate : this.dateService.getStartOfDayWithOffsetAdjustment();

    codeRequestsFromTheWeek.forEach(codeRequest => {
      if (!codeRequest.extra_dispense && codeRequest.number_codes_dispensed !== undefined) {
        codesDispensedThisWeek += +codeRequest.number_codes_dispensed;
        const transactionDate: moment.Moment = moment(codeRequest.created);
        if (transactionDate.isSameOrAfter(startOfToday)) {
          codesDispensedToday += +codeRequest.number_codes_dispensed;
        }
      }
    });

    return [
      this.calculateNumberCodesStillAvailableToday(pharmacy, program, codesDispensedToday),
      this.calculateNumberCodesStillAvailableThisWeek(pharmacy, program, codesDispensedThisWeek),
    ];
  }

  private calculateNumberCodesStillAvailableThisWeek(pharmacy: IPharmacy, program: Program, codesDispensedThisWeek: number): number {
    return this.pharmacyUtilityService.getPharmacyEnrolledProgramWeeklyLimit(pharmacy, program) - codesDispensedThisWeek;
  }

  private calculateNumberCodesStillAvailableToday(pharmacy: IPharmacy, program: Program, codesDispensedToday: number): number {
    return this.pharmacyUtilityService.getPharmacyEnrolledProgramDailyLimit(pharmacy, program) - codesDispensedToday;
  }
}
