import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { isString } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-formly-field-input',
  template: `
    <chitin-form-input
      [formControl]="formControl"
      [ngClass]="{ 'is-invalid': !formControl.valid && formControl.touched && !field.props?.disabled }"
      [name]="fieldName"
      [type]="inputType"
      [isDisabled]="field.props?.disabled ?? false"
      [readonly]="field.props?.readonly ?? false"
      [size]="field.props?.size"
      [placeholder]="field.props?.placeholder ? field.props?.placeholder : defaultPlaceholder"
      [min]="field.props?.min"
      [max]="field.props?.max"
      [stepSize]="field.props?.step"
      [minLength]="field.props?.minLength"
      [maxLength]="field.props?.maxLength"
      [minDate]="field.props?.minDate"
      [maxDate]="field.props?.maxDate"
      [inputInfoText]="field.props?.inputInfoText"
      [pattern]="inputPattern"
      [clearable]="false"
      [prefix]="field.props?.prefix"
      [suffix]="field.props?.suffix"
      [autocomplete]="field.props?.autocomplete"
    />
  `,
})
export class FormlyFieldInputComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  fieldSubscription?: Subscription;
  inputPattern: string;
  inputType?: string | any;
  fieldName: string;
  defaultPlaceholder: string;

  ngOnInit() {
    this.setInputFields();
    if (this.field.options?.fieldChanges)
      this.fieldSubscription = this.field.options.fieldChanges.subscribe(() => {
        this.setInputFields();
      });
  }

  ngOnDestroy() {
    this.fieldSubscription?.unsubscribe();
  }

  private setInputFields() {
    this.fieldName = isString(this.field.key) ? this.field.key : '';
    this.inputPattern = this.field.props?.pattern as string;
    this.inputType = this.field.props?.type ?? 'text';
    this.defaultPlaceholder = this.inputType === 'date' ? 'm/d/yyyy' : '';
  }
}
