<div class="pm-search">
  <div class="pm-search__top-section">
    <h1 class="pm-search__header">Directory Search</h1>

    <div class="pm-search__search-area">
      <app-search-with-suggestions class="pm-search__search-field" (valueChanged)="updateSearch($event)"> </app-search-with-suggestions>

      <button class="button button--retail pm-search__search-btn" (click)="search()">
        <img src="assets/icons/magnifying-glass-white.svg" />
        <span class="pm-search__button-label"> Search </span>
      </button>
    </div>
  </div>

  <div class="pm-search__results" *ngIf="searchBtnClicked">
    <app-product-manufacturer-search-results> </app-product-manufacturer-search-results>
  </div>
</div>
