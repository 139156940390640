<div class="position-relative">
  <input
    class="position-absolute"
    matInput
    [matDatepicker]="datePicker"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
    style="visibility: hidden; top: 0; bottom: 0; width: 100%"
    [min]="minDate"
    [max]="maxDate"
  />
  <chitin-button
    [disabled]="disabled"
    [isLoading]="isLoading"
    [color]="color"
    [size]="size"
    [outline]="outline"
    [label]="displayLabel ?? label"
    (clicked)="datePicker.open()"
    [iconPosition]="iconPosition"
    [icon]="icon ?? 'fa-regular fa-calendar'"
  >
  </chitin-button>
</div>
<mat-datepicker [disabled]="disabled" #datePicker></mat-datepicker>
