import { AgreementType } from '@shared/models/agreement-type.enum';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { UserRole } from '@shared/models/user-role.model';
import { UserStatusType } from '@shared/models/user-status.type';

export interface IUser {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  role: UserRole | string;
  password?: string;
  avatar?: string;
  date_verified?: string;
  ncpdps?: string[];
  identity_id?: string;
  tenants?: TenantIdType[];
  currentTenant?: TenantIdType;
  firstTimeOn?: boolean;
  status: UserStatusType;
  time_of_status?: Date;
  sync_date?: string;
  accepted_agreements?: Array<string | { id: AgreementType; dateModified: string }>;
  business_territories?: Record<string, string[]>;
  business_regions?: Record<string, string[]>;
  business_area?: Record<string, string[]>;
}

export const REQUIRED_USER_FIELDS = ['username', 'first_name', 'last_name', 'role', 'email'];
