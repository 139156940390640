import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { PharmacyApiService } from '@services/pharmacy-api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Agreement } from '@shared/models/agreement.model';
import { AgreementType } from '@shared/models/agreement-type.enum';
import { UserAgreementComponent } from '@app/root/components/agreements/user-agreement/user-agreement.component';
import { HorizoncaresProgramGuidelinesComponent } from '@app/root/components/agreements/horizoncares-program-guidelines/horizoncares-program-guidelines.component';
import { HorizonEcmonarchTermsConditionsComponent } from '@app/root/components/agreements/horizon-ecmonarch-terms-conditions/horizon-ecmonarch-terms-conditions.component';
import { EagleCopayAssistanceProgramComponent } from '@app/root/components/agreements/eagle-copay-assistance-program/eagle-copay-assistance-program.component';
import { BaseService } from '@services/base.service';
import { LogService } from '@services/log.service';
import { LogOptions } from '@shared/models/log-options.model';
import { Program } from '@shared/models/program.model';
import { AlmirallSeysaraTermsConditionsComponent } from '@app/root/components/agreements/almirall-seysara-terms-conditions/almirall-seysara-terms-conditions.component';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { NewAgreement } from '@shared/models/new-agreement.model';
import { takeUntil } from 'rxjs/operators';
import Utils from '@shared/providers/utils';
import { ReducedAgreement } from '@shared/models/reduced-agreement.model';
import { RetailStateService } from '@app/retail/providers/retailState.service';
import { DateService } from '@services/date.service';
import { PharmacyService } from '@services/pharmacy.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AgreementService extends BaseService implements OnDestroy {
  agreementsForPharmacy: BehaviorSubject<Agreement[]> = new BehaviorSubject<Agreement[]>([]);
  newAgreementsForPharmacy: BehaviorSubject<Agreement[]> = new BehaviorSubject<Agreement[]>([]);

  allAgreements: Agreement[] = [
    new Agreement({
      id: AgreementType.USER_AGREEMENT,
      name: 'Brightscrip User Agreement',
      component: UserAgreementComponent,
      selector: 'app-user-agreement',
      dateModified: '2020-04-29',
      file: 'Brightscrip_Access_Agreement_04-29-20.pdf',
    }),
    new Agreement({
      id: AgreementType.HORIZONCARES_PROGRAM_GUIDELINES,
      name: 'HorizonCares Program Guidelines',
      component: HorizoncaresProgramGuidelinesComponent,
      selector: 'app-horizoncares-program-guidelines',
      dateModified: '2019-12-10',
      file: 'HorizonCares_Information.pdf',
    }),
    new Agreement({
      id: AgreementType.HORIZON_ECMONARCH_TERMS_CONDITIONS,
      name: 'Horizon ECMonarch Terms & Conditions',
      component: HorizonEcmonarchTermsConditionsComponent,
      selector: 'app-horizon-ecmonarch-terms-conditions',
      dateModified: '2020-04-06',
      file: 'Horizon_ECMONARCH_Terms_and_Conditions_04.06.20.pdf',
      hasOptionalHeader: true,
    }),
    new Agreement({
      id: AgreementType.EAGLE_COPAY_ASSISTANCE_PROGRAM,
      name: 'Eagle Copay Assistance Program Terms & Conditions',
      component: EagleCopayAssistanceProgramComponent,
      selector: 'app-eagle-copay-assistance-program',
      dateModified: '2019-06-19',
      file: 'ECHORIZON8_ECHORIZON9_Terms_and_Conditions.pdf',
      hasOptionalHeader: true,
    }),
    new Agreement({
      id: AgreementType.ALMIRALL_SEYSARA_TERMS_CONDITIONS,
      name: 'Almirall Seysara Program Terms & Conditions',
      component: AlmirallSeysaraTermsConditionsComponent,
      selector: 'app-almirall-seysara-terms-conditions',
      dateModified: '2020-01-16',
      file: 'Almirall_Seysara_Terms_and_Conditions.pdf',
      hasOptionalHeader: false,
    }),
  ];

  newAgreements: NewAgreement[] = [];

  private currentPharmacy: IPharmacy;
  private unsubscribe = new Subject<void>();

  constructor(
    private authService: AuthService,
    private dateService: DateService,
    private pharmacyApiService: PharmacyApiService,
    private pharmacyService: PharmacyService,
    private retailStateService: RetailStateService,
    private Log: LogService,
  ) {
    super();
    this.reloadAgreementsOnPharmacyChange();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  reset() {
    this.agreementsForPharmacy.next([]);
    this.newAgreementsForPharmacy.next([]);
  }

  private reloadAgreementsOnPharmacyChange() {
    this.retailStateService.pharmacy.pipe(takeUntil(this.unsubscribe)).subscribe(pharmacy => {
      if (pharmacy) {
        this.currentPharmacy = pharmacy;
        this.loadAgreements();
      }
    });
  }

  searchAgreementsById(id: AgreementType, includeNewVersions = false): Agreement {
    const agreement: Agreement = new Agreement(this.allAgreements.find(item => item.id === id));

    if (includeNewVersions) {
      const newAgreement = this.findNewAgreementForAgreement(agreement);

      if (newAgreement && this.doesAgreementHaveNewVersion(agreement) && newAgreement.isTimeToShow()) {
        agreement.updateFromNewAgreement(newAgreement);
      }
    }

    return agreement;
  }

  private searchCurrentAgreementsById(id: AgreementType, includeNewVersions = false): Agreement {
    const agreement: Agreement = new Agreement(this.agreementsForPharmacy.getValue().find(item => item.id === id));

    if (includeNewVersions) {
      const newAgreement = this.findNewAgreementForAgreement(agreement);

      if (newAgreement && this.doesAgreementHaveNewVersion(agreement) && newAgreement.isTimeToShow()) {
        agreement.updateFromNewAgreement(newAgreement);
      }
    }

    return agreement;
  }

  private searchAgreementsByFile(file: string): Agreement | undefined {
    const agreement: Agreement | undefined = this.allAgreements.find(item => {
      return item.file === file;
    });

    return agreement;
  }

  loadAgreements(): void {
    this.isLoading.next(true);
    this.loadAcceptedAgreements();
    if (this.doAgreementsNeedToBeReset(this.currentPharmacy.accepted_agreements ?? [])) {
      this.checkPharmacyProgramForAdditionalAgreements();
    }
    this.loadNewAgreements();
    this.isLoading.next(false);
  }

  private doAgreementsNeedToBeReset(acceptedAgreements: any[]): boolean {
    return !this.areAcceptedAgreementsCorrectType(acceptedAgreements);
  }

  private areAcceptedAgreementsCorrectType(agreements: any[]): boolean {
    return Utils.isNonEmptyArray(agreements) && agreements[0] && agreements[0].id && agreements[0].dateModified;
  }

  markAgreed(agreementId: AgreementType, useNewVersion = false): Observable<any> {
    const pharmacy = this.currentPharmacy;
    const agreement: Agreement = this.searchAgreementsById(agreementId, useNewVersion);
    const reducedAgreement = agreement.reduce();
    reducedAgreement.username = this.authService.getCurrentUser()?.username ?? '';
    reducedAgreement.dateAccepted = this.dateService.formatDateForApi(new Date(), 'YYYY-MM-DD');

    if (
      pharmacy.accepted_agreements &&
      pharmacy.accepted_agreements.find(acceptedAgreement => acceptedAgreement.id === reducedAgreement.id)
    ) {
      const oldAgreement = pharmacy.accepted_agreements.find(acceptedAgreement => acceptedAgreement.id === reducedAgreement.id);
      if (oldAgreement) {
        oldAgreement.dateModified = reducedAgreement.dateModified;
        oldAgreement.dateAccepted = reducedAgreement.dateAccepted;
      }
    } else if (pharmacy.accepted_agreements) {
      pharmacy.accepted_agreements.push(reducedAgreement);
    } else {
      pharmacy.accepted_agreements = [reducedAgreement];
      pharmacy.date_joined = reducedAgreement.dateAccepted;
    }
    const acceptedAgreements = this.agreementsForPharmacy.getValue();
    const newAgreedAgreement = this.getAgreementsFromReducedAgreements([reducedAgreement])[0];
    acceptedAgreements.push(newAgreedAgreement);
    this.agreementsForPharmacy.next(acceptedAgreements);
    let newAgreements = this.newAgreementsForPharmacy.getValue();
    newAgreements = newAgreements.filter(a => a.id !== agreementId);
    this.newAgreementsForPharmacy.next(newAgreements);
    return this.pharmacyService.savePharmacy({
      data: {
        ncpdp: pharmacy.ncpdp,
        acceptedAgreements: pharmacy.accepted_agreements.map((row: ReducedAgreement) => {
          return {
            acceptedAt: this.dateService.formatDateForApi(moment(row.dateAccepted).toDate()),
            createdByUser: row.username,
            id: row.id,
            modifiedAt: this.dateService.formatDateForApi(moment(row.dateModified).toDate()),
          };
        }),
        joinedAt: this.dateService.formatDateForApi(moment(pharmacy.date_joined).toDate()),
      },
    });
  }

  private loadNewAgreements(): void {
    this.newAgreementsForPharmacy.next(this.newAgreementsToShowUser);
  }

  private get newAgreementsToShowUser(): Agreement[] {
    const _newUsersAgreements: Agreement[] = [];

    this.agreementsForPharmacy.getValue().forEach(agreement => {
      if (this.isTimeToShowNewVersionOfAgreement(agreement.id)) {
        const newAgreement = this.findNewAgreementForAgreement(agreement);
        _newUsersAgreements.push(new Agreement(agreement).updateFromNewAgreement(newAgreement)!);
      } else if (
        !this.currentPharmacy.accepted_agreements ||
        !this.currentPharmacy.accepted_agreements.find(acceptedAgreement => AgreementType[acceptedAgreement.id] === agreement.id)
      ) {
        _newUsersAgreements.push(new Agreement(agreement));
      }
    });
    return _newUsersAgreements;
  }

  isTimeToShowNewVersionOfAgreement(agreementId: AgreementType): boolean {
    const agreement = this.searchCurrentAgreementsById(agreementId);
    const newAgreement = this.findNewAgreementForAgreement(agreement);
    return this.doesAgreementHaveNewVersion(agreement) && !!newAgreement?.isTimeToShow();
  }

  private doesAgreementHaveNewVersion(agreement: Agreement): boolean {
    const newAgreement = this.findNewAgreementForAgreement(agreement);

    if (newAgreement) {
      return agreement.isOlderThan(newAgreement);
    }

    return false;
  }

  findNewAgreementForAgreement(agreement: Agreement): NewAgreement | undefined {
    return this.newAgreements.find(newAgreement => newAgreement.id === agreement.id);
  }

  private loadAcceptedAgreements(): void {
    const pharmacy = this.currentPharmacy;
    const agreementForEveryUser = new Agreement(this.searchAgreementsById(AgreementType.USER_AGREEMENT));
    let agreements: Agreement[] = [];
    if (pharmacy && pharmacy.accepted_agreements) {
      const acceptedAgreements = this.getAgreementsFromReducedAgreements(pharmacy.accepted_agreements);
      acceptedAgreements.forEach(acceptedAgreement => {
        if (acceptedAgreement && acceptedAgreement.id) {
          agreements.push(acceptedAgreement);
        }
      });
    }
    if (agreements.length === 0 && this.currentPharmacy.ncpdp) {
      agreements = [agreementForEveryUser];
    }
    this.agreementsForPharmacy.next(agreements);
  }

  getAgreementsFromReducedAgreements(reducedAgreements: ReducedAgreement[]): Agreement[] {
    return reducedAgreements.map(reducedAgreement => {
      const foundAgreement = new Agreement(this.allAgreements.find(agreement => agreement.id === AgreementType[reducedAgreement.id]));
      // Apply user specific data.
      foundAgreement.dateAccepted = reducedAgreement.dateAccepted;
      foundAgreement.username = reducedAgreement.username;
      if (new Date(foundAgreement.dateModified ?? '') < new Date(reducedAgreement.dateModified ?? '')) {
        foundAgreement.updateFromNewAgreement(this.newAgreements.find(agreement => agreement.id === AgreementType[reducedAgreement.id]));
      }
      return foundAgreement;
    });
  }

  protected checkPharmacyProgramForAdditionalAgreements(): void {
    if (!this.currentPharmacy || !this.currentPharmacy.ncpdp) {
      return;
    }
    this.pharmacyApiService.getProgramForPharmacy(this.currentPharmacy.ncpdp).subscribe(
      (program: Program) => {
        this.isLoading.next(false);

        if (!program.agreement_docs) {
          return;
        }

        this.addNewAgreementDocsFromProgram(program);
      },
      error => {
        this.isLoading.next(false);

        if (this.authService.getCurrentUser()) {
          this.logAgreementError(error, this.currentPharmacy.ncpdp);
          this.agreementsForPharmacy.error("Error: Can't fetch agreements. Please refresh.");
        }
      },
    );
  }

  private addNewAgreementDocsFromProgram(program: Program): void {
    const currentAgreements: Agreement[] = this.agreementsForPharmacy.getValue();

    program.agreement_docs?.forEach(doc => {
      const newAgreement: Agreement | undefined = this.searchAgreementsByFile(doc);

      if (newAgreement && !currentAgreements.find(agreement => agreement.id === newAgreement?.id)) {
        currentAgreements.push(new Agreement(newAgreement));
      }

      if (Utils.isNonEmptyArray(currentAgreements)) {
        const pharmacy = this.currentPharmacy;
        if (pharmacy && pharmacy.accepted_agreements) {
          pharmacy.accepted_agreements = currentAgreements.map(agreement => new Agreement(agreement).reduce());
          this.pharmacyApiService.savePharmacy(pharmacy);
        } else if (pharmacy) {
          pharmacy.accepted_agreements = currentAgreements.map(agreement => agreement.reduce());
          this.pharmacyApiService.savePharmacy(pharmacy);
        }
      }

      this.agreementsForPharmacy.next(currentAgreements);
    });
  }

  private logAgreementError(error: Error, ncpdp: string): void {
    const options: LogOptions = {
      tags: {
        action: 'fetch-agreements',
      },
      extraInfo: {
        ncpdp: ncpdp,
      },
    };
    this.Log.error(new Error("Can't get pharmacy: " + JSON.stringify(error)), options);
  }
}
