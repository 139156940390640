<div class="vh-100 overflow-auto">
  <div class="offcanvas-header">
    <h3 id="offcanvasRightLabel">Edit columns</h3>
    <button (click)="activeOffcanvas.dismiss()" type="button" class="btn-close text-reset" data-cy="closeBtn" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body d-flex gap-3">
    <div style="flex-grow: 1; flex-basis: 0">
      <div class="card mb-4">
        <chitin-form-input placeholder="Search available columns..." [(value)]="searchQuery" />
      </div>
      <ng-container *ngIf="favouriteColumns.length > 0">
        <h4>Favourites</h4>
        <div
          class="card columnContainer"
          cdkDropList
          id="favourites-list"
          [cdkDropListData]="favouriteColumns"
          [cdkDropListConnectedTo]="['selected-list']"
          (cdkDropListDropped)="columnDroppedFavourites($event)"
        >
          <ng-container *ngFor="let column of favouriteColumns">
            <div
              *ngIf="!searchQuery || (column.display ?? column.field).toLowerCase().includes(searchQuery.toLowerCase())"
              [ngClass]="{ column: !column.isAdded }"
            >
              <ng-container *ngIf="!column.isAdded">
                <div class="d-flex gap-3 align-items-center">
                  <chitin-icon
                    (click)="columnToggleFavourite(column)"
                    [clickable]="true"
                    [icon]="(column.favourite ? 'fa-solid' : 'fa-regular') + ' fa-star'"
                  />
                  <h5 class="mb-0">{{ column.display }}</h5>
                </div>
                <chitin-button (clicked)="columnAdded(column)" icon="fa-solid fa-plus" color="secondary" label="" iconPosition="center" />
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngFor="let category of columnCategories">
        <h4>{{ category.name }}</h4>
        <div
          class="card columnContainer"
          cdkDropList
          [id]="'categories-list-' + category.id"
          [cdkDropListData]="availableColumns[category.id]"
          [cdkDropListConnectedTo]="['selected-list']"
          (cdkDropListDropped)="columnDroppedCategories($event)"
          *ngIf="availableColumns[category.id].length > 0"
        >
          <div
            cdkDrag
            *ngFor="let column of availableColumns[category.id]"
            [ngClass]="{
              'column draggable': !column.isAdded && (!searchQuery || column.display.toLowerCase().includes(searchQuery.toLowerCase())),
            }"
          >
            <ng-container *ngIf="!column.isAdded && (!searchQuery || column.display.toLowerCase().includes(searchQuery.toLowerCase()))">
              <div class="d-flex align-items-center">
                <chitin-icon class="pe-3" icon="fa-solid fa-bars" />
                <div class="d-flex gap-3 align-items-center">
                  <chitin-icon
                    (click)="columnToggleFavourite(column)"
                    [clickable]="true"
                    [icon]="(column.favourite ? 'fa-solid' : 'fa-regular') + ' fa-star'"
                  />
                  <h5 class="mb-0">{{ column.display }}</h5>
                </div>
              </div>
              <chitin-button
                (clicked)="columnAdded(column)"
                icon="fa-solid fa-plus"
                data-cy="addColumn"
                color="secondary"
                label=""
                iconPosition="center"
              />
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div style="flex-grow: 1; flex-basis: 0">
      <h4>Selected Columns</h4>
      <div
        class="card columnContainer"
        data-cy="selectedColumns"
        cdkDropList
        id="selected-list"
        [cdkDropListData]="appliedColumns"
        [cdkDropListConnectedTo]="availableColumnsListIds"
        (cdkDropListDropped)="columnDroppedSelected($event)"
      >
        <div cdkDrag *ngFor="let column of appliedColumns; let i = index" class="column draggable">
          <div class="d-flex align-items-center">
            <chitin-icon class="pe-3" icon="fa-solid fa-bars" />
            <div class="d-flex gap-3 align-items-center">
              <chitin-icon
                (click)="columnToggleFavourite(column)"
                [clickable]="true"
                [icon]="(column.favourite ? 'fa-solid' : 'fa-regular') + ' fa-star'"
              />
              <h5 class="mb-0">{{ column.display }}</h5>
            </div>
          </div>
          <chitin-button
            (clicked)="columnRemoved(i)"
            icon="fa-solid fa-xmark"
            data-cy="deleteColumn"
            color="secondary"
            label=""
            iconPosition="center"
          />
        </div>
      </div>
    </div>
  </div>
</div>
