<div class="sidebar-cta">
  <div class="sidebar-cta-content">
    <strong class="d-inline-block mb-2" *ngIf="title">{{ title }}</strong>
    <div class="mb-3 text-sm" *ngIf="text">{{ text }}</div>

    <div class="d-grid" *ngIf="button">
      <a [routerLink]="button.path" class="btn" [ngClass]="button.classNames ?? ''" target="_blank">{{ button.title }}</a>
    </div>
  </div>
</div>
