import { pharmacyPaymentStatementFragment } from '@services/graphql/pharmacy/pharmacy-statement.fragment';
import gql from 'graphql-tag';

export const GetPharmacyStatementsQuery = gql`
  ${pharmacyPaymentStatementFragment}
  query GetPharmacyPaymentStatements(
    $tenantId: String!
    $npi: String!
    $filter: PatientAssistancePharmacyPaymentStatementFilterInput!
    $page: ConnectionEdgePage!
  ) {
    tenant(id: $tenantId) {
      pharmacy(npi: $npi) {
        paymentStatements(filter: $filter, page: $page, orderDirection: desc) {
          edges {
            node {
              ...PharmacyPaymentStatement
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
      }
    }
  }
`;
