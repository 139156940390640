import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash';

const ordinals: string[] = ['th', 'st', 'nd', 'rd'];

@Pipe({ name: 'ordinal', pure: true })
export class OrdinalPipe implements PipeTransform {
  transform(value: number | string, keepNumber: boolean = true) {
    if (isString(value)) value = parseInt(value);

    const mod = value % 100;

    return (keepNumber ? value : '') + (ordinals[(mod - 20) % 10] || ordinals[mod] || ordinals[0]);
  }
}
