<div>
  <div class="ua">
    <h1><strong>Brightscrip™ Access Agreement</strong></h1>

    <p>
      Monarch Specialty Group, LLC (“<strong>Monarch</strong>”) is providing your registered pharmacy organization
      (“<strong>Pharmacy</strong>”), and those to whom your Pharmacy delegates access or permissions, including you (“<strong>You</strong>”)
      (hereinafter, “<strong>Users</strong>”)) with voluntary access to our portal service (“<strong>Brightscrip™</strong>”) for the
      purpose of facilitating between your Pharmacy and our pharmaceutical manufacturer customer that has given your Pharmacy permission to
      access Brightscrip™ (“<strong>Pharmaceutical Company</strong>”) the transmission of prescription coupon codes and related documents
      on behalf of select drugs (“<strong>Files</strong>”) that such Pharmaceutical Company makes available to your Pharmacy (“<strong
        >Intended Use</strong
      >”).
    </p>

    <p>
      BY USING BRIGHTSCRIP™ IN ANY MANNER, YOU, ON BEHALF OF THE PHARMACY FOR WHICH YOU ARE EMPLOYED ACKNOWLEDGE THE INTENDED USE OF
      BRIGHTSCRIP™, AND REPRESENT AND WARRANT TO MONARCH THAT YOU HAVE THE AUTHORITY OF YOUR PHARMACY TO VALIDLY EXECUTE THIS ACCESS
      AGREEMENT (“<strong>AGREEMENT</strong>”) ON BEHALF OF YOUR PHARMACY AND TO BIND PHARMACY AND ITS USERS TO THE TERMS OF THIS AGREEMENT
      AND THAT YOUR PHARMACY IS AN ORGANIZATION USING BRIGHTSCRIP™ FOR ITS INTENDED USE, AND YOU, ON BEHALF OF YOUR PHARMACY, ACCEPT ALL OF
      THE TERMS OF THIS AGREEMENT, INCLUDING, IN PARTICULAR, THE PROVISIONS ON ACCOUNT INFORMATION AND SECURITY CONTAINED IN
      <strong>SECTION 1</strong>, THE WARRANTY DISCLAIMERS IN <strong>SECTION 10</strong>. THE LIMITATION ON LIABILITY IN
      <strong>SECTION 11</strong>, THE INDEMNITY OBLIGATIONS IN <strong>SECTION 12</strong> AND THE MANDATORY ARBITRATION IN
      <strong>SECTION 15</strong>. UPON ACCEPTANCE, THIS AGREEMENT IS ENFORCEABLE AGAINST PHARMACY AND ITS USERS, AND YOU ACKNOWLEDGE AND
      AGREE TO ITS TERMS FOR PHARMACY AND ITS USERS, AND YOURSELF AS A USER.
    </p>

    <p>
      <strong>
        IF PHARMACY, AND YOU, AS A USER FOR PHARMACY, DO NOT AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, PLEASE DO NOT ACCESS AND USE
        BRIGHTSCRIP™.
      </strong>
    </p>

    <p>
      <strong> IF YOU ARE AN INDIVIDUAL THAT HAS GAINED ACCESS TO</strong> BRIGHTSCRIP™
      <strong
        >YOU ARE NOT AFFILIATED WITH A PHARMACY THAT HAS DELEGATED TO YOU ACCESS OR PERMISSIONS, THEN THE TERM “PHARMACY” SHALL APPLY TO YOU
        AS AN INDIVIDUAL.
      </strong>
    </p>

    <p>
      <strong>
        IF YOU ARE AN INDIVIDUAL THAT HAS GAINED ACCESS TO BRIGHTSCRIP™ AND YOU ARE NOT AFFILIATED WITH A PHARMACY THAT HAS DELEGATED TO
        YOU ACCESS OR PERMISSIONS, THEN THE TERM “PHARMACY” SHALL APPLY TO YOU AS AN INDIVIDUAL.
      </strong>
    </p>

    <p>
      Brightscrip™ is not intended or authorized for use by persons under the age of eighteen (18). By using Brightscrip™, you represent
      and warrant that you are eighteen (18) years of age or older and that you, as a User, agree to abide by all of the terms and
      conditions of the Agreement. If we believe that you are under the age of eighteen (18) or that you are not old enough to consent to
      and be legally bound by the Agreement, we may, at any time, in our sole discretion, and with or without notice, terminate your access
      to or use of Brightscrip™ (or any portion, aspect, or feature of it).
    </p>

    <p>
      Subject to the terms and conditions of the Master Agreement, Monarch reserves the right to deny access to Brightscrip™ at our
      discretion and for any reason, including any breach of the Agreement.
    </p>

    <p>
      <strong>
        FURTHER, MONARCH RESERVES THE RIGHT, AT OUR SOLE DISCRETION, TO CHANGE, MODIFY, ADD OR REMOVE PORTIONS OF THIS AGREEMENT FROM TIME
        TO TIME, WITHOUT PRIOR NOTICE (AS FURTHER DESCRIBED IN SECTION 13 BELOW). WE WILL POST MATERIAL CHANGES ON BRIGHTSCRIP™, HOWEVER,
        IT IS YOUR RESPONSIBILITY TO CHECK THIS AGREEMENT PERIODICALLY FOR CHANGES. CONTINUED USE OF BRIGHTSCRIP™ FOLLOWING THE POSTING OF
        CHANGES WILL MEAN ACCEPTANCE AND AGREEMENT TO THE CHANGES BY PHARMACY AND ITS USERS.
      </strong>
    </p>

    <h2>Account Information and Security</h2>

    <p class="ua__indented">
      In order to use Brightscrip™, we may ask your Pharmacy and each of its Users designated by it to register an account and select a
      password and/or provide us with certain personal information, including, without limitation, an email address for the Pharmacy and/or
      each of its Users so that we or the Pharmaceutical Company that has given the Pharmacy permission to access Brightscrip™ may contact
      Pharmacy and its Users with regard to program information and Files and other similar transaction activity that occurs on
      Brightscrip™.
    </p>

    <span>Responsibility for the security of the account and account credentials:</span>
    <ul>
      <li>Establish a secure password and do not share the password with anyone.</li>
      <li>Practice safe computing habits to avoid phishing and other attacks.</li>
    </ul>

    <p class="ua__indented">
      By accessing Brightscrip™, Pharmacy and its Users undertake not to use Brighscrip™ for any purpose that is unlawful, abusive,
      libellous, obscene or threatening.
    </p>

    <p class="ua__indented">
      If Pharmacy chooses to assign access or permissions to additional Users, it is the responsibility of Pharmacy to monitor their use,
      assign permissions for additional Users, and revoke access for Users that no longer require it, and it is the responsibility of
      Pharmacy to obtain any consents from Users so that Brightscrip™ and the Pharmacuetical Company that has given the Pharmacy permission
      to access Brightscrip™ may utilize email addresses provided by Users in order to contact Pharmacy and its Users with regard to
      program information and Files and other similar transaction activity that occurs on Brightscrip™. Pharmacy will monitor the
      activities of its Users, and is responsible for the acts and omissions of each of its Users, for which it has given access or
      permissions.
    </p>

    <p class="ua__indented">
      No one other than employees of Pharmacy, or its legal fiduciaries, that require access to Brightscrip™ should be granted access or
      permissions to Brightscrip™. We recommend no sharing of account details or passwords with others. Monarch assumes no obligation to
      monitor such access or permissions.
    </p>

    <p class="ua__indented">
      Monarch may discontinue a Pharmacy or its User’s access to Brightscrip™, modify Brightscrip™, change providers, terminate this
      Agreement, or change the terms and conditions of this Agreement at any time (hereinafter, the period during which Monarch permits You
      and/or Pharmacy to have access to, and use of, Brightscrip™ shall be referred to as the “<strong>Agreement Term</strong>”).
    </p>

    <p class="ua__indented">
      WHEN PHARMACY OR A USER GIVES MONARCH AN EMAIL ADDRESS WHEN SETTING UP AN ACCOUNT AT BRIGHTSCRIP™, PHARMACY AND EACH SUCH USER AGREE
      THAT MONARCH AND BRIGHTSCRIP™ MAY USE THIS EMAIL ADDRESS TO CONTACT PHARMACY AND USER WITH REGARD TO PROGRAM INFORMATION AND FILES
      AND OTHER SIMILAR TRANSACTION ACTIVITY THAT OCCURS ON BRIGHTSCRIP™.
    </p>

    <p class="ua__indented">
      Brightscrip™ will be provided with accurate, complete, and updated information on any account that is setup.
    </p>

    <p class="ua__indented">
      PHARMACY AND ITS USERS ARE SOLELY RESPONSIBLE FOR ANY ACTIVITY ON THE ACCOUNT, WHETHER OR NOT AUTHORIZED, INCLUDING CONTENT PUBLISHED
      OR PURCHASES MADE THROUGH THE ACCOUNT. Please notify us immediately of any actual or suspected loss, theft, fraud, or unauthorized use
      of the account or account password.
    </p>

    <h2>Operation</h2>

    <p class="ua__indented">
      We reserve complete and sole discretion with respect to the operation of Brightscrip™. We reserve the right to change terms and
      warranties without notice. We also reserve the right to withdraw, suspend, or discontinue any functionality or feature of
      Brightscrip™ at any time.
    </p>

    <h2>Availability of Brightscrip™</h2>

    <p class="ua__indented">
      It is not possible to operate Brightscrip™ with 100% guaranteed uptime. Monarch will make reasonable commercial efforts to keep
      Brightscrip™ operational and available on a 24 hour basis, but Monarch cannot guarantee continuous availability. MONARCH DOES NOT
      GUARANTEE THAT BRIGHTSCRIP™ WILL BE AVAILABLE OR THAT IT WILL MEET ANY REQUIREMENTS OF PHARMACY OR ITS USERS, THAT ACCESS WILL BE
      UNINTERRUPTED, THAT THERE WILL BE NO DELAYS, FAILURES, ERRORS OR OMISSIONS OR LOSS OF TRANSMITTED INFORMATION, THAT NO VIRUSES OR
      OTHER CONTAMINATING OR DESTRUCTIVE PROPERTIES WILL BE TRANSMITTED OR THAT NO DAMAGE WILL OCCUR TO COMPUTER SYSTEMS OF PHARMACY OR ITS
      USERS. Certain technical difficulties, routine site maintenance and upgrades, and other events may, from time to time, result in
      interruptions to or outages of Brightscrip™. In addition, Files may be modified, deleted or removed from Brightscrip™ by Monarch at
      any time, and without warning, at its sole discretion, and Monarch otherwise reserves the right at any time, and from time to time, to
      modify or discontinue (on a temporary or permanent basis) certain functions of Brightscrip™, with or without notice and at its sole
      discretion. Monarch, through Brightscrip™, is a manager conduit for coupon deployment, relying on the dictates of the manufacturer
      and its coupon plans, policies and other requirements. MONARCH SHALL NOT BE LIABLE TO PHARMACY OR ITS USERS OR TO ANY THIRD PARTY FOR
      ANY DIRECT OR INDIRECT CONSEQUENCE OF ANY MODIFICATION, SUSPENSION, DISCONTINUANCE OF, OR INTERRUPTION TO, BRIGHTSCRIP™ OR ANY FILE
      MODIFICATION, DELETION OR REMOVAL FROM BRIGHTSCRIP™.
    </p>

    <h2>Technical Requirements</h2>

    <p class="ua__indented">
      Use of Brightscrip™ requires Internet access through a Pharmacy and/or User’s computer or mobile device. Brightscrip™ is not
      responsible for any mobile carrier charges resulting from the use of Brightscrip™, including from any notifications provided by
      Brightscrip™. We do not guarantee that Brightscrip™ will be compatible with all devices or will be supported by all mobile carriers.
      Pharmacy and its Users are required to have JavaScript (or similar technologies) enabled in Google’s Chrome browser to use
      Brightscrip™, and some features and portions of Brightscrip™ (including, but not limited to, making, modifying, or canceling
      reservations) may not be accessible with JavaScript disabled.
    </p>

    <h2>Trademarks</h2>

    <p class="ua__indented">
      The name “Brightscrip” and the “Brightscrip” logo are trademarks of Monarch Specialty Group, LLC in the United States and other
      countries. All other company names, logos, and trademarks mentioned herein are the property of their respective owners. Nothing herein
      shall be construed as granting, by implication, estoppel, or otherwise, any license or right to use our names, logos, trademarks or
      other intellectual property displayed on Brightscrip™ except as expressly provided herein or by obtaining the written permission of
      us or such other third party owner, as applicable. Pharmacy and its Users acknowledge that Brightscrip™ will aggressively enforce its
      intellectual property rights with respect to its trademarks to the fullest extent of the law, including, without limitation, the
      seeking of criminal prosecution.
    </p>

    <h2>Monarch’s Proprietary Rights</h2>

    <p class="ua__indented">
      Brightscrip™ is owned and operated by us. As between Pharmacy and its Users and us, we own and retain all proprietary rights in and
      to Brightscrip™.
    </p>

    <p class="ua__indented">
      The structure, organization and code of Brightscrip™ are the valuable intellectual property, trade secrets and proprietary and
      confidential information of Monarch. Pharmacy and its Users agree to keep those aspects of Brightscrip™ that have not been made
      public by Monarch confidential and not to disclose it to any third-party without the express written permission ng-template
      #agreementof Monarch. Pharmacy and its Users further agree that any obligations of confidentiality hereunder shall survive termination
      of this Agreement. In addition, Brightscrip™ is protected by law, including without limitation, the patent and copyright laws of the
      United States and other countries, and by international treaty provisions. Except as expressly stated in
      <strong>Section 7</strong> below, this Agreement does not grant Pharmacy or its Users any intellectual property rights in
      Brightscrip™ and all rights not expressly granted herein are reserved by Monarch.
    </p>

    <p class="ua__indented">
      The hardware, software, content, text, photographs, visual interfaces, interactive features, information, trademarks, logos, graphics,
      music, design, artwork, compilation, computer code, products, software, services, structure and “look and feel” of this content and
      all other elements of Brightscrip™ (hereinafter, the foregoing, and specifically excluding any Protected Health Information as
      hereinafter defined, shall be referred to as “<span class="u-underline">Brightscrip</span>™
      <span class="u-underline">Materials</span>”), is owned, controlled or licensed by or to us, and is protected by copyright, trade
      dress, patent, trademark, unfair competition, and other intellectual property laws of the United States and other jurisdictions,
      international conventions, and all other relevant intellectual property and proprietary rights, and applicable laws. All Brightscrip™
      Materials, including intellectual property rights therein and thereto, are the property of us or its subsidiaries or affiliated
      companies and/or third-party licensors. Pharmacy and its Users may not sell, license, distribute, copy, reproduce, republish, upload,
      modify, publicly perform or display, encode, translate, transmit, publish, edit, adapt, create derivative works from, or otherwise
      make unauthorized use of the Brightscrip™ Materials, without our express prior written consent. We reserve all rights not expressly
      granted in this Agreement. Pharmacy and its Users shall not acquire any right, title or interest to the Brightscrip™ Materials,
      whether by implication, estoppel, or otherwise, except for the limited rights set forth in this Agreement. Pharmacy and its Users
      acknowledge that Brightscrip™ will aggressively enforce its intellectual property rights with respect to our Brightscrip™ Materials
      to the fullest extent of the law, including, without limitation, the seeking of criminal prosecution.
    </p>

    <p class="ua__indented">
      Notwithstanding the foregoing, a User may view, download, print, and copy Brightscrip™ Materials for personal, informational use,
      provided that (i) the Brightscrip™ Materials are not modified and (ii) all copyright and propriety notices originally contained in
      the Brightscrip™ Materials are retained on any copies.
    </p>

    <p class="ua__indented">
      Pharmacy acknowledges that any violation of this <strong>Section 6</strong> will result in irreparable harm to Monarch and that
      monetary damages will be inadequate to compensate Monarch fully for such harm and that injunctive relief will be an appropriate remedy
      to enforce the provisions of this Agreement. Therefore, in addition to other remedies at law and in no way restricting such other
      remedies, Pharmacy and its Users agree that Monarch shall be entitled to seek equitable relief regarding any such violation and to
      such other and further relief as a court of competent jurisdiction may deem proper under the circumstances, without the necessity of
      posting a bond, even if the same is normally required.
    </p>

    <h2>License for Intended Use</h2>

    <p class="ua__indented">
      Certain Brightscrip™ Materials on Brightscrip™ are protected by health care laws and intellectual property laws, including but not
      limited to HIPAA and U.S. copyright laws. Pharmacy and its Users expressly acknowledge and agree that the content accessible within
      Brightscrip™ that is not expressly designated as being provided by a third party is the property of Monarch and its content
      providers, and we and our content providers retain all right, title, and interest in the content.
    </p>

    <p class="ua__indented">
      Subject to the terms of this Agreement, Pharmacy for it and its Users is granted a limited, personal, non-exclusive,
      non-sublicensable, non-assignable, non-transferable, and revocable license to access and use Brightscrip™ and related Brightscrip™
      Materials during the Agreement Term (as defined in <strong>Section 1</strong>) solely for the Intended Use. Except as expressly
      provided, all rights are reserved.
    </p>

    <h2>Business Associate Agreement</h2>

    <h3>
      <strong>Definitions</strong>. For purposes of this <strong>Section 8</strong>, the following capitalized terms shall have the meanings
      set forth below in this <strong>Section 8</strong>.
    </h3>

    <p><strong>“Breach”</strong> shall have the same meaning as the term “breach” in 45 CFR §164.402.</p>

    <p><strong>“Breach Notification Rule”</strong> shall mean the rules governing Breaches at 45 C.F.R. §164.400 et seq.</p>

    <p><strong>“Business Associate”</strong> shall mean Monarch Specialty Group, LLC.</p>

    <p><strong>“Covered Entity”</strong> shall mean Pharmacy.</p>

    <p><strong>“Data Aggregation”</strong> shall have the same meaning as the term “data aggregation” in 45 CFR §164.501.</p>

    <p><strong>“Designated Record Set”</strong> shall have the same meaning as the term “designated record set” in 45 CFR §164.501.</p>

    <p><strong>“Disclose”, “Disclosed” and “Disclosure”</strong> shall have the same meaning as the term “disclosure” in 45 CFR §160.103</p>

    <p><strong>“Electronic Health Record”</strong> shall have the same meaning as the term in Section 13400 of the HITECH Act.</p>

    <p><strong>“Health Care Operations”</strong> shall have the same meaning as the term “health care operations” in 45 CFR §164.501.</p>

    <p>
      <strong>“HIPAA Rules”</strong> shall mean Health Insurance Portability and Accountability Act of 1996 Privacy, Security, Breach
      Notification, and Enforcement Rules at 45 CFR Parts 160 and 164.
    </p>

    <p>
      <strong>“HITECH Act”</strong> shall mean The Health Information Technology for Economic and Clinical Health Act, part of the American
      Recovery and Reinvestment Act of 2009 (“ARRA” or “Stimulus Package”), specifically DIVISION A: TITLE XIII Subtitle D—Privacy, any
      HITECH Act amendments and any corresponding regulations as enacted under the authority of the Act, including but not limited to the
      HITECH Act Final Rule published January 25, 2013, and which may be amended from time to time.
    </p>

    <p>
      <strong>“Individual”</strong> shall have the same meaning as the term "individual" in 45 CFR §160.103 and shall include a person who
      qualifies as a personal representative in accordance with 45 CFR §164.502(g).
    </p>

    <p>
      <strong>“Laws”</strong> means any national, regional, state or local law, statute, rule, regulation, code, ordinance, administrative
      ruling, judgment, decree, order or directive of any jurisdiction applicable to this Agreement.
    </p>

    <p>
      <strong>“Privacy Rule”</strong> shall mean Standards for Privacy of Individually Identifiable Health Information at 45 CFR Part 160
      and 45 CFR Part 164, Subparts A and E.
    </p>

    <p>
      <strong>“Protected Health Information” or “PHI”</strong> shall have the same meaning as the term “protected health information” in 45
      CFR §160.103, limited to the information created, received, maintained or transmitted by Business Associate on behalf of Covered
      Entity.
    </p>

    <p><strong>“Required By Law”</strong> shall have the same meaning as the term "required by law" in 45 CFR §164.103.</p>

    <p><strong>“Secretary”</strong> shall have the same meaning as the term “Secretary” in 45 CFR §160.103.</p>

    <p><strong>“Security Incident”</strong> shall have the same meaning as the term “Security incident” in 45 CFR §164.304.</p>

    <p>
      <strong>“Security Rule”</strong> shall mean the Standards for Security of Electronic Protected Health Information at 45 CFR Part 160
      and 45 CFR Part 164, Subparts A and C.
    </p>

    <p><strong>“Subcontractor”</strong> shall have the same meaning as the term “Subcontractor” in 45 CFR §160.103.</p>

    <p>
      <strong>“Unsecured Protected Health Information” or “Unsecured PHI”</strong> shall have the same meaning as the term “unsecured
      protected health information” in 45 CFR §164.402.
    </p>

    <p><strong>“Use”</strong> shall have the same meaning as the term “Use” in 45 CFR §160.103.</p>

    <h3>Business Associate Agreement - Obligations and Activities of Business Associate.</h3>

    <ol type="i">
      <li>
        Business Associate agrees to not use or Disclose PHI other than as permitted or required by this Agreement or as Required By Law.
      </li>

      <li>
        Business Associate agrees to use appropriate safeguards to prevent use or disclosure of PHI other than as provided for by this
        Agreement. Business Associate further agrees to implement administrative, physical and technical safeguards that reasonably and
        appropriately protect the confidentiality, integrity and availability of any electronic PHI, as provided for in the Security Rule
        and as mandated by Section 13401 of the HITECH Act.
      </li>

      <li>
        Business Associate further agrees to report to Covered Entity any use or disclosure of PHI in violation of the applicable HIPAA
        Rules or the requirements of this Agreement of which it becomes aware, including, without limitation, any Security Incident or any
        Breach, and in a manner as prescribed herein.
      </li>

      <li>
        In the event of any such impermissible or improper use, disclosure, Security Incident, Breach, or action as described above,
        Business Associate shall report the surrounding circumstances to Covered Entity to the extent available and reasonable, and in the
        case of any Breach, the names of each individual whose Unsecured PHI has been, or is reasonably believed by Business Associate to
        have been, accessed, acquired, or Disclosed as a result of such Breach and any other available information needed by Covered Entity
        to enable it to comply with its notification obligations under the Breach Notification Rule and in a time and manner consistent with
        Section 13402 of the HITECH Act.
      </li>

      <li>
        Notwithstanding anything to the contrary contained in this Agreement, to avoid unnecessary burden on either party, Business
        Associate shall not be required to report to Covered Entity any unsuccessful security incident, further defined as insignificant or
        trivial attempts that occur on a daily basis, including but not limited to scans, “pings”, or unsuccessful attempts to penetrate
        computer networks or servers maintained by Business Associate.
      </li>

      <li>
        Business Associate agrees to mitigate, to the extent practicable, any harmful effect that is known to Business Associate of a use or
        disclosure of PHI by Business Associate in violation of the HIPAA Rules or the requirements of this Agreement.
      </li>

      <li>
        Business Associate agrees to ensure that any Subcontractor, to whom Business Associate provides PHI, agrees to the same or similar
        restrictions and conditions that apply through this Agreement to Business Associate with respect to such information.
      </li>

      <li>
        Business Associate agrees to provide access, at the request of Covered Entity and during normal Business Hours, to PHI in a
        Designated Record Set to Covered Entity or, as directed by Covered Entity, to an Individual, to allow Covered Entity to meet the
        requirements under 45 CFR §164.524, provided that Covered Entity delivers to Business Associate a written notice at least three (3)
        business days in advance of requesting such access.
      </li>

      <li>
        Business Associate agrees to make any amendment(s) to PHI in a Designated Record Set that Covered Entity directs or agrees to
        pursuant to 45 CFR §164.526, at the request of Covered Entity or an Individual. This provision does not apply if Business Associate
        and its employees or Subcontractors have no PHI from a Designated Record Set of Covered Entity.
      </li>

      <li>
        Unless otherwise protected or prohibited from discovery or disclosure by Law, Business Associate agrees to make internal practices,
        books, and records, including policies and procedures relating to the use or disclosure of PHI, available to the Secretary for
        purposes of the Secretary determining Covered Entity's compliance with the HIPAA Rules.
      </li>

      <li>
        Business Associate agrees to maintain necessary and sufficient documentation of disclosures of PHI as would be required for Covered
        Entity to respond to a request by an Individual for an accounting of such disclosures, in accordance with 45 CFR §164.528. On
        request of Covered Entity, Business Associate agrees to provide to Covered Entity documentation made in accordance with this
        Agreement to permit Covered Entity to respond to a request by an Individual for an accounting of disclosures of PHI in accordance
        with 45 C.F.R.§ 164.528. These provisions do not apply if Business Associate and its employees and Subcontractors have no PHI in a
        Designated Record Set of Covered Entity.
      </li>

      <li>
        Except as provided for in this Agreement, in the event Business Associate receives an access, amendment, accounting of disclosure,
        or other similar request directly from an Individual, Business Associate shall redirect the Individual to the Covered Entity.
      </li>

      <li>
        Upon termination of this Agreement for any reason, Business Associate shall return to Covered Entity or destroy all PHI received
        from Covered Entity. Business Associate shall retain no copies of the PHI in any form. Business Associate shall promptly provide
        written confirmation of such destruction to Client. Notwithstanding the foregoing, in the event that Business Associate determines
        that returning or destroying the PHI is infeasible, Business Associate shall provide to Covered Entity notification of the
        conditions that make return or destruction infeasible. If the return or destruction of PHI is infeasible, Business Associate shall
        extend the protections of this Agreement to such PHI and limit further uses and disclosures of such PHI to those purposes that make
        the return or destruction infeasible, for so long as Business Associate maintains such PHI.
      </li>
    </ol>

    <h3>Business Associate Agreement - Permitted Uses and Disclosures by Business Associate.</h3>

    <ol type="i">
      <li>
        Except as otherwise limited by this Agreement, Business Associate may make any Uses and Disclosures of PHI necessary to perform its
        services to Covered Entity and otherwise meet its obligations under this Agreement, if such Use or disclosure would not violate the
        HIPAA Rules, or the privacy provisions of the HITECH Act, if done by Covered Entity.
      </li>

      <li>
        Except as otherwise limited by this Agreement, Business Associate may make any Uses and Disclosures of PHI necessary to perform its
        services to Covered Entity and otherwise meet its obligations under this Agreement, if such Use or disclosure would not violate the
        HIPAA Rules, or the privacy provisions of the HITECH Act, if done by Covered Entity.
      </li>

      <li>
        Except as otherwise limited in this Agreement, Business Associate may Disclose PHI to a researcher for research purposes, either
        with patient authorization pursuant to 45 CFR §164.512(i), or as a limited data set pursuant to 45 CFR §164.514(e).
      </li>

      <li>
        Except as otherwise limited in this Agreement, Business Associate may Disclose PHI for the proper management and administration of
        the Business Associate, provided that disclosures are Required By Law, or Business Associate obtains reasonable assurances from the
        person to whom the information is Disclosed that it will remain confidential and used, or further Disclosed, only as Required By
        Law, or for the purpose for which it was Disclosed to the person, and the person notifies the Business Associate of any instances of
        which it is aware in which the confidentiality of the information has been breached. Business Associate may Use PHI and further
        Disclose de-identified, aggregated data derived from PHI to provide data aggregation services as permitted by 45 C.F.R.
        §164.504(e)(2)(i)(B).
      </li>

      <li>
        Except as otherwise limited in this Agreement, Business Associate may Disclose PHI for the proper management and administration of
        the Business Associate, provided that disclosures are Required By Law, or Business Associate obtains reasonable assurances from the
        person to whom the information is Disclosed that it will remain confidential and used, or further Disclosed, only as Required By
        Law, or for the purpose for which it was Disclosed to the person, and the person notifies the Business Associate of any instances of
        which it is aware in which the confidentiality of the information has been breached. Business Associate may Use PHI and further
        Disclose de-identified, aggregated data derived from PHI to provide data aggregation services as permitted by 45 C.F.R.
        §164.504(e)(2)(i)(B).
      </li>

      <li>
        Business Associate agrees to make Uses and disclosures and requests for PHI consistent with covered entity’s minimum necessary
        policies and procedures.
      </li>

      <li>
        Business Associate agrees to make Uses and disclosures and requests for PHI consistent with covered entity’s minimum necessary
        policies and procedures.
      </li>
    </ol>

    <h3>Business Associate Agreement - Obligations and Activities of Covered Entity.</h3>

    <ol type="i">
      <li>
        Covered Entity shall notify Business Associate of the provisions and any limitation(s) in its notice of privacy practices of Covered
        Entity in accordance with 45 CFR §164.520, to the extent that such provisions and limitation(s) may affect Business Associate’s Use
        or disclosure of PHI.
      </li>

      <li>
        Covered Entity shall notify Business Associate of any changes in, or revocation of, permission by an Individual to Use or Disclose
        PHI, to the extent that the changes or revocation may affect Business Associate's Use or disclosure of PHI.
      </li>

      <li>
        Covered Entity shall notify Business Associate of any restriction to the Use or disclosure of PHI that Covered Entity has agreed to
        in accordance with 45 CFR §164.522, and also notify Business Associate regarding restrictions that must be honored under section
        13405(a) of the HITECH Act.
      </li>

      <li>
        Covered Entity shall provide Business Associate, within thirty (30) business days of Covered Entity executing this Agreement, a
        description and/or specification regarding the manner and format in which Business Associate shall provide information to Covered
        Entity, wherein such information is required to be provided to Covered Entity as agreed to by Business Associate in this Agreement.
      </li>
    </ol>

    <h2>Representations and Warranties of Pharmacy and You</h2>

    <p class="ua__indented">
      Under the terms of this Agreement, Pharmacy, and each of its Users, represent, warrant and agree that: (i) Pharmacy has all
      appropriate licenses to operate as a Pharmacy and any pharmacist that is accessing and using Brightscrip™ has all appropriate
      licenses to operate as a Pharmacist; (ii) all information that is inputted by Pharmacy and its Users into Brightscrip™ is true and
      correct; and (iii) Pharmacy and its Users are operating in compliance with all federal and state laws and regulations, and all rules,
      regulations, and policies of the Pharmaceutical Company that gave Pharmacy the permission to access and use Brightscrip™, with regard
      to any activities under this Agreement.
    </p>

    <h2>Warranty Disclaimers</h2>

    <p class="ua__indented">
      ALL INFORMATION REGARDING THE FILES IS BASED ON INFORMATION PROVIDED TO MONARCH BY THE THIRD-PARTY PHARMACEUTICAL COMPANY THAT HAS
      GIVEN PHARMACY PERMISSION TO PARTICIPATE IN BRIGHTSCRIP™. BRIGHTSCRIP™ IS NOT INTENDED AS A COMMUNICATION TOOL OTHER THAN FOR THE
      TRANSMISSION OF FILES, AND MONARCH CANNOT GUARANTY THAT THE INFORMATION IS ACCURATE AND MAKES NO REPRESENTATIONS OR WARRANTIES
      REGARDING THE ACCURACY, FUNCTIONALITY OR PERFORMANCE OF SUCH FILES THAT MAY BE ACCESSED BY PHARMACY AND/OR ITS USERS IN CONNECTION
      WITH BRIGHTSCRIP™ AND IS NOT RESPONSIBLE FOR ANY ERRORS OR OMISSIONS.
    </p>

    <p class="ua__indented">
      BRIGHTSCRIP™ DOES NOT PURPORT TO ENGAGE DIRECTLY OR INDIRECTLY IN THE PRACTICE OF MEDICINE OR PHARMACY, OR DISPENSE ANY MEDICAL,
      PHARMACY OR PHARMACEUTICAL ADVICE WITH ANY BRIGHTSCRIP™ MATERIALS THAT IT PROVIDES. PHARMACY AND EACH OF ITS USERS EXPRESSLY
      ACKNOWLEDGE THAT BRIGHTSCRIP™, OR ANY PART THEREOF, IS BEING PROVIDED BY MONARCH SIMPLY AS A TOOL FOR COMMUNICATION AND IT IS NOT
      BEING PROVIDED BY MONARCH TO PHARMACY OR ITS USERS FOR MEDICAL, PHARMACY OR PHARMACEUTICAL ADVICE OR RECOMMENDATIONS. IN THIS RESPECT,
      MONARCH DISCLAIMS ANY RESPONSIBILITY WHATSOEVER FOR ANY MEDICAL, PHARMACY OR PHARMACEUTICAL ADVICE OR RECOMMENDATIONS THAT MAY BE
      PROVIDED BY PHARMACY OR ITS USERS THROUGH USE OF BRIGHTSCRIP™ BY PHARMACY OR ITS USERS.
    </p>

    <p class="ua__indented">
      PHARMACY AND EACH OF ITS USERS EXPRESSLY AGREE THAT ACCESS TO AND USE OF BRIGHTSCRIP™ IS AT ITS AND THEIR SOLE RISK. BRIGHTSCRIP™ IS
      PROVIDED ON AN “AS IS” AND AN “AS AVAILABLE” BASIS. MONARCH DOES NOT MAKE, AND HEREBY DISCLAIMS, ANY REPRESENTATIONS OR WARRANTIES,
      EXPRESS, IMPLIED, OR STATUTORY, REGARDING BRIGHTSCRIP™, INCLUDING (WITHOUT LIMITATION) IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
      FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, AND ANY WARRANTIES ARISING BY COURSE OF DEALING OR CUSTOM OF
      TRADE. MONARCH MAKES NO REPRESENTATION OR WARRANTY THAT ANY BRIGHTSCRIP™ MATERIAL DISPLAYED ON OR OFFERED THROUGH BRIGHTSCRIP™ IS
      ACCURATE, COMPLETE, APPROPRIATE, RELIABLE, OR TIMELY.
    </p>

    <p class="ua__indented">
      MONARCH also makeS no representations or warranties that BRIGHTSCRIP™ will meet THE REQUIREMENTS OF PHARMACY OR ITS USERS, or that
      access to and use of BRIGHTSCRIP™ will be uninterrupted or error-free, free of viruses, malicious code, or other harmful components,
      or otherwise will be secure. BRIGHTSCRIP™ IS INTEnDED TO PROVIDE A SECURE MEANS OF TRANSMITTING FILES, AND WHILE MONARCH HAS
      CONDUCTED USER TESTING OF BRIGHTSCRIP™, NO ABSOLUTE ASSURANCE OF ITS ACCURACY, RELIABILITY, QUALITY, CURRENCY, TIMELINESS,
      AVAILABILITY, CORRECTNESS OR COMPLETENESS OF INFORMATION CAN BE MADE. ACCORDINGLY, PHARMACY AND EACH OF ITS USERS ACKNOWLEDGE AND
      ASSUME ALL RISK OF ANY USE OF BRIGHTSCRIP™. MONARCH MAKES NO WARRANTY, EXPRESS OR IMPLIED, REGARDING THE OPERATIONAL PERFORMANCE OR
      USE BY PHARMACY OR YOU OF BRIGHTSCRIP™.
    </p>

    <p class="ua__indented">
      Some jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above exclusions may not apply to you.
    </p>

    <p class="ua__indented">
      PHARMACY AND ITS USERS ALSO ACKNOWLEDGE AND AGREE THAT BRIGHTSCRIP™, NOR ANY PART THEREOF, IS NOT FAULT TOLERANT AND IS NOT DESIGNED
      OR INTENDED FOR USE OR LICENSING AS ON-LINE CONTROL EQUIPMENT IN HAZARDOUS OR “LIFE AND DEATH” ENVIRONMENTS REQUIRING FAIL-SAFE
      PERFORMANCE, SUCH AS IN THE OPERATION OF NUCLEAR FACILITIES, AIRCRAFT NAVIGATION OR COMMUNICATIONS, AIR TRAFFIC CONTROL, DIRECT LIFE
      SUPPORT MACHINES OR WEAPONS SYSTEMS, IN WHICH THE FAILURE OF IT, OR ANY PART THEREOF, COULD LEAD DIRECTLY TO DEATH, PERSONAL INJURY OR
      SEVERE PHYSICAL OR ENVIRONMENTAL DAMAGE, I.E. <strong>“CRITICAL CONTROL APPLICATIONS”</strong>. PHARMACY SHALL BE SOLELY RESPONSIBLE
      FOR, AND SHALL INDEMNIFY AND HOLD MONARCH HARMLESS FOR ANY USE OR INCORPORATION OF BRIGHTSCRIP™, OR ANY PART THEREOF, INTO ITS
      PRODUCTS PERFORMING ANY CRITICAL CONTROL APPLICATIONS.
    </p>

    <h2>Limitation on Liability</h2>

    <ol type="A">
      <li>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND NOTWITHSTANDING ANY OTHER PROVISION OF THIS AGREEMENT, IN NO EVENT SHALL
        MONARCH OR ANY OF ITS AFFILIATES, PARTNERS, OFFICERS, EMPLOYEES, AGENTS, CONTRACTORS, SUCCESSORS, OR ASSIGNEES BE LIABLE TO PHARMACY
        OR ANY OF ITS USERS (INCLUDING ANY OF ITS PARTNERS, OFFICERS, EMPLOYEES, AGENTS, CONTRACTORS, SUCCESSORS, OR ASSIGNEES) FOR ANY
        INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, WHETHER ARISING IN CONTRACT, EQUITY, TORT, OR
        OTHERWISE (INCLUDING BREACH OF WARRANTY, NEGLIGENCE, AND STRICT LIABILITY IN TORT), INCLUDING, WITHOUT LIMITATION, DAMAGES ARISING
        FROM DELAY, LOSS OF GOODWILL, LOSS OF OR DAMAGE TO DATA, INTERRUPTION IN USE OR AVAILABILITY OF DATA, LOSS OF USE OF MONEY OR USE OF
        PRODUCTS, LOST PROFITS, REVENUE OR SAVINGS (ACTUAL OR ANTICIPATED), LIABILITY OR LOSSES FROM THE DENIAL OF COUPONS OR THE LACK OF
        TRANSMITTAL OF FILES, OR OTHER ECONOMIC LOSS ENSUING FROM OR IN CONNECTION WITH BRIGHTSCRIP™, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING THE FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED
        REMEDY.
      </li>

      <li>
        IF, DESPITE THE FOREGOING LIMITATION OF LIABILITY FOR DAMAGES, A COURT OR OTHER TRIBUNAL OF COMPETENT JURISDICTION DECIDES TO AWARD
        MONETARY DAMAGES TO PHARMACY AND/OR ANY OF ITS USERS FOR ANY CLAIM OR CAUSE OF ACTION ARISING FROM THE SAME, THE AMOUNT OF MONETARY
        DAMAGES FOR SUCH CLAIM OR CAUSE OF ACTION, IN THE AGGREGATE, SHALL BE NO GREATER THAN ONE HUNDRED DOLLARS ($100.00).
      </li>

      <li>
        NOTWITHSTANDING THE FOREGOING, THE FOREGOING SHALL NOT APPLY TO A BREACH OR A SECURITY INCIDENT WITH REGARD TO PROTECTED HEALTH
        INFORMATION AS DESCRIBED UNDER <strong>SECTION 8</strong>; HOWEVER, IF A COURT OR OTHER TRIBUNAL OF COMPETENT JURISDICTION DECIDES
        TO AWARD MONETARY DAMAGES TO PHARMACY AND/OR ANY OF ITS USERS FOR ANY CLAIM OR CAUSE OF ACTION ARISING FROM A BREACH OR A SECURITY
        INCIDENT WITH REGARD TO PERSONAL HEALTH DATA AS DESCRIBED UNDER <strong>SECTION 8</strong>, THE AMOUNT OF MONETARY DAMAGES FOR SUCH
        CLAIM OR CAUSE OF ACTION, IN THE AGGREGATE, SHALL BE NO GREATER THAN TEN THOUSAND DOLLARS ($10,000.00).
      </li>

      <li>
        THE PARTIES ACKNOWLEDGE THAT THE LIMITATIONS IN THIS AGREEMENT REFLECT A RISK ALLOCATION BETWEEN THE PARTIES TAKING INTO ACCOUNT,
        INTER ALIA, THEIR RESPECTIVE ABILITIES TO MANAGE RISK AND THE FACT THAT NO FEES ARE PAID BY PHARMACY OR ANY OF ITS USERS FOR
        BRIGHTSCRIP™. FURTHER, PHARMACY AND ITS USERS ACKNOWLEDGE AND AGREE THAT MONARCH IS SOLELY ENGAGING IN THE MANAGEMENT AND
        CONFIRMATION OF ELIGIBLE COUPON DEPLOYMENT AT NO COST TO PHARMACY OR ITS USERS AS A CONVENIENCE FOR PHARMACY AND ITS USERS AND IN SO
        DOING, IS RELYING UPON THE SUFFICIENCY OF THE REQUEST BY PHARMACY AND/OR ITS USERS AND THE RULES THAT HAVE BEEN PUT IN PLACE BY THE
        PHARMACEUTICAL MANUFACTURER CUSTOMER. IN THIS RESPECT, PHARMACY AND ITS USERS ACKNOWLEDGE AND AGREE THAT MONARCH SHALL HAVE NO
        LIABILITY WHATSOEVER FOR ANY CLAIMS MADE BY PHARMACY OR ANY OF ITS USERS OR ANY DAMAGES INCURRED BY PHARMACY OR ANY OF ITS USERS
        (PERCEIVED OR OTHERWISE) FOR DENYING ANY COUPONS SUBMITTED BY PHARMACY OR ANY OF ITS USERS OR THE TIMELINESS OF ANY ACCEPTANCE OR
        REJECTION OF ANY OF THE COUPONS SUBMITTED BY PHARMACY OR ANY OF ITS USERS. IN THE EVENT THAT ANY EXCLUSION OR LIMITATION SHALL BE
        UNENFORCEABLE UNDER THE LAWS OF THE JURISDICTION APPLICABLE TO PHARMACY OR ITS USERS, THE PARTIES AGREE THAT THIS PROVISION SHALL BE
        DEEMED AMENDED TO SUCH EXTENT ONLY AS TO MAKE IT ENFORCEABLE.
      </li>
    </ol>

    <h2>Indemnity</h2>

    <p class="ua__indented">
      PHARMACY AND ITS USERS UNDERSTAND AND AGREE THAT IT AND ITS USERS ARE PERSONALLY RESPONSIBLE FOR ANY BEHAVIOR BY PHARMACY OR ANY USERS
      IN CONNECTION WITH BRIGHTSCRIP™ AND USE OF BRIGHTSCRIPT™. PHARMACY AGREES TO INDEMNIFY, DEFEND, AND HOLD MONARCH HARMLESS, OUR
      PARENTS, SUBSIDIARIES, AND AFFILIATES, AND OUR AND THEIR RESPECTIVE DIRECTORS, OFFICERS, PARTNERS, EMPLOYEES, AGENTS, CONTRACTORS,
      SUCCESSORS, AND ASSIGNS FROM AND AGAINST ALL CLAIMS, DEMANDS, LAWSUITS, DAMAGES, LIABILITIES, LOSSES, COSTS, OR EXPENSES (INCLUDING,
      BUT NOT LIMITED TO, REASONABLE FEES AND DISBURSEMENTS OF COUNSEL AND COURT COSTS), JUDGMENTS, SETTLEMENTS, AND PENALTIES OF EVERY KIND
      ARISING FROM OR RELATING TO ANY VIOLATION OF THIS AGREEMENT AND ANY ACTIVITY RELATED TO USE OR MISUSE OF BRIGHTSCRIP™ BY PHARMACY OR
      ANY OF ITS USERS, INCLUDING ANY CLAIMS MADE BY A USER THAT HE/SHE DID NOT GIVE BRIGHTSCRIP™ CONSENT TO CONTACT HIM/HER VIA EMAIL AT
      AN EMAIL ADDRESS FOR COMMUNICATIONS THROUGH BRIGHTSCRIP™ WHEN HE/SHE PROVIDED BRIGHTSCRIPT WITH SUCH EMAIL ADDRESS.
    </p>

    <h2>Modifications and Updates</h2>

    <p class="ua__indented">
      At any time and at Monarch’s sole discretion, we may add, delete, or modify the Agreement or Brightscrip™ or any functionality
      provided through Brightscrip™ without liability to Pharmacy or any of its Users. If we materially modify these terms, we will make
      reasonable efforts to notify Pharmacy and its Users of the change. For example, we may send a message to an email address, if we have
      one on file, or generate a pop-up or similar notification when Pharmacy or a User accesses Brightscrip™ for the first time after such
      material changes are made. No modifications to this Agreement will apply to any dispute between Pharmacy and its Users and us that
      arose prior to the date of such modification. Use of Brightscrip™ after modifications to this Agreement become effective constitutes
      binding acceptance of such changes. If Pharmacy or its Users are dissatisfied with the terms of this Agreement, the sole and exclusive
      remedy is to discontinue any use of Brightscrip™.
    </p>

    <p class="ua__indented">
      We may also from time to time provide enhancements or improvements to the features and/or functionality of Brightscrip™, which may
      include patches, bug fixes, updates, upgrades, and other modifications (<strong>“Updates”</strong>). All Updates are subject to the
      terms and conditions of this Agreement, including the Agreement. Updates may modify or delete certain features and/or functionalities
      of Brightscrip™. Pharmacy and its Users acknowledge and agree that we have no obligation to (i) provide any Updates; or (ii) continue
      to provide or enable any particular features and/or functionalities, except as expressly provided in the Agreement.
    </p>

    <h2>International Use</h2>

    <p class="ua__indented">
      Brightscrip™ is hosted from the United States, and our corporate office and activities are located in and directed from the United
      States. Accordingly, if Pharmacy and/or its Users access Brightscrip™ from outside the United States, information may be transferred
      to and maintained on computers and servers located outside of their state, province, country, or other governmental jurisdiction where
      the privacy laws may not be as protective as those in their jurisdiction. Consent to the Agreement followed by submission of such
      information represents agreement to the transfer of such information to the United States and the collection, use, and disclosure of
      information in accordance with United States law and Monarch’s Privacy Policy.
    </p>

    <p class="ua__indented">
      We make no representation that Brightscrip™ or any aspect thereof will be available or appropriate for use in locations outside of
      the United States. Those who choose to access Brightscrip™ from locations outside the United States do so at their own risk and are
      responsible for compliance with local laws and regulations. Users are responsible for compliance with all United States export laws
      and regulations when exporting any products, services, content, or materials provided through Brightscrip™.
    </p>

    <h2>Dispute Resolution and Binding Arbitration</h2>

    <ol type="A">
      <li>
        <strong>Initial Dispute Resolution Process.</strong> Monarch intends to resolve any and all disputes that may arise between us and
        Pharmacy and/or its Users in a cost-effective and non-disruptive manner, preferably without the time and expense of litigation.
        Toward this end, Pharmacy and its Users agree to the following dispute resolution procedure. If Pharmacy and/or its Users are unable
        to resolve any dispute in the ordinary course of business, Pharmacy and/or its Users shall send a written notice to us in which you
        outline the issues in dispute, enclose any relevant documents, and state the requested relief. We shall respond within ten (10)
        business days with identical information from its perspective. Pharmacy and/or its Users and one of our representatives shall meet
        or communicate electronically within ten (10) business days of the delivery of the response, and as often as the parties mutually
        deem necessary or desirable thereafter, in an attempt to resolve the matter. If, within sixty (60) days of the first communication,
        the parties fail to resolve the matter, either party may then proceed to arbitration as described in
        <strong>Section 15B</strong> below.
      </li>

      <li>
        <strong>Arbitration.</strong> After failing to mutually settle any disputes as required in <strong>Section 15A</strong>, and except
        for equitable claims, small claims court, and claims of defamation, violation of the Computer Fraud and Abuse Act, and infringement
        or misappropriation of the other party’s patent, copyright, trademark, or trade secret, any controversy or claim arising out of or
        relating to this Agreement shall be submitted to and settled through binding arbitration.
      </li>

      <ol type="a">
        <li>
          The arbitration will be conducted in accordance with this Agreement, the Federal Arbitration Act and the then-current Commercial
          Arbitration Rules of the American Arbitration Association (“<strong>AAA Rules</strong>”). In the event of a conflict, the
          provisions of the AAA Rules will control, except where those Rules conflict with this Agreement, in which case this Agreement will
          control.
        </li>

        <li>
          The arbitration shall be conducted before a single arbitrator selected as provided in the AAA Rules and who shall have at least
          five (5) years of relevant experience. The arbitration shall be commenced and held in the State of New York. No potential
          arbitrator may be appointed unless he or she has agreed in writing to be bound by these procedures.
        </li>

        <li>
          To the extent state law is applicable, the arbitrator shall apply the substantive law of the State of New York. The U.N.
          Convention on Contracts for the International Sale of Goods shall not apply. Any issue concerning the location of the arbitration,
          the extent to which any dispute is subject to arbitration, the applicability, interpretation, or enforceability of these
          procedures, including any contention that all or part of these procedures are invalid or unenforceable, and any discovery
          disputes, shall be resolved by the arbitrator.
        </li>

        <li>
          Each party will be entitled to reasonable discovery in preparation for the arbitration, including the production of relevant
          documents and the deposition of witnesses. Any disputes involving discovery shall be heard and resolved by the arbitrator. All
          objections are reserved for the arbitration hearing, except for objections based on privilege and proprietary or confidential
          information.
        </li>

        <li>
          All aspects of the arbitration shall be treated as confidential and neither the parties nor the arbitrator may disclose the
          existence, content or results of the arbitration, except as necessary to comply with legal or regulatory requirements. Before
          making any such disclosure, a party shall give written notice to all other parties and shall afford such parties a reasonable
          opportunity to protect their interests.
        </li>

        <li>
          The decision of the arbitrator shall be reduced to writing, shall be final and binding on the parties except for fraud,
          misconduct, or errors of law, and judgment upon the decision rendered may be entered in any court having jurisdiction thereof. The
          arbitrator may not, in any event, make any ruling, finding or award that does not conform to the terms and conditions of this
          Agreement.
        </li>

        <li>
          The arbitrator shall award all costs of the arbitration, including arbitrator’s fees, arbitration filing fees, travel costs of
          witnesses, costs of depositions and reasonable attorney fees to the substantially prevailing party; provided, however, that if
          Pharmacy is the substantially prevailing party, the Pharmacy shall bear the costs of its own attorney fees.
        </li>
      </ol>

      <li>
        <strong>Class Action Waiver.</strong> Monarch and Pharmacy and its Users each agree that any proceedings to resolve or litigate any
        dispute, whether through a court of law or arbitration, shall be solely conducted on an individual basis. Pharmacy and its Users
        agree not to seek to have any dispute heard as a class action, representative action, collective action, private attorney general
        action, or similar action.
      </li>

      <li>
        <strong>Wavier of Jury Trial.</strong> THE PARTIES UNDERSTAND THAT BY ENTERING INTO THIS AGREEMENT, BOTH PARTIES ARE GIVING UP THEIR
        CONSTITUTIONAL RIGHT TO A JURY TRIAL AND, IF APPLICABLE, ANY RIGHTS THEY MAY HAVE TO BRING ANY CLAIMS ON A CLASS, REPRESENTATIVE,
        CONSOLIDATED OR MASS ACTION BASIS.
      </li>

      <li>
        <strong>Injunctive Relief.</strong> Nothing herein shall prevent either party from seeking immediate injunctive relief pursuant to
        the Emergency Measures of Protection procedures in the AAA Commercial Rules if, in its judgment, such relief is necessary to protect
        its interests prior to utilizing or completing this dispute resolution process.  Such remedy shall be in addition to all other
        remedies, including money damages, available to such party at law or in equity. Pharmacy and its Users also agree that a violation
        of this Agreement will constitute an unlawful and unfair business practice that will cause irreparable harm to Monarch for which
        monetary damages would be inadequate. In this respect, Pharmacy and its Users consent to our obtaining any injunctive or equitable
        relief that we deem necessary or appropriate in such circumstances, without the need for a bond. These remedies are in addition to
        any other remedies we may have at law or in equity.
      </li>

      <li>
        <strong>Limitations.</strong> No action arising out of this Agreement or your access to or use of Brightscrip™, regardless of form
        or the basis of the claim, may be brought by you more than one (1) year after the cause of action has arisen (or if multiple causes,
        from the date the first such cause arose) and Pharmacy and its Users hereby waive any longer statute of limitations that may be
        permitted by law.
      </li>
    </ol>

    <h2>Miscellaneous</h2>

    <p class="ua__indented">
      <strong>Applicable Law and Venue.</strong> Any action related to the Agreement will be governed by the laws of the State of New York,
      without regard to the choice or conflicts of law provisions of any jurisdiction. Monarch and Pharmacy and its Users each agree and
      consent that jurisdiction, proper venue, and the most convenient forums for all claims, actions, and proceedings of any kind relating
      to Brightscrip™ or the Agreement that are not submitted to arbitration will be exclusively in the federal or local courts with
      jurisdiction over Illinois. If Monarch does take any legal action against you as a result of your violation of the Agreement, we will
      be entitled to recover from you, and Pharmacy and its Users agree to pay, all reasonable attorneys’ fees and costs of such action, in
      addition to any other relief granted to us. Pharmacy and its Users agree that Monarch will not be liable to Pharmacy or its Users or
      to any third party for termination of access to, or use of, Brightscrip™ as a result of any violation of the Agreement or for any
      reason at all.
    </p>

    <p class="ua__indented">
      <strong>Entire Agreement.</strong> This Agreement (including the various additional terms incorporated herein by reference)
      constitutes the entire agreement between Monarch and Pharmacy and its Users, and supersedes any and all prior agreements,
      negotiations, or other communications between the parties, whether oral or written, with respect to the subject matter hereof, and,
      except as expressly provided herein, cannot be modified except in writing signed by Monarch and Pharmacy.
    </p>

    <p class="ua__indented">
      <strong>Entire Agreement.</strong> This Agreement (including the various additional terms incorporated herein by reference)
      constitutes the entire agreement between Monarch and Pharmacy and its Users, and supersedes any and all prior agreements,
      negotiations, or other communications between the parties, whether oral or written, with respect to the subject matter hereof, and,
      except as expressly provided herein, cannot be modified except in writing signed by Monarch and Pharmacy.
    </p>

    <p class="ua__indented">
      <strong>Waiver.</strong> No failure or delay in enforcing any provision, exercising any option, or requiring performance, shall be
      construed to be a waiver of that or any other right in connection with the Agreement.
    </p>

    <p class="ua__indented">
      <strong>Assignment.</strong> Pharmacy and its Users may not assign, by operation of law or otherwise, any rights or delegate any
      duties under the Agreement to any third party, including in connection with a merger or change of control, without prior written
      consent by us. Any purported assignment lacking such consent will be void at its inception. Monarch may assign all or part of its
      rights and/or delegate all or part of its duties under the Agreement to any party, at any time, and in its sole discretion, upon
      notice of assignment by posting such notice on Brightscrip™.
    </p>

    <p class="ua__indented">
      <strong>Enforcement.</strong> Pharmacy and its Users acknowledge and agree that Monarch shall have the right to enforce any of the
      provisions of this Agreement against Pharmacy and against any individual User that has acted in violation of the terms of this
      Agreement. However, at the same time, Pharmacy shall be responsible to Monarch for any of the acts and omissions of its Users in
      violation of the terms of this Agreement.
    </p>
  </div>
</div>
