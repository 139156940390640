import { Component, Input, OnDestroy, Output, EventEmitter, OnChanges } from '@angular/core';
import { IProgramGroup } from '@shared/models/program-group.model';
import { Program } from '@shared/models/program.model';
import { IPharmacy } from '@shared/models/pharmacy.model';
import Utils from '@shared/providers/utils';
import { Subject } from 'rxjs';
import { ProgramUtilityService } from '@services/program-utility.service';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'app-program-selector',
  templateUrl: './program-selector.component.html',
  styleUrls: ['./program-selector.component.scss'],
})
export class ProgramSelectorComponent implements OnDestroy, OnChanges {
  @Input() pharmacy: IPharmacy;
  @Input() showSelectProgramButtonAfterSelection = true;
  @Input() disableSelect = false;

  @Output() programSelected = new EventEmitter<Program>();

  programGroup: IProgramGroup;

  enrolledPrograms: Program[];
  selectedProgram: Program;
  hasSelectedProgramBeenEmitted = false;

  private unsubscribe = new Subject<void>();

  constructor(private programUtilityService: ProgramUtilityService) {}

  ngOnChanges(changes: NgChanges<this>) {
    if (changes && changes.pharmacy) {
      this.setEnrolledProgramsFromPharmacy();
      this.selectProgramWhenOnlyOneEnrolledProgram();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setEnrolledProgramsFromPharmacy(): void {
    this.enrolledPrograms = this.programUtilityService.getEnrolledProgramsFromPharmacy(this.pharmacy);
  }

  private selectProgramWhenOnlyOneEnrolledProgram(): void {
    if (this.enrolledPrograms && this.enrolledPrograms.length === 1) {
      this.selectedProgram = this.enrolledPrograms[0];
      this.onProgramSelected();
    }
  }

  getProgramsProductsFormatted(program: Program): string {
    if (program && Utils.isNonEmptyArray(program.products)) {
      return program.products.map(product => product.name).join(', ');
    }

    return '';
  }

  onSelectedProgramChange(newProgram: Program): void {
    this.selectedProgram = newProgram;
  }

  onProgramSelected(): void {
    this.programSelected.next(this.selectedProgram);
    this.hasSelectedProgramBeenEmitted = true;
  }
}
