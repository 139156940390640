<div class="card" [ngClass]="cardClass()" [ngStyle]="cardStyle()">
  <div class="card-body" [ngClass]="bodyClass()" [ngStyle]="bodyStyle()">
    <h5 class="card-title" (click)="opened.set(!opened())">
      <span>{{ title() }}</span>
      <i class="fas" [ngClass]="opened() ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
    </h5>
    <div [@openClose]="opened()" class="overflow-hidden" [ngStyle]="{ 'margin-top': opened() ? '12px' : '' }">
      <ng-content></ng-content>
    </div>
  </div>
</div>
