import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { IconWithText } from '@shared/components/icon-with-text/icon-with-text.model';
import { Router } from '@angular/router';
import { NgChanges } from '@shared/ng-changes';

type LabelModifier = 'small';

@Component({
  selector: 'app-icon-with-text',
  templateUrl: './icon-with-text.component.html',
  styleUrls: ['./icon-with-text.component.scss'],
})
export class IconWithTextComponent implements OnInit, OnChanges {
  // Component is disabled when not active
  @Input() isActive = true;
  @Input() addObject: IconWithText;
  @Input() labelModifier?: LabelModifier;

  imageAddress: string;

  constructor(private router: Router) {}

  ngOnInit() {
    this.setImageAddress();
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.addObject || changes.isActive) {
      this.setImageAddress();
    }
  }

  setImageAddress(): void {
    this.imageAddress = this.getImageSrc();
  }

  getImageSrc(): string {
    const baseUrl = 'assets/icons/';
    const addImageBase = 'add-';
    const removeImageAddress = 'remove-icon.svg';

    if (this.addObject.iconType === 'add' && this.isActive) {
      return baseUrl + addImageBase + 'active.png';
    } else if (this.addObject.iconType === 'add') {
      return baseUrl + addImageBase + 'inactive.png';
    }
    return baseUrl + removeImageAddress;
  }

  handleClick(): void {
    if (this.addObject && this.addObject.objectUrl) {
      this.router.navigate([this.addObject.objectUrl]);
    }
  }

  isIconLocationOnLeft(): boolean {
    return this.addObject && this.addObject.iconLocation === 'left';
  }

  isIconLocationOnRight(): boolean {
    return this.addObject && this.addObject.iconLocation === 'right';
  }
}
