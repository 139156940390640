import { Injectable } from '@angular/core';
import { Links } from '@shared/models/constants';

export interface MessageWithLinks {
  message: string;
  hrefText?: string;
  href?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  private readonly LinkPattern = /(\[.+\])(\(.+\))/;

  convertMessageWithLinks(message: string): MessageWithLinks {
    if (this.doesMessageHaveLink(message)) {
      let [linkTextAndLink, hrefText, hrefLookupKey] = this.LinkPattern.exec(message)!;

      const hrefTextOpeningDelimeter = '[';
      const hrefTextClosingDelimeter = ']';
      hrefText = hrefText.replace(hrefTextOpeningDelimeter, '');
      hrefText = hrefText.replace(hrefTextClosingDelimeter, '');

      const hrefLookupKeyOpeningDelimeter = '(';
      const hrefLookupKeyClosingDelimeter = ')';
      hrefLookupKey = hrefLookupKey.replace(hrefLookupKeyOpeningDelimeter, '');
      hrefLookupKey = hrefLookupKey.replace(hrefLookupKeyClosingDelimeter, '');

      linkTextAndLink = linkTextAndLink ?? '';
      message = message.replace(linkTextAndLink, '');

      return {
        message: message,
        hrefText: hrefText,
        href: Links[hrefLookupKey],
      };
    } else {
      return { message: message };
    }
  }

  doesMessageHaveLink(message: string): boolean {
    return !!message && this.LinkPattern.test(message);
  }
}
