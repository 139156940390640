import { Injectable } from '@angular/core';
import Utils from '@shared/providers/utils';
import {
  DataSourceType,
  DataTransformationReturn,
  DataTransformationStrategy,
} from '@shared/providers/data-transformation/data-transformation.model';

@Injectable()
export class StringArrayToCommaSeparatedStringStrategy implements DataTransformationStrategy {
  transform(parameters?: Record<DataSourceType, any>): DataTransformationReturn {
    let value;
    if (parameters && Object.keys(parameters).length > 0) {
      const parameterKey = Object.keys(parameters)[0] as DataSourceType;
      const data = parameters[parameterKey];

      if (Utils.isNonEmptyArray(data) && typeof data[0] === 'string') {
        value = data.join(', ');
      }
    }
    return {
      value: value,
    };
  }
}
