import { Injectable, inject } from '@angular/core';
import { ClaimFlagSettingsSchema } from '@libs/shared/domain/claim/flag/claim-flag-settings.schema';
import { LogService } from '@services/log.service';
import { PatientAssistanceRequestViewModel } from '@services/models/patient-assistance-request-view.model';
import { SettingsStore } from '@services/settings.store';
import { FlagDiplayCategory, FlagsMap } from '@shared/components/claim-flags/claim-flags-types';
import { cloneDeep, concat, isString, keyBy, maxBy, orderBy } from 'lodash';
import moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClaimFlagsService {
  private readonly settingsStore = inject(SettingsStore);
  private readonly logService = inject(LogService);

  public getFlagsSettings(tenantId: string) {
    return this.settingsStore.getSettings<ClaimFlagSettingsSchema[]>(`flags-${tenantId}`).pipe(
      map(data => {
        const items = data || [];
        return orderBy(items, ['title']);
      }),
    );
  }

  public getFlagsIdenity(map: FlagsMap) {
    let identity = 'Identified';
    const flags = concat(...Object.values(map));

    for (const flag of flags) {
      if (flag.actions.reject) {
        identity = 'Blocked';
        break;
      } else if (flag.actions.brightlink) {
        identity = 'brightLINK';
        break;
      }
    }

    return identity;
  }

  public getFlagsMap(claim?: PatientAssistanceRequestViewModel, flags?: ClaimFlagSettingsSchema[]): FlagsMap {
    const flagsMap: FlagsMap = { 'Vulnerability Detection': [], 'Demand Enablement': [], 'Demand Conversion': [] };

    const flagsSettings = keyBy(flags ?? [], 'name');
    claim?.flagsList?.forEach(key => {
      const flag = cloneDeep(flagsSettings[key]);
      if (flag) {
        flag.description = this.interpolateFlagDescription(claim, flag.name, flag.description);
        flag.amount_description = this.interpolateFlagDescription(claim, flag.name, flag.amount_description);
        if (flag.category in flagsMap) {
          flagsMap[flag.category as FlagDiplayCategory].push(flag);
        } else {
          this.logService.message(`Unknown category: ${flag.category} for flag: ${key}`);
        }
      } else {
        this.logService.message(`Unknown flag: ${key}`);
      }
    });

    return flagsMap;
  }

  public getFlagsValueDescription(claim: PatientAssistanceRequestViewModel, flagsMap: FlagsMap) {
    const flags = Object.values(flagsMap).flat();
    const highest = maxBy(Object.entries(claim.flagsAmounts), ([, value]) => value);
    const flag = highest ? flags.find(flag => flag.name === highest[0]) : undefined;
    return flag?.amount_description ?? '';
  }

  private interpolateFlagDescription(claim: PatientAssistanceRequestViewModel, name: string, description?: string): string | undefined {
    if (!description) return undefined;
    const interpolationMap: Record<string, string> = {
      pharmacyOrFamilyName: claim.pharmacyName ?? `NCPDP ${claim?.pharmacyNcpdp} (unknown pharmacy/pharmacy family)`,
    };
    const prescriberNpi = claim.flagsContexts?.flag_prescriber_new?.claimPrescriberNpi;
    if (prescriberNpi) {
      const prescriberValue = claim.prescriberName ? `${claim.prescriberName} (${prescriberNpi})` : `${prescriberNpi} (Unknown Prescriber)`;
      description = description.replaceAll(`{claimPrescriberNpi}`, prescriberValue);
    }
    for (const interpolationKey of Object.keys(interpolationMap)) {
      description = description.replaceAll(`{${interpolationKey}}`, interpolationMap[interpolationKey]);
    }
    for (const key of Object.keys(claim.flagsContexts[name] ?? {})) {
      const value = claim.flagsContexts[name][key];
      description = description.replaceAll(`{${key}}`, `${value}`);
      description = description.replaceAll(`{${key}|date}`, this.formatDate(value));
    }
    for (const [field, value] of Object.entries(claim)) {
      description = description.replaceAll(`{${field}}`, value);
      description = description.replaceAll(`{${field}|date}`, this.formatDate(value));
    }
    return description;
  }

  private formatDate(value: unknown) {
    if (isString(value) && moment(value, true).isValid()) {
      return moment(value).format('MM/DD/YYYY hh:mm A');
    }
    return `${value}`;
  }
}
