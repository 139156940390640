import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DataViewConfigService } from '@services/dataview-config.service';
import { BaseComponent } from '@shared/components/base-component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { forkJoin } from 'rxjs';
import { UserService } from '@services/user.service';
import { Column } from '@shared/components/data-view/data-view-types';

@Component({
  selector: 'app-data-view-edit-columns',
  templateUrl: './data-view-edit-columns.component.html',
  styleUrls: ['./data-view-edit-columns.component.scss'],
})
export class DataViewEditColumnsComponent extends BaseComponent implements AfterViewInit {
  @Input() pageName: string;
  @Input() appliedColumns: Column.AppliedDataViewColumn[] = [];
  private _favouriteColumns: Column.DataViewColumnGalleryColumn[];
  @Input()
  set favouriteColumns(favouriteColumns: Column.DataViewColumnGalleryColumn[]) {
    for (const availColumn of Object.values(this.availableColumns).flatMap(c => c)) {
      availColumn.favourite = favouriteColumns.findIndex(favouriteColumn => favouriteColumn.id === availColumn.id) !== -1;
    }
    for (const appliedColumn of this.appliedColumns) {
      appliedColumn.favourite = favouriteColumns.findIndex(favouriteColumn => favouriteColumn.id === appliedColumn.id) !== -1;
    }
    this._favouriteColumns = favouriteColumns.map(favouriteColumn => ({ ...favouriteColumn, favourite: true }));
  }
  get favouriteColumns() {
    return this._favouriteColumns;
  }
  @Output() triggerColumnsUpdate = new EventEmitter<void>();
  @Output() triggerColumnAdded = new EventEmitter<void>();
  columnCategories: any[] = [];
  availableColumns: Record<string, Column.DataViewColumnGalleryColumn[]> = {};
  searchQuery: string | null;
  availableColumnsListIds: string[];

  constructor(
    protected activeOffcanvas: NgbActiveOffcanvas,
    private dataViewConfigService: DataViewConfigService,
    private userService: UserService,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.subscribe(
      forkJoin({
        columnCategories: this.dataViewConfigService.getColumnCategories(this.pageName, this.userService.getCurrentUser()),
        columns: this.dataViewConfigService.getAvailableColumns(this.pageName, this.userService.getCurrentUser()),
      }),
      result => {
        this.columnCategories = result.columnCategories;
        const columns = result.columns;
        for (const column of columns) {
          if (!column.categoryId) {
            return;
          }
          if (!this.availableColumns[column.categoryId]) this.availableColumns[column.categoryId] = [];
          if (this.appliedColumns.findIndex(ac => ac.id === column.id) !== -1) {
            (column as Column.DataViewColumnGalleryColumn).isAdded = true;
          }
          if (this.favouriteColumns.findIndex(favouriteColumn => favouriteColumn.id === column.id) !== -1) {
            (column as Column.DataViewColumnGalleryColumn).favourite = true;
          }
          this.availableColumns[column.categoryId].push(column);
        }

        this.availableColumnsListIds = ['favourites-list', ...this.columnCategories.map(cc => 'categories-list-' + cc.id)];
      },
    );
  }

  onSubmit(appliedColumns: Column.DataViewColumn[]) {
    this.activeOffcanvas.dismiss({
      ...appliedColumns,
    });
  }

  columnAdded(column: Column.DataViewColumnGalleryColumn, atIndex?: number) {
    column.isAdded = true;
    (column as Column.AppliedDataViewColumn).width = 'auto'; // Need to pass a reference instead of a copy
    if (atIndex !== undefined) {
      this.appliedColumns.splice(atIndex, 0, column);
    } else {
      this.appliedColumns.push(column);
    }
    this.triggerColumnsUpdate.next();
    this.triggerColumnAdded.next();
  }

  columnRemoved(columnId: number) {
    const removedCol = this.appliedColumns.splice(columnId, 1);
    for (const colCategory of Object.keys(this.availableColumns)) {
      for (const col of this.availableColumns[colCategory]) {
        if (col.id === removedCol[0].id) {
          col.isAdded = false;
          break;
        }
      }
    }
    for (const favouriteColumn of this.favouriteColumns) {
      if (favouriteColumn.id === removedCol[0].id) {
        favouriteColumn.isAdded = false;
        break;
      }
    }
    this.triggerColumnsUpdate.next();
  }

  columnToggleFavourite(column: Column.DataViewColumnGalleryColumn) {
    const favouriteIndex = this.favouriteColumns.findIndex(favouriteColumn => favouriteColumn.id === column.id);
    if (favouriteIndex === -1) {
      console.log(column);
      this.favouriteColumns = [...this.favouriteColumns, column];
    } else {
      this.favouriteColumns.splice(favouriteIndex, 1);
      this.favouriteColumns = [...this.favouriteColumns]; // Trigger setter
    }
    this.triggerColumnsUpdate.next();
  }

  columnDroppedFavourites(event: CdkDragDrop<Column.DataViewColumn[], Column.DataViewColumn[], any>) {
    if (event.previousContainer.id === 'selected-list') {
      this.columnRemoved(event.previousIndex);
    }
  }

  columnDroppedCategories(event: CdkDragDrop<Column.DataViewColumn[], Column.DataViewColumn[], any>) {
    if (event.previousContainer.id === 'selected-list') {
      this.columnRemoved(event.previousIndex);
    }
  }

  columnDroppedSelected(event: CdkDragDrop<Column.DataViewColumn[], Column.DataViewColumn[], any>) {
    if (event.container === event.previousContainer) {
      moveItemInArray(this.appliedColumns, event.previousIndex, event.currentIndex);
      this.triggerColumnsUpdate.next();
    } else if (event.previousContainer.id.startsWith('categories-list')) {
      const categoryId = event.previousContainer.id.split('categories-list-')[1];
      this.columnAdded(this.availableColumns[categoryId][event.previousIndex], event.currentIndex);
    } else {
      this.columnAdded(this.favouriteColumns[event.previousIndex], event.currentIndex);
    }
  }
}
