<div class="d-flex inputWrapper" [ngClass]="{ hasError: model.error }">
  <div class="inputLabel" [ngClass]="{ 'text-danger': model.error, 'me-2': type === 'option' }" [style]="labelStyles">{{ label }}</div>
  <div *ngIf="type !== 'option'" class="flex-grow-1 textInputWrapper">
    <input
      type="{{ type ?? 'text' }}"
      class="textInput"
      [(ngModel)]="model.value"
      (ngModelChange)="clearErrors()"
      [placeholder]="type === 'tel' ? 'xxx-xxx-xxxx' : ''"
      [disabled]="disabled"
    />
  </div>
  <ng-container *ngIf="type === 'option'">
    <div class="d-flex me-2" *ngFor="let option of options">
      <input
        type="checkbox"
        class="checkboxInput"
        id="option-{{ (label ?? '').split(' ').join('') }}-{{ option.value }}"
        (change)="onOptionSelected(option.value)"
        [checked]="model.value === option.value"
        [disabled]="disabled"
      />
      <label class="checkboxLabel" for="option-{{ (label ?? '').split(' ').join('') }}-{{ option.value }}">
        {{ option.label ?? option.value }}
      </label>
    </div>
    <div class="d-flex me-2" *ngIf="allowCustomValue">
      <input
        type="checkbox"
        class="checkboxInput"
        id="option-{{ (label ?? '').split(' ').join('') }}-custom"
        (change)="onCustomSelected()"
        [checked]="_isCustomSelected"
        [disabled]="disabled"
      />
      <label class="checkboxLabel" for="option-{{ (label ?? '').split(' ').join('') }}-custom">Other</label>
      <div class="flex-grow-1 textInputWrapper" *ngIf="_isCustomSelected">
        <input
          #custom
          type="text"
          class="textInput bg-none"
          [ngModel]="model.value"
          (change)="onCustomChange(custom.value)"
          [disabled]="disabled"
        />
      </div>
    </div>
  </ng-container>
  <div class="fieldError text-danger"><i class="fa-regular fa-circle-exclamation"></i> {{ model.error }}</div>
</div>
