import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { createFormGroup } from '@shared/components/forms/form';
import { AccountInfoForm } from '@shared/components/verify-account-info/verify-account-info-form';
import { IUser } from '@shared/models/user.model';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-verify-account-info',
  templateUrl: './verify-account-info.component.html',
  styleUrls: ['./verify-account-info.component.scss'],
})
export class VerifyAccountInfoComponent {
  @Output() verify = new EventEmitter<IUser>();

  form = createFormGroup<IUser>();
  fields: FormlyFieldConfig[];

  currentUser?: IUser;

  isLoading = false;
  errorMessage: string;

  constructor(
    protected activeModal: NgbActiveModal,
    private userService: UserService,
    private authService: AuthService,
  ) {
    this.currentUser = this.authService.getCurrentUser();
    this.fields = AccountInfoForm.create(cloneDeep(this.currentUser), userService);
  }

  confirm(): void {
    this.errorMessage = '';
    this.updateUsersDateVerified();
    this.saveUser();
  }

  private updateUsersDateVerified(): void {
    if (this.currentUser) {
      this.currentUser.date_verified = moment(new Date()).toISOString();
    }
  }

  private saveUser(): void {
    this.isLoading = true;
    if (!this.currentUser) {
      return;
    }
    this.userService
      .updateCurrentUser(this.currentUser)
      .pipe(
        finalize(() => (this.isLoading = false)),
        catchError(error => {
          this.errorMessage = 'Unable to save user';
          return of(error);
        }),
      )
      .subscribe(result => {
        if (result && !result.error) {
          this.authService.saveUser(this.currentUser!);
          this.verify.emit(this.currentUser);
          this.activeModal.dismiss();
        }
      });
  }
}
