import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@config';
import { OpenSearchQuery, OpenSearchQuerySimpleResult } from '@shared/providers/opensearch-api';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OpenSearchClient {
  static readonly OPEN_SEARCH_LIMIT = 10_000;

  private readonly baseUrl = config.openSearchUrl;

  constructor(private http: HttpClient) {}

  search<T>(index: string, query: OpenSearchQuery): Observable<OpenSearchQuerySimpleResult<T>> {
    const url = `${this.baseUrl}/${index}/_search`;
    return this.http.post<OpenSearchQuerySimpleResult<T>>(url, query);
  }

  count(index: string, query: OpenSearchQuery['query']): Observable<{ count: number }> {
    const url = `${this.baseUrl}/${index}/_count`;
    return this.http.post<{ count: number }>(url, { query });
  }

  export<T>(index: string, query: OpenSearchQuery, params: Record<string, string> = {}): Observable<T> {
    const url = `${this.baseUrl}/${index}/_export?${new URLSearchParams(params).toString()}`;
    return this.http.post<T>(url, query);
  }
}
