import { SelectListItem } from '@shared/models/select-list-item';

export interface TableHeader {
  name: string; // column name
  styles?: Record<string, number | string>;
  allowFilter?: boolean;
  filterType?: TableHeaderFilterType;
  filterItems?: SelectListItem[];
  filterStyles?: Record<string, number | string>;
  filterModifiers?: string[];
}

export enum TableHeaderFilterType {
  Text = 'Text',
  Dropdown = 'Dropdown',
  DatePicker = 'DatePicker',
}
