import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CursorPage, CursorPaginator } from '@shared/components/data-table/paginator';

@Component({
  selector: 'app-cursor-paginator',
  templateUrl: './cursor-paginator.component.html',
  styleUrls: ['./cursor-paginator.component.scss'],
})
export class CursorPaginatorComponent implements CursorPaginator {
  static readonly DEFAULT_PAGE_SIZE = 20;
  static readonly DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

  @Input()
  pageSize = CursorPaginatorComponent.DEFAULT_PAGE_SIZE;
  @Input()
  pageSizeOptions = CursorPaginatorComponent.DEFAULT_PAGE_SIZE_OPTIONS;
  @Input()
  length = 0;
  @Input()
  loading = false;
  @Output()
  pageChange = new EventEmitter<CursorPage>();
  pageIndex = 0;
  private pages: CursorPage[] = [];
  private nextPage?: CursorPage;

  constructor() {}

  get isPrevDisabled(): boolean {
    return this.loading ? true : this.startIndex < this.pageSize;
  }

  get isNextDisabled(): boolean {
    return this.loading ? true : this.endIndex >= this.length;
  }

  get startIndex() {
    return this.pageIndex * this.pageSize;
  }

  get endIndex() {
    return this.startIndex < this.length ? Math.min(this.startIndex + this.pageSize, this.length) : this.startIndex + this.pageSize;
  }

  addNextPage(page: CursorPage) {
    this.nextPage = page;
  }

  reset() {
    this.pageIndex = 0;
    this.pages = [];
    this.nextPage = undefined;
    this.length = 0;
  }

  onPageSizeChange(newPageSize: unknown) {
    this.pageSize = newPageSize as number;
    this.reset();
    this.pageChange.emit(this.getPage());
  }

  prev() {
    this.pageIndex--;
    this.pages.pop();
    const prevPage = this.pages[this.pages.length - 1];
    this.pageChange.emit(this.getPage(prevPage));
  }

  next() {
    this.pageIndex++;
    if (this.nextPage) {
      this.pages.push(this.nextPage);
    }
    this.pageChange.emit(this.getPage(this.nextPage));
  }

  getPage(pageCursor?: CursorPage): CursorPage {
    const pageSize = this.pageSize;
    return Object.assign(pageCursor || {}, { pageSize });
  }
}
