import { Directive, ElementRef, EventEmitter, HostListener, Output, Input } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef) {}

  @Input() classToExclude: string;

  @Output() clickOutside = new EventEmitter<Element>();

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: Element) {
    if (!this.wasClickInside(targetElement)) {
      this.clickOutside.emit(targetElement);
    }
  }

  wasClickInside(element: Element): boolean {
    const clickInside = this._elementRef.nativeElement.contains(element);
    let isExcludedClassInElementClassList = false;
    (element.classList as DOMTokenList).forEach(className => {
      if (className.includes(this.classToExclude)) {
        isExcludedClassInElementClassList = true;
      }
    });
    return clickInside || isExcludedClassInElementClassList;
  }
}
