import gql from 'graphql-tag';
import { instantPaymentRequestDetailFragment } from '@services/graphql/pharmacy/instant-payment-request-detail.fragment';

export const RequestInstantPaymentMutation = gql`
  ${instantPaymentRequestDetailFragment}
  mutation RequestInstantPayment(
    $pharmacyNpi: String!
    $pharmacyPaymentStatementEarlyPaymentProductId: String!
    $pharmacyPaymentStatementPeriodEnd: AWSDateTime!
  ) {
    requestPharmacyPaymentStatementEarlyPayment(
      pharmacyNpi: $pharmacyNpi
      pharmacyPaymentStatementEarlyPaymentProductId: $pharmacyPaymentStatementEarlyPaymentProductId
      pharmacyPaymentStatementPeriodEnd: $pharmacyPaymentStatementPeriodEnd
    ) {
      ...InstantPaymentRequestDetail
    }
  }
`;
