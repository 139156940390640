import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value = '', args?: string): any {
    if (value === null) {
      return '';
    }

    value = value.trim().replace(/\D/g, '');

    let formattedNumber = value.toString();

    if (!value) {
      return value;
    }

    if (formattedNumber.length !== 10) {
      return value;
    }

    if (value.length >= 3) {
      formattedNumber = '(' + value.substr(0, 3) + ')';
    }

    if (value.length >= 6) {
      formattedNumber += ' ' + value.substr(3, 3);
    }

    if (value.length >= 10) {
      formattedNumber += '-' + value.substr(6, 4);
    }

    return formattedNumber;
  }
}
