import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { Program } from '@shared/models/program.model';
import { ProgramApiService } from '@services/program-api.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { IStateService } from '@shared/models/state-service.model';
import { Tenant } from '@shared/models/tenant.model';
import { StateServiceName } from '@shared/models/state-service-name.enum';
import { IUser } from '@shared/models/user.model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { TenantService } from '@services/tenant.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramStateService implements IStateService {
  name = StateServiceName.PROGRAM;
  loading = new BehaviorSubject<boolean>(false);

  allProgramsForTenant = new BehaviorSubject<Program[]>([]);

  private newUpdateRequest = new Subject<void>();

  constructor(
    private programApiService: ProgramApiService,
    private tenantService: TenantService,
  ) {}

  get isLoading(): boolean {
    return this.loading.getValue();
  }

  reset() {
    this.allProgramsForTenant.next([]);
  }

  setFromCurrentTenant(currentTenant: Tenant): void {
    if (currentTenant) {
      this.getAllPrograms().subscribe();
    }
  }

  setFromCurrentUser(currentUser: IUser): void {
    if (currentUser && currentUser.tenants) {
      this.getAllProgramsForTenants(currentUser.tenants).subscribe();
    }
  }

  setOnTenantChange(newTenant: Tenant): void {
    this.setFromCurrentTenant(newTenant);
  }

  updateOnTenantChange(newTenant: Tenant): void {
    this.setFromCurrentTenant(newTenant);
  }

  setFromObservable(observable: Observable<Program[]>): Observable<Program[]> {
    return observable.pipe(takeUntil(this.newUpdateRequest)).pipe(
      tap(newPrograms => {
        this.allProgramsForTenant.next(newPrograms);
        this.loading.next(false);
      }),
    );
  }

  getAllPrograms(perProduct = false): Observable<Program[]> {
    this.cancelExistingRequests();
    this.loading.next(true);
    return this.setFromObservable(this.programApiService.getAllPrograms(perProduct));
  }

  getAllProgramsForTenants(tenants: TenantIdType[]): Observable<Program[]> {
    this.cancelExistingRequests();
    this.loading.next(true);
    return this.setFromObservable(this.programApiService.getAllProgramsForTenants(tenants));
  }

  private cancelExistingRequests(): void {
    if (this.isLoading) {
      this.newUpdateRequest.next();
    }
  }
}
