import gql from 'graphql-tag';

export const ResetPatientAssistancePharmacyPaymentStatementTransmittedDateMutation = gql`
  mutation ResetPatientAssistancePharmacyPaymentStatementTransmittedDate(
    $data: ResetPatientAssistancePharmacyPaymentStatementTransmittedDateInput!
  ) {
    resetPatientAssistancePharmacyPaymentStatementTransmittedDate(data: $data) {
      transmittedAt
      transmittedAtHistory {
        changedAt
        transmittedAt
      }
    }
  }
`;
