import { pharmacyPaymentStatementFragment } from '@services/graphql/pharmacy/pharmacy-statement.fragment';
import gql from 'graphql-tag';

export const GetChainStatementsQuery = gql`
  ${pharmacyPaymentStatementFragment}
  query GetChainStatements($npi: String!, $filter: PatientAssistancePharmacyPaymentStatementFilterInput!, $page: ConnectionEdgePage!) {
    pharmacyChain(npi: $npi) {
      paymentStatements(filter: $filter, page: $page, orderDirection: desc) {
        edges {
          node {
            ...PharmacyPaymentStatement
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`;
