import { Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { FormFieldService } from '@shared/providers/form-field-service';

@Component({
  selector: 'app-form-field-errors',
  templateUrl: './form-field-errors.component.html',
  styles: [':host {display: block; height: 23px; overflow: hidden;}'],
})
export class FormFieldErrorsComponent implements OnDestroy {
  control?: AbstractControl;

  errors = '';

  private valueChanges?: Subscription;

  constructor(private fieldService: FormFieldService) {}

  get field(): AbstractControl | undefined {
    return this.control;
  }

  @Input()
  set field(field: AbstractControl | undefined) {
    this.control = field;
    this.calculateErrors();
    this.valueChanges = this.control?.valueChanges.subscribe(() => this.calculateErrors());
  }

  ngOnDestroy(): void {
    if (this.valueChanges) {
      this.valueChanges.unsubscribe();
    }
  }

  private calculateErrors() {
    this.errors = this.fieldService.calculateError(this.control?.errors ?? undefined);
  }
}
