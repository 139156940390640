import { NgClass, NgStyle } from '@angular/common';
import { Component, input, model } from '@angular/core';
import { openCloseAnimation } from '@app/animations';

@Component({
  selector: 'app-toggle-card',
  standalone: true,
  imports: [NgClass, NgStyle],
  templateUrl: './toggle-card.component.html',
  styleUrls: ['./toggle-card.component.scss'],
  animations: [openCloseAnimation],
})
export class ToggleCardComponent {
  opened = model(false);
  title = input('');
  cardClass = input('');
  cardStyle = input({});
  bodyClass = input('');
  bodyStyle = input({});
}
