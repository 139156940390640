<app-loading-spinner *ngIf="loadingUI === LoadingUI.Spinner" [isLoading]="isLoading"></app-loading-spinner>
<div class="bs-table no-wrap" [ngClass]="{ resizableColumns }">
  <div class="dataViewTable" data-cy="dataViewTable" [style]="'grid-template-columns: ' + columnWidths.join(' ') + ';'">
    <div *ngFor="let column of columns; let columnIndex = index" [class]="'cell headCell tableColumn' + columnIndex + 'Item'">
      <span (click)="onHeaderSortClick(column)">
        <span *ngIf="sortField === column.field">
          <i class="fas" [class.fa-arrow-up]="sortOrder === 'asc'" [class.fa-arrow-down]="sortOrder === 'desc'"></i>
        </span>
        <span> {{ column.display }} </span>
      </span>
      <chitin-icon
        *ngIf="resizableColumns"
        class="resizeDragger"
        (mousedown)="onMouseDragBegin(columnIndex)"
        (dblclick)="onDoubleClick(columnIndex)"
        icon="fa-solid fa-pipe"
      />
    </div>

    <ng-container *ngIf="data.length <= 0 && !isLoading && columns && columns.length > 0">
      <div [style.grid-column]="'1 / span ' + columns.length" class="empty-table">
        <div class="alert alert-warning" role="alert">
          <div class="alert-message" data-cy="emptyTableText">No results found</div>
        </div>
      </div>
    </ng-container>
    <div
      *ngIf="isFetchingRows"
      class="d-flex justify-content-center align-items-center pt-4"
      style="height: 20rem"
      [style.grid-column]="'1 / span ' + columns.length"
    >
      <app-loading-spinner [isLoading]="true" />
    </div>
    <ng-container *ngIf="(!columns || columns.length === 0) && !isFetchingRows">
      <div class="empty-table">
        <div class="alert alert-warning" role="alert">
          <div class="alert-message" data-cy="emptyTableText">No columns found. Please contact customer support for assistance.</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="columns && columns.length > 0">
      <ng-container *ngIf="isLoading && loadingUI === LoadingUI.Placeholder && (!showLoadingInitOnly || data.length <= 0)">
        <ng-container *ngFor="let _ of [].constructor(loadingRowsCount)">
          <div *ngFor="let column of columns" class="placeholder-glow py-1">
            <span class="placeholder ms-3 col-9">L</span>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="loadingUI !== LoadingUI.Placeholder || !isLoading || showLoadingInitOnly">
        <div
          [class]="'cellRow cellRow' + itemIndex"
          [ngClass]="{ active: activeRowId === itemIndex }"
          *ngFor="let item of data; let itemIndex = index"
        >
          <div
            class="cell"
            [ngClass]="{ clickable: itemClicked.observers.length > 0 }"
            *ngFor="let column of columns; let columnIndex = index"
            (click)="onItemClick(item, itemIndex, column)"
          >
            <span
              class="cell-value"
              *ngIf="column.templateRef"
              [ngTemplateOutlet]="templates[column.templateRef]"
              [ngTemplateOutletContext]="{ item, index: itemIndex, column }"
            ></span>
            <span *ngIf="!column.templateRef" placement="top" ngbTooltip="{{ item | gridField: column }}" container="body">
              {{ item | gridField: column }}
            </span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
