import { CurrencyPipe, DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '@shared/models/grid-column.model';
import { get } from 'lodash';
import { StatusLabelPipe } from '@shared/pipes/status-label.pipe';
import { Column } from '@shared/components/data-view/data-view-types';

@Pipe({
  name: 'gridField',
  pure: true,
})
export class GridFieldPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private statusLabelPipe: StatusLabelPipe,
  ) {}

  transform(item: any, column: GridColumn | Column.AppliedDataViewColumn): null | string {
    const value = get(item, column.field);
    let result: null | string;

    if (column.type === 'date') {
      result = this.datePipe.transform(value, 'MM/dd/yyyy');
    } else if (column.type === 'daterange') {
      result = `${this.datePipe.transform(value.startDate, 'MM/dd/yyyy')} - ${this.datePipe.transform(value.endDate, 'MM/dd/yyyy')}`;
    } else if (column.type === 'datetime') {
      result = this.datePipe.transform(value, 'MM/dd/yyyy hh:mm a');
    } else if (column.type === 'time') {
      result = this.datePipe.transform(value, 'hh:mm a');
    } else if (column.type === 'timeprecise') {
      result = this.datePipe.transform(value, 'hh:mm:ss a');
    } else if (column.type === 'duration') {
      result = value ? `${value}s` : null;
    } else if (column.type === 'currency') {
      result = this.currencyPipe.transform(value, 'USD');
    } else if ('pipe' in column && column.pipe?.name === 'statusLabel') {
      result = this.statusLabelPipe.transform(value, column.pipe.arguments);
    } else {
      result = value;
    }

    return result;
  }
}
