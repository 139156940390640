import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AgreementService } from '@services/agreement.service';
import { DialogService } from '@services/dialog.service';
import { LogService } from '@services/log.service';
import { PrinterService } from '@services/printer.service';
import { AgreementOpenBeforeEvent } from '@shared/models/agreement-open-before-event.enum';
import { Agreement } from '@shared/models/agreement.model';
import { LogOptions } from '@shared/models/log-options.model';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-agreements-modal',
  templateUrl: './agreements-modal.component.html',
  styleUrls: ['./agreements-modal.component.scss'],
})
export class AgreementsModalComponent implements OnInit, OnDestroy {
  @ViewChild('agreementDocument') agreementDocument: ElementRef<HTMLElement>;
  @Input() username?: string;
  @Input() pharmacy?: IPharmacy;
  @Input() onlyShowNewAgreements = false;
  @Input() agreementOpenEvent?: AgreementOpenBeforeEvent; // should be present if onlyShowNewAgreements is true
  @Output() agreementDeclined = new EventEmitter<void>();

  error: string;

  isAgree = false;
  agreementStep = 1;
  agreeLabelHover = false;
  currentAgreement: Agreement;
  agreementsToShow: Agreement[] = [];
  numberOfAgreements: number; // agreementsToShow
  hasLoadedAgreements: boolean;
  showSteps = true;

  isMobile = false;
  isTablet = false;

  isLoadingAgreements: boolean;
  isMarkingAgreed: boolean;

  unsubscribe = new Subject<void>();

  constructor(
    private agreementService: AgreementService,
    private Log: LogService,
    private printer: PrinterService,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.setAgreementsToShow();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onAgree() {
    this.isMarkingAgreed = true;
    this.agreementService
      .markAgreed(this.currentAgreement.id, true)
      .pipe(finalize(() => (this.isMarkingAgreed = false)))
      .subscribe(() => {
        this.agreementStep++;
        if (this.agreementStep <= this.numberOfAgreements) {
          this.nextAgreement();
        } else {
          this.closeModal('user updated');
        }
      });
  }

  onDecline() {
    this.closeModal('declined');
    this.agreementDeclined.emit();
  }

  closeModal(reason?: string): void {
    this.dialogService.dismissAllModals(reason);
  }

  onAgreeLabelMouseOver(): void {
    this.agreeLabelHover = true;
  }

  onAgreeLabelMouseOut(): void {
    this.agreeLabelHover = false;
  }

  isCurrentAgreement(agreement: Agreement): boolean {
    const isStepInBounds = this.agreementStep <= this.agreementsToShow.length + 1;
    const isCurrent = isStepInBounds && this.currentAgreement === agreement;

    return isCurrent;
  }

  private nextAgreement(): void {
    this.currentAgreement = this.agreementsToShow[this.agreementStep - 1];
  }

  private setAgreementsToShow(): void {
    this.isLoadingAgreements = true;

    if (this.onlyShowNewAgreements) {
      this.setNewAgreements();
    } else {
      this.setAllAgreements();
    }
  }

  private setAllAgreements(): void {
    this.agreementService.agreementsForPharmacy.pipe(takeUntil(this.unsubscribe)).subscribe(
      agreements => {
        this.setupAgreements(agreements.map(agreement => this.agreementService.searchAgreementsById(agreement.id, true)));
        this.stopLoadingSpinners();
      },
      error => {
        this.error = error;
        this.setAllAgreementsError(error);
      },
    );
  }

  private setAllAgreementsError(error: Error) {
    const options: LogOptions = {
      tags: {
        action: 'get-agreements',
      },
      extraInfo: {
        attemptedUsername: this.username ? this.username : '',
      },
    };
    this.Log.error(error, options);
  }

  private setupAgreements(agreements: Agreement[]): void {
    this.agreementsToShow = agreements;
    this.currentAgreement = this.agreementsToShow[0];
    this.numberOfAgreements = this.agreementsToShow.length;
    this.showSteps = this.numberOfAgreements > 1;
    this.hasLoadedAgreements = true;
  }

  private setNewAgreements(): void {
    const agreements = this.agreementService.newAgreementsForPharmacy.getValue();
    const agreementsNeeded = agreements.filter(agreement => this.isAgreementForEvent(agreement));
    this.setupAgreements(agreementsNeeded);
    this.stopLoadingSpinners();
  }

  private isAgreementForEvent(agreement: Agreement): boolean {
    return agreement.openBeforeEvent === this.agreementOpenEvent;
  }

  private stopLoadingSpinners(): void {
    this.isLoadingAgreements = false;
  }

  onPrint(): void {
    const innerContents = this.agreementDocument.nativeElement.innerHTML;
    this.printer.print(innerContents);
  }
}
