import { Injectable } from '@angular/core';
import {
  AttachmentUrl,
  CreatePharmacy,
  CreatePharmacyChain,
  CreatePharmacyPaymentInfo,
  GetConsignmentPharmacyForTerminal,
  GetPharmacy,
  GetPharmacyChain,
  GetPharmacyChainDownloadAttachmentUrl,
  GetPharmacyChains,
  GetPharmacyEngagements,
  GetPharmacyEnrollment,
  GetPharmacyEnrollments,
  GetPharmacyForFamily,
  GetPharmacyForInventory,
  GetPharmacyForTerminal,
  GetPharmacyMain,
  GetPharmacyNcpdpByNpi,
  GetPharmacyOverview,
  GetPharmacyPaymentInfoDownloadAttachmentUrl,
  GetPharmacyScorecardInfo,
  Maybe,
  SendPharmacyOnboardingInstructions,
  UpdatePharmacy,
  UpdatePharmacyChain,
  UpdatePharmacyEnrollment,
  UpdatePharmacyPaymentInfo,
} from '@generated/graphql';
import { GraphqlService } from '@services/graphql.service';
import { CreatePharmacyChainMutation } from '@services/graphql/pharmacy/chain/create.pharmacy-chain.mutation';
import { GetPharmacyChainDownloadAttachmentUrlQuery } from '@services/graphql/pharmacy/chain/get-pharmacy-chain-download-attachment-url.query';
import { GetPharmacyChainQuery } from '@services/graphql/pharmacy/chain/get-pharmacy-chain.query';
import { GetPharmacyChainsQuery } from '@services/graphql/pharmacy/chain/get-pharmacy-chains.query';
import { UpdatePharmacyChainMutation } from '@services/graphql/pharmacy/chain/update-pharmacy-chain.mutation';
import { CreatePharmacyPaymentInfoMutation } from '@services/graphql/pharmacy/create-pharmacy-payment-info.mutation';
import { CreatePharmacyMutation } from '@services/graphql/pharmacy/create-pharmacy.mutation';
import { GetConsignmentPharmacyForTerminalQuery } from '@services/graphql/pharmacy/get-consignment-pharmacy-for-terminal.query';
import { GetPharmacyEngagementsQuery } from '@services/graphql/pharmacy/get-pharmacy-engagements.query';
import { GetPharmacyEnrollmentQuery } from '@services/graphql/pharmacy/get-pharmacy-enrollment.query';
import { GetPharmacyEnrollmentsQuery } from '@services/graphql/pharmacy/get-pharmacy-enrollments.query';
import { GetPharmacyForFamilyQuery } from '@services/graphql/pharmacy/get-pharmacy-for-family.mutation';
import { GetPharmacyForInventoryQuery } from '@services/graphql/pharmacy/get-pharmacy-for-inventory.query';
import { GetPharmacyForTerminalQuery } from '@services/graphql/pharmacy/get-pharmacy-for-terminal.query';
import { GetPharmacyMainQuery } from '@services/graphql/pharmacy/get-pharmacy-main.query';
import { GetPharmacyNcpdpByNpiQuery } from '@services/graphql/pharmacy/get-pharmacy-ncpdp-by-npi.query';
import { GetPharmacyOverviewQuery } from '@services/graphql/pharmacy/get-pharmacy-overview.query';
import { GetPharmacyPaymentInfoDownloadAttachmentUrlQuery } from '@services/graphql/pharmacy/get-pharmacy-payment-info-download-attachment-url.query';
import { GetPharmacyScorecardInfoQuery } from '@services/graphql/pharmacy/get-pharmacy-scorecard-info.query';
import { GetPharmacyQuery } from '@services/graphql/pharmacy/get-pharmacy.query';
import { SendPharmacyOnboardingInstructionsMutation } from '@services/graphql/pharmacy/send-pharmacy-onboarding-instructions.mutation';
import { UpdatePharmacyEnrollmentMutation } from '@services/graphql/pharmacy/update-pharmacy-enrollment.mutation';
import { UpdatePharmacyPaymentInfoMutation } from '@services/graphql/pharmacy/update-pharmacy-payment-info.mutation';
import { UpdatePharmacyMutation } from '@services/graphql/pharmacy/update-pharmacy.mutation';
import { PharmacyFamilyModel } from '@shared/models/pharmacy-family-model';
import { MutateResult, QueryResult } from '@shared/providers/graphql-client';
import { Observable, lastValueFrom, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PharmacyService extends GraphqlService {
  getPharmacy(variables: GetPharmacy.Variables): Observable<QueryResult<GetPharmacy.Query>> {
    return this.graphqlClient.query<GetPharmacy.Query>(GetPharmacyQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result;
      }),
    );
  }

  getPharmacyNcpdpByNpi(variables: GetPharmacyNcpdpByNpi.Variables): Observable<QueryResult<GetPharmacyNcpdpByNpi.Query>> {
    return this.graphqlClient.query<GetPharmacyNcpdpByNpi.Query>(GetPharmacyNcpdpByNpiQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result;
      }),
    );
  }

  getPharmacyMain(variables: GetPharmacyMain.Variables): Observable<Maybe<GetPharmacyMain.Pharmacy>> {
    return this.graphqlClient.query<GetPharmacyMain.Query>(GetPharmacyMainQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.pharmacy ?? null;
      }),
    );
  }

  getPharmacyOverview(variables: GetPharmacyOverview.Variables): Observable<Maybe<GetPharmacyOverview.Pharmacy>> {
    return this.graphqlClient.query<GetPharmacyOverview.Query>(GetPharmacyOverviewQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.pharmacy ?? null;
      }),
    );
  }

  getPharmacyForFamily(variables: GetPharmacyForFamily.Variables): Observable<Maybe<GetPharmacyForFamily.Pharmacy>> {
    return this.graphqlClient.query<GetPharmacyForFamily.Query>(GetPharmacyForFamilyQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.pharmacy ?? null;
      }),
    );
  }

  getPharmacyForInventory(variables: GetPharmacyForInventory.Variables): Observable<Maybe<GetPharmacyForInventory.Pharmacy>> {
    return this.graphqlClient.query<GetPharmacyForInventory.Query>(GetPharmacyForInventoryQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.pharmacy ?? null;
      }),
    );
  }

  getPharmacyForTerminal(variables: GetPharmacyForTerminal.Variables): Observable<Maybe<GetPharmacyForTerminal.Pharmacy>> {
    return this.graphqlClient.query<GetPharmacyForTerminal.Query>(GetPharmacyForTerminalQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.pharmacy ?? null;
      }),
    );
  }

  getConsignmentPharmacyForTerminal(
    variables: GetConsignmentPharmacyForTerminal.Variables,
  ): Observable<Maybe<GetConsignmentPharmacyForTerminal.Pharmacy>> {
    return this.graphqlClient.query<GetConsignmentPharmacyForTerminal.Query>(GetConsignmentPharmacyForTerminalQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.pharmacy ?? null;
      }),
    );
  }

  getPharmacyScorecardInfo(variables: GetPharmacyScorecardInfo.Variables): Observable<Maybe<GetPharmacyScorecardInfo.Pharmacy>> {
    return this.graphqlClient.query<GetPharmacyScorecardInfo.Query>(GetPharmacyScorecardInfoQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.pharmacy ?? null;
      }),
    );
  }

  getPharmacyEnrollment(ncpdp: string, tenantId: string): Observable<QueryResult<GetPharmacyEnrollment.Query>> {
    const variables = { ncpdp, tenantIds: [tenantId] };
    return this.graphqlClient.query<GetPharmacyEnrollment.Query>(GetPharmacyEnrollmentQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result;
      }),
    );
  }

  getPharmacyEnrollments(variables: GetPharmacyEnrollments.Variables): Observable<QueryResult<GetPharmacyEnrollments.Query>> {
    return this.graphqlClient.query<GetPharmacyEnrollments.Query>(GetPharmacyEnrollmentsQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        const enrollments = result.data?.pharmacy?.enrollments ?? [];
        const filteredEnrollments = enrollments.filter(enrollment => enrollment.tenant.id !== 'HZN');
        if (result.data?.pharmacy) {
          result.data.pharmacy.enrollments = filteredEnrollments;
        }
        return result;
      }),
    );
  }

  getPharmacyEngagements(ncpdp: string, tenantId: string): Observable<QueryResult<GetPharmacyEngagements.Query>> {
    const variables = { ncpdp, tenantIds: [tenantId] };
    return this.graphqlClient.query<GetPharmacyEngagements.Query>(GetPharmacyEngagementsQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result;
      }),
    );
  }

  createPharmacy(variables: CreatePharmacy.Variables): Observable<MutateResult<CreatePharmacy.Mutation>> {
    this.sanitizeInputType(variables.data);
    return this.graphqlClient.mutate<CreatePharmacy.Mutation>(CreatePharmacyMutation, variables);
  }

  savePharmacy(variables: UpdatePharmacy.Variables): Observable<MutateResult<UpdatePharmacy.Mutation>> {
    this.sanitizeInputType(variables.data);
    return this.graphqlClient.mutate<UpdatePharmacy.Mutation>(UpdatePharmacyMutation, variables);
  }

  createPharmacyPaymentInfo(variables: CreatePharmacyPaymentInfo.Variables): Observable<MutateResult<CreatePharmacyPaymentInfo.Mutation>> {
    this.sanitizeInputType(variables.data);
    return this.graphqlClient.mutate<CreatePharmacyPaymentInfo.Mutation>(CreatePharmacyPaymentInfoMutation, variables);
  }

  savePharmacyPaymentInfo(variables: UpdatePharmacyPaymentInfo.Variables): Observable<MutateResult<UpdatePharmacyPaymentInfo.Mutation>> {
    this.sanitizeInputType(variables.data);
    return this.graphqlClient.mutate<UpdatePharmacyPaymentInfo.Mutation>(UpdatePharmacyPaymentInfoMutation, variables);
  }

  getPharmacyPaymentInfoAttachmentDownloadUrl(
    variables: GetPharmacyPaymentInfoDownloadAttachmentUrl.Variables,
  ): Observable<AttachmentUrl[]> {
    return this.graphqlClient
      .query<GetPharmacyPaymentInfoDownloadAttachmentUrl.Query>(GetPharmacyPaymentInfoDownloadAttachmentUrlQuery, variables)
      .pipe(
        map(result => {
          this.processGraphqlQueryResult(result);
          return result.data?.pharmacy?.paymentInfo?.authorizationAttachmentUrls ?? [];
        }),
      );
  }

  savePharmacyEnrollment(variables: UpdatePharmacyEnrollment.Variables) {
    this.sanitizeInputType(variables.data);
    return this.graphqlClient.mutate<UpdatePharmacyEnrollment.Mutation>(UpdatePharmacyEnrollmentMutation, variables);
  }

  isPharmacyHub(pharmacy?: Maybe<GetPharmacyEnrollments.Pharmacy>): boolean {
    const enrollments = pharmacy?.enrollments ?? [];
    return enrollments.reduce<boolean>((result, item) => {
      return item.isConsignmentHub ? true : result;
    }, false);
  }

  getPharmacyFamilies(): Observable<PharmacyFamilyModel[]> {
    return this.lookupsService.getSettings<PharmacyFamilyModel[]>('pharmacy', 'family').pipe(map(value => value ?? []));
  }

  setPharmacyFamilies(families: PharmacyFamilyModel[]) {
    return this.lookupsService.setSettings('pharmacy', 'family', families);
  }

  getPharmacyFamily(familyId: string): Observable<PharmacyFamilyModel | undefined> {
    return this.lookupsService
      .getSettings<PharmacyFamilyModel[]>('pharmacy', 'family')
      .pipe(map(value => (value ?? []).find(family => family.id === familyId)));
  }

  setPharmacyFamily(family: PharmacyFamilyModel) {
    return this.lookupsService.getSettings<PharmacyFamilyModel[]>('pharmacy', 'family').pipe(
      mergeMap(families => {
        if (!families) {
          return of(undefined);
        }
        const originalIndex = families.findIndex(family => family.id === family.id);
        families[originalIndex] = family;
        return this.setPharmacyFamilies(families);
      }),
    );
  }

  getPharmacyChains(variables: GetPharmacyChains.Variables): Observable<GetPharmacyChains.PharmacyChains> {
    return this.graphqlClient.query<GetPharmacyChains.Query>(GetPharmacyChainsQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.pharmacyChains ?? this.getEmptyConnection();
      }),
    );
  }

  getPharmacyChain(variables: GetPharmacyChain.Variables): Observable<GetPharmacyChain.PharmacyChain | undefined> {
    return this.graphqlClient.query<GetPharmacyChain.Query>(GetPharmacyChainQuery, variables).pipe(
      map(result => {
        this.processGraphqlQueryResult(result);
        return result.data?.pharmacyChain ?? undefined;
      }),
    );
  }

  getPharmacyChainAttachmentDownloadUrl(variables: GetPharmacyChainDownloadAttachmentUrl.Variables): Observable<AttachmentUrl[]> {
    return this.graphqlClient
      .query<GetPharmacyChainDownloadAttachmentUrl.Query>(GetPharmacyChainDownloadAttachmentUrlQuery, variables)
      .pipe(
        map(result => {
          this.processGraphqlQueryResult(result);
          return result.data?.pharmacyChain?.authorizationAttachmentUrls ?? [];
        }),
      );
  }

  createPharmacyChain(variables: CreatePharmacyChain.Variables): Observable<CreatePharmacyChain.CreatePharmacyChain | undefined> {
    this.sanitizeInputType(variables.data);
    return this.graphqlClient.mutate<CreatePharmacyChain.Mutation>(CreatePharmacyChainMutation, variables).pipe(
      map(result => {
        this.processGraphqlMutationResult(result);
        return result.data?.createPharmacyChain;
      }),
    );
  }

  updatePharmacyChain(variables: UpdatePharmacyChain.Variables): Observable<UpdatePharmacyChain.UpdatePharmacyChain | undefined> {
    this.sanitizeInputType(variables.data);
    return this.graphqlClient.mutate<UpdatePharmacyChain.Mutation>(UpdatePharmacyChainMutation, variables).pipe(
      map(result => {
        this.processGraphqlMutationResult(result);
        return result.data?.updatePharmacyChain;
      }),
    );
  }

  async validatePharmacyChainNpi(npi: string): Promise<boolean> {
    if (!npi) {
      return true;
    }
    const chain = await lastValueFrom(this.getPharmacyChain({ npi }));
    return !!chain;
  }

  sendPharmacyOnboardingInstructions(
    variables: SendPharmacyOnboardingInstructions.Variables,
  ): Observable<MutateResult<SendPharmacyOnboardingInstructions.Mutation>> {
    this.sanitizeInputType(variables.data);
    return this.graphqlClient.mutate<SendPharmacyOnboardingInstructions.Mutation>(SendPharmacyOnboardingInstructionsMutation, variables);
  }
}
