/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

type ColorsType = 'default' | 'primary';

@Component({
  selector: 'button[utility-btn]',
  exportAs: 'app-shared-utility-btn',
  templateUrl: './utility-btn.component.html',
  styleUrls: ['./utility-btn.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'utility-btn',
    '[class.utility-btn--default]': 'isColorDefault',
    '[class.utility-btn--primary]': 'isColorPrimary',
  },
})
export class UtilityBtnComponent {
  @Input()
  color: ColorsType = 'default';

  get isColorDefault() {
    return this.color === 'default';
  }

  get isColorPrimary() {
    return this.color === 'primary';
  }
}
