import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent, Paginator } from '@shared/components/data-table/paginator';

type PaginatorColor = 'black' | 'pink';

@Component({
  selector: 'app-shared-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements Paginator {
  static readonly DEFAULT_PAGE_SIZE = 20;
  static readonly DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

  @Input()
  color: PaginatorColor = 'black';
  @Input()
  loading = false;
  @Input()
  length = 0;
  @Input()
  pageIndex = 0;
  @Input()
  pageSize = PaginatorComponent.DEFAULT_PAGE_SIZE;
  @Input()
  pageSizeOptions = PaginatorComponent.DEFAULT_PAGE_SIZE_OPTIONS;
  @Input()
  totalItems?: number;
  @Input()
  totalLimit = 0;
  @Output()
  page = new EventEmitter<PageEvent>();

  get lastPageIndex(): number {
    return this.getNumberOfPages() - 1;
  }

  get startIndex() {
    return this.pageIndex * this.pageSize;
  }

  get endIndex() {
    return this.startIndex < this.length ? Math.min(this.startIndex + this.pageSize, this.length) : this.startIndex + this.pageSize;
  }

  firstPage(): void {
    const previousPageIndex = this.pageIndex;
    this.pageIndex = 0;
    this.page.emit(this.getPageEvent(previousPageIndex));
  }

  getNumberOfPages(): number {
    return Math.ceil(this.length / this.pageSize);
  }

  goToPage(page: number): void {
    const pageIndex = page - 1;
    if (0 <= pageIndex && pageIndex <= this.lastPageIndex) {
      const previousPageIndex = this.pageIndex;
      this.pageIndex = pageIndex;
      this.page.emit(this.getPageEvent(previousPageIndex));
    }
  }

  hasNextPage(): boolean {
    return this.pageIndex < this.lastPageIndex;
  }

  hasPreviousPage(): boolean {
    return this.pageIndex > 0;
  }

  lastPage(): void {
    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.getNumberOfPages() - 1;
    this.page.emit(this.getPageEvent(previousPageIndex));
  }

  nextPage(): void {
    if (this.hasNextPage()) {
      const previousPageIndex = this.pageIndex;
      this.pageIndex++;
      this.page.emit(this.getPageEvent(previousPageIndex));
    }
  }

  previousPage(): void {
    if (this.hasPreviousPage()) {
      const previousPageIndex = this.pageIndex;
      this.pageIndex++;
      this.page.emit(this.getPageEvent(previousPageIndex));
    }
  }

  reset() {
    this.pageIndex = 0;
    this.length = 0;
  }

  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    this.reset();
    this.page.emit(this.getPageEvent(0));
  }

  serialize() {
    return { pageIndex: this.pageIndex, pageSize: this.pageSize, length: this.length };
  }

  protected getPageEvent(previousPageIndex: number): PageEvent {
    return {
      length: this.length,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      previousPageIndex,
    };
  }
}
