import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

const ANIMATION_DURATION_SEC = 0.75;

@Component({
  selector: 'app-shared-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss'],
  animations: [
    trigger('showHide', [
      state(
        'show',
        style({
          width: '564px',
          opacity: 1,
        }),
      ),
      state(
        'hide',
        style({
          width: '0px',
          opacity: 0,
        }),
      ),
      transition('show <=> hide', [animate(`${ANIMATION_DURATION_SEC}s ease-in-out`)]),
    ]),
  ],
})
export class SidepanelComponent implements OnInit {
  @Input()
  public showOnInit = false;

  @Input()
  public useBackdrop = true;

  @Output()
  public showed = new EventEmitter<void>();

  @Output()
  public hidden = new EventEmitter<void>();

  public containerWidth = '0px';

  isHidden = true;

  @HostBinding('style.display')
  display = 'none';

  ngOnInit() {
    if (this.showOnInit) {
      this.show();
    }
  }

  hide() {
    this.isHidden = true;
    setTimeout(() => {
      this.display = 'none';
      this.hidden.emit();
    }, ANIMATION_DURATION_SEC * 1000);
  }

  show() {
    this.display = 'block';
    this.isHidden = false;
    setTimeout(() => {
      this.showed.emit();
    }, ANIMATION_DURATION_SEC * 1000);
  }
}
