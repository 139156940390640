import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { DateService } from '@services/date.service';
import { LogService } from '@services/log.service';
import { TenantService } from '@services/tenant.service';
import { isDateRange } from '@shared/components/chitin/molecules/daterange-picker/date-range';
import { Filter } from '@shared/components/data-view/data-view-types';
import {
  OpenSearchQuery,
  OpenSearchQueryContainer,
  OpenSearchQueryRange,
  OpenSearchQueryResult,
  OpenSearchQuerySimpleResult,
  OpenSearchSimpleSort,
} from '@shared/providers/opensearch-api';
import { OpenSearchClient } from '@shared/providers/opensearch-client';
import Utils from '@shared/providers/utils';
import { cloneDeep, isArray, isEmpty, isString, zipObject } from 'lodash';
import { of } from 'rxjs';

export type GroupByField = { field: string; groupBy: string };
export type GroupMap = Record<string, Record<string, unknown[]>>;
export type QueryContainer = {
  bool: {
    must: OpenSearchQueryContainer[];
    must_not: OpenSearchQueryContainer[];
    minimum_should_match?: number;
    should: OpenSearchQueryContainer[];
  };
};

@Injectable()
export class OpenSearchService {
  protected nestedPaths: string[] = [];
  protected groupFields: GroupByField[] = [];

  constructor(
    protected authService: AuthService,
    protected client: OpenSearchClient,
    protected dateService: DateService,
    protected logger: LogService,
    protected tenantService: TenantService,
  ) {}

  createSearchSort(sort: OpenSearchSimpleSort): OpenSearchQuery['sort'] {
    return Object.entries(sort).map(([key, order]) => {
      return { [key]: { order } };
    });
  }

  mapQueryFilters(input: Filter.AppliedDataViewFilter[]): OpenSearchQueryContainer[] {
    return cloneDeep(input)
      .filter(item => !isEmpty(item.value))
      .map(filter => this.mapQueryFilter(filter));
  }

  mapQueryFilter(input: Filter.AppliedDataViewFilter): OpenSearchQueryContainer {
    const item = cloneDeep(input);
    if (isDateRange(item.value)) {
      item.value = { gte: item.value.from.toISOString(), lte: item.value.to.toISOString() };
    }
    if (isArray(item.value)) {
      item.value = item.value.map(v => (isString(v) && v.includes('|') ? v.split('|')[1] : v));
    }

    if (['contains', 'beginsWith', 'endsWith'].includes(item.type)) {
      const expression: Record<string, string> = {
        contains: `*${item.value}*`,
        beginsWith: `${item.value}*`,
        endsWith: `*${item.value}`,
      };
      item.value = { query: expression[item.type], default_field: item.field, default_operator: 'and' };
    }

    switch (item.type) {
      case 'equal':
        return { match: { [item.field]: item.value } };
      case 'contains':
      case 'beginsWith':
      case 'endsWith':
        return { query_string: item.value as OpenSearchQueryContainer };
      case 'in':
      case 'isOneOf':
        return { terms: { [item.field]: item.value } };
      case 'dateRange':
      case 'datetimeRange':
        return { range: { [item.field]: item.value as OpenSearchQueryRange } };
      case 'gt':
        return { range: { [item.field]: { gt: item.value as string } } };
      case 'lt':
        return { range: { [item.field]: { lt: item.value as string } } };
      case 'exists':
        return { exists: { field: item.field } };
      default:
        return { [item.type]: { [item.field]: item.value } };
    }
  }

  protected processSimpleSearchError<T>(error: HttpErrorResponse) {
    this.logger.error(error);
    return of(this.getEmptySimpleResult<T>(error));
  }

  protected processSearchError<T>(error: HttpErrorResponse) {
    this.logger.error(error);
    return of(this.getEmptyResult<T>(error));
  }

  protected getEmptySimpleResult<T>(error: HttpErrorResponse): OpenSearchQuerySimpleResult<T> {
    return { error, items: [], total: 0 };
  }

  protected getEmptyResult<T>(error: Error): OpenSearchQueryResult<T> {
    return {
      error,
      hits: {
        hits: [],
        total: { value: 0 },
      },
      timed_out: false,
      took: 0,
    };
  }

  protected buildQuery(filter: OpenSearchQueryContainer[]): OpenSearchQueryContainer {
    const items = cloneDeep(filter);
    const rootQuery: QueryContainer = { bool: { must: [], must_not: [], should: [] } };
    const rootQueryItems: OpenSearchQueryContainer[] = [];
    const subQueries = this.createSubQueriesMap();

    items.forEach(item => {
      const nestedPath = this.nestedPaths.find(path => item.path?.startsWith(path));
      nestedPath ? subQueries[nestedPath].push(item) : rootQueryItems.push(item);
    });

    this.buildQueryItems(rootQuery, rootQueryItems);

    Object.entries(subQueries).forEach(([path, items]) => {
      if (items.length) {
        const query: QueryContainer = { bool: { must: [], must_not: [], should: [] } };
        this.buildQueryItems(query, items);
        rootQuery.bool.must.push({ nested: { path, query } });
      }
    });

    if (rootQuery.bool.should.length) {
      rootQuery.bool.minimum_should_match = 1;
    }

    return rootQuery;
  }

  protected buildQueryItems(query: QueryContainer, items: OpenSearchQueryContainer[]) {
    const groupMap: GroupMap = {};

    items.forEach(item => {
      const negate = Utils.extractField(item, 'negate');
      const should = Utils.extractField(item, 'should');
      const path = Utils.extractField(item, 'path');
      const groupByValue = Utils.extractField(item, 'groupBy');
      const groupBy = this.groupFields.find(f => path?.startsWith(f.field));

      if (groupBy && groupByValue) {
        this.updateGroupMap(groupMap, groupBy, groupByValue);
      } else if (should) {
        query.bool.should.push(item);
      } else {
        negate ? query.bool.must_not.push(item) : query.bool.must.push(item);
      }
    });

    this.buildGroupQueries(query, groupMap);
  }

  protected updateGroupMap(groupMap: GroupMap, groupBy: GroupByField, groupByValue: Record<string, unknown[]>) {
    Object.entries(groupByValue).forEach(([groupValue, values]) => {
      const key = `${groupBy.groupBy}:${groupValue}`;
      const currentValue = groupMap[key] ?? {};
      groupMap[key] = Object.assign(currentValue, { [groupBy.field]: values });
    });
  }

  protected buildGroupQueries(parentQuery: QueryContainer, groupMap: GroupMap) {
    Object.entries(groupMap).forEach(([key, fields]) => {
      const [groupField, groupValue] = key.split(':');
      const query: OpenSearchQueryContainer[] = [{ match: { [groupField]: groupValue } }];

      Object.entries(fields).forEach(([field, values]) => {
        query.push({ terms: { [field]: values } });
      });

      parentQuery.bool.minimum_should_match = 1;
      parentQuery.bool.should.push({
        bool: { must: query },
      });
    });
  }

  protected createSubQueriesMap(): Record<string, OpenSearchQueryContainer[]> {
    return zipObject(
      this.nestedPaths,
      this.nestedPaths.map(() => []),
    );
  }
}
