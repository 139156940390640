import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { SelectListItem } from '@shared/models/select-list-item';
import { NgChanges } from '@shared/ng-changes';
import Utils from '@shared/providers/utils';
import { isString } from 'lodash';
import { Observable, Subscription } from 'rxjs';

type LabelSide = 'top' | 'left';
type Modifier =
  | string
  | 'font-15'
  | 'horizontal'
  | 'font-14'
  | 'h-30'
  | 'gray'
  | 'mw-65'
  | 'mw-120'
  | 'label-mr-3'
  | 'label-mb-half-rem'
  | 'control-inline-flex';

@Component({
  selector: 'app-select-control',
  templateUrl: './select-control.component.html',
  styleUrls: ['./select-control.component.scss'],
})
export class SelectControlComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label?: string;
  @Input() value?: number | string | string[];
  @Input() valueOptions: SelectListItem[];
  @Input() valueOptionsStream?: Observable<SelectListItem[]>;
  @Input() allowMultipleValues = false;
  @Input() hideControl = false;
  @Input() placeholder = '';
  @Input() isLoading = false;
  @Input() disabled = false;
  @Input() clearable = false;
  @Input() searchable = false;
  @Input() includeShadow = false;
  @Input() container = '';
  @Input() useNarrowArrow = false;
  @Input() isForPaginator = false;
  @Input() autoWidth = false;
  @Input() menuPosition: 'left' | 'right' = 'left';
  @Input() useTransparentBackground = false;
  @Input() isTableFilter = false;
  @Input() useDarkTheme = false;
  @Input() forceClear: Observable<void>;
  @Input() set labelSide(labelSide: LabelSide) {
    this.setLabelSide(labelSide);
  }
  @Input() set modifiers(modifiers: Modifier[]) {
    this.setModifiers(modifiers);
  }

  @Output() valueChange = new EventEmitter<any>();
  @Output() keyUpEnter = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();

  randomId: string;

  isLabelOnTop = false;
  isLabelOnLeft = false;

  classModifiers: string;
  valueOptions$?: Subscription;

  displayNumOfSelected?: string;
  forceClearSubscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.randomId = this.generateRandomIdForInputField();
    if (this.valueOptionsStream) {
      this.valueOptionsStream.subscribe(options => (this.valueOptions = options));
    }
    if (this.forceClear) {
      this.forceClearSubscription = this.forceClear.subscribe(() => this.onForceClear());
    }
  }

  ngOnDestroy() {
    if (this.valueOptions$) {
      this.valueOptions$.unsubscribe();
    }
    if (this.forceClearSubscription) {
      this.forceClearSubscription.unsubscribe();
    }
  }

  onItemAdded(newValue: SelectListItem) {
    if (!Array.isArray(this.value)) {
      return;
    }
    const isAllItemsSelected = newValue.value === '';
    if (isAllItemsSelected) {
      this.value = [''];
      this.updateNumOfSelected();
    } else {
      this.value = this.value.filter(item => item !== '');
      this.updateNumOfSelected();
    }
    this.valueChange.emit(this.value);
  }

  onItemRemoved() {
    if (Array.isArray(this.value)) {
      if (this.value.length <= 0) this.value = undefined;
      this.updateNumOfSelected();
    }
    this.valueChange.emit(this.value);
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.value) this.updateNumOfSelected();
  }

  private updateNumOfSelected() {
    if (!this.value || !Array.isArray(this.value)) {
      this.displayNumOfSelected = undefined;
      return;
    }
    this.displayNumOfSelected = this.value.length > 0 ? `${this.value.length}` : undefined;
  }

  private onForceClear() {
    this.value = undefined;
    this.updateNumOfSelected();
  }

  emitValueChange(newValue: SelectListItem): void {
    if (Array.isArray(this.value) && newValue === undefined) {
      this.displayNumOfSelected = undefined;
      this.value = undefined;
    }
    this.valueChange.emit(this.value);
  }

  private generateRandomIdForInputField(): string {
    return Math.trunc(Math.random() * 1000000).toString();
  }

  setLabelSide(side: LabelSide): void {
    this.isLabelOnTop = side === 'top';
    this.isLabelOnLeft = side === 'left';
  }

  setModifiers(modifiers: Modifier[]): void {
    this.classModifiers = Utils.getModifiedStringArrayAsString(modifiers, 'select-control--', 'prepend');
  }
}
