import gql from 'graphql-tag';

export const GetPharmacyMainQuery = gql`
  query GetPharmacyMain($ncpdp: String!) {
    pharmacy(ncpdp: $ncpdp) {
      name
      ncpdp
      npi
    }
  }
`;
