import { Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

export abstract class BaseStoreService {
  protected store: Record<string, any> = {};

  protected get<T>(storeName: string, observable: Observable<T>): Observable<T> {
    if (this.store[storeName]) {
      return of<T>(this.store[storeName]);
    }

    return observable.pipe(
      shareReplay(1),
      tap(result => (this.store[storeName] = result)),
    );
  }

  resetStore() {
    this.store = {};
  }
}
