import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe, KeyValuePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MATERIAL_SANITY_CHECKS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { AgreementContainerDirective } from '@app/root/components/agreements/agreement-container.directive';
import { AlmirallSeysaraTermsConditionsComponent } from '@app/root/components/agreements/almirall-seysara-terms-conditions/almirall-seysara-terms-conditions.component';
import { EagleCopayAssistanceProgramComponent } from '@app/root/components/agreements/eagle-copay-assistance-program/eagle-copay-assistance-program.component';
import { HorizonEcmonarchTermsConditionsComponent } from '@app/root/components/agreements/horizon-ecmonarch-terms-conditions/horizon-ecmonarch-terms-conditions.component';
import { HorizoncaresProgramGuidelinesComponent } from '@app/root/components/agreements/horizoncares-program-guidelines/horizoncares-program-guidelines.component';
import { UserAgreementComponent } from '@app/root/components/agreements/user-agreement/user-agreement.component';
import { SignOutComponent } from '@app/shared/components/sign-out/sign-out.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { AuthGuardDirective } from '@services/auth-guard.directive';
import { ButtonDatePickerComponent } from '@shared//components/chitin/molecules/button-date-picker/button-date-picker.component';
import { AddPharmacyFromNcpdpComponent } from '@shared/components/add-pharmacy-from-ncpdp/add-pharmacy-from-ncpdp.component';
import { AgreementsModalComponent } from '@shared/components/agreements-modal/agreements-modal.component';
import { AllPharmaciesSelectComponent } from '@shared/components/all-pharmacies-select/all-pharmacies-select.component';
import { BrightscripNeedHelpCardComponent } from '@shared/components/brightscrip-need-help-card/brightscrip-need-help-card.component';
import { BrightViewSaveViewComponent } from '@shared/components/brightview/save-view/brightview-save-view.component';
import { BrightViewGalleryComponent } from '@shared/components/brightview/view-gallery/data-view-gallery.component';
import { CardWithHeaderSeperatorAndShadowComponent } from '@shared/components/card-with-header-seperator-and-shadow/card-with-header-seperator-and-shadow.component';
import { ChartsBaseComponent } from '@shared/components/charts/base/charts-base.component';
import { ChitinButtonComponent } from '@shared/components/chitin/atoms/button/button.component';
import { ChitinCheckInputGroupComponent } from '@shared/components/chitin/atoms/check-input-group/check-input-group.component';
import { ChitinCheckboxComponent } from '@shared/components/chitin/atoms/checkbox/checkbox.component';
import { ChitinChipsInputComponent } from '@shared/components/chitin/atoms/chips-input/chips-input.component';
import { ChitinColorPickerComponent } from '@shared/components/chitin/atoms/color-picker/color-picker.component';
import { ChitinFormInputComponent } from '@shared/components/chitin/atoms/form-input/form-input.component';
import { ChitinIconComponent } from '@shared/components/chitin/atoms/icon/chitin-icon.component';
import { ChitinSelectOldComponent } from '@shared/components/chitin/atoms/select-old/select-old.component';
import { ChitinSelectComponent } from '@shared/components/chitin/atoms/select/select.component';
import { ChitinSpinnerComponent } from '@shared/components/chitin/atoms/spinner/chitin-spinner.component';
import { ChitinAccordionComponent } from '@shared/components/chitin/molecules/accordion/accordion.component';
import { ChitinDateRangePickerComponent } from '@shared/components/chitin/molecules/daterange-picker/daterange-picker.component';
import { ChitinRangeInputComponent } from '@shared/components/chitin/molecules/range-input/range-input.component';
import { CirclePercentComponent } from '@shared/components/circle-percent/circle-percent.component';
import { ClaimFlagsDetailComponent } from '@shared/components/claim-flags-detail/claim-flags-detail.component';
import { ClaimFlagsComponent } from '@shared/components/claim-flags/claim-flags.component';
import { CodeAreaComponent } from '@shared/components/code-area/code-area.component';
import { CodeDiffViewerComponent } from '@shared/components/code-diff-viewer/code-diff-viewer.component';
import { ConnectedCircleStepsComponent } from '@shared/components/connected-circle-steps/connected-circle-steps.component';
import { CopyToClipboardButtonComponent } from '@shared/components/copy-to-clipboard-button/copy-to-clipboard-button.component';
import { CardAsideDirective } from '@shared/components/dark-card-with-rounded-borders/card-aside/card-aside.directive';
import { CardHeaderDirective } from '@shared/components/dark-card-with-rounded-borders/card-header/card-header.directive';
import { CardSectionDirective } from '@shared/components/dark-card-with-rounded-borders/card-section/card-section.directive';
import { DarkCardWithRoundedBordersComponent } from '@shared/components/dark-card-with-rounded-borders/dark-card-with-rounded-borders.component';
import { DarkThemeBorderComponent } from '@shared/components/dark-theme-border/dark-theme-border.component';
import { DataGridComponent } from '@shared/components/data-grid/data-grid.component';
import { DataGridFilterComponent } from '@shared/components/data-grid/filter/data-grid-filter.component';
import { CursorPaginatorComponent } from '@shared/components/data-table/cursor-paginator/cursor-paginator.component';
import { DashboardPaginatorComponent } from '@shared/components/data-table/dashboard-paginator/dashboard-paginator.component';
import { InfiniscrollCursorPaginatorComponent } from '@shared/components/data-table/infiniscroll-cursor-paginator/infiniscroll-cursor-paginator.component';
import { InfiniscrollPaginatorComponent } from '@shared/components/data-table/infiniscroll-paginator/infiniscroll-paginator.component';
import { PaginatorComponent } from '@shared/components/data-table/paginator/paginator.component';
import { DataViewAddFilterComponent } from '@shared/components/data-view/add-filter/data-view-add-filter.component';
import { DataViewEditColumnsComponent } from '@shared/components/data-view/edit-columns/data-view-edit-columns.component';
import { DataViewFilterGalleryComponent } from '@shared/components/data-view/filter-gallery/data-view-filter-gallery.component';
import { DataMapFiltersComponent } from '@shared/components/data-view/filters/data-map-filters.component';
import { DataViewFiltersComponent } from '@shared/components/data-view/filters/data-view-filters.component';
import { DataViewGridComponent } from '@shared/components/data-view/grid/data-view-grid.component';
import { DataViewSaveViewComponent } from '@shared/components/data-view/save-view/data-view-save-view.component';
import { DataViewGalleryComponent } from '@shared/components/data-view/view-gallery/data-view-gallery.component';
import { DetailContainerComponent } from '@shared/components/detail-container/detail-container.component';
import { DownloadableResourceComponent } from '@shared/components/downloadable-resource/downloadable-resource.component';
import { DropdownToggleComponent } from '@shared/components/dropdown-toggle/dropdown-toggle.component';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';
import { ExportResultModalComponent } from '@shared/components/export-result-modal/export-result-modal.component';
import { FormFieldErrorsComponent } from '@shared/components/form-field-errors/form-field-errors.component';
import { FileValueAccessorDirective } from '@shared/components/forms/file-value-accessor.directive';
import { FormlyFieldButtonComponent } from '@shared/components/forms/formly-fields/formly-field-button.component';
import { FormlyFieldDateComponent } from '@shared/components/forms/formly-fields/formly-field-date.component';
import { FormlyFieldDaterangeComponent } from '@shared/components/forms/formly-fields/formly-field-daterange.component';
import { FormlyFieldErrorComponent } from '@shared/components/forms/formly-fields/formly-field-error.component';
import { FormlyFieldFileComponent } from '@shared/components/forms/formly-fields/formly-field-file.component';
import { FormlyFieldInputGroupComponent } from '@shared/components/forms/formly-fields/formly-field-input-group.component';
import { FormlyFieldInputComponent } from '@shared/components/forms/formly-fields/formly-field-input.component';
import { FormlyFieldPasswordComponent } from '@shared/components/forms/formly-fields/formly-field-password.component';
import { FormlyFieldPhoneComponent } from '@shared/components/forms/formly-fields/formly-field-phone.component';
import { FormlyFieldSelectComponent } from '@shared/components/forms/formly-fields/formly-field-select.component';
import { InputDatePickerComponent } from '@shared/components/forms/input-date-picker/input-date-picker.component';
import { InputDateRangePickerComponent } from '@shared/components/forms/input-date-range-picker/input-date-range-picker.component';
import { HorizontalScrollComponent } from '@shared/components/horizontal-scroll/horizontal-scroll.component';
import { HoverableIconComponent } from '@shared/components/hoverable-icon/hoverable-icon.component';
import { HowDidWeDoComponent } from '@shared/components/how-did-we-do/how-did-we-do.component';
import { IconWithTextComponent } from '@shared/components/icon-with-text/icon-with-text.component';
import { LoadingSpinnerComponent } from '@shared/components/loading-spinner/loading-spinner.component';
import { MaterialIconComponent } from '@shared/components/material-icon/material-icon.component';
import { MessageModalComponent } from '@shared/components/message-modal/message-modal.component';
import { MultiSelectWithSliderFilterComponent } from '@shared/components/multi-select-with-slider-filter/multi-select-with-slider-filter.component';
import { FooterComponent } from '@shared/components/new/footer/footer.component';
import { PageHeaderComponent } from '@shared/components/new/page-header/page-header.component';
import { PlaceholderComponent } from '@shared/components/new/placeholder/placeholder.component';
import { SidebarCtaComponent } from '@shared/components/new/sidebar-cta/sidebar-cta.component';
import { SidebarComponent } from '@shared/components/new/sidebar/sidebar.component';
import { TablePlaceholderComponent } from '@shared/components/new/table-placeholder/table-placeholder.component';
import { TopbarComponent } from '@shared/components/new/topbar/topbar.component';
import { PageContentComponent } from '@shared/components/page-content/page-content.component';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { PerfectScrollbarComponent, PerfectScrollbarDirective } from '@shared/components/perfect-scrollbar/perfect-scrollbar';
import { PharmacyNcpdpNamePillComponent } from '@shared/components/pharmacy-ncpdp-name-pill/pharmacy-ncpdp-name-pill.component';
import { PharmacySelectComponent } from '@shared/components/pharmacy-select/pharmacy-select.component';
import { PharmacyDotComponent } from '@shared/components/pharmacy/pharmacy-dot/pharmacy-dot.component';
import { PrescriberFormInputComponent } from '@shared/components/prescriber-form/form-input/form-input.component';
import { PrescriberFormComponent } from '@shared/components/prescriber-form/prescriber-form.component';
import { PrimaryTabsComponent } from '@shared/components/primary-tabs/primary-tabs.component';
import { ProductManufacturerSearchResultsComponent } from '@shared/components/product-manufacturer-search-results/product-manufacturer-search-results.component';
import { ProductManufacturerSearchComponent } from '@shared/components/product-manufacturer-search/product-manufacturer-search.component';
import { ProgramGroupListTableComponent } from '@shared/components/program-group-list-table/program-group-list-table.component';
import { ProgramSelectorComponent } from '@shared/components/program-selector/program-selector.component';
import { RadioButtonsComponent } from '@shared/components/radio-buttons/radio-buttons.component';
import { RetailMessageComponent } from '@shared/components/retail-message/retail-message.component';
import { ScrollbarComponent } from '@shared/components/scrollbar/scrollbar.component';
import { SearchDropdownComponent } from '@shared/components/search-dropdown/search-dropdown.component';
import { SearchWithSuggestionsComponent } from '@shared/components/search-with-suggestions/search-with-suggestions.component';
import { SecondaryTabsComponent } from '@shared/components/secondary-tabs/secondary-tabs.component';
import { SelectControlComponent } from '@shared/components/select-control/select-control.component';
import { SemiTransparentCardWithShadowComponent } from '@shared/components/semi-transparent-card-with-shadow/semi-transparent-card-with-shadow.component';
import { SidepanelComponent } from '@shared/components/sidepanel/sidepanel.component';
import { SignaturePadComponent } from '@shared/components/signature-pad/signature-pad.component';
import { SliderComponent } from '@shared/components/slider/slider.component';
import { SquareCheckmarkComponent } from '@shared/components/square-checkmark/square-checkmark.component';
import { SquareRadioButtonComponent } from '@shared/components/square-radio-button/square-radio-button.component';
import { StatusIconComponent } from '@shared/components/status-icon/status-icon.component';
import { TabMenuComponent } from '@shared/components/tab-menu/tab-menu.component';
import { TableSortColumnComponent } from '@shared/components/table-sort/table-sort-column.component';
import { TableSortDirective } from '@shared/components/table-sort/table-sort.directive';
import { TableComponent } from '@shared/components/table/table.component';
import { TabContentDirective } from '@shared/components/tabs/tab-content.directive';
import { TabDirective } from '@shared/components/tabs/tab.directive';
import { TabsComponent } from '@shared/components/tabs/tabs/tabs.component';
import { TenantLogoComponent } from '@shared/components/tenant-logo/tenant-logo.component';
import { TextControlComponent } from '@shared/components/text-control/text-control.component';
import { TimelineComponent } from '@shared/components/timeline/timeline.component';
import { ToggleCardComponent } from '@shared/components/toggle-card/toggle-card.component';
import { ToggleSwitchComponent } from '@shared/components/toggle-switch/toggle-switch.component';
import { TooltipComponent } from '@shared/components/tooltip/tooltip.component';
import { TopPageDatepickerComponent } from '@shared/components/top-page-datepicker/top-page-datepicker.component';
import { UpDownArrowComponent } from '@shared/components/up-down-arrow/up-down-arrow.component';
import { UtilityBtnComponent } from '@shared/components/utility-btn/utility-btn.component';
import { VariableSliderComponent } from '@shared/components/variable-slider/variable-slider.component';
import { VerifyAccountInfoOldComponent } from '@shared/components/verify-account-info-old/verify-account-info.component';
import { VerifyAccountInfoComponent } from '@shared/components/verify-account-info/verify-account-info.component';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { MaskDirective } from '@shared/directives/mask.directive';
import { TypedTemplateDirective } from '@shared/directives/typed-template.directive';
import { EmptyPipe } from '@shared/pipes/empty.pipe';
import { GridFieldPipe } from '@shared/pipes/grid-field.pipe';
import { GroupByPipe } from '@shared/pipes/group-by.pipe';
import { HighlightCodePipe } from '@shared/pipes/highlight-code.pipe';
import { JoinPipe } from '@shared/pipes/join.pipe';
import { MaskifyPipe } from '@shared/pipes/maskify.pipe';
import { OrdinalPipe } from '@shared/pipes/ordinal.pipe';
import { PageTotalPipe } from '@shared/pipes/page-total.pipe';
import { PhoneWithSpacesPipe } from '@shared/pipes/phone-with-spaces.pipe';
import { PhonePipe } from '@shared/pipes/phone.pipe';
import { SafePipe } from '@shared/pipes/safe.pipe';
import { StatusLabelPipe } from '@shared/pipes/status-label.pipe';
import { TimeDurationPipe } from '@shared/pipes/time-duration.pipe';
import { TrendIconPipe } from '@shared/pipes/trend-icon.pipe';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { UserRolePipe } from '@shared/pipes/user-role.pipe';
import { FormFieldService } from '@shared/providers/form-field-service';
import { GraphqlClient } from '@shared/providers/graphql-client';
import { OpenSearchClient } from '@shared/providers/opensearch-client';
import { OpenSearchService } from '@shared/providers/opensearch.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { NgxPaginationModule } from 'ngx-pagination';

const agreements = [
  UserAgreementComponent,
  HorizoncaresProgramGuidelinesComponent,
  HorizonEcmonarchTermsConditionsComponent,
  EagleCopayAssistanceProgramComponent,
  AlmirallSeysaraTermsConditionsComponent,
];

const declarations = [
  AuthGuardDirective,
  PerfectScrollbarComponent,
  PerfectScrollbarDirective,
  PageContentComponent,
  PhonePipe,
  PhoneWithSpacesPipe,
  GroupByPipe,
  PageTotalPipe,
  MaskifyPipe,
  ToggleSwitchComponent,
  TabsComponent,
  TabDirective,
  TabContentDirective,
  TableComponent,
  IconWithTextComponent,
  DetailContainerComponent,
  ProductManufacturerSearchComponent,
  ProductManufacturerSearchResultsComponent,
  SignOutComponent,
  SearchWithSuggestionsComponent,
  HowDidWeDoComponent,
  VariableSliderComponent,
  VerifyAccountInfoComponent,
  VerifyAccountInfoOldComponent,
  PharmacySelectComponent,
  TooltipComponent,
  PageContentComponent,
  AllPharmaciesSelectComponent,
  ConnectedCircleStepsComponent,
  AgreementsModalComponent,
  MessageModalComponent,
  UserAgreementComponent,
  HorizoncaresProgramGuidelinesComponent,
  HorizonEcmonarchTermsConditionsComponent,
  EagleCopayAssistanceProgramComponent,
  AgreementContainerDirective,
  AlmirallSeysaraTermsConditionsComponent,
  SearchDropdownComponent,
  ProgramGroupListTableComponent,
  SquareCheckmarkComponent,
  DropdownComponent,
  ProgramSelectorComponent,
  SquareRadioButtonComponent,
  FormFieldErrorsComponent,
  LoadingSpinnerComponent,
  TextControlComponent,
  SelectControlComponent,
  PharmacyNcpdpNamePillComponent,
  AddPharmacyFromNcpdpComponent,
  StatusIconComponent,
  DarkCardWithRoundedBordersComponent,
  CardSectionDirective,
  RadioButtonsComponent,
  CardAsideDirective,
  CardHeaderDirective,
  ClickOutsideDirective,
  SemiTransparentCardWithShadowComponent,
  CardWithHeaderSeperatorAndShadowComponent,
  DownloadableResourceComponent,
  FooterComponent,
  MultiSelectWithSliderFilterComponent,
  ScrollbarComponent,
  UpDownArrowComponent,
  DropdownToggleComponent,
  PaginatorComponent,
  CursorPaginatorComponent,
  InfiniscrollPaginatorComponent,
  DashboardPaginatorComponent,
  InfiniscrollCursorPaginatorComponent,
  SidepanelComponent,
  UtilityBtnComponent,
  MaterialIconComponent,
  TimelineComponent,
  CirclePercentComponent,
  TimeDurationPipe,
  HoverableIconComponent,
  SecondaryTabsComponent,
  PrimaryTabsComponent,
  DarkThemeBorderComponent,
  TopPageDatepickerComponent,
  SecondaryTabsComponent,
  TabMenuComponent,
  TableSortDirective,
  TableSortColumnComponent,
  TruncatePipe,
  InputDatePickerComponent,
  InputDateRangePickerComponent,
  FormlyFieldPhoneComponent,
  FormlyFieldPasswordComponent,
  FormlyFieldSelectComponent,
  FormlyFieldInputComponent,
  FormlyFieldDateComponent,
  FormlyFieldDaterangeComponent,
  FormlyFieldButtonComponent,
  FormlyFieldInputGroupComponent,
  FormlyFieldErrorComponent,
  FormlyFieldFileComponent,
  FileValueAccessorDirective,
  BrightscripNeedHelpCardComponent,
  RetailMessageComponent,
  GridFieldPipe,
  CodeAreaComponent,
  DataGridComponent,
  DataGridFilterComponent,
  TypedTemplateDirective,
  SidebarComponent,
  SidebarCtaComponent,
  PlaceholderComponent,
  FooterComponent,
  TopbarComponent,
  PageHeaderComponent,
  TablePlaceholderComponent,
  PrescriberFormComponent,
  PrescriberFormInputComponent,
  JoinPipe,
  UserRolePipe,
  ChartsBaseComponent,
  PharmacyDotComponent,
  SliderComponent,
  DataViewGridComponent,
  DataViewFiltersComponent,
  DataMapFiltersComponent,
  DataViewFilterGalleryComponent,
  DataViewAddFilterComponent,
  StatusLabelPipe,
  EmptyPipe,
  DataViewEditColumnsComponent,
  DataViewSaveViewComponent,
  DataViewGalleryComponent,
  TrendIconPipe,
  ChitinChipsInputComponent,
  SafePipe,
  ChitinColorPickerComponent,
  TenantLogoComponent,
  PageNotFoundComponent,
  CodeDiffViewerComponent,
  ExportResultModalComponent,
  MaskDirective,
  OrdinalPipe,
  ClaimFlagsComponent,
  ClaimFlagsDetailComponent,
  HighlightCodePipe,
  BrightViewSaveViewComponent,
  BrightViewGalleryComponent,
  CopyToClipboardButtonComponent,
];

const chitinDeclarations = [
  ChitinButtonComponent,
  ChitinCheckboxComponent,
  ChitinFormInputComponent,
  ChitinCheckInputGroupComponent,
  ChitinSelectOldComponent,
  ChitinSelectComponent,
  ChitinSpinnerComponent,
  ChitinIconComponent,
  ChitinDateRangePickerComponent,
  ButtonDatePickerComponent,
  ChitinRangeInputComponent,
  ChitinAccordionComponent,
];

const imports = [
  CommonModule,
  FormsModule,
  NgbModule,
  NgSelectModule,
  RouterModule,
  NgxPaginationModule,
  FormlyModule,
  MonacoEditorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  DragDropModule,
  CdkDropList,
  CdkDrag,
  ColorPickerModule,
  NgxMaskDirective,
  NgxMaskPipe,
  SignaturePadComponent,
  HorizontalScrollComponent,
  ToggleCardComponent,
];

@NgModule({
  declarations: [...declarations, ...chitinDeclarations],
  imports: [
    ...imports,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
  ],
  exports: [...imports, ...declarations, ...chitinDeclarations, DatePipe, ReactiveFormsModule],
  providers: [
    provideNgxMask(),
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: false,
    },
    GraphqlClient,
    NgbActiveModal,
    FormFieldService,
    OpenSearchClient,
    OpenSearchService,
    DatePipe,
    StatusLabelPipe,
    KeyValuePipe,
    HighlightCodePipe,
  ],
})
export class SharedModule {}
