import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({ selector: 'ng-template[typed]' })
export class TypedTemplateDirective<Type> {
  @Input() typed: Type;

  constructor(private ref: TemplateRef<Type>) {}

  static ngTemplateContextGuard<Type>(dir: TypedTemplateDirective<Type>, ctx: unknown): ctx is Type {
    return true;
  }
}
