import { get } from 'lodash';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';

export type StorageType = 'memory' | 'session';

export abstract class Store<T> {
  protected static storageType: StorageType = 'memory';
  protected storage = localStorage;
  protected store: Record<string, T> = {};
  protected loaders: Record<string, Observable<T>> = {};
  public readonly state$ = new Subject<[string, any]>();
  protected abstract getDataSource(key: string): Observable<T>;

  constructor() {
    this.loadStorage();
  }

  select(key: string): Observable<T> {
    return this.state$.pipe(
      filter(([itemKey, value]) => key === itemKey),
      map(([itemKey, value]) => value),
    );
  }

  get(key: string): Observable<T> {
    const source = this.getDataSource(key);
    const value = this.store[key];
    if (value) return of<T>(value);
    if (this.loaders[key]) return this.loaders[key];
    this.loaders[key] = source.pipe(
      shareReplay(1),
      tap(result => this.set(key, result)),
    );
    return this.loaders[key];
  }

  set(key: string, value: T) {
    this.store[key] = value;
    this.state$.next([key, this.store[key]]);
    this.syncStorage();
  }

  update(key: string, updateFn: (value: T) => T) {
    this.store[key] = updateFn(this.store[key]);
    this.state$.next([key, this.store[key]]);
    this.syncStorage();
  }

  remove(key: string) {
    delete this.store[key];
    this.state$.next([key, undefined]);
    this.syncStorage();
  }

  reset() {
    this.store = {};
    this.syncStorage();
  }

  keys() {
    return Object.keys(this.store);
  }

  refresh() {
    const sources$ = this.keys().map(key => {
      const source = this.getDataSource(key);
      return source.pipe(tap(result => this.set(key, result)));
    });
    return forkJoin(sources$);
  }

  protected getStorageType(): StorageType {
    return get(this.constructor, 'storageType') ?? 'memory';
  }

  protected getNamespace(): string {
    return this.constructor.name;
  }

  protected loadStorage() {
    const item: Record<string, T> | null = JSON.parse(this.storage.getItem(this.getNamespace()) ?? 'null');
    if (item) this.store = item;
  }

  protected syncStorage() {
    if (this.getStorageType() === 'session') {
      this.storage.setItem(this.getNamespace(), JSON.stringify(this.store));
    }
  }
}
