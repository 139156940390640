<div class="search-dropdown" (click)="toggleExpand($event)">
  <div>
    <span class="search-dropdown__label js-search-dropdown-label">{{ selectedItem?.name }} </span>
    <div *ngIf="!isExpanded" class="search-dropdown__arrow js-search-dropdown-caret"></div>
    <div *ngIf="isExpanded" class="search-dropdown__arrow search-dropdown__arrow--expanded js-search-dropdown-caret"></div>
  </div>
  <div *ngIf="isExpanded" class="search-dropdown__contents" clickOutside (clickOutside)="closePopoverOnOutsideClick($event)">
    <div>
      <div class="row search-dropdown__search-panel">
        <input
          required
          type="text"
          [(ngModel)]="valueToSearch"
          name="txtValueToSearch"
          class="form-control search-dropdown__input"
          [attr.placeholder]="placeholder"
        />
        <p class="w-100">
          <button (click)="searchValue()" class="button button--primary" [attr.disabled]="searching ? 'disabled' : null">
            {{ searching ? 'SEARCHING...' : 'SEARCH' }}
          </button>
          <a *ngIf="!searching" (click)="reset()" class="search-dropdown__reset-link">
            <span>RESET</span>
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
