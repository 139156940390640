import Utils from '@shared/providers/utils';

export interface PrescriberFormModelEntry<T> {
  value?: T;
  valueCustom?: T;
  error?: string;
  optional?: boolean;
  validator?: (value: string, model: PrescriberFormModel) => boolean;
}

export const PrescriberFormFields = {
  pharmacy_name: {},
  pharmacy_address: {},
  pharmacy_city: {},
  pharmacy_state: {},
  pharmacy_zip: {},

  patient_name: {},
  patient_dob: {},

  prescriber_name: {},
  prescriber_npi: {},
  prescriber_practice_name: {},
  prescriber_address: {},
  prescriber_city: {},
  prescriber_state: {},
  prescriber_zip: {},
  prescriber_contact_name: { optional: true },
  prescriber_phone: { optional: true, validator: (value: string) => Utils.isPhoneNumberValid(value) },
  prescriber_fax: { optional: true, validator: (value: string) => Utils.isPhoneNumberValid(value) },
  prescriber_best_time_to_call: { optional: true },
  icd10: {},
  declaration: { optional: true },
  l20_declaration: { optional: true },
  l80_declaration: { optional: true },
  pa_denial_reason: { value: '' },
  pa_denial_date: {},
  signature_date: { optional: true },
  signature_base64: {},
};

export type PrescriberFormModel = Record<keyof typeof PrescriberFormFields, PrescriberFormModelEntry<string>>;
