import { Injectable } from '@angular/core';
import {
  DataSourceFilled,
  DataSourceType,
  DataTransformationParameterSelectionStrategy,
  DataTransformationParameterSelectionStrategyType,
  DataTransformationReturn,
  DataTransformationStrategy,
  DataTransformationStrategyType,
} from '@shared/providers/data-transformation/data-transformation.model';
import { NextEligibleDateWithReadyTextAndIconTableValuesStrategy } from '@shared/providers/data-transformation/next-eligible-date-with-ready-text-and-icon-table-values-strategy';
import { ParameterSelectionStrategies } from '@shared/providers/data-transformation/parameter-selection-strategies';
import { PassThroughStrategy } from '@shared/providers/data-transformation/pass-through-strategy';
import { PassThroughTableValueStrategy } from '@shared/providers/data-transformation/pass-through-table-value-strategy';
import { RequestCodesButtonWithDisableTableValueStrategy } from '@shared/providers/data-transformation/request-codes-button-with-disable-table-value-strategy';
import { StringArrayToCommaSeparatedStringStrategy } from '@shared/providers/data-transformation/string-array-to-comma-separated-string-strategy';
import { StringArrayToCommaSeparatedStringTableValueStrategy } from '@shared/providers/data-transformation/string-array-to-comma-separated-string-table-value-strategy';
import Utils from '@shared/providers/utils';

@Injectable({
  providedIn: 'root',
})
export class DataTransformationService {
  private transformations = new Map([
    [DataTransformationStrategyType.PassThrough, this.passThroughStrategy],
    [DataTransformationStrategyType.PassThroughTableValue, this.passThroughTableValue],
    [DataTransformationStrategyType.StringArrayToCommaSeparatedString, this.stringArrayToCommaSeparatedStringStrategy],
    [DataTransformationStrategyType.StringArrayToCommaSeparatedStringTableValue, this.stringArrayToCommaSeparatedStringTableValueStrategy],
    [
      DataTransformationStrategyType.NextEligibleDateWithReadyTextAndIconTableValues,
      this.nextEligibleDateWithReadyTextAndIconTableValuesStrategy,
    ],
    [DataTransformationStrategyType.RequestCodesButtonWithDisableTableValue, this.requestCodesButtonWithDisableTableValueStrategy],
  ]) as Map<DataTransformationStrategyType, DataTransformationStrategy>;

  private parameterSelections = new Map([
    [DataTransformationParameterSelectionStrategyType.PassThrough, this.parameterSelectionStrategies.passThroughStrategy],
    [DataTransformationParameterSelectionStrategyType.ProductNames, this.parameterSelectionStrategies.productNamesStrategy],
    [
      DataTransformationParameterSelectionStrategyType.FirstArrayElementHowSupplied,
      this.parameterSelectionStrategies.firstElementHowSuppliedStrategy,
    ],
    [DataTransformationParameterSelectionStrategyType.FirstArrayElementNdc, this.parameterSelectionStrategies.firstElementNdcStrategy],
    [DataTransformationParameterSelectionStrategyType.TenantName, this.parameterSelectionStrategies.tenantNameStrategy],
  ]) as Map<DataTransformationParameterSelectionStrategyType, DataTransformationParameterSelectionStrategy>;

  constructor(
    private parameterSelectionStrategies: ParameterSelectionStrategies,
    private passThroughStrategy: PassThroughStrategy,
    private stringArrayToCommaSeparatedStringStrategy: StringArrayToCommaSeparatedStringStrategy,
    private passThroughTableValue: PassThroughTableValueStrategy,
    private stringArrayToCommaSeparatedStringTableValueStrategy: StringArrayToCommaSeparatedStringTableValueStrategy,
    private nextEligibleDateWithReadyTextAndIconTableValuesStrategy: NextEligibleDateWithReadyTextAndIconTableValuesStrategy,
    private requestCodesButtonWithDisableTableValueStrategy: RequestCodesButtonWithDisableTableValueStrategy,
  ) {}

  transform(transformationStrategyType: DataTransformationStrategyType, dataSources: DataSourceFilled[]): DataTransformationReturn {
    const transformationStrategy = this.transformations.get(transformationStrategyType);
    if (transformationStrategy) {
      const parameters = this.getParametersFromDataSources(dataSources);
      return transformationStrategy.transform(parameters);
    }
    return { value: null };
  }

  private getParametersFromDataSources(dataSources: DataSourceFilled[]): Record<DataSourceType, any> {
    const parameters = {} as Record<DataSourceType, any>;

    if (Utils.isNonEmptyArray(dataSources)) {
      dataSources.forEach(dataSource => {
        if (dataSource.parameterSelectionStrategyType) {
          const strategyType = this.getDataSourceStrategyType(dataSource);
          const parameterSelectionStrategy = this.parameterSelections.get(strategyType);

          if (parameterSelectionStrategy) {
            parameters[dataSource.type] = parameterSelectionStrategy(dataSource.data);
          }
        }
      });
    }

    return parameters;
  }

  private getDataSourceStrategyType(dataSource: DataSourceFilled): DataTransformationParameterSelectionStrategyType {
    return dataSource.parameterSelectionStrategyType;
  }
}
