<ul class="tabs" [ngClass]="appearance + '-tabs'">
  <li
    *ngFor="let tab of tabs; let i = index"
    class="tabs__tab"
    [class.tabs__tab--active]="i === selectedIndex"
    [class.tabs__tab--disabled]="tab.disabled"
    (click)="changeTab(i)"
  >
    <img *ngIf="tab.iconPath" class="tabs__icon" [class.tabs__icon--disabled]="tab.disabled" [src]="tab.iconPath" [alt]="tab.description" />

    <div class="tabs__text">
      <p class="tabs__value" [class.tabs__value--active]="i === selectedIndex" [class.tabs__value--disabled]="tab.disabled">
        {{ tab.value }}
      </p>

      <p *ngIf="tab.description" class="tabs__description" [class.tabs__description--disabled]="tab.disabled">{{ tab.description }}</p>
    </div>
  </li>
</ul>
