import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { IUser } from '@shared/models/user.model';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-verify-account-info-old',
  templateUrl: './verify-account-info.component.html',
  styleUrls: ['./verify-account-info.component.scss'],
})
export class VerifyAccountInfoOldComponent implements OnInit {
  @Output() verify = new EventEmitter<IUser>();

  currentUser: IUser;

  isFirstNameFieldDisabled = true;
  isLastNameFieldDisabled = true;

  isLoading = false;

  errorMessage: string;

  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.setUserFromSavedUser();
  }

  private setUserFromSavedUser(): void {
    this.currentUser = this.authService.getCurrentUser() ?? ({} as any);
  }

  closePopup(): void {
    this.activeModal.dismiss();
  }

  toggleFirstNameFieldDisable(): void {
    this.isFirstNameFieldDisabled = !this.isFirstNameFieldDisabled;
  }

  toggleLastNameFieldDisable(): void {
    this.isLastNameFieldDisabled = !this.isLastNameFieldDisabled;
  }

  confirm(): void {
    this.resetErrorMessage();
    this.updateUsersDateVerified();
    this.saveUser();
    this.disableAllFields();
  }

  private resetErrorMessage(): void {
    this.errorMessage = '';
  }

  private updateUsersDateVerified(): void {
    if (this.currentUser) {
      this.currentUser.date_verified = moment(new Date()).toISOString();
    }
  }

  private saveUser(): void {
    this.isLoading = true;
    if (!this.currentUser) {
      return;
    }
    this.userService
      .updateCurrentUser(this.currentUser)
      .pipe(
        finalize(() => (this.isLoading = false)),
        catchError(error => {
          this.errorMessage = 'Unable to save user';
          return of(error);
        }),
      )
      .subscribe(result => {
        if (result && !result.error) {
          this.authService.saveUser(this.currentUser!);
          this.verify.emit(this.currentUser);
          this.closePopup();
        }
      });
  }

  private disableAllFields(): void {
    this.isFirstNameFieldDisabled = true;
    this.isLastNameFieldDisabled = true;
  }
}
