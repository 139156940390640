<div class="vh-100 overflow-auto">
  <div class="offcanvas-header gap-2">
    <div class="col">
      @if (header) {
        <div [innerHTML]="header"></div>
      }
      <h3 id="offcanvasRightLabel"><span>Add filter</span></h3>
    </div>
    <button
      (click)="activeOffcanvas.dismiss()"
      type="button"
      class="btn-close text-reset"
      data-cy="closeBtn"
      aria-label="Close"
      style="align-self: start"
    ></button>
  </div>
  <div class="offcanvas-body pt-1">
    @if (filtersCount > 10) {
      <div class="card" style="margin-bottom: var(--bs5-offcanvas-padding-y)">
        <chitin-form-input #filterSearch placeholder="Search filters..." [(value)]="searchQuery" />
      </div>
    }
    @if (filtersCount === 0) {
      <div class="alert alert-warning">No filters available</div>
    }
    <div *ngFor="let fcat of filterCategories">
      <ng-container *ngIf="filters.hasOwnProperty(fcat.id) && filters[fcat.id].length > 0">
        <h5>{{ fcat.name }}</h5>
        <p>{{ fcat.description }}</p>
        <div class="card overflow-hidden">
          <div class="list-group d-flex flex-row flex-wrap">
            <ng-container *ngFor="let f of filters[fcat.id]">
              <a
                (click)="onFilterSelected(f)"
                class="p-3 list-group-item-action listGroupFilter d-flex justify-content-between"
                data-cy="filterSelect"
                *ngIf="!searchQuery || f.display.toLowerCase().includes(searchQuery.toLowerCase())"
                placement="top-left"
                container="body"
              >
                <div>{{ f.display }}</div>
                <div *ngIf="f.disabled" class="text-success ms-4 text-nowrap">
                  <chitin-icon icon="fa-solid fa-check" />
                  <span class="ms-2">Added</span>
                </div>
              </a>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
