import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { DateService } from '@services/date.service';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'app-input-date-range-picker',
  templateUrl: './input-date-range-picker.component.html',
  styleUrls: ['./input-date-range-picker.component.scss'],
})
export class InputDateRangePickerComponent implements OnChanges {
  readonly SEPARATOR = ',';
  @Input() value = '';
  @Output() valueChange = new EventEmitter<string>();
  @Output() confirmed = new EventEmitter<string>();
  @Output() closed = new EventEmitter<string>();
  @Input() disabled = false;
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  @Input() size?: 'sm' | 'lg';
  @Input() container: 'body' | null = null;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  constructor(
    private calendar: NgbCalendar,
    private dateService: DateService,
  ) {}

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.value?.currentValue) {
      const values = this.value.split(this.SEPARATOR);
      this.fromDate = NgbDate.from(this.dateService.parseNgbDate(values[0]) as NgbDateStruct);
      this.toDate = NgbDate.from(this.dateService.parseNgbDate(values[1]) as NgbDateStruct);
    }
  }

  formatValue() {
    let value = '';
    if (this.fromDate) {
      value += this.dateService.formatNgbDate(this.fromDate);
    }
    if (this.toDate) {
      value += `${this.SEPARATOR}${this.dateService.formatNgbDate(this.toDate)}`;
    }
    this.value = value;
    this.valueChange.next(this.value);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.formatValue();
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.formatValue();
  }

  ok() {
    this.confirmed.next(this.value);
  }

  close() {
    this.closed.next(this.value);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate!) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate!) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }
}
