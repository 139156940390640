import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, Output, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDateStruct, NgbInputDatepicker, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateService } from '@services/date.service';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'app-input-date-picker',
  templateUrl: './input-date-picker.component.html',
  styleUrls: ['./input-date-picker.component.scss'],
})
export class InputDatePickerComponent implements OnChanges {
  @Input() value = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() disabled = false;
  @Input() outsideDays: NgbInputDatepicker['outsideDays'] = 'collapsed';
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  @Input() showReset = true;
  @Input() showToday = true;
  @Input() timepicker = false;
  @Input() readonly = false;
  @Input() format = 'mediumDate';
  @Input() placeholder = '';
  @ViewChild('dp') datepicker: NgbInputDatepicker;
  date: NgbDateStruct | null;
  time: NgbTimeStruct | null = { hour: 0, minute: 0, second: 0 };
  textValue = '';

  constructor(
    private calendar: NgbCalendar,
    private dateService: DateService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.value) {
      this.formatDateTime();
      this.formatTextValue();
    }
  }

  formatValue() {
    let value = this.date ? this.dateService.ngbDateToMomentDate(this.date).format('YYYY-MM-DD') : '';
    if (value && this.timepicker) {
      value += `T${this.dateService.formatNgbTime(this.time)}`;
    }
    this.value = value;
    this.valueChange.next(this.value);
    this.formatTextValue();
  }

  formatDateTime() {
    this.date = this.dateService.parseNgbDate(this.value);
    this.time = this.dateService.parseNgbTime(this.value);
  }

  dateSelect() {
    this.formatValue();
  }

  dateInputChange(value: string) {
    if (this.dateService.isDateValid(value)) {
      this.value = value;
      this.valueChange.emit(this.value);
      this.formatDateTime();
    } else {
      this.reset();
    }
  }

  timeSelect() {
    this.formatValue();
  }

  reset() {
    this.datepicker?.close();
    this.date = null;
    this.formatValue();
  }

  setToday() {
    this.datepicker?.close();
    this.date = this.calendar.getToday();
    this.formatValue();
  }

  private formatTextValue() {
    this.textValue = new DatePipe(this.locale).transform(this.value, this.format) ?? '';
  }
}
