import gql from 'graphql-tag';

export const GetPharmacyChainDownloadAttachmentUrlQuery = gql`
  query GetPharmacyChainDownloadAttachmentUrl($npi: String!, $attachmentName: String!) {
    pharmacyChain(npi: $npi) {
      authorizationAttachmentUrls(forDownload: true, attachmentName: $attachmentName) {
        name
        url
      }
    }
  }
`;
