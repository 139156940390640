<div class="tabs">
  <div class="tabs__tab-headers-container">
    <div
      *ngFor="let tab of tabs; index as i"
      class="tabs__tab-header"
      [@activeInactive]="isActiveTab(i) ? 'active' : 'inactive'"
      (click)="setTab(i)"
    >
      {{ tab.title }}
    </div>
  </div>

  <div class="tabs__tab-content-container">
    <div
      *ngFor="let tab of tabs; index as i"
      class="tabs__tab-content"
      [style.flexDirection]="tab.flexDirection"
      [@showHide]="isActiveTab(i) ? 'show' : 'hide'"
    >
      <ng-container *ngIf="tab.content?.templateRef">
        <ng-template [ngTemplateOutlet]="tab.content.templateRef"> </ng-template>
      </ng-container>
    </div>
  </div>
</div>
