import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FormFieldService {
  calculateError(errors?: ValidationErrors): string {
    if (!errors) {
      return '';
    }

    let errorMessage = '';

    if (errors.min) {
      errorMessage += `Minimum value is ${errors.min.min}. `;
    }
    if (errors.max) {
      errorMessage += `Maximum value is ${errors.max.max}. `;
    }
    if (errors.required) {
      errorMessage += 'Field is required. ';
    }
    if (errors.email) {
      errorMessage += 'Value is not valid email. ';
    }
    if (errors.minLength) {
      errorMessage += `Minimum length of value is ${errors.minLength.requiredLength}. `;
    }
    if (errors.maxLength) {
      errorMessage += `Maximum length of value is ${errors.maxLength.requiredLength}. `;
    }
    if (errors.number) {
      errorMessage += 'Value is not valid number. ';
    }

    if (!errorMessage.length) {
      errorMessage += 'Value is bad. ';
    }

    return errorMessage;
  }
}
