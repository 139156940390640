<div class="d-flex justify-content-between align-items-center py-1 px-3 paginatorWrapper">
  <ng-template [ngTemplateOutlet]="detailTemplate ?? emptyTemplate" />
  <button
    class="btn btn-primary d-flex justify-content-center align-items-center load-more-btn"
    data-cy="loadMoreBtn"
    [ngClass]="{ disabled: loading }"
    (click)="next()"
    *ngIf="loading || !isNextDisabled"
  >
    <span *ngIf="!loading">Load more</span>
    <div *ngIf="loading" class="spinner-border spinner-border-sm text-white mx-4" data-cy="loadBtn" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </button>
  <div *ngIf="!loading && length > 0" class="resultsInfo text-end" data-cy="paginator">
    Showing {{ Math.min((pageIndex + 1) * pageSize, length) }} out of {{ length }} items.
  </div>
</div>

<ng-template #emptyTemplate>
  <div></div>
</ng-template>
