import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusLabel',
})
export class StatusLabelPipe implements PipeTransform {
  transform(value: string, statusLabelEnum: Record<string, string>): string {
    let result = value;
    if (value && value in statusLabelEnum) {
      result = statusLabelEnum[value as keyof typeof statusLabelEnum];
    }

    return result;
  }
}
