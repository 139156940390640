<nav class="navbar navbar-expand navbar-light navbar-bg w-100 py-1 px-2">
  <a class="sidebar-toggle" (click)="sidebarClose.emit()">
    <i class="hamburger align-self-center"></i>
  </a>

  <div class="navbar-collapse collapse">
    <div class="btn-group" *ngIf="false">
      <button class="btn btn-light" (click)="setTheme('light')">Light</button>
      <button class="btn btn-primary" (click)="setTheme('dark')">Dark</button>
    </div>

    <a class="navbar-brand topbarLogo p-0" [ngClass]="{ visible: topbarExpanded }" [routerLink]="homeLink?.path">
      <img src="/assets/logo.png" height="30" class="d-inline-block align-top ms-2" alt="brightscrip logo" />
    </a>

    <ul class="navbar-nav ms-auto align-items-center">
      <li
        class="nav-item dropdown me-2"
        container="body"
        display="dynamic"
        ngbDropdown
        placement="bottom-end"
        *ngIf="tenants.length > 0 && !tenantsDisabled"
      >
        <a class="nav-link dropdown-toggle" id="navbarDropdown2" ngbDropdownToggle role="button" tabindex="0" data-cy="tenantSelect">
          <span class="text-dark ng-star-inserted">{{ tenantsDisabled ? 'Global' : currentTenant?.name }}</span>
        </a>
        <div aria-labelledby="navbarDropdown2" class="dropdown-menu" ngbDropdownMenu>
          <a
            *ngFor="let tenant of tenants"
            class="dropdown-item"
            data-cy="tenant"
            [ngClass]="{ active: tenant.id === currentTenant?.id, disabled: tenantsDisabled }"
            (click)="tenantChange.emit(tenant)"
          >
            <span class="align-middle">{{ tenant.name }}</span>
          </a>
        </div>
      </li>
      <li class="nav-item dropdown" container="body" display="dynamic" ngbDropdown placement="bottom-end">
        <a class="nav-link dropdown-toggle p-0" id="navbarDropdown3" ngbDropdownToggle role="button" tabindex="0" data-cy="avatarDropdown">
          @if (currentUser?.avatar) {
            <img
              class="avatar"
              src="/assets/icons/{{ currentUser?.avatar }}-avatar.png"
              data-cy="avatarImg"
              style="height: 30px; width: 30px"
            />
          } @else {
            <i *ngIf="!currentUser?.avatar" class="fas fa-circle-user avatar" style="font-size: 30px; vertical-align: middle"></i>
          }
          <span class="text-dark ps-1 d-none d-md-inline">
            @if (currentUser) {
              {{ currentUser.first_name }} {{ currentUser.last_name }}
            } @else {
              My account
            }
          </span>
          <small *ngIf="currentUserWarning"><i class="text-danger fa-solid fa-circle-exclamation notificationDot"></i></small>
        </a>
        <div aria-labelledby="navbarDropdown3" class="dropdown-menu" ngbDropdownMenu>
          <!--          <a class="dropdown-item" ngbDropdownItem (click)="profileClick()">My Profile</a>-->
          <!--          <div class="dropdown-divider"></div>-->
          <a
            *ngFor="let link of userLinks"
            class="dropdown-item"
            data-cy="dropdownItem"
            (click)="link.action && link.action()"
            ngbDropdownItem
            >{{ link.label }}<i *ngIf="link.messageIcon" class="ms-2 {{ link.messageIcon }}" [ngbTooltip]="link.message"></i
          ></a>
        </div>
      </li>
    </ul>
  </div>
</nav>
