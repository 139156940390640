export class EtlWorkflow {
  [key: string]: any;
  workflows_set_id: string;
  module_name: string;
  execution_order: number;
  parameters: string | { [index: string]: string };

  constructor(workflowToCopy?: EtlWorkflow) {
    if (workflowToCopy) {
      this.execution_order = workflowToCopy.execution_order;
      this.module_name = workflowToCopy.module_name;
      this.parameters = workflowToCopy.parameters;
      this.workflows_set_id = workflowToCopy.workflows_set_id;
    } else {
      this.execution_order = 1;
      this.module_name = '';
      this.parameters = '';
      this.workflows_set_id = '';
    }
  }

  isValid(): boolean {
    return !!this.workflows_set_id && !!this.module_name && this.execution_order > 0;
  }
}
