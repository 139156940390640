<span class="ngb-datepicker-dark">
  <input
    class="top-page-dp__input"
    ngbDatepicker
    #dp="ngbDatepicker"
    [displayMonths]="1"
    [weekdays]="true"
    [(ngModel)]="date"
    [startDate]="date"
    [maxDate]="maxDate"
    [firstDayOfWeek]="7"
    (closed)="onClose()"
    (dateSelect)="onDateSelect($event)"
    outsideDays="collapsed"
    navigation="arrows"
    placement="bottom"
  />
</span>
<div class="top-page-dp">
  <button class="top-page-dp__button" [ngClass]="{ active: active }" (click)="open(dp)" data-cy="datepickerBtn">
    <span class="icon"></span>
    <span>{{ fullDateString }}</span>
  </button>

  <app-dark-theme-border class="top-page-dp__border"></app-dark-theme-border>
</div>
