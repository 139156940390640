import { Injectable } from '@angular/core';
import { TableValue } from '@shared/models/table-value.model';
import {
  DataSourceType,
  DataTransformationReturn,
  DataTransformationStrategy,
} from '@shared/providers/data-transformation/data-transformation.model';
import { StringArrayToCommaSeparatedStringStrategy } from '@shared/providers/data-transformation/string-array-to-comma-separated-string-strategy';

@Injectable()
export class StringArrayToCommaSeparatedStringTableValueStrategy implements DataTransformationStrategy {
  constructor(private stringArrayToCommaSeparatedStringStrategy: StringArrayToCommaSeparatedStringStrategy) {}

  transform(parameters?: Record<DataSourceType, any>): DataTransformationReturn {
    if (parameters && Object.keys(parameters).length > 0) {
      return {
        value: {
          value: this.stringArrayToCommaSeparatedStringStrategy.transform(parameters).value as TableValue,
        },
      };
    }
    return {
      value: { value: '' } as TableValue,
    };
  }
}
