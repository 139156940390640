import { NewAgreement } from '@shared/models/new-agreement.model';
import { AgreementType } from '@shared/models/agreement-type.enum';
import { ReducedAgreement } from '@shared/models/reduced-agreement.model';
import { AgreementOpenBeforeEvent } from '@shared/models/agreement-open-before-event.enum';

export class Agreement {
  id: AgreementType;
  name: string;
  component?: any;
  selector?: string;
  hasOptionalHeader?: boolean;
  dateModified?: string; // Ex: 'January 01, 2020'
  dateAccepted?: string;
  file?: string;
  headerText?: string;
  openBeforeEvent?: AgreementOpenBeforeEvent;
  username: string;

  constructor(initObject?: Partial<Agreement>) {
    if (initObject) {
      Object.assign(this, initObject);
    }
  }

  isOlderThan(agreement: NewAgreement): boolean {
    return new Date(this.dateModified ?? '') < new Date(agreement.effectiveDate);
  }

  updateFromNewAgreement(newAgreement?: NewAgreement): Agreement | undefined {
    if (!newAgreement) {
      console.warn('New agreement does not exist. Date may need to be reset to old date.');
      return;
    } else if (this.id !== newAgreement.id) {
      console.warn('Trying to update agreement from an incorrect ID!');
      return;
    }

    this.dateModified = newAgreement.effectiveDate;
    this.file = newAgreement.file;
    this.headerText = newAgreement.headerText;
    this.openBeforeEvent = newAgreement.openBeforeEvent;
    return this;
  }

  reduce(): ReducedAgreement {
    return {
      id: this.id,
      dateModified: this.dateModified,
      dateAccepted: this.dateAccepted,
      username: this.username,
    };
  }
}
