import gql from 'graphql-tag';

export const GetPharmacyOverviewQuery = gql`
  query GetPharmacyOverview($ncpdp: String!) {
    pharmacy(ncpdp: $ncpdp) {
      name
      npi
      contact {
        email
      }
      pbaEnrollment {
        systemVendor {
          id
          name
        }
      }
      enrollments {
        programGroup {
          id
          name
          isBlocked
        }
        tenant {
          id
        }
        engagements {
          brightscrip {
            isScorecardEnabled
          }
        }
        businessRegionId
      }
      softwareInstructionsSentAt
      paymentInfo {
        bankAccountNumber
        bankRoutingNumber
      }
    }
  }
`;
