<ul class="primary-tabs" role="navigation">
  <li *ngFor="let tab of internalTabs" class="primary-tabs__tab" [class.primary-tabs__tab--disabled]="tab.disabled" (click)="emitTab(tab)">
    <img
      *ngIf="tab.iconPath"
      class="primary-tabs__icon"
      [class.primary-tabs__icon--disabled]="tab.disabled"
      [class.primary-tabs__icon--active]="tab.active"
      [src]="tab.iconPath"
    />

    <div class="primary-tabs__text">
      <p class="primary-tabs__value" [class.primary-tabs__value--disabled]="tab.disabled" [class.primary-tabs__value--active]="tab.active">
        {{ tab?.value }}
      </p>

      <p *ngIf="tab.description" class="primary-tabs__description" [class.primary-tabs__description--disabled]="tab.disabled">
        {{ tab.description }}
      </p>
    </div>
  </li>
</ul>
