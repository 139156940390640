import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-detail-container',
  templateUrl: './detail-container.component.html',
  styleUrls: ['./detail-container.component.scss'],
})
export class DetailContainerComponent {
  @Input() title: string;

  constructor() {}

  hasTitle(): boolean {
    return this.title !== undefined;
  }
}
