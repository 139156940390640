import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmationDialogComponent } from '@app/business-rules/components/confirmation-dialog/confirmation-dialog.component';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '@services/dialog.service';
import { BaseComponent } from '@shared/components/base-component';
import { View } from '@shared/components/brightview/brightview-types';
import _ from 'lodash';

@Component({
  selector: 'app-data-view-gallery',
  templateUrl: './data-view-gallery.component.html',
  styleUrls: ['./data-view-gallery.component.scss'],
})
export class BrightViewGalleryComponent<T> extends BaseComponent {
  _views: Array<View.UserView<T>> = [];
  protected pinnedViews: Array<View.UserView<T>>;
  protected defaultViews: Array<View.UserView<T>>;
  protected customViews: Array<View.UserView<T>>;

  @Input()
  set views(views: Array<View.UserView<T>>) {
    this._views = views;
    this.pinnedViews = this._views.filter(v => v.isPinned);
    this.defaultViews = this._views.filter(v => !v.isCustom);
    this.customViews = this._views.filter(v => v.isCustom);
  }
  @Output()
  viewsChange = new EventEmitter<Array<View.UserView<T>>>();

  constructor(
    protected activeOffcanvas: NgbActiveOffcanvas,
    private dialogService: DialogService,
  ) {
    super();
  }

  viewTogglePinned(view: View.UserView<T>) {
    view.isPinned = !view.isPinned;
    this.pinnedViews = this._views.filter(v => v.isPinned);
    this.updateViews();
  }

  viewDropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pinnedViews, event.previousIndex, event.currentIndex);
    this.updateViews();
  }

  viewDeleted(view: View.UserView<T>, viewId: number) {
    const confirmationModal = this.dialogService.openModal(ConfirmationDialogComponent, { centered: true });
    confirmationModal.componentInstance.question = `Are you sure you want to delete your ${view.label} view?`;
    this.subscribe(confirmationModal.closed, answer => {
      if (answer === true) {
        this.customViews.splice(viewId, 1);
        this.pinnedViews = this.pinnedViews.filter(v => !_.isEqual(v, view));
        this.updateViews();
      }
    });
  }

  private updateViews() {
    this.viewsChange.next([
      ...this.pinnedViews,
      ...this.defaultViews.filter(v => !v.isPinned),
      ...this.customViews.filter(v => !v.isPinned),
    ]);
  }
}
