import { Component, Input, OnChanges } from '@angular/core';
import { IconPath } from '@shared/models/icon-path.enum';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'app-hoverable-icon',
  templateUrl: './hoverable-icon.component.html',
  styleUrls: ['./hoverable-icon.component.scss'],
})
export class HoverableIconComponent implements OnChanges {
  static readonly DefaultSuffix = 'default';
  static readonly HoverSuffix = 'hover';
  static readonly ActiveSuffix = 'active';
  static readonly SuffixPattern = /-(\w*)\./;

  @Input() iconPathDefault: IconPath;
  @Input() active = false;
  @Input() hover = false;

  iconPath: string;

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.iconPathDefault) {
      this.iconPath = this.iconPathDefault;
    }
    if (changes.active && changes.active.currentValue) {
      this.iconPath = this.iconPath.replace(HoverableIconComponent.SuffixPattern, `-${HoverableIconComponent.ActiveSuffix}.`);
    } else if (changes.hover && changes.hover.currentValue) {
      this.iconPath = this.iconPath.replace(HoverableIconComponent.SuffixPattern, `-${HoverableIconComponent.HoverSuffix}.`);
    } else if (changes.active || changes.hover) {
      this.iconPath = this.iconPath.replace(HoverableIconComponent.SuffixPattern, `-${HoverableIconComponent.DefaultSuffix}.`);
    }
  }

  mouseOn(): void {
    this.iconPath = this.iconPath.replace(HoverableIconComponent.SuffixPattern, `-${HoverableIconComponent.HoverSuffix}.`);
  }

  mouseOff(): void {
    if (this.active) {
      this.iconPath = this.iconPath.replace(HoverableIconComponent.SuffixPattern, `-${HoverableIconComponent.ActiveSuffix}.`);
    } else {
      this.iconPath = this.iconPath.replace(HoverableIconComponent.SuffixPattern, `-${HoverableIconComponent.DefaultSuffix}.`);
    }
  }
}
