import { Component, Input } from '@angular/core';
import Utils from '@shared/providers/utils';
import { IconPath } from '@shared/models/icon-path.enum';
import { LinkService, MessageWithLinks } from '@services/link-service.service';

type Modifier =
  | 'small'
  | 'extra-small'
  | 'icon-top'
  | 'gray'
  | 'icon-80'
  | 'nevada-border'
  | 'nevada-bg'
  | 'azure-bg'
  | 'rounded-3'
  | 'font-75'
  | 'font-weight-300'
  | 'anchor-font-weight-600'
  | 'icon-20'
  | 'icon-35'
  | 'p-15'
  | 'anchor-nero'
  | 'mt-2';

@Component({
  selector: 'app-retail-message',
  templateUrl: './retail-message.component.html',
  styleUrls: ['./retail-message.component.scss'],
})
export class RetailMessageComponent {
  @Input() set message(message: string) {
    this.setMessage(message);
  }
  @Input() set modifiers(modifiers: Modifier[]) {
    this.setModifiers(modifiers);
  }
  @Input() icon: IconPath = IconPath.Fail;
  @Input() header = '';

  _modifiers = '';
  componentMessage: MessageWithLinks;

  constructor(private linkService: LinkService) {}

  setMessage(message: string): void {
    this.componentMessage = this.linkService.convertMessageWithLinks(message);
  }

  setModifiers(modifiers: Modifier[]): void {
    this._modifiers = Utils.getModifiedStringArrayAsString(modifiers, 'retail-message--', 'prepend');
  }
}
