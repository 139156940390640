<div [ngClass]="{ row: layout === 'inline' }">
  <label *ngIf="label" [attr.for]="inputId" class="form-label" [ngClass]="{ 'col-form-label col-sm-2 text-sm-right': layout === 'inline' }">
    {{ label }}
  </label>
  <div [ngClass]="{ 'col-sm-10': layout === 'inline' }">
    <div [ngClass]="{ 'has-clear': clearable }">
      <div>
        <div class="crisps gap-1" *ngIf="chips.length > 0">
          <div *ngFor="let chip of chips; let i = index" class="badge rounded-pill bg-primary crisp cursor-pointer" (click)="removeChip(i)">
            <span class="me-1">{{ chip }}</span>
            <chitin-icon icon="fa-solid fa-x" />
          </div>
        </div>
        <input
          #inputFieldToFocus
          type="text"
          class="form-control"
          [ngClass]="{ disabled: isDisabled, 'form-control-sm': this.size === 'small', 'form-control-lg': this.size === 'large' }"
          [attr.id]="inputId"
          [attr.name]="name"
          [attr.placeholder]="placeholder"
          [disabled]="isDisabled"
          [readonly]="readonly"
          [(ngModel)]="htmlValue"
          (blur)="onBlur()"
          [attr.data-cy]="'input'"
        />
      </div>
    </div>
  </div>
</div>
