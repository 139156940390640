import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GetPaRequest, GetTenantPrescriber } from '@generated/graphql';
import { AuthService } from '@services/auth.service';
import { CopayCodeRequestStateService } from '@services/copay-code-request-state.service';
import { CouponRequestStateService } from '@services/coupon-request-state.service';
import { TenantService } from '@services/tenant.service';
import { InsuranceBenefit } from '@shared/models/insurance-benefit.model';
import { Manufacturer } from '@shared/models/manufacturer.model';
import { IPayor } from '@shared/models/payor.model';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { IProgramGroup } from '@shared/models/program-group.model';
import { Program } from '@shared/models/program.model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import Utils from '@shared/providers/utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type PaRequest = GetPaRequest.PatientAssistancePriorAuthorizationRequest;
type Prescriber = GetTenantPrescriber.Prescriber;

@Injectable({
  providedIn: 'root',
})
export class RetailStateService implements OnDestroy {
  static readonly STEP_CONTAINER_SELECTOR = '.retail-application__steps-container';
  showNavbar = new BehaviorSubject(false);
  showPbaHeader = new BehaviorSubject(false);
  manufacturer = new BehaviorSubject<Manufacturer | undefined>(undefined);
  pharmacy = new BehaviorSubject<IPharmacy | undefined>(undefined);
  program = new BehaviorSubject<Program | undefined>(undefined);
  programGroups = new BehaviorSubject<IProgramGroup[]>([]);
  payor = new BehaviorSubject<IPayor | undefined>(undefined);
  payorAnimationDone = new Subject<void>();
  ineligibleCode = new BehaviorSubject<boolean>(false);
  activeLoaders = new BehaviorSubject<string[]>([]);
  startDate = new BehaviorSubject<Date | undefined>(undefined);
  endDate = new BehaviorSubject<Date | undefined>(undefined);
  selectedBenefit = new BehaviorSubject<InsuranceBenefit | undefined>(undefined);
  sponsors = new BehaviorSubject<TenantIdType[]>([]);
  dataUploadSponsor = new BehaviorSubject<TenantIdType>('ALM');
  pharmacyDoesNotExistForSponsor = new BehaviorSubject<boolean>(false);
  paRequest = new BehaviorSubject<PaRequest | null>(null);
  patientCannotWait = new BehaviorSubject<boolean>(false);
  paDetermination = new BehaviorSubject<boolean>(false);
  prescriber = new BehaviorSubject<Prescriber | null>(null);
  thanksMessage = new BehaviorSubject<string | null>(null);
  finishExtraMessage = new BehaviorSubject<string | null>(null);
  extraConsignmentMessage = new BehaviorSubject<string | null>(null);
  currentStep = new BehaviorSubject<number>(0);
  stepsLength = new BehaviorSubject<number>(0);
  isLastStep = new BehaviorSubject<boolean>(false);
  readonly triggerContinue = new Subject<void>();

  private serviceDestroyed = new Subject<void>();

  constructor(
    private tenantService: TenantService,
    private authService: AuthService,
    private router: Router,
    private copayCodeRequestStateService: CopayCodeRequestStateService,
    private couponRequestService: CouponRequestStateService,
  ) {
    this.getManufacturer();
    this.updateManufacturerOnTenantChange();
    this.trackAuthChange();
  }

  ngOnDestroy() {
    this.serviceDestroyed.next();
    this.serviceDestroyed.complete();
  }

  reset() {
    this.sponsors.next([]);
    this.resetPharmacy();
    this.manufacturer.next(undefined);
    this.program.next(undefined);
    this.resetProgramGroup();
    this.resetPayor();
    this.ineligibleCode.next(false);
    this.startDate.next(undefined);
    this.endDate.next(undefined);
    this.resetPaRequest();
    this.resetPrescriber();
    this.couponRequestService.retailStateServiceReset.next(true);
    this.resetBenefit();
    this.patientCannotWait.next(false);
    this.paDetermination.next(false);
  }

  resetPharmacy() {
    this.pharmacy.next(undefined);
  }

  resetPayor(): void {
    this.payor.next(undefined);
  }

  resetBenefit(): void {
    this.selectedBenefit.next(undefined);
  }

  resetProgramGroup(): void {
    this.programGroups.next([]);
  }

  resetPaRequest() {
    this.paRequest.next(null);
  }

  resetPrescriber() {
    this.prescriber.next(null);
  }

  complete() {
    this.router.navigate([CouponRequestStateService.FINISHED_LINK_ROUTE]);
    this.copayCodeRequestStateService.setUpdateState();
    this.copayCodeRequestStateService.complete({ end: new Date() }).subscribe({
      complete: () => this.reset(),
    });
  }

  updatePharmacy(pharmacy?: IPharmacy): void {
    if (pharmacy) {
      this.pharmacy.next(pharmacy);
    }
  }

  addLoader(loaderName: string): void {
    if (!loaderName || this.activeLoadersValue.includes(loaderName)) {
      return;
    }
    this.activeLoaders.next([...this.activeLoadersValue, loaderName]);
  }

  removeLoader(loaderName: string): void {
    if (!loaderName || !this.activeLoadersValue.includes(loaderName)) {
      return;
    }
    const LOADER_INDEX = this.activeLoadersValue.findIndex(activeLoaderName => activeLoaderName === loaderName);
    const activeLoadersCopy = Array.from(this.activeLoadersValue);
    activeLoadersCopy.splice(LOADER_INDEX, 1);
    this.activeLoaders.next(activeLoadersCopy);
  }

  private get activeLoadersValue(): string[] {
    return this.activeLoaders.getValue();
  }

  private trackAuthChange(): void {
    this.authService.authStatusChanged.pipe(takeUntil(this.serviceDestroyed)).subscribe(isAuthed => {
      this.reset();
      this.getManufacturer();
    });
  }

  private getManufacturer(): void {
    if (this.tenantService.currentTenant) {
      this.setManufacturerFromTenantId(this.tenantService.currentTenant.id);
    }

    this.sponsors.pipe(takeUntil(this.serviceDestroyed)).subscribe(sponsors => {
      if (Utils.isNonEmptyArray(sponsors)) {
        this.setManufacturerFromTenantId(sponsors[0]);
      }
    });
  }

  private setManufacturerFromTenantId(tenantId: TenantIdType): void {
    const manufacturer = new Manufacturer();
    manufacturer.setInfoFromTenant(this.tenantService.getTenantById(tenantId)!);
    this.manufacturer.next(manufacturer);
  }

  private updateManufacturerOnTenantChange(): void {
    this.tenantService.activeTenantChanged.pipe(takeUntil(this.serviceDestroyed)).subscribe(haschanged => {
      if (haschanged) {
        this.getManufacturer();
      }
    });
  }
}
