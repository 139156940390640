import { pharmacyChainFragment } from '@services/graphql/pharmacy/chain/pharmacy-chain.fragment';
import gql from 'graphql-tag';

export const GetPharmacyChainQuery = gql`
  ${pharmacyChainFragment}
  query GetPharmacyChain($npi: String!) {
    pharmacyChain(npi: $npi) {
      ...PharmacyChain
    }
  }
`;
