import { Injectable } from '@angular/core';
import { ExtendedProgramCodeAvailability } from '@app/retail/components/user-programs-listing/user-programs-listing.component';
import { Program } from '@shared/models/program.model';
import { TableValue } from '@shared/models/table-value.model';
import {
  DataSourceType,
  DataTransformationReturn,
  DataTransformationReturnType,
  DataTransformationStrategy,
} from '@shared/providers/data-transformation/data-transformation.model';

@Injectable()
export class RequestCodesButtonWithDisableTableValueStrategy implements DataTransformationStrategy {
  transform(parameters: Record<DataSourceType, any> = {} as any): DataTransformationReturn {
    const program: Program = parameters[DataSourceType.Program];
    const availabilityForProgram: ExtendedProgramCodeAvailability = parameters[DataSourceType.ProgramCodeAvailability];
    const transformation = {} as DataTransformationReturn;
    transformation.value = {} as TableValue;

    if (program && availabilityForProgram) {
      const canRequest = !!program.is_weekly_batch_program || availabilityForProgram.canDispense;

      transformation.value = {
        value: 'Request Codes',
        valueType: 'button',
      };

      transformation.types = [DataTransformationReturnType.ButtonTableValue];

      if (!canRequest) {
        transformation.types.push(DataTransformationReturnType.Disabled);
      }
    }

    if (!transformation.value) {
      transformation.value = { value: '' };
    }

    return transformation;
  }
}
