import gql from 'graphql-tag';

export const UpdatePharmacyEnrollmentMutation = gql`
  mutation UpdatePharmacyEnrollment($data: UpdatePatientAssistancePharmacyEnrollmentInput!) {
    updatePharmacyEnrollment(data: $data) {
      tenant {
        id
        name
      }
      programGroup {
        id
        name
        isBlocked
      }
      networkGroup {
        id
        name
      }
      isConsignmentHub
    }
  }
`;
