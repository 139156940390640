import { Component } from '@angular/core';
import { IconPath } from '@shared/models/icon-path.enum';

@Component({
  selector: 'app-dark-theme-border',
  templateUrl: './dark-theme-border.component.html',
  styleUrls: ['./dark-theme-border.component.scss'],
})
export class DarkThemeBorderComponent {
  IconPath = IconPath;

  constructor() {}
}
