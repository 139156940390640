import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { editor } from 'monaco-editor';
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;

@Component({
  selector: 'code-area',
  templateUrl: './code-area.component.html',
})
export class CodeAreaComponent {
  @HostBinding('component') readonly componentEl = this;
  readonly defaultOptions: IStandaloneEditorConstructionOptions = {
    language: 'json',
    minimap: { enabled: false },
    scrollbar: { alwaysConsumeMouseWheel: false },
    scrollBeyondLastLine: false,
    automaticLayout: true,
  };
  codeOptions: IStandaloneEditorConstructionOptions = {};
  model = '{}';
  @Input() modelType: 'json' | 'string' = 'json';
  @Input() cssClass: string | string[] | Record<string, any> = {};
  @Input() cssStyle: Record<string, any> = {};
  @Output() valueChange = new EventEmitter<object | string | null>();

  constructor() {
    this.codeOptions = Object.assign({}, this.defaultOptions);
  }

  get value() {
    let value: object | string | null = this.model;
    if (this.modelType === 'json') {
      try {
        value = JSON.parse(this.model);
      } catch (error) {
        value = this.model;
      }
    }
    return value;
  }

  @Input()
  set value(value: object | string | null) {
    if (typeof value === 'object') {
      this.model = JSON.stringify(value, null, 2);
    } else {
      this.model = value;
    }
  }

  get options() {
    return this.codeOptions;
  }

  @Input()
  set options(value: IStandaloneEditorConstructionOptions) {
    this.codeOptions = Object.assign({}, this.defaultOptions, value);
  }

  modelChange() {
    this.valueChange.emit(this.value);
  }
}
