import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-shared-material-icon',
  templateUrl: './material-icon.component.html',
  styleUrls: ['./material-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialIconComponent {
  @Input()
  icon = 'icon';
  @HostBinding('class')
  hostClass = 'material-icons';
  @HostBinding('style')
  hostStyle = 'font-size: inherit;line-height: inherit;';
}
