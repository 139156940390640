import { Directive, EventEmitter, Input, Output } from '@angular/core';

export type SortDirection = 'asc' | 'desc';

export interface Sort {
  field: string;
  direction: SortDirection;
}

@Directive({
  selector: '[table-sort]',
  exportAs: 'app-table-sort',
})
export class TableSortDirective {
  @Input()
  sortField: string;

  @Input()
  sortDirection: SortDirection = 'asc';

  @Output()
  readonly sortChange = new EventEmitter<Sort>();

  static sortData<T extends {}>(data: T[], sort: Sort): T[] {
    return Array.from(data).sort((a: Record<string, any>, b: Record<string, any>) => {
      const compare = a[sort.field] < b[sort.field] ? -1 : 1;
      return sort.direction === 'asc' ? compare : compare * -1;
    });
  }

  sort(sort: Sort): void {
    if (this.sortField !== sort.field) {
      this.sortField = sort.field;
      this.sortDirection = sort.direction ? sort.direction : this.sortDirection;
    } else {
      this.sortDirection = this.getNextSortDirection(sort);
    }
    this.sortChange.emit({ field: this.sortField, direction: this.sortDirection });
  }

  getNextSortDirection(sort: Sort): SortDirection {
    const sortDirectionCycle = this.getSortDirectionCycle(sort.direction || this.sortDirection);
    let nextDirectionIndex = sortDirectionCycle.indexOf(this.sortDirection) + 1;
    if (nextDirectionIndex >= sortDirectionCycle.length) {
      nextDirectionIndex = 0;
    }

    return sortDirectionCycle[nextDirectionIndex];
  }

  getSortDirectionCycle(direction: SortDirection): SortDirection[] {
    const sortOrder: SortDirection[] = ['asc', 'desc'];

    if (direction === 'desc') {
      sortOrder.reverse();
    }

    return sortOrder;
  }
}
