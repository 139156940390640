<svg viewBox="0 0 36 36" class="circular-chart">
  <defs>
    <filter id="glow">
      <feGaussianBlur stdDeviation="1" result="coloredBlur" />
      <feMerge>
        <feMergeNode in="coloredBlur" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </defs>
  <path
    class="circle-bg"
    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
  />
  <path
    class="circle"
    filter="url(#glow)"
    [ngClass]="['circle--' + color]"
    [attr.stroke-dasharray]="value + ', 100'"
    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
  />
  <text x="18" y="20.35" class="percentage">{{ value }}%</text>
</svg>
