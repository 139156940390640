<div class="card">
  <div class="card-body">
    <a (click)="toggleOpen()">
      <div class="card-title mb-0 p-2 d-flex justify-content-between align-items-center">
        <h3>{{ title ?? '' }}</h3>
        <chitin-icon [icon]="isOpen ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'" />
      </div>
    </a>
    @if (isOpen) {
      <div class="mt-3 mb-2 px-4">
        <ng-content />
      </div>
    }
  </div>
</div>
