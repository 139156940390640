<div class="multi-select" [ngClass]="_modifiers">
  <div class="multi-select__options-container">
    <ul class="multi-select__options">
      <app-scrollbar>
        <ng-container *ngIf="hasValueOptions">
          <li class="multi-select__option" data-cy="multiSelectOption" *ngFor="let option of valueOptions">
            <app-square-checkmark
              [value]="isOptionSelected(option)"
              [label]="option.name"
              [modifiers]="['rounded-3', 'w-20', 'h-18', 'margin-20']"
              (valueChange)="updateValue($event, option)"
            >
            </app-square-checkmark>
          </li>
        </ng-container>
      </app-scrollbar>
    </ul>
  </div>

  <div *ngIf="filterText" class="multi-select__filter-container">
    <label class="multi-select__filter-label"> {{ filterText }} </label>

    <app-toggle-switch> </app-toggle-switch>
  </div>
</div>
