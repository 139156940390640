import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RetailStateService } from '@app/retail/providers/retailState.service';
import { PharmacyStateService } from '@services/pharmacy-state.service';
import { SupportRequestsService } from '@services/support-requests.service';
import { TenantService } from '@services/tenant.service';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { SelectListItem } from '@shared/models/select-list-item';
import { ISupportRequest } from '@shared/models/support-request.model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import Utils from '@shared/providers/utils';
import moment from 'moment';
import { timer } from 'rxjs';

type ContactMethod = 'contactViaPhone' | 'contactViaEmail';

interface NeedHelpStep {
  active: boolean;
  header: string;
  next: (contactMethod?: ContactMethod) => void;
}

interface NeedHelpSteps {
  [index: string]: NeedHelpStep;
}

interface PhoneNumberComponent {
  value: string;
  requiredNumberOfDigits: number;
}

interface PhoneNumber {
  areaCode: PhoneNumberComponent;
  centralOfficeCode: PhoneNumberComponent;
  lineNumber: PhoneNumberComponent;
}

@Component({
  selector: 'app-brightscrip-need-help-card',
  templateUrl: './brightscrip-need-help-card.component.html',
  styleUrls: ['./brightscrip-need-help-card.component.scss'],
})
export class BrightscripNeedHelpCardComponent implements OnInit {
  static readonly FIRST_STEP_NAME = 'inactive';
  static readonly HELP_TOPIC_STEP_NAME = 'helpTopic';

  @Input() showFormOnly = false;

  @ViewChild('centralOfficeCodeInput', { static: false }) centralOfficeCodeInput: any;
  @ViewChild('lineNumberInput', { static: false }) lineNumberInput: any;

  @Output() close = new EventEmitter<void>();

  helpTopics: string[] = [];
  steps: NeedHelpSteps = {
    inactive: {
      active: true,
      header: 'Need help?',
      next: () => this.onStepNext('inactive', 'helpTopic'),
    },
    helpTopic: {
      active: false,
      header: 'Select a help topic',
      next: () => this.onStepNext('helpTopic', 'needHelp'),
    },
    needHelp: {
      active: false,
      header: 'Confirmation',
      next: contactMethod => this.onStepNext('needHelp', 'thankYou'),
    },
    thankYou: {
      active: false,
      header: 'Thank You!',
      next: () => this.onStepNext(),
    },
  };
  phoneInvalid = false;
  emailInvalid = false;
  firstNameInvalid = false;
  lastNameInitialInvalid = false;
  selectedHelpTopic = '';
  tenants: SelectListItem[] = [];
  pharmacy?: IPharmacy;

  phoneNumber: PhoneNumber = {
    areaCode: {
      value: '',
      requiredNumberOfDigits: 3,
    },
    centralOfficeCode: {
      value: '',
      requiredNumberOfDigits: 3,
    },
    lineNumber: {
      value: '',
      requiredNumberOfDigits: 4,
    },
  };
  email: string;
  sponsor: TenantIdType;
  firstName: string;
  lastNameInitial: string;

  constructor(
    private supportRequestsService: SupportRequestsService,
    private tenantService: TenantService,
    private pharmacyStateService: PharmacyStateService,
    private retailStateService: RetailStateService,
  ) {}

  ngOnInit() {
    this.helpTopics = this.supportRequestsService.getHelpTopics();
    this.pharmacy = this.retailStateService.pharmacy.value;
    this.tenants = this.tenantService.usersTenants.map(tenant => ({ name: tenant.name, value: tenant.id.toString() }));
    const currentTenant = this.tenantService.currentTenant;
    if (currentTenant) {
      this.sponsor = currentTenant.id;
    }
    if (this.showFormOnly) {
      this.activate();
    }
  }

  onStepNext(currentStepKey?: string, nextStepKey?: string): void {
    if (!currentStepKey || !nextStepKey) {
      return;
    }

    this.steps[currentStepKey].active = false;
    this.steps[nextStepKey].active = true;
  }

  get currentStep(): NeedHelpStep {
    const currentStepName = Object.keys(this.steps).find(stepName => this.steps[stepName].active);
    return this.steps[currentStepName ?? ''];
  }

  nextStep(contactMethod?: 'contactViaPhone' | 'contactViaEmail'): void {
    this.currentStep.next(contactMethod);
  }

  activate(): void {
    if (this.steps.inactive.active) {
      this.nextStep();
    }
  }

  onHelpTopicSelected(helpTopic: string): void {
    this.selectedHelpTopic = helpTopic;
  }

  submit(): void {
    if (this.steps.helpTopic.active) {
      this.nextStep();
      return;
    }
    const canSubmit = this.validateForm();
    if (canSubmit) {
      this.nextStep();
      this.supportRequestsService.submitRequest(this.request).subscribe(_ => this.onSuccessfulSubmit());
    }
  }

  private validateForm(): boolean {
    this.phoneInvalid = !this.isPhoneNumberFilled();
    this.emailInvalid = !Utils.isValidEmail(this.email);
    this.firstNameInvalid = !this.firstName?.trim();
    this.lastNameInitialInvalid = !this.lastNameInitial?.trim();

    const formInvalid = (this.phoneInvalid && this.emailInvalid) || this.firstNameInvalid || this.lastNameInitialInvalid || !this.sponsor;
    return !formInvalid;
  }

  get canSubmit(): boolean {
    return this.isPhoneNumberFilled() || Utils.isValidEmail(this.email);
  }

  onSuccessfulSubmit(): void {
    if (this.showFormOnly) {
      return;
    }
    timer(2000).subscribe(_ => this.reset());
  }

  reset(): void {
    this.resetSteps();
    this.resetPhoneNumber();
    this.resetEmail();
    this.firstName = '';
    this.lastNameInitial = '';
    this.phoneInvalid = false;
    this.emailInvalid = false;
    this.firstNameInvalid = false;
    this.lastNameInitialInvalid = false;
  }

  resetSteps(): void {
    Object.keys(this.steps).forEach(stepName => {
      this.steps[stepName].active = stepName === BrightscripNeedHelpCardComponent.FIRST_STEP_NAME;
    });
  }

  resetPhoneNumber(): void {
    Utils.getObjectKeys(this.phoneNumber).forEach(fieldName => (this.phoneNumber[fieldName].value = ''));
  }

  resetEmail(): void {
    this.email = '';
  }

  resetHelpTopic(): void {
    this.selectedHelpTopic = '';
  }

  onCloseClicked() {
    this.close.emit();
  }

  get request(): Partial<ISupportRequest> {
    const request: Partial<ISupportRequest> = {
      requester_id: this.retailStateService.pharmacy.getValue()?.ncpdp ?? '',
      requested_time: moment().toISOString(),
      tenant: this.sponsor,
      help_topic: this.selectedHelpTopic,
      phone_number: this.phoneNumberComplete,
      email: this.email.trim(),
      first_name: this.firstName.trim(),
      last_name_initial: this.lastNameInitial.trim(),
    };

    return request;
  }

  isPhoneNumberFilled(): boolean {
    let isFilled = true;
    Utils.getObjectKeys(this.phoneNumber).forEach(fieldName => {
      if (this.phoneNumber[fieldName].value.toString().length !== this.phoneNumber[fieldName].requiredNumberOfDigits) {
        isFilled = false;
      }
    });

    return isFilled;
  }

  get phoneNumberComplete(): string {
    const AREA_CODE = this.phoneNumber.areaCode.value;
    const CENTRAL_OFFICE_CODE = this.phoneNumber.centralOfficeCode.value;
    const LINE_NUMBER = this.phoneNumber.lineNumber.value;
    return `${AREA_CODE}${CENTRAL_OFFICE_CODE}${LINE_NUMBER}`;
  }

  preventNonNumericInput(event: KeyboardEvent) {
    const pattern = /^[0-9]+$/;
    if (!pattern.test(event.key)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  onPhoneNumberInput(inputValue: string, inputName: keyof PhoneNumber): void {
    if (String(inputValue).length === this.phoneNumber[inputName].requiredNumberOfDigits) {
      switch (inputName) {
        case 'areaCode':
          this.centralOfficeCodeInput.nativeElement.focus();
          return;
        case 'centralOfficeCode':
          this.lineNumberInput.nativeElement.focus();
          return;
      }
    }
  }
}
