import { Injectable } from '@angular/core';
import { DataTransformationService } from '@services/data-transformation.service';
import { TenantService } from '@services/tenant.service';
import { TableEntry } from '@shared/models/table-entry.model';
import { TableHeader } from '@shared/models/table-header.model';
import { TableRow } from '@shared/models/table-row.model';
import { TableValue } from '@shared/models/table-value.model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { TenantTableColumnType } from '@shared/models/tenant.model';
import {
  DataSourceFilled,
  DataSourceType,
  DataTransformationReturn,
  DataTransformationReturnType,
  TableColumn,
} from '@shared/providers/data-transformation/data-transformation.model';
import Utils from '@shared/providers/utils';
import { Subject } from 'rxjs';

interface NewColumn {
  index: number;
  column: TableColumnWithTenants;
}

interface TableColumnWithTenants extends TableColumn {
  tenants: TenantIdType[];
}

@Injectable({
  providedIn: 'root',
})
export class TableService {
  collapseAllRows = new Subject<void>();
  refresh = new Subject<void>();

  constructor(
    private tenantService: TenantService,
    private dataTransformationService: DataTransformationService,
  ) {}

  setupTableHeaders(columnInfoFieldForTenant: TenantTableColumnType, tenantIds?: TenantIdType[]): TableHeader[] {
    const columnInfo = this.getTableColumns(columnInfoFieldForTenant, tenantIds);

    if (!columnInfo) {
      return [];
    }

    return columnInfo.map(column => {
      const styles = {};
      if (column.columnStyles) {
        Object.assign(styles, column.columnStyles);
      }
      if (column.headerStyles) {
        Object.assign(styles, column.headerStyles);
      }

      return {
        name: column.name,
        styles: Object.keys(styles).length > 0 ? styles : undefined,
        filterType: column.filterType || undefined,
      };
    });
  }

  private canGetTableColumn(columnInfoFieldForTenant: TenantTableColumnType, tenantIds?: TenantIdType[]): boolean {
    return false;
  }

  private getTableColumns(columnInfoFieldForTenant: TenantTableColumnType, tenantIds?: TenantIdType[]): TableColumnWithTenants[] {
    if (!this.canGetTableColumn(columnInfoFieldForTenant, tenantIds)) {
      return [];
    }

    if (!tenantIds) {
      const tenant = this.tenantService.currentTenant!;
      const columns: TableColumn[] = [];
      return columns.map(column => ({ ...column, tenants: [tenant.id] }));
    } else if (Utils.isNonEmptyArray(tenantIds)) {
      const tenants = tenantIds.map(tenant => this.tenantService.getTenantById(tenant)!);
      const firstTenantsColumns: TableColumn[] = [];
      const columns = firstTenantsColumns.map(column => ({ ...column, tenants: [tenants[0].id] }));
      if (tenants.length > 1) {
        tenants.slice(1).forEach(tenant => {
          const newColumns: NewColumn[] = [];

          ([] as TableColumn[]).forEach(column => {
            const doesColumnAlreadyExist =
              Utils.isNonEmptyArray(columns) && columns.some(existingColumn => existingColumn.name === column.name);
            if (Utils.isNonEmptyArray(columns) && !doesColumnAlreadyExist) {
              const newColumnIndex = columns.findIndex((existingColumn, index) => {
                const columns: TableColumn[] = [];
                return existingColumn.name !== columns[index].name;
              });
              newColumns.push({ index: newColumnIndex, column: { ...column, tenants: [tenant.id] } });
            } else {
              const columnIndex = columns.findIndex(existingColumn => existingColumn.name === column.name);
              columns[columnIndex].tenants.push(tenant.id);
            }
          });

          newColumns.forEach(newColumn => columns.splice(newColumn.index, 0, newColumn.column));
        });
      }
      return columns;
    } else {
      return [];
    }
  }

  setupTableRows(
    columnInfoFieldForTenant: TenantTableColumnType,
    dataSources: Record<DataSourceType, any>,
    dataSourceToIterateOver: DataSourceType,
    tenantIds?: TenantIdType[],
  ): TableRow[] {
    const columnInfo = this.getTableColumns(columnInfoFieldForTenant, tenantIds);
    if (!columnInfo) {
      return [];
    }

    return dataSources[dataSourceToIterateOver].map((dataIter: Record<string, any>) => ({
      entries: columnInfo.map(column => {
        const data = this.getData(column, dataIter, dataSources);
        const transformation = this.dataTransformationService.transform(column.dataTransformationStrategyType!, data);

        const tableEntry: TableEntry = {
          values: transformation.value,
        };
        this.addStylesToTableEntry(tableEntry, column, transformation);

        if ('tenant' in dataIter && !column.tenants.includes(dataIter.tenant)) {
          (tableEntry.values as TableValue).value = '';
        }

        return tableEntry;
      }),
    }));
  }

  private getData(column: TableColumn, dataIter: any, dataSources: Record<DataSourceType, any>): DataSourceFilled[] {
    return (
      column.dataSources?.map(dataSource => {
        if (dataSource.type === DataSourceType.Program) {
          return { ...dataSource, data: dataSource.field ? dataIter[dataSource.field] : dataIter } as any;
        } else if (dataSource.type === DataSourceType.ProgramCodeAvailability) {
          const codeAvailability = dataSources[DataSourceType.ProgramCodeAvailability].find(
            (availability: Record<string, any>) =>
              availability.programId === dataIter.copay_program_id && availability.tenantId === dataIter.tenant,
          );
          return { ...dataSource, data: dataSource.field ? codeAvailability[dataSource.field] : codeAvailability };
        }
      }) ?? []
    );
  }

  private addStylesToTableEntry(tableEntry: TableEntry, column: TableColumn, transformation: DataTransformationReturn): void {
    tableEntry.styles = {};
    this.addColumnStylesToTableEntryStyles(tableEntry, column);
    this.addTableValueStylesStylesFromColumnToTableEntryStyles(tableEntry, column, transformation);
    this.addTableValueStyleClassesFromColumnToTableEntryStyles(tableEntry, column, transformation);
  }

  private addColumnStylesToTableEntryStyles(tableEntry: TableEntry, column: TableColumn): void {
    if (column.columnStyles) {
      if (!tableEntry.styles) tableEntry.styles = {};
      Object.assign(tableEntry.styles, column.columnStyles);
    }
  }

  private addTableValueStylesStylesFromColumnToTableEntryStyles(
    tableEntry: TableEntry,
    column: TableColumn,
    transformation: DataTransformationReturn,
  ): void {
    if (column.tableValueStyles) {
      column.tableValueStyles.forEach(styles => {
        if (this.isReturnTypeInTransformation(styles.tranformationReturnType!, transformation)) {
          if (styles.valueType) {
            this.addTableValueStylesStylesToTableEntryStyles(tableEntry, styles);
          }
        } else if (!styles.tranformationReturnType && styles.valueType) {
          this.addTableValueStylesStylesToTableEntryStyles(tableEntry, styles);
        }
      });
    }
  }

  private addTableValueStylesStylesToTableEntryStyles(tableEntry: TableEntry, tableValueStyle: any): void {
    if (Utils.isArray(tableEntry.values)) {
      (tableEntry.values as TableValue[]).forEach(tableValue => {
        if (tableValue.valueType === tableValueStyle.valueType) {
          if (!tableValue.styles) tableValue.styles = {};
          Object.assign(tableValue.styles, tableValueStyle.styles);
        }
      });
    } else if (tableEntry.values && (tableEntry.values as TableValue).valueType === tableValueStyle.valueType) {
      if ((tableEntry.values as TableValue).styles) {
        Object.assign((tableEntry.values as TableValue).styles!, tableValueStyle.styles);
      } else {
        (tableEntry.values as TableValue).styles = { ...tableValueStyle.styles };
      }
    }
  }

  private isReturnTypeInTransformation(returnType: DataTransformationReturnType, transformation: DataTransformationReturn): boolean {
    return transformation?.types?.includes(returnType) ?? false;
  }

  private addTableValueStyleClassesFromColumnToTableEntryStyles(
    tableEntry: TableEntry,
    column: TableColumn,
    transformation: DataTransformationReturn,
  ): void {
    if (column.tableValueStyleClasses) {
      column.tableValueStyleClasses.forEach(styleClass => {
        if (this.isReturnTypeInTransformation(styleClass.tranformationReturnType!, transformation)) {
          if (styleClass.valueType) {
            this.addTableValueStyleClassesToTableEntryStyles(tableEntry, styleClass);
          }
        } else if (!styleClass.tranformationReturnType && styleClass.valueType) {
          this.addTableValueStyleClassesToTableEntryStyles(tableEntry, styleClass);
        }
      });
    }
  }

  private addTableValueStyleClassesToTableEntryStyles(tableEntry: TableEntry, tableValueStyleClass: any): void {
    if (Utils.isArray(tableEntry.values)) {
      (tableEntry.values as TableValue[]).forEach(tableValue => {
        if (tableValue.styleClasses) {
          tableValue.styleClasses.push(...tableValueStyleClass.classes);
        } else {
          tableValue.styleClasses = [...tableValueStyleClass.classes];
        }
      });
    } else if (tableEntry.values && (tableEntry.values as TableValue).valueType === tableValueStyleClass.valueType) {
      if ((tableEntry.values as TableValue).styleClasses) {
        (tableEntry.values as TableValue).styleClasses?.push(...tableValueStyleClass.classes);
      } else {
        (tableEntry.values as TableValue).styleClasses = [...tableValueStyleClass.classes];
      }
    }
  }
}
