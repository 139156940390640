import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DropdownService implements OnDestroy {
  static readonly LABEL_DELIMETER = ', ';

  activeOptionLabels = new BehaviorSubject<string[]>([]);
  activeOptionLabelsCombined = new BehaviorSubject<string>('');

  private serviceDestroyed = new Subject<void>();

  constructor() {
    this.combineActiveOptionLabelsAndEmit();
  }

  ngOnDestroy() {
    this.serviceDestroyed.next();
    this.serviceDestroyed.complete();
  }

  combineActiveOptionLabelsAndEmit(): void {
    this.activeOptionLabels.pipe(takeUntil(this.serviceDestroyed)).subscribe(labels => {
      const COMBINED_LABELS = labels.join(DropdownService.LABEL_DELIMETER);
      this.activeOptionLabelsCombined.next(COMBINED_LABELS);
    });
  }
}
