import { Directive, HostBinding, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { GuardType } from '@services/guards/guards';
import { GuardsService } from '@services/guards/guards.service';
import { BaseComponent } from '@shared/components/base-component';
import { isArray, isString } from 'lodash';
import { forkJoin, from } from 'rxjs';

@Directive({ selector: 'auth, [auth]' })
export class AuthGuardDirective extends BaseComponent implements OnInit {
  @Input() auth?: string | string[];
  @Input() authGuard?: GuardType;
  @HostBinding('class') className = 'd-none';
  private readonly guardsService = inject(GuardsService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly routerLink = inject(RouterLink, { optional: true });

  ngOnInit() {
    if (isArray(this.auth)) {
      const checks = this.auth.map(url => this.guardsService.canShow(this.getUrl(url)));
      this.subscribe(forkJoin(checks), result => {
        this.className = result.includes(true) ? '' : 'd-none';
      });
    } else {
      const url = isString(this.auth) ? this.auth : '';
      const check = this.guardsService.canShow(this.getUrl(url));
      this.subscribe(from(check), result => {
        if (result) this.className = '';
      });
    }
  }

  private getUrl(url = ''): string {
    if (url.length) {
      const urlTree = this.router.createUrlTree([url], { relativeTo: this.activatedRoute });
      url = this.router.serializeUrl(urlTree);
    } else if (this.routerLink?.urlTree) {
      url = this.router.serializeUrl(this.routerLink.urlTree);
    } else {
      console.error('Unsupported auth directive usage');
    }

    return url;
  }
}
