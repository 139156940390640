import { Injectable } from '@angular/core';
import {
  DataSourceType,
  DataTransformationReturn,
  DataTransformationStrategy,
} from '@shared/providers/data-transformation/data-transformation.model';

@Injectable()
export class PassThroughStrategy implements DataTransformationStrategy {
  transform(parameters?: Record<DataSourceType, any>): DataTransformationReturn {
    let value;
    if (parameters && Object.keys(parameters).length > 0) {
      const parameterKey = Object.keys(parameters)[0] as DataSourceType;
      value = parameters[parameterKey];
    }
    return {
      value: value,
    };
  }
}
