import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IPharmacy } from '@shared/models/pharmacy.model';

@Component({
  selector: 'app-pharmacy-ncpdp-name-pill',
  templateUrl: './pharmacy-ncpdp-name-pill.component.html',
  styleUrls: ['./pharmacy-ncpdp-name-pill.component.scss'],
})
export class PharmacyNcpdpNamePillComponent {
  @Input() pharmacy: IPharmacy;

  @Output() delete = new EventEmitter<IPharmacy>();

  constructor() {}

  emitDelete(): void {
    this.delete.emit(this.pharmacy);
  }
}
