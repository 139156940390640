import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmationDialogComponent } from '@app/business-rules/components/confirmation-dialog/confirmation-dialog.component';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '@services/dialog.service';
import { UserService } from '@services/user.service';
import { BaseComponent } from '@shared/components/base-component';
import { View } from '@shared/components/data-view/data-view-types';

@Component({
  selector: 'app-data-view-gallery',
  templateUrl: './data-view-gallery.component.html',
  styleUrls: ['./data-view-gallery.component.scss'],
})
export class DataViewGalleryComponent extends BaseComponent {
  _views: View.UserView[] = [];
  protected pinnedViews: Array<View.UserView & { isShareable?: boolean }>;
  protected defaultViews: View.DefaultView[];
  protected customViews: View.UserView[];
  protected currentUsername: string;
  protected isSuperAdmin: boolean;

  @Input()
  set views(views: View.UserView[]) {
    this._views = views;
    this.updateViewCategories();
  }
  @Output()
  viewsChange = new EventEmitter<Array<View.UserView | View.DefaultView>>();

  constructor(
    protected activeOffcanvas: NgbActiveOffcanvas,
    protected userService: UserService,
    private dialogService: DialogService,
  ) {
    super();
    this.currentUsername = userService.getCurrentUser().username;
    this.isSuperAdmin = userService.isSuperAdmin();
  }

  viewTogglePinned(view: View.UserView | View.DefaultView) {
    view.isPinned = !view.isPinned;
    this.updateViews();
  }

  viewToggleShared(view: View.UserView | View.DefaultView) {
    if ('isCustom' in view && view.isCustom) {
      view.isCustom = false;
      (view as View.DefaultView).ownerUsername = this.userService.getCurrentUser().username;
    } else {
      (view as View.UserView).isCustom = true;
      if ('ownerUsername' in view) {
        delete view.ownerUsername;
      }
    }
    this.updateViews();
  }

  viewDropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pinnedViews, event.previousIndex, event.currentIndex);
    this.updateViews();
  }

  viewDeleted(view: View.UserView) {
    const confirmationModal = this.dialogService.openModal(ConfirmationDialogComponent, { centered: true });
    confirmationModal.componentInstance.question = `Are you sure you want to delete your ${view.label} view?`;
    this.subscribe(confirmationModal.closed, answer => {
      if (answer === true) {
        this._views = this._views.filter(v => v.id !== view.id);
        this.updateViews();
      }
    });
  }

  private updateViewCategories() {
    this.pinnedViews = this._views.filter(v => v.isPinned);
    for (const pinnedView of this.pinnedViews) {
      pinnedView.isShareable =
        this.isSuperAdmin && (pinnedView.isCustom || ('ownerUsername' in pinnedView && pinnedView.ownerUsername === this.currentUsername));
    }
    this.defaultViews = this._views.filter(v => !v.isCustom && (!('ownerUsername' in v) || v.ownerUsername !== this.currentUsername));
    this.customViews = this._views.filter(v => v.isCustom || ('ownerUsername' in v && v.ownerUsername === this.currentUsername));
  }

  private updateViews() {
    this.updateViewCategories();
    this.viewsChange.next([
      ...this.pinnedViews,
      ...this.defaultViews.filter(v => !v.isPinned),
      ...this.customViews.filter(v => !v.isPinned),
    ]);
  }
}
