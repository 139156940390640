import { Injectable } from '@angular/core';
import { PROJECTION_INDEXES } from '@libs/shared/infrastructure/projection_indexes';
import { PharmacyViewModel } from '@services/models/pharmacy-view.model';
import {
  OpenSearchPage,
  OpenSearchQuery,
  OpenSearchQueryContainer,
  OpenSearchQuerySimpleResult,
  OpenSearchSimpleSort,
} from '@shared/providers/opensearch-api';
import { GroupByField, OpenSearchService } from '@shared/providers/opensearch.service';
import { Observable, lastValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PharmaciesSearchService extends OpenSearchService {
  readonly indexName = PROJECTION_INDEXES.PHARMACY_GENERAL_VIEW;
  readonly dataviewColumns = 'pharmacy_general_columns';
  nestedPaths = ['enrollments'];
  groupFields: GroupByField[] = [
    { field: 'enrollments.networkGroup', groupBy: 'enrollments.tenantId' },
    { field: 'enrollments.programGroup', groupBy: 'enrollments.tenantId' },
  ];

  get(ncpdp: string): Observable<PharmacyViewModel | undefined> {
    const filter: OpenSearchQueryContainer[] = [{ match: { ncpdp } }];
    const query: OpenSearchQuery = { size: 1, query: this.buildQuery(filter) };
    return this.client.search<PharmacyViewModel>(this.indexName, query).pipe(map(result => result.items[0]));
  }

  countData(filter: OpenSearchQueryContainer[]) {
    return this.client.count(this.indexName, this.buildQuery(filter));
  }

  searchData(
    filter: OpenSearchQueryContainer[],
    page: OpenSearchPage,
    sort: OpenSearchSimpleSort,
    fields?: string[],
  ): Observable<OpenSearchQuerySimpleResult<PharmacyViewModel>> {
    const query: OpenSearchQuery = {
      from: page.from,
      size: page.size,
      sort: this.createSearchSort(sort),
      query: this.buildQuery(filter),
      _source: fields,
    };
    return this.client
      .search<PharmacyViewModel>(this.indexName, query)
      .pipe(catchError(error => this.processSimpleSearchError<PharmacyViewModel>(error)));
  }

  public exportData(
    filter: OpenSearchQueryContainer[],
    sort: OpenSearchSimpleSort,
    fields?: string[],
    dataview?: boolean,
  ): Observable<{
    fileUrl: string;
  }> {
    return this.client.export<{ fileUrl: string }>(
      this.indexName,
      {
        query: this.buildQuery(filter),
        sort: this.createSearchSort(sort),
        _source: fields,
      },
      {
        dataview: dataview ? this.dataviewColumns : '',
      },
    );
  }

  public async validateUniqueNpi(npi: string): Promise<boolean> {
    const find = await lastValueFrom(this.searchData([{ match: { npi } }], { size: 1, from: 0 }, {}));
    return find.total === 0;
  }

  getPharmaciesByProgram(programGroup: string, tenantId: string): Observable<OpenSearchQuerySimpleResult<PharmacyViewModel>> {
    const query = [
      {
        nested: {
          path: 'enrollments',
          query: {
            bool: {
              must: [{ match: { 'enrollments.programGroup': programGroup } }, { match: { 'enrollments.tenantId': tenantId } }],
            },
          },
        },
      },
    ];
    return this.searchData(query, { from: 0, size: 9999 }, { name: 'asc' });
  }

  getPharmaciesHubsForNetwork(items: PharmacyViewModel[], programGroup: string, tenantId: string, network: string) {
    const hubs: string[] = [];

    items.forEach(pharmacy => {
      const enrollment = pharmacy.enrollments.find(item => item.programGroup === programGroup && item.tenantId === tenantId);
      if (enrollment?.networkGroup === network && enrollment?.isConsignmentHub) hubs.push(pharmacy.npi ?? 'Unknown');
    });

    return hubs;
  }
}
