import { ComponentFactoryResolver, Directive, Input, ViewContainerRef } from '@angular/core';
import { Agreement } from '@shared/models/agreement.model';
import { AgreementService } from '@services/agreement.service';
import { AgreementType } from '@shared/models/agreement-type.enum';

@Directive({
  selector: '[appAgreement]',
})
export class AgreementContainerDirective {
  @Input() set appAgreement(agreementId: AgreementType) {
    this.currentAgreement = this.agreementService.searchAgreementsById(agreementId);
    this._loadComponent(this.currentAgreement);
    this._updateComponentsShowNewVersion();
  }

  @Input() set showHeader(doShow: boolean) {
    this._updateComponentsShowHeader(doShow);
  }

  currentAgreement: Agreement;
  currentComponentRef: any;

  constructor(
    private viewContainer: ViewContainerRef,
    private componentFactory: ComponentFactoryResolver,
    private agreementService: AgreementService,
  ) {}

  private _loadComponent(agreement: Agreement): void {
    const component = this.componentFactory.resolveComponentFactory(agreement.component);

    this.viewContainer.clear();
    this.currentComponentRef = this.viewContainer.createComponent(component);
  }

  private _updateComponentsShowHeader(doShowHeader: boolean): void {
    if (this.currentAgreement.hasOptionalHeader) {
      (this.currentComponentRef.instance as any).shouldShowHeader = doShowHeader;
    }
  }

  private _updateComponentsShowNewVersion(): void {
    if (this.currentComponentRef.instance.showNewVersion !== undefined && this.currentAgreement) {
      this.currentComponentRef.instance.showNewVersion = this.agreementService.isTimeToShowNewVersionOfAgreement(this.currentAgreement.id);
    }
  }
}
