<div [ngClass]="{ row: layout === 'inline' }">
  <label *ngIf="label" [for]="id" class="form-label" [ngClass]="{ 'col-form-label col-sm-2 text-sm-right': layout === 'inline' }">
    {{ label }}
  </label>
  <div [ngClass]="{ 'col-sm-10': layout === 'inline', 'd-flex align-items-center': layout === 'inline' && direction === 'horizontal' }">
    <label
      *ngFor="let item of items; index as index"
      class="form-check"
      [for]="id + index"
      [ngClass]="{ 'form-check-inline': direction === 'horizontal' }"
    >
      <input
        [ngClass]="{ disabled: disabled }"
        class="form-check-input"
        [type]="allowMultiple ? 'checkbox' : 'radio'"
        [attr.id]="id + index"
        [attr.name]="name"
        [disabled]="disabled"
        [checked]="isChecked(item)"
        [ngModel]="item[valueExpr]"
        (ngModelChange)="onNgModelChange(item)"
      />
      <span class="form-check-label"> {{ item[displayExpr] }} </span>
    </label>
  </div>
</div>
