<label *ngIf="header" class="retail-message__header">{{ header }}</label>
<div *ngIf="componentMessage" class="retail-message" data-cy="retailMessage" [ngClass]="_modifiers">
  <img class="retail-message__icon" [src]="icon" />

  <p class="retail-message__text">
    {{ componentMessage.message }}

    <a *ngIf="componentMessage?.href" [href]="componentMessage.href"> {{ componentMessage?.hrefText }} </a>
  </p>
</div>
