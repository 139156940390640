import { inject, Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { RoutesExplorer } from '@app/router-explorer';
import { AuthGuard } from '@services/guards/auth-guard';
import { GUARDS, GuardType } from '@services/guards/guards';

@Injectable({ providedIn: 'root' })
export class GuardsService {
  private readonly guards: Record<GuardType, AuthGuard> = {
    Auth: inject(GUARDS.Auth),
    Pharmacy: inject(GUARDS.Pharmacy),
    Scorecard: inject(GUARDS.Scorecard),
    Inventory: inject(GUARDS.Inventory),
  };

  async canShow(url: string, authGuard?: GuardType) {
    const route = RoutesExplorer.findRoute(url);
    const params = RoutesExplorer.getParams(url);
    if (!route) throw new Error(`Route was not found for path: ${url}`);
    const guard = this.getGuard(route, authGuard);
    if (!guard) throw new Error(`No guard for route: ${route}`);
    return await guard.canShow(route, params);
  }

  getGuard(route: Route, authGuard?: GuardType): AuthGuard {
    if (route.canActivate?.length) {
      const routeGuard: Function = route.canActivate[0];
      for (const guard of Object.values(this.guards)) {
        if (routeGuard.name === guard.constructor.name) return guard;
      }
      throw new Error('Unsupported guard');
    } else if (authGuard) {
      return this.guards[authGuard];
    } else {
      return this.guards['Auth'];
    }
  }
}
