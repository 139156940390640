import { Component, Input, OnDestroy } from '@angular/core';
import { IconWithText } from '@shared/components/icon-with-text/icon-with-text.model';
import { SitePage } from '@shared/models/site-page.model';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from '@services/auth.service';

type Modifier = '' | 'compressed' | 'mobile' | 'scrollable' | 'off-white-bg';

@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
})
export class PageContentComponent implements OnDestroy {
  @Input() title = '';
  @Input() class = '';
  @Input() useScrollbar = false;
  @Input() includeBackButton = false;
  @Input() backPage: SitePage; // must be passed in if includeBackButton is true
  @Input() includeAddButton = false;
  @Input() addObject: IconWithText; // must be passed in if includeAddButton is true
  @Input() themeModifier: Modifier = '';
  @Input() showFooter = false;

  isDefaultHeader = true;
  isTokenLogin = false;

  private componentDestroyed = new Subject<void>();

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    this.isTokenLogin = this.authService.isTokenizedLogin();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  goBack(): void {
    this.router.navigate([this.backPage.url]);
  }
}
