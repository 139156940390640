import { pharmacyPaymentStatementFragment } from '@services/graphql/pharmacy/pharmacy-statement.fragment';
import gql from 'graphql-tag';

export const UpdatePaymentStatementMutation = gql`
  ${pharmacyPaymentStatementFragment}
  mutation UpdatePaymentStatement($data: UpdatePatientAssistancePharmacyPaymentStatementInput!) {
    updatePatientAssistancePharmacyPaymentStatementAmount(data: $data) {
      ...PharmacyPaymentStatement
    }
  }
`;
