import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'chitin-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class ChitinAccordionComponent {
  @Input()
  title?: string;
  @Input()
  isOpen: boolean = false;

  @Output()
  isOpenChanges = new EventEmitter<boolean>();

  protected toggleOpen() {
    this.isOpen = !this.isOpen;
    this.isOpenChanges.next(this.isOpen);
  }
}
