import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ChitinDateRangePickerComponent } from '@shared/components/chitin/molecules/daterange-picker/daterange-picker.component';

@Component({
  selector: 'app-formly-field-daterange',
  template: `
    <chitin-daterange-picker
      [formControl]="formControl"
      [ngClass]="{ 'is-invalid': !formControl.valid && formControl.touched && !field.props?.disabled }"
      [name]="field.name"
      [isDisabled]="field.props?.disabled ?? false"
      [readonly]="field.props?.readonly ?? false"
      [size]="field.props?.size"
      [placeholder]="field.props?.placeholder ?? ''"
      [minDate]="field.props?.minDate"
      [maxDate]="field.props?.maxDate"
    />
  `,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => ChitinDateRangePickerComponent) }],
})
export class FormlyFieldDaterangeComponent extends FieldType<FieldTypeConfig> {}
