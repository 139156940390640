import { inject, Injectable } from '@angular/core';
import {
  ROLE_ADMINISTRATOR,
  ROLE_FIELD_ADMIN,
  ROLE_FINANCE_ADMIN,
  ROLE_PROGRAM_ADMIN,
} from '@libs/shared/domain/master-data/user/shared-master-data-user-role.type';
import { AuthGuard } from '@services/guards/auth-guard';
import { PharmaciesSearchStore } from '@services/pharmacies.search.store';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthPharmacyGuard extends AuthGuard {
  private readonly pharmaciesStore = inject(PharmaciesSearchStore);

  async isAccessGranted(componentName: string, featureFlagKey: string, params: Record<string, string>) {
    const access = await super.isAccessGranted(componentName, featureFlagKey, params);
    const granted = access && (await this.isPharmacyAvailable(params));
    if (!granted) console.warn('AuthPharmacyGuard', params);
    return granted;
  }

  async isPharmacyAvailable(params: Record<string, string>) {
    const ncpdp = params.ncpdp;

    if (!ncpdp) {
      console.error('AuthPharmacyGuard: Param ncpdp is required', ncpdp);
      return false;
    }

    const pharmacy = await lastValueFrom(this.pharmaciesStore.get(ncpdp));
    const user = this.userService.getCurrentUser();

    if (user.role === ROLE_PROGRAM_ADMIN) {
      const tenantId = user.tenants?.[0];
      const enrollment = pharmacy?.enrollments.find(item => item.tenantId === tenantId);
      if (!enrollment) console.error('AuthPharmacyGuard: Enrollment missing for pharmacy and tenant', ncpdp, tenantId);
      return !enrollment?.programGroupIsBlocked;
    } else if (user.role === ROLE_FIELD_ADMIN) {
      const userRegions = user.business_regions ?? {};
      let check = true;
      pharmacy?.enrollments.forEach(item => {
        const tenantRegions = userRegions[item.tenantId];
        const region = item.businessRegionId;
        if (region && tenantRegions && !tenantRegions.includes(region)) check = false;
      });
      return check;
    } else if ([ROLE_ADMINISTRATOR, ROLE_FINANCE_ADMIN].includes(user.role)) {
      return true;
    }

    return (user.ncpdps ?? []).includes(ncpdp);
  }
}
