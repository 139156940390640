import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { NgChanges } from '@shared/ng-changes';
import Utils from '@shared/providers/utils';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

type Modifier =
  | string
  | 'small-bottom-margin'
  | 'small-gray'
  | 'small-white'
  | 'width-48'
  | 'uppercase'
  | 'height-40'
  | 'inline-input'
  | 'height-25';
type ToolTipLocation = 'top' | 'right' | 'bottom';
type TextControlType = 'text' | 'number' | 'tel' | 'currency' | 'date';

@Component({
  selector: 'app-text-control',
  templateUrl: './text-control.component.html',
  styleUrls: ['./text-control.component.scss'],
})
export class TextControlComponent implements OnInit, OnChanges, OnDestroy {
  static readonly DEFAULT_MIN_LENGTH = 0;
  static readonly DUMMY_MAX_LENGTH = 300;
  static readonly EXPONENT_CHARACTER = 'e';

  @Input() label?: string;
  @Input() id?: string;
  @Input() disabled = false;
  @Input() focus = false;
  @Input() isLoading = false;
  @Input() placeholder = '';
  @Input() clearable = false;
  @Input() tooltip: string;
  @Input() tooltipIconText: string;
  @Input() useTooltipIcon = false;
  @Input() allowNoneText: string;
  @Input() maxWidthPercent = '100';
  @Input() includeDoNotKnow = false;
  @Input() isPreferred = false;
  @Input() minLength = TextControlComponent.DEFAULT_MIN_LENGTH;
  @Input() maxLength = TextControlComponent.DUMMY_MAX_LENGTH;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() emissionDelayInMs = 0;
  @Input() controlCssClass = '';
  @Input() set value(value: any) {
    this._value = value;
  }
  @Input() set type(type: TextControlType) {
    this.onType(type);
  }
  @Input() set modifiers(modifiers: Modifier[]) {
    this.onModifiers(modifiers);
  }
  @Input() set editable(editable: boolean) {
    this._editable = editable;
  }
  @Input() set displayAsText(displayAsText: boolean) {
    this._displayAsText = displayAsText;
  }
  @Input() set tooltipLocation(location: ToolTipLocation) {
    this.onTooltipLocation(location);
  }
  @Input() set allowNone(allowNone: boolean) {
    this.onAllowNone(allowNone);
  }
  @Input() set reset(reset: any) {
    this.clear();
  }

  @Output() valueChange = new EventEmitter<any>();
  @Output() keyUpEnter = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();
  @Output() none = new EventEmitter<boolean>();
  @Output() doNotKnow = new EventEmitter<boolean>();

  _type = 'text';
  _value: any;
  _allowNone = false;
  randomId: string;
  noValue: boolean;
  _displayAsText: boolean;
  _editable: boolean;
  isTooltipLocationTop = false;
  isTooltipLocationRight = false;
  isTooltipLocationBottom = false;
  _modifiers: string;
  _doNotKnow = false;
  isNumberType = false;
  isPhoneType = false;
  isCurrencyType = false;

  private valueDebouncer = new Subject<any>();
  private componentDestroyed = new Subject<void>();

  @ViewChild('inputField', { static: true }) inputField: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.randomId = this.generateRandomIdForInputField();
    this.debounceValueEmissions();
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes && changes.focus) {
      this.inputField?.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  debounceValueEmissions(): void {
    this.valueDebouncer
      .pipe(debounceTime(this.emissionDelayInMs))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(value => {
        this.valueChange.next(this._value);
        this.doNotKnow.emit(this._doNotKnow);
      });
  }

  private generateRandomIdForInputField(): string {
    return Math.trunc(Math.random() * 1000000).toString();
  }

  onType(type: TextControlType): void {
    this._type = type;
    this.isPhoneType = type === 'tel';
    this.isNumberType = type === 'number';
    this.isCurrencyType = type === 'currency';
  }

  onModifiers(modifiers: Modifier[]): void {
    this._modifiers = Utils.getModifiedStringArrayAsString(modifiers, 'text-control--', 'prepend');
  }

  onAllowNone(allowNone: boolean): void {
    this._allowNone = allowNone;
    if (allowNone) {
      this.clear();
    }
  }

  onTooltipLocation(newLocation: ToolTipLocation): void {
    this.isTooltipLocationTop = newLocation === 'top';
    this.isTooltipLocationRight = newLocation === 'right';
    this.isTooltipLocationBottom = newLocation === 'bottom';
  }

  clear(): void {
    this._value = '';
    this.cdr.detectChanges();
  }

  clearAndEmit(): void {
    this.clear();
    this.emitChange();
  }

  onAllowNoneClick(): void {
    this.none.emit(this.noValue);
    this.clearAndEmit();
  }

  emitChange(value?: any, event?: any): void {
    if (this.checkValueCharacterType(value, event)) {
      this.checkValueLength(value, event);
    }
    this.valueDebouncer.next(value);
  }

  /*
   * Returns true when value consists of all correct type characters,
   * and false otherwise
   */
  checkValueCharacterType(value?: any, event?: any): boolean {
    if (this.doNotUpdateValue(value)) {
      event.target.value = this._value || '';
      return false;
    }
    return true;
  }

  doNotUpdateValue(value?: any): boolean {
    if (this.isNumberType) {
      return !this.isValueAValidWholeNumber(value);
    } else if (this.isCurrencyType) {
      return !this.isValueAValidCurrency(value);
    }
    return false;
  }

  isValueAValidWholeNumber(value: any): boolean {
    return this.isValueAValidCurrency(value) && !value.toString().includes('.');
  }

  isValueAValidCurrency(value: any): boolean {
    return !isNaN(value) && !value.toString().includes(TextControlComponent.EXPONENT_CHARACTER);
  }

  checkValueLength(value?: any, event?: any): void {
    if (!value || value.length <= this.maxLength) {
      this._value = !value ? '' : value;
      this._value = this.doesValueNeedToBeUppercased() ? this._value.toUpperCase() : this._value;
    } else {
      event.target.value = this._value;
    }
  }

  doesValueNeedToBeUppercased(): boolean {
    return Utils.isNonEmptyString(this._modifiers) && this._modifiers.includes('uppercase');
  }

  onDoNotKnow(): void {
    this._doNotKnow = true;
    this._displayAsText = true;
    this.clearAndEmit();
    this.doNotKnow.emit(true);
  }

  get showToolTipOnRight(): boolean {
    return !!this.tooltip && this.isTooltipLocationRight && !this._displayAsText && !this._doNotKnow;
  }
}
