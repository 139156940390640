<div class="modal-header" data-cy="downloadDataScreen">
  <h3 class="modal-title">
    @if (exportFailed) {
      Data export failed
    } @else if (exportExpired) {
      Data export expired
    } @else {
      Data exported
    }
  </h3>
  <button type="button" class="btn-close" data-cy="closeBtn" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body d-flex flex-column gap-4">
  @if (exportFailed) {
    Data export failed due to a timeout error. The requested data size exceeds the limit for exporting.
    <br />
    <br />
    Please refine your export parameters or contact support for assistance.
  } @else if (exportExpired) {
    <div class="align-self-center text-center">The links has expired - please generate a new export.</div>
  } @else {
    <div class="align-self-center">You can download the file at this link:</div>
    <div class="align-self-center"><a [href]="data.fileUrl" target="_blank">Download</a></div>
  }
</div>
