import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskify',
  pure: true,
})
export class MaskifyPipe implements PipeTransform {
  transform(value: string, lastCharsCount: number = 0, replaceChar = '*'): string {
    if (!value) return '';

    if (lastCharsCount === 0) lastCharsCount = -value.length;
    return value.slice(0, -lastCharsCount).replace(/./g, replaceChar) + value.slice(-lastCharsCount);
  }
}
