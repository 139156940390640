import { pharmacyChainFragment } from '@services/graphql/pharmacy/chain/pharmacy-chain.fragment';
import gql from 'graphql-tag';

export const GetPharmacyChainsQuery = gql`
  ${pharmacyChainFragment}
  query GetPharmacyChains($filter: PharmacyChainFilterInput!, $page: ConnectionEdgePage!) {
    pharmacyChains(filter: $filter, page: $page) {
      edges {
        node {
          ...PharmacyChain
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
