import { Component, Input, TemplateRef } from '@angular/core';
import { PaginatorComponent } from '@app/shared/components/data-table/paginator/paginator.component';

@Component({
  selector: 'app-infiniscroll-paginator',
  templateUrl: './infiniscroll-paginator.component.html',
  styleUrls: ['./infiniscroll-paginator.component.scss'],
})
export class InfiniscrollPaginatorComponent extends PaginatorComponent {
  @Input() detailTemplate?: TemplateRef<any>;
  Math = Math;
}
