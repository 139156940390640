import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { set } from 'lodash';
import { NgxMaskService } from 'ngx-mask';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-formly-field-phone',
  template: `<input
    type="text"
    class="form-control"
    [ngClass]="{ 'is-invalid': !formControl.valid && formControl.touched && !field.props?.disabled }"
    [formControl]="formControl"
    [attr.disabled]="field.props?.disabled ? true : null"
    [attr.readonly]="field.props?.readonly ? true : null"
    [formlyAttributes]="field"
  />`,
})
export class FormlyFieldPhoneComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  readonly maskService = inject(NgxMaskService);
  mask = '000-000-0000';
  valueChanges?: Subscription;

  ngOnInit() {
    if (this.field.props?.mask) {
      this.mask = this.field.props.mask;
    }

    this.applyMask(this.formControl.value);
    this.valueChanges = this.formControl.valueChanges.subscribe(input => this.applyMask(input));
  }

  ngOnDestroy() {
    this.valueChanges?.unsubscribe();
  }

  private applyMask(input: string) {
    const value = this.maskService.applyMask(input, this.mask);
    this.formControl.setValue(value, { emitEvent: false });
    set(this.model, this.key, value);
  }
}
