export interface IProduct {
  ndc: string;
  name: string;
  dosage?: string;
  brand?: string;
  howSupplied?: string;
  quantityUnit?: string;
}

export class Product implements IProduct {
  ndc: string;
  name: string;
  dosage?: string;
  brand?: string;
  howSupplied?: string;
  quantityUnit?: string;

  constructor(productFields: Partial<IProduct> = {}) {
    Object.assign(this, productFields);
  }

  get combinedName(): string {
    let name = `${this.name} ${this.howSupplied || ''}`;
    if (this.ndc) {
      name += ` (${this.ndc})`;
    }
    return name;
  }

  static isProductInList(product: Partial<IProduct>, listOfProducts: Product[]) {
    return listOfProducts.some(productInList => {
      let productInListId = productInList.name;
      if (productInList.ndc) {
        productInListId += productInList.ndc;
      }

      let productId = product.name;
      if (product.ndc) {
        productId += product.ndc;
      }

      return productInListId === productId;
    });
  }
}
