import { Component, computed, input } from '@angular/core';
import { PatientAssistanceRequestViewModel } from '@services/models/patient-assistance-request-view.model';

@Component({
  selector: 'app-shared-claim-flags',
  templateUrl: './claim-flags.component.html',
  styleUrl: './claim-flags.component.scss',
})
export class ClaimFlagsComponent {
  claim = input.required<PatientAssistanceRequestViewModel>();
  size = input<'regular' | 'small'>('regular');
  flagsCount = computed(() => this.claim().flagsList?.length ?? 0);
  flagsFillsRemaning = computed(() => {
    const refillsAuthorizedCount = this.claim().refillsAuthorizedCount ?? 0;
    const fillNumber = Number.parseInt(this.claim().fillNumber ?? '0');
    return Math.max(0, refillsAuthorizedCount - fillNumber);
  });
}
