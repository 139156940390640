import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component';

export interface FormModel {
  value?: string;
  error?: string;
}
export interface FormOption {
  label?: string;
  value: string;
}

@Component({
  selector: 'prescriber-form-input',
  templateUrl: 'form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class PrescriberFormInputComponent extends BaseComponent {
  private _model: FormModel;
  protected _isCustomSelected = false;

  get model() {
    return this._model;
  }
  @Input() set model(newModel: FormModel) {
    this._model = newModel;
    this.modelChange.emit(newModel);
  }
  @Input() type?: 'text' | 'date' | 'option' | 'tel';
  @Input() options?: FormOption[];
  @Input() allowCustomValue?: boolean;
  @Input() label?: string;
  @Input() labelStyles?: string;
  @Input() disabled: boolean = false;
  @Output() modelChange = new EventEmitter<FormModel>();

  clearErrors() {
    this._model.error = undefined;
  }

  onOptionSelected(option: string) {
    this._isCustomSelected = false;
    this.model = {
      ...this.model,
      error: undefined,
      value: this._model.value === option ? undefined : option,
    };
  }

  onCustomSelected() {
    this.model = {
      ...this.model,
      error: undefined,
      value: undefined,
    };
    this._isCustomSelected = !this._isCustomSelected;
  }

  onCustomChange(value: string) {
    this.model = {
      ...this.model,
      error: undefined,
      value,
    };
  }
}
