import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UrlService } from '@services/url.service';
import { SettingSubjectType } from '@shared/models/settings/settings';
import { chain } from 'lodash';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

type SubjectType = SettingSubjectType | string;

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private readonly http = inject(HttpClient);
  private readonly url = inject(UrlService);
  public readonly $settingsChanged = new Subject<SettingSubjectType>();

  get<TSetting>(subject: SubjectType, attribute: string, useCache: boolean = false): Observable<TSetting | undefined> {
    const url = this.url.LOOKUPS_URL.replace('{subject}', subject).replace('{attribute}', attribute);
    return this.http
      .get<{ value: TSetting }>(url, {
        params: new HttpParams({
          fromObject: {
            useCache,
          },
        }),
      })
      .pipe(
        catchError(() => of(undefined)),
        map(result => result?.value),
      );
  }

  /**
   * @deprecated
   * @see getTenant
   */
  getSettings<TSetting>(subject: SubjectType, tenantId = 'DEFAULT'): Observable<TSetting | undefined> {
    return this.getTenant(subject, tenantId);
  }

  getList<TSetting>(subject: SubjectType, useCache: boolean = false): Observable<TSetting[]> {
    const url = this.url.LOOKUPS_LIST_URL.replace('{subject}', subject);
    return this.http
      .get<{ items: Array<{ value: TSetting }> }>(url, {
        params: new HttpParams({
          fromObject: {
            useCache,
          },
        }),
      })
      .pipe(map(result => result.items.map(item => item.value)));
  }

  getTenant<TSetting>(subject: SubjectType, tenantId = 'DEFAULT', useCache: boolean = false): Observable<TSetting | undefined> {
    return this.get<TSetting>(subject, `tenant:${tenantId}`, useCache);
  }

  getTenants<TSetting>(subject: SubjectType, tenantIds: string[]): Observable<Record<string, TSetting | undefined>> {
    return forkJoin(
      chain(tenantIds)
        .keyBy()
        .mapValues(id => this.getTenant<TSetting>(subject, id))
        .value(),
    );
  }

  saveSettings<TSetting>(subject: SubjectType, attribute: string, value: TSetting): Observable<TSetting | Error> {
    const url = this.url.LOOKUPS_URL.replace('{subject}', subject).replace('{attribute}', attribute);
    return this.http.post<{ value: TSetting }>(url, { value }).pipe(catchError(error => of(error)));
  }
}
