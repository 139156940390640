import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrinterService {
  constructor() {}

  print(htmlToPrint: string): void {
    const styles = this.getPageStyles();
    const windowFeatures = 'width=600,height=700,scrollbars=yes,menubar=no,toolbar=no,location=no,status=no,titlebar=no';
    const popupWindow = window.open('', '_pageToPrint', windowFeatures);
    popupWindow?.document.open();
    popupWindow?.document.write(
      '<html><head><link rel="stylesheet" type="text/css" href="styles.css" /><style>' +
        styles +
        '</style></head><body onload="window.print()" onafterprint="self.close()">' +
        htmlToPrint +
        '</body></html>',
    );
    popupWindow?.document.close();
  }

  private getPageStyles(): string {
    let styles = '';

    document.querySelectorAll('style').forEach(element => {
      styles += element.innerHTML;
    });

    return styles;
  }
}
