import gql from 'graphql-tag';

export const GetPharmacyForFamilyQuery = gql`
  query GetPharmacyForFamily($ncpdp: String!) {
    pharmacy(ncpdp: $ncpdp) {
      contactPerson {
        firstName
        lastName
      }
      name
      ncpdp
      npi
      family
      pharmacyChain {
        npi
        name
      }
    }
  }
`;
