import { SelectListItem } from '@shared/models/select-list-item';

export class StatesService {
  static getStates(): SelectListItem[] {
    const states = [];

    states.push({ name: 'AL', value: 'AL' });
    states.push({ name: 'AK', value: 'AK' });
    states.push({ name: 'AR', value: 'AR' });
    states.push({ name: 'AZ', value: 'AZ' });
    states.push({ name: 'CA', value: 'CA' });
    states.push({ name: 'CT', value: 'CT' });
    states.push({ name: 'CO', value: 'CO' });
    states.push({ name: 'DC', value: 'DC' });
    states.push({ name: 'DE', value: 'DE' });
    states.push({ name: 'FL', value: 'FL' });
    states.push({ name: 'GA', value: 'GA' });
    states.push({ name: 'GU', value: 'GU' });
    states.push({ name: 'HI', value: 'HI' });
    states.push({ name: 'IA', value: 'IA' });
    states.push({ name: 'ID', value: 'ID' });
    states.push({ name: 'IL', value: 'IL' });
    states.push({ name: 'IN', value: 'IN' });
    states.push({ name: 'KS', value: 'KS' });
    states.push({ name: 'KY', value: 'KY' });
    states.push({ name: 'LA', value: 'LA' });
    states.push({ name: 'MA', value: 'MA' });
    states.push({ name: 'MD', value: 'MD' });
    states.push({ name: 'ME', value: 'ME' });
    states.push({ name: 'MI', value: 'MI' });
    states.push({ name: 'MN', value: 'MN' });
    states.push({ name: 'MO', value: 'MO' });
    states.push({ name: 'MS', value: 'MS' });
    states.push({ name: 'MT', value: 'MT' });
    states.push({ name: 'NC', value: 'NC' });
    states.push({ name: 'ND', value: 'ND' });
    states.push({ name: 'NE', value: 'NE' });
    states.push({ name: 'NH', value: 'NH' });
    states.push({ name: 'NJ', value: 'NJ' });
    states.push({ name: 'NM', value: 'NM' });
    states.push({ name: 'NY', value: 'NY' });
    states.push({ name: 'NV', value: 'NV' });
    states.push({ name: 'OH', value: 'OH' });
    states.push({ name: 'OK', value: 'OK' });
    states.push({ name: 'OR', value: 'OR' });
    states.push({ name: 'PA', value: 'PA' });
    states.push({ name: 'PR', value: 'PR' });
    states.push({ name: 'RI', value: 'RI' });
    states.push({ name: 'SC', value: 'SC' });
    states.push({ name: 'SD', value: 'SD' });
    states.push({ name: 'TN', value: 'TN' });
    states.push({ name: 'TX', value: 'TX' });
    states.push({ name: 'UT', value: 'UT' });
    states.push({ name: 'VA', value: 'VA' });
    states.push({ name: 'VI', value: 'VI' });
    states.push({ name: 'VT', value: 'VT' });
    states.push({ name: 'WA', value: 'WA' });
    states.push({ name: 'WI', value: 'WI' });
    states.push({ name: 'WV', value: 'WV' });
    states.push({ name: 'WY', value: 'WY' });

    return states;
  }

  static getStatesAsStrings(): string[] {
    return StatesService.getStates().map(item => item.name);
  }

  static getStatesAsOptions(): SelectListItem[] {
    return StatesService.getStates().map(item => {
      return { value: item.name, name: item.name };
    });
  }
}
