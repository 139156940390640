import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import config from '@config';
import { GrowthBook, LoadFeaturesOptions } from '@growthbook/growthbook';
import { AuthService } from '@services/auth.service';
import { TenantService } from '@services/tenant.service';
import { UserService } from '@services/user.service';
import { FeatureFlagContextAttributes } from '@shared/models/feature-flag-context-attributes';
import { IUser } from '@shared/models/user.model';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  public flags: BehaviorSubject<GrowthBook>;
  private gb: GrowthBook;
  private identity: IUser | undefined = undefined;

  public constructor(
    protected readonly authService: AuthService,
    protected readonly userService: UserService,
    protected readonly tenantSerivce: TenantService,
  ) {
    this.gb = new GrowthBook({
      clientKey: config.growthbook.clientKey,
      enableDevMode: config.growthbook.enabledDevMode,
      attributes: {
        version: config.version,
      },
    });
    this.flags = new BehaviorSubject(this.gb);

    this.authService.authStatusChanged.subscribe(() => {
      this.setUser();
    });

    this.userService.userChanged.subscribe(() => {
      this.setUser();
    });

    this.tenantSerivce.activeTenantChanged.subscribe(() => {
      this.setTenant();
    });

    this.setUser();
  }

  async load(options?: LoadFeaturesOptions) {
    await this.gb.loadFeatures(options);
    this.flags.next(this.gb);
  }

  isOnWithContext(key: string, context: FeatureFlagContextAttributes): boolean {
    const contextGb: GrowthBook = cloneDeep(this.gb);
    contextGb.setAttributes({
      ...contextGb.getAttributes(),
      ...this.mapContextAttributes(context),
    });

    return contextGb.isOn(key);
  }

  isOn(key: string): boolean {
    return this.gb.isOn(key);
  }

  select(key: string) {
    return toSignal(this.flags.pipe(map(flags => flags.isOn(key))), { requireSync: true });
  }

  getJsonValue(key: string, fallback?: Record<string, unknown>): Record<string, unknown> | undefined {
    return this.gb.getFeatureValue<Record<string, unknown> | undefined>(key, fallback);
  }

  getStringValue(key: string, fallback?: string): string | undefined {
    return this.gb.getFeatureValue<string | undefined>(key, fallback);
  }

  exists(key: string): boolean {
    return !!this.gb.getFeatures()[key];
  }

  getKeyForUrl(url: string): string {
    return `path:${url.replaceAll('/', '|')}`;
  }

  getUrlFromKey(key: string): string {
    return key.replaceAll('|', '/').substring('path:'.length);
  }

  protected setUser(): void {
    const newUser = this.authService.isUserAuthenticated() ? this.userService.getCurrentUser() : null;
    if (newUser && (!this.identity || newUser?.username !== this.identity.username || newUser?.role !== this.identity.role)) {
      this.identity = newUser;

      if (this.identity) {
        const attributes = this.gb.getAttributes();
        this.gb.setAttributes({
          ...attributes,
          id: this.identity.username,
          role: this.identity.role,
          ncpdps: this.identity.ncpdps,
          tenantIds: this.tenantSerivce.usersTenants?.map(t => t.id),
          currentTenantId: this.tenantSerivce.currentTenant?.id,
        });
      }

      this.reload();
    }
  }

  protected setTenant(): void {
    if (this.identity) {
      const attributes = this.gb.getAttributes();
      this.gb.setAttributes({
        ...attributes,
        tenantIds: this.tenantSerivce.usersTenants?.map(t => t.id),
        currentTenantId: this.tenantSerivce.currentTenant?.id,
      });
    }

    this.reload();
  }

  protected reload(): void {
    this.gb.loadFeatures({ autoRefresh: true }).then(() => {
      this.flags.next(this.gb);
    });
  }

  private mapContextAttributes(context: FeatureFlagContextAttributes): Record<string, any> {
    const attributes: Record<string, any> = {};
    Object.keys(context).forEach((key: string) => {
      attributes[`context${key.charAt(0).toUpperCase() + key.slice(1)}`] = context[key as keyof FeatureFlagContextAttributes];
    });
    attributes.version = config.version;

    return attributes;
  }
}
