import { Component, Input, OnInit } from '@angular/core';
import { WindowService } from '@services/window.service';

@Component({
  selector: 'app-horizon-ecmonarch-terms-conditions',
  templateUrl: './horizon-ecmonarch-terms-conditions.component.html',
  styleUrls: ['./horizon-ecmonarch-terms-conditions.component.scss'],
})
export class HorizonEcmonarchTermsConditionsComponent implements OnInit {
  @Input() shouldShowHeader = true;

  isMobile = false;
  isTablet = false;

  constructor(private windowService: WindowService) {}

  ngOnInit() {
    this._checkDeviceType();
  }

  private _checkDeviceType(): void {
    this.windowService.isMobile.subscribe(newIsMobile => {
      this.isMobile = newIsMobile;
    });

    this.windowService.isTablet.subscribe(newIsTablet => {
      this.isTablet = newIsTablet;
    });
  }
}
