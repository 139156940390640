import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-formly-field-password',
  template: `
    <div class="input-group">
      <input
        [type]="showPassword ? 'text' : 'password'"
        class="form-control"
        [ngClass]="{ 'is-invalid': !formControl.valid && formControl.touched && !field.props?.disabled }"
        [formControl]="formControl"
        [attr.disabled]="field.props?.disabled ? true : null"
        [attr.readonly]="field.props?.readonly ? true : null"
        [formlyAttributes]="field"
        [autocomplete]="field.props?.autocomplete"
      />
      <button
        (click)="showPassword = !showPassword"
        type="button"
        class="btn"
        style="box-shadow: none;border: 1px solid var(--bs5-border-color);"
      >
        @if (showPassword) {
          <i class="fa-fw fas fa-eye-slash"></i>
        } @else {
          <i class="fa-fw fas fa-eye"></i>
        }
      </button>
    </div>
  `,
})
export class FormlyFieldPasswordComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  fieldSubscription?: Subscription;
  showPassword = false;

  ngOnInit() {
    this.setInputFields();
    if (this.field.options?.fieldChanges)
      this.fieldSubscription = this.field.options.fieldChanges.subscribe(() => {
        this.setInputFields();
      });
  }

  ngOnDestroy() {
    this.fieldSubscription?.unsubscribe();
  }

  private setInputFields() {
    this.showPassword = this.field.props?.showPassword ?? false;
  }
}
