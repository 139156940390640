import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TableData } from '@shared/models/table-data.model';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Utils from '@shared/providers/utils';
import { Program } from '@shared/models/program.model';
import { TableValue } from '@shared/models/table-value.model';
import { IProgramGroup } from '@shared/models/program-group.model';
import { PharmacyUtilityService } from '@services/pharmacy-utility.service';
import { SelectListItem } from '@shared/models/select-list-item';
import { TenantService } from '@services/tenant.service';
import { TableAction } from '@shared/models/table-action.model';
import { TableRow } from '@shared/models/table-row.model';
import { EnrolledProgram } from '@shared/models/enrolled-program.model';
import { ProgramGroupUtilityService } from '@services/program-group-utility.service';
import { ProgramGroupStateService } from '@services/program-group-state.service';
import { ProgramStateService } from '@services/program-state.service';
import { ProgramUtilityService } from '@services/program-utility.service';

type DailyOrWeeklyLimit = 'daily_limit' | 'weekly_limit';

@Component({
  selector: 'app-program-group-list-table',
  templateUrl: './program-group-list-table.component.html',
  styleUrls: ['./program-group-list-table.component.scss'],
})
export class ProgramGroupListTableComponent implements OnInit, OnDestroy {
  @Input() set pharmacy(newPharmacy: IPharmacy) {
    this.onSetPharmacy(newPharmacy);
  }
  @Input() disabled = false;

  @Output() pharmacyChange = new EventEmitter<IPharmacy>();

  private _pharmacy: IPharmacy;
  programGroupListItem?: SelectListItem;

  defaultProgramsForProgramGroup: Program[];
  allProgramsForTenant: Program[];
  allProgramsToShowForPharmacy: Program[] = [];

  tableData = {} as TableData;

  allProgramGroups: IProgramGroup[];
  allProgramGroupsListItems: SelectListItem[];
  private hasRetrievedNewProgramGroups = false;

  private readonly COLUMN_INDEX = {
    PROGRAM_ENROLLMENT_STATUS: 0,
    PROGRAM_NAME: 1,
    DAILY_LIMIT: 3,
    WEEKLY_LIMIT: 4,
    PROGRAM_ID: 5,
  };

  showAllPrograms = false;
  currentTenantName: string;

  initialPharmacyGotten = false;

  showTable = false;

  private hasProgramGroupBeenChanged = false;

  private unsubscribe = new Subject<void>();

  constructor(
    private programGroupUtilityService: ProgramGroupUtilityService,
    private programGroupStateService: ProgramGroupStateService,
    private programStateService: ProgramStateService,
    private programUtilityService: ProgramUtilityService,
    private pharmacyUtilityService: PharmacyUtilityService,
    private tenantService: TenantService,
  ) {}

  ngOnInit() {
    this.getCurrentTenant();
    this.observeAllProgramGroups();
    this.getAllProgramsForCurrentTenant();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get pharmacy(): IPharmacy {
    return this._pharmacy;
  }

  get programGroup(): IProgramGroup | undefined {
    return this.programGroupUtilityService.getProgramGroupFromId(this.pharmacy.program_group);
  }

  private onSetPharmacy(newPharmacy: IPharmacy): void {
    this._pharmacy = Utils.copyObject(newPharmacy);
    if (!this.initialPharmacyGotten && newPharmacy && newPharmacy.ncpdp) {
      const pharmacyProgramGroup = this.programGroupUtilityService.getProgramGroupFromId(newPharmacy.program_group);
      this.retrieveProgramsForProgramGroup(pharmacyProgramGroup!);
      if (Utils.isNonEmptyArray(this.allProgramGroupsListItems)) {
        this.programGroupListItem = this.allProgramGroupsListItems.find(
          programGroupListItem => programGroupListItem.value === newPharmacy.program_group,
        );
      }
      this.initialPharmacyGotten = true;
    }
  }

  retrieveProgramsForCurrentProgramGroup(): void {
    this.hasProgramGroupBeenChanged = true;
    this.pharmacy.program_group = this.programGroupListItem?.value;
    const programGroup = this.programGroupUtilityService.getProgramGroupFromId(this.pharmacy.program_group);
    this.retrieveProgramsForProgramGroup(programGroup!);
  }

  private retrieveProgramsForProgramGroup(programGroup: IProgramGroup): void {
    this.defaultProgramsForProgramGroup = this.programGroupUtilityService.getProgramsFromProgramGroup(programGroup);
    this.setProgramsForPharmacy();
    this.setProgramGroupListItem(programGroup);
    this.setShowTable();
  }

  private setProgramGroupListItem(programGroup: IProgramGroup): void {
    if (programGroup) {
      this.programGroupListItem = {
        name: programGroup.name,
        value: programGroup.id,
      };
    }
  }

  private setProgramsForPharmacy(): void {
    this.setAllProgramsToShowToDefaultPrograms();
    this.setEnrolledProgramsForPharmacy();
    this.addEnrolledProgramsToProgramsToShow();
    this.pharmacyChange.emit(this.pharmacy);
    this.setupTableData();
  }

  private setAllProgramsToShowToDefaultPrograms(): void {
    this.allProgramsToShowForPharmacy = Utils.isNonEmptyArray(this.defaultProgramsForProgramGroup)
      ? [...this.defaultProgramsForProgramGroup]
      : [];
  }

  private setEnrolledProgramsForPharmacy(): void {
    if (this.programGroup && this.programGroup.is_blocked) {
      this.pharmacy.enrolled_programs = undefined;
    } else if (this.pharmacy && this.programGroup && this.hasProgramGroupBeenChanged) {
      this.pharmacy.enrolled_programs = this.defaultProgramsForProgramGroup.map(defaultProgram => {
        return {
          program_id: defaultProgram.copay_program_id,
          tenant: defaultProgram.tenant,
          daily_limit: this.programGroup?.default_programs?.find(
            defaultProgramInGroup => defaultProgramInGroup.program_id === defaultProgram.copay_program_id,
          )?.daily_limit,
          weekly_limit: this.programGroup?.default_programs?.find(
            defaultProgramInGroup => defaultProgramInGroup.program_id === defaultProgram.copay_program_id,
          )?.weekly_limit,
        } as any;
      });
    }
  }

  private addEnrolledProgramsToProgramsToShow(): void {
    if (this.pharmacy && this.pharmacy.enrolled_programs) {
      const enrolledPrograms = this.programUtilityService.getEnrolledProgramsFromPharmacy(this.pharmacy);
      enrolledPrograms.forEach(enrolledProgram => {
        if (!this.isProgramBeingShown(enrolledProgram)) {
          this.allProgramsToShowForPharmacy.push(enrolledProgram);
        }
      });

      if (this.showAllPrograms) {
        this.addRemainingProgramsForCurrentTenant();
      }
    }
  }

  private isProgramBeingShown(program: Program): boolean {
    return this.allProgramsToShowForPharmacy.some(shownProgram => shownProgram.copay_program_id === program.copay_program_id);
  }

  private addRemainingProgramsForCurrentTenant(): void {
    this.allProgramsForTenant.forEach(tenantProgram => {
      if (!this.allProgramsToShowForPharmacy.find(program => program.copay_program_id === tenantProgram.copay_program_id)) {
        this.allProgramsToShowForPharmacy.push(tenantProgram);
      }
    });
  }

  private getCurrentTenant(): void {
    this.currentTenantName = this.tenantService.currentTenant?.name ?? '';
  }

  private observeAllProgramGroups(): void {
    this.programGroupStateService.allProgramGroupsForTenant.pipe(takeUntil(this.unsubscribe)).subscribe(programGroups => {
      this.allProgramGroups = programGroups;
      this.setupAllProgramGroupsListItems();
      this.setupTableData();
    });
  }

  private setupAllProgramGroupsListItems(): void {
    this.allProgramGroupsListItems = this.allProgramGroups.map(programGroup => {
      const listItem = { name: programGroup.name, value: programGroup.id };
      if (this.pharmacy && this.pharmacy.program_group === programGroup.id) {
        this.programGroupListItem = listItem;
      }

      return listItem;
    });

    this.setShowTable();
  }

  private getAllProgramsForCurrentTenant(): void {
    this.programStateService.allProgramsForTenant.subscribe(programResults => {
      if (Utils.isNonEmptyArray(programResults)) {
        this.allProgramsForTenant = programResults.filter(program => {
          const PROGRAM_IDS_TO_IGNORE = [ProgramUtilityService.BLOCKED_PROGRAM_ID, ProgramUtilityService.UNDEFINED_PROGRAM_ID];

          return !PROGRAM_IDS_TO_IGNORE.includes(program.copay_program_id);
        });
      }
    });
  }

  private setupTableData(): void {
    this.setupTableHeaders();
    this.setupTableRows();
    this.tableData = Utils.copyObject(this.tableData);
  }

  private setupTableHeaders(): void {
    this.tableData.headers = [
      {
        name: '', // Enrolled
        styles: this.getEnrolledColumnStyles(),
      },
      {
        name: 'Program Name',
        styles: this.getProgramNameColumnStyles(),
      },
      {
        name: 'Products',
        styles: this.getProductsColumnStyles(),
      },
      {
        name: 'Daily Limit',
        styles: this.getDailyOrWeeklyLimitColumnStyles(),
      },
      {
        name: 'Weekly Limit',
        styles: this.getDailyOrWeeklyLimitColumnStyles(),
      },
      {
        name: 'Program ID',
        styles: this.getProgramIdColumnStyles(),
      },
    ];
  }

  private getProgramIdColumnStyles() {
    return {
      flex: '0 0 0',
      display: 'none',
    };
  }

  private getEnrolledColumnStyles() {
    return { flex: '0.5 0 120px' };
  }

  private getProgramNameColumnStyles() {
    return { flex: '5 0 200px' };
  }

  private getProductsColumnStyles() {
    return { flex: '4 0 200px' };
  }

  private getDailyOrWeeklyLimitColumnStyles() {
    return { flex: '1 0 120px' };
  }

  private setupTableRows(): void {
    this.tableData.rows = [];

    this.allProgramsToShowForPharmacy.forEach(program => {
      if (program) {
        this.tableData.rows.push({
          entries: [
            {
              // Enrolled column
              values: {
                value: this.isPharmacyEnrolledInProgram(program),
                valueType: 'checkmark',
              },
              styles: this.getEnrolledColumnStyles(),
            },
            {
              // Program Name column
              values: {
                value: program.program_name,
              },
              styles: this.getProgramNameColumnStyles(),
            },
            {
              // Products column
              values: this.getTableEntryForProgramProducts(program),
              styles: this.getProductsColumnStyles(),
            },
            {
              // Daily Limit column
              values: this.getTableValueForProgramsDailyOrWeeklyLimit(program, 'daily_limit'),
              styles: this.getDailyOrWeeklyLimitColumnStyles(),
            },
            {
              // Weekly Limit column
              values: this.getTableValueForProgramsDailyOrWeeklyLimit(program, 'weekly_limit'),
              styles: this.getDailyOrWeeklyLimitColumnStyles(),
            },
            {
              // Program Id column
              values: {
                value: program.copay_program_id,
              },
              styles: this.getProgramIdColumnStyles(),
            },
          ],
        });
      }
    });
  }

  private getTableEntryForProgramProducts(program: Program): TableValue[] {
    return Utils.isNonEmptyArray(program.products) ? program.products.map(product => ({ value: product.name })) : [{ value: '' }];
  }

  private getTableValueForProgramsDailyOrWeeklyLimit(program: Program, limitType: DailyOrWeeklyLimit): TableValue {
    let tableValue: TableValue;

    if (this.programGroup && Utils.castToBool(this.programGroup.is_blocked)) {
      tableValue = this.getTableValueForBlockedProgramsDailyOrWeeklyLimit();
    } else if (this.isPharmacyEnrolledInProgram(program)) {
      tableValue = this.getTableValueForEnrolledProgramsDailyOrWeeklyLimit(program, limitType);
    } else if (this.isProgramInDefaultPrograms(program)) {
      tableValue = this.getTableValueForNotEnrolledDefaultProgramsDailyOrWeeklyLimit(program, limitType);
    } else {
      tableValue = this.getTableValueForDefaultDailyOrWeeklyLimit(program, limitType);
    }

    this.addStylesForDailyOrWeeklyTableValue(tableValue);

    return tableValue;
  }

  private isProgramInDefaultPrograms(program: Program): boolean {
    return this.programGroup?.default_programs?.some(defaultProgram => defaultProgram.program_id === program.copay_program_id) ?? false;
  }

  private getTableValueForBlockedProgramsDailyOrWeeklyLimit(): TableValue {
    return {
      value: 'BLOCKED',
      valueType: 'text',
    };
  }

  private getTableValueForEnrolledProgramsDailyOrWeeklyLimit(program: Program, limitType: DailyOrWeeklyLimit): TableValue {
    return {
      value:
        limitType === 'daily_limit'
          ? this.pharmacyUtilityService.getPharmacyEnrolledProgramDailyLimit(this.pharmacy, program)
          : this.pharmacyUtilityService.getPharmacyEnrolledProgramWeeklyLimit(this.pharmacy, program),
      valueType: 'numberForm',
      styleClasses: this.getStyleClassesForDailyOrWeeklyLimitTableValue(program, limitType),
    };
  }

  private getTableValueForNotEnrolledDefaultProgramsDailyOrWeeklyLimit(program: Program, limitType: DailyOrWeeklyLimit): TableValue {
    return {
      value:
        this.programGroup?.default_programs?.find(defaultProgram => defaultProgram.program_id === program.copay_program_id)?.[limitType] ??
        '',
      valueType: 'numberForm',
      styleClasses: this.getStyleClassesForDailyOrWeeklyLimitTableValue(program, limitType),
    };
  }

  private getTableValueForDefaultDailyOrWeeklyLimit(program: Program, limitType: DailyOrWeeklyLimit): TableValue {
    return {
      value: 0,
      valueType: 'numberForm',
      styleClasses: this.getStyleClassesForDailyOrWeeklyLimitTableValue(program, limitType),
    };
  }

  private getStyleClassesForDailyOrWeeklyLimitTableValue(program: Program, limitType: DailyOrWeeklyLimit): string[] {
    return this.hideLimitFieldForProgram(program, limitType)
      ? ['form-control', 'form-control--disabled', 'form-control--hide-disabled']
      : ['form-control'];
  }

  private hideLimitFieldForProgram(program: Program, limitType: DailyOrWeeklyLimit): boolean {
    return (limitType === 'daily_limit' && Utils.castToBool(program.is_weekly_batch_program)) || !this.isPharmacyEnrolledInProgram(program);
  }

  private addStylesForDailyOrWeeklyTableValue(tableValueForDailyOrWeeklyLimit: TableValue): void {
    tableValueForDailyOrWeeklyLimit.styles = { width: '100px' };
  }

  toggleShowAllPrograms(): void {
    this.showAllPrograms = !this.showAllPrograms;

    if (this.showAllPrograms) {
      this.addRemainingProgramsForCurrentTenant();
    } else {
      this.removeProgramsThatAreNotInProgramGroupOrPharmacyEnrolledPrograms();
    }

    this.setupTableData();
  }

  private removeProgramsThatAreNotInProgramGroupOrPharmacyEnrolledPrograms(): void {
    this.allProgramsForTenant.forEach(program => {
      if (!this.isProgramInDefaultPrograms(program) && !this.isPharmacyEnrolledInProgram(program)) {
        this.removeProgramFromDisplayedPrograms(program);
      }
    });
  }

  private isPharmacyEnrolledInProgram(program: Program): boolean {
    return this.pharmacyUtilityService.isPharmacyEnrolledInProgram(this.pharmacy, program);
  }

  private removeProgramFromDisplayedPrograms(programToRemove: Program): void {
    const programIndex = this.allProgramsToShowForPharmacy.findIndex(
      program => program.copay_program_id === programToRemove.copay_program_id,
    );
    if (programIndex > -1) {
      this.allProgramsToShowForPharmacy.splice(programIndex, 1);
    }
  }

  setShowTable(): void {
    this.showTable =
      this.pharmacy && !!this.programGroup && (!this.programGroup.is_blocked || Utils.castToBool(this.pharmacy.enrolled_programs));
  }

  onAction(tableAction: TableAction): void {
    const tableRow = tableAction.rowContainingAction;
    this.updateEnrolledProgramsForPharmacyFromTableRow(tableRow!);
  }

  private updateEnrolledProgramsForPharmacyFromTableRow(tableRow: TableRow): void {
    if (!this.pharmacy.enrolled_programs) {
      this._pharmacy.enrolled_programs = [] as EnrolledProgram[];
    }

    if (this.isPharmacyEnrolledInTableRowProgram(tableRow) && !this.getProgramEnrollmentStatusFromTableRow(tableRow)) {
      this.resetLimitFieldsForTableRow(tableRow);
      this.updatePharmacyProgramFromTableRow(tableRow);
      this.unenrollPharmacyFromTableRowProgram(tableRow);
      this.setLimitFieldsVisibilityForTableRow(tableRow);
    } else if (this.isPharmacyEnrolledInTableRowProgram(tableRow)) {
      this.updatePharmacyProgramFromTableRow(tableRow);
    } else {
      this.enrollPharmacyInTableRowProgram(tableRow);
      this.setLimitFieldsVisibilityForTableRow(tableRow);
    }

    this.pharmacyChange.emit(this._pharmacy);
  }

  resetLimitFieldsForTableRow(tableRow: TableRow): void {
    (tableRow.entries[this.COLUMN_INDEX.DAILY_LIMIT].values as TableValue).value = 0;
    (tableRow.entries[this.COLUMN_INDEX.WEEKLY_LIMIT].values as TableValue).value = 0;
  }

  setLimitFieldsVisibilityForTableRow(tableRow: TableRow): void {
    (tableRow.entries[this.COLUMN_INDEX.DAILY_LIMIT].values as TableValue).styleClasses =
      this.getStyleClassesForDailyOrWeeklyLimitTableValue(this.getProgramFromTableRow(tableRow), 'daily_limit');
    (tableRow.entries[this.COLUMN_INDEX.WEEKLY_LIMIT].values as TableValue).styleClasses =
      this.getStyleClassesForDailyOrWeeklyLimitTableValue(this.getProgramFromTableRow(tableRow), 'weekly_limit');
  }

  getProgramFromTableRow(tableRow: TableRow): Program {
    return this.allProgramsForTenant.find(program => program.program_name === this.getProgramNameFromTableRow(tableRow))!;
  }

  private isPharmacyEnrolledInTableRowProgram(tableRow: TableRow): boolean {
    return this.pharmacy.enrolled_programs?.some(program => program.program_id === this.getProgramIdFromTableRow(tableRow)) ?? false;
  }

  private unenrollPharmacyFromTableRowProgram(tableRow: TableRow): void {
    const programIdToRemove = this.getProgramIdFromTableRow(tableRow);
    const programIndex = this.pharmacy.enrolled_programs?.findIndex(program => program.program_id === programIdToRemove) ?? -1;
    if (programIndex > -1) {
      this._pharmacy.enrolled_programs?.splice(programIndex, 1);
    }
  }

  private updatePharmacyProgramFromTableRow(tableRow: TableRow): void {
    const program = this.pharmacy.enrolled_programs?.find(enrolledProgram => {
      return enrolledProgram.program_id === this.getProgramIdFromTableRow(tableRow);
    });
    if (program) {
      program.daily_limit = this.getDailyLimitFromTableRow(tableRow);
      program.weekly_limit = this.getWeeklyLimitFromTableRow(tableRow);
    }
  }

  private enrollPharmacyInTableRowProgram(tableRow: TableRow): void {
    this._pharmacy.enrolled_programs?.push({
      program_id: this.getProgramIdFromTableRow(tableRow),
      daily_limit: this.getDailyLimitFromTableRow(tableRow),
      weekly_limit: this.getWeeklyLimitFromTableRow(tableRow),
      tenant: this.tenantService.getCurrentTenantId(),
    });
  }

  private getProgramIdFromTableRow(tableRow: TableRow): string {
    return (tableRow.entries[this.COLUMN_INDEX.PROGRAM_ID].values as TableValue).value as string;
  }

  private getProgramNameFromTableRow(tableRow: TableRow): string {
    return (tableRow.entries[this.COLUMN_INDEX.PROGRAM_NAME].values as TableValue).value as string;
  }

  private getProgramEnrollmentStatusFromTableRow(tableRow: TableRow): boolean {
    return (tableRow.entries[this.COLUMN_INDEX.PROGRAM_ENROLLMENT_STATUS].values as TableValue).value as boolean;
  }

  private getDailyLimitFromTableRow(tableRow: TableRow): number {
    return (tableRow.entries[this.COLUMN_INDEX.DAILY_LIMIT].values as TableValue).value as number;
  }

  private getWeeklyLimitFromTableRow(tableRow: TableRow): number {
    return (tableRow.entries[this.COLUMN_INDEX.WEEKLY_LIMIT].values as TableValue).value as number;
  }
}
