import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-with-header-seperator-and-shadow',
  templateUrl: './card-with-header-seperator-and-shadow.component.html',
  styleUrls: ['./card-with-header-seperator-and-shadow.component.scss'],
})
export class CardWithHeaderSeperatorAndShadowComponent {
  @Input() headerText: string;

  constructor() {}
}
