import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import { BaseComponent } from '@shared/components/base-component';
import { NgChanges } from '@shared/ng-changes';
import { isArray, last, uniqBy } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

type ExtendedItem<T> = T & {
  group?: string;
};

const SELECT_ALL_VALUE = 'SELECT_ALL';

@Component({
  selector: 'chitin-select-old',
  templateUrl: './select-old.component.html',
  styleUrls: ['./select-old.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => ChitinSelectOldComponent) }],
})
export class ChitinSelectOldComponent<TItem, TValue>
  extends BaseComponent
  implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges
{
  @Input()
  inputId?: string;

  @Input()
  @HostBinding('attr.name')
  name = '';

  @Input()
  layout: 'block' | 'inline' = 'block';

  @Input()
  size: 'sm' | 'md' | 'lg' = 'md';

  @Input()
  buttonColor: 'primary' | 'danger' | 'success' | 'secondary' | 'text' = 'secondary';

  @Input()
  label?: string;

  @Input()
  displayType: 'input' | 'button' = 'input';

  @Input()
  set value(value: TValue) {
    this.rawValue = value;
    this.onValue(value);
  }

  @Input()
  valueExpr: string = 'value';

  @Input()
  displayExpr: string = 'name';

  @Input()
  items: Array<Record<string, ExtendedItem<TItem>>> = [];

  @Input()
  itemsStream?: Observable<Array<Record<string, ExtendedItem<TItem>>>>;

  @Input()
  multiple: boolean = false;

  @Input()
  showSelectAll: boolean = false;

  @Input()
  placeholder: string = '';

  @Input()
  isLoading = false;

  @Input()
  isDisabled: boolean = false;

  @Input()
  autoWidth = true;

  @Input()
  dropdownRtl = false;

  @Input()
  readonly: boolean = false;

  @Input()
  clearable: boolean = true;

  @Input()
  searchable: boolean | 'smart' = 'smart';

  @Input()
  groupItems: boolean = false;

  @Input()
  expandSelectedGroupItems: boolean = false;

  @Input()
  maxNumOfOptions?: number;

  @Input()
  displayNumberOfSelected: boolean = false;

  @Input()
  emissionDelayInMs?: number;

  @Input()
  forceClear: Observable<void>;

  @Input()
  appendTo?: string = 'body';

  @Input()
  dropdownPosition: DropdownPosition = 'auto';

  @Input()
  valueType: 'item' | 'value' = 'value';

  @Input()
  alwaysOpen?: boolean = false;

  @Input()
  checkboxView?: boolean = false;

  @Input()
  maxNumOfItems?: number;

  @Input()
  disableSelectOnEnter = false;

  @Input()
  maxOneGroup = false;

  @Input()
  selectionDisplay?: string;

  @Input()
  compareFn?: (a: any, b: any) => boolean;

  @Output()
  closed = new EventEmitter<void>();

  @Output()
  valueChange = new EventEmitter<TValue | null>();

  @ViewChild('select', { static: false })
  selectRef: NgSelectComponent;

  public selectedItems: unknown;
  protected canOpenBtnSelect: boolean = true;
  protected preClickTestFailed: boolean = true;
  protected groupsExpanded: Record<string, boolean> = {};
  protected optionsCount = 0;
  protected options: Array<Record<string, ExtendedItem<TItem>>> = [];
  private rawValue?: TValue;
  private valueDebouncer = new Subject<TValue | null>();
  private onChange?: Function;
  private onTouched?: Function;

  SELECT_ALL_VALUE = SELECT_ALL_VALUE;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    if (this.isEmissionDelay()) {
      this.debounceValueEmissions();
    }
    if (this.forceClear) {
      this.subscribe(this.forceClear, () => this.onForceClear());
    }
    if (this.itemsStream) {
      this.isLoading = true;
      this.subscribe(this.itemsStream, options => {
        this.items = options;
        this.updateOptions();
        this.optionsCount = options.length;
        this.isLoading = false;
        this.onValue(this.rawValue);
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.items) {
      this.optionsCount = this.items.length;
    }
    if (changes.items || changes.displayType || changes.clearable || changes.placeholder) {
      this.updateOptions();
    }
  }

  ngAfterViewInit() {
    if (this.alwaysOpen) {
      this.onOpen(this.selectRef);
    }
  }

  focus() {
    setTimeout(() => {
      this.selectRef.focus();
    });
  }

  private updateOptions() {
    if (this.clearable && this.displayType === 'button') {
      this.options = [
        { [this.displayExpr]: this.placeholder as ExtendedItem<TItem>, [this.valueExpr]: null as unknown as ExtendedItem<TItem> },
        ...this.items,
      ];
    } else {
      this.options = this.items;
    }
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(value: TValue) {
    this.value = value;
  }

  onClose() {
    this.onTouched ? this.onTouched() : null;
    this.closed.emit();
    setTimeout(() => {
      this.canOpenBtnSelect = true;
    });
  }

  handlePreClickBtn() {
    // (click) events fire on mouse release, whereas the ng-select onClose event triggers on mouse press.
    // To prevent canOpenBtnSelect from being reset prior to testing for it, we need to do an additional
    // check using the pointerdown event, which happens BEFORE the onClose event.
    this.preClickTestFailed = !this.canOpenBtnSelect;
  }

  handleClickBtn() {
    if (this.canOpenBtnSelect && !this.preClickTestFailed) {
      this.selectRef.toggle();
      this.canOpenBtnSelect = false;
    }
  }

  onOpen(select: NgSelectComponent) {
    setTimeout(() => {
      const isMobile = document.body.clientWidth < 576;
      const panelEl = document.getElementById(select.dropdownId);
      if (panelEl && this.autoWidth && !isMobile) {
        panelEl.classList.add('width-auto');
      }
      if (panelEl && this.dropdownRtl && !isMobile) {
        panelEl.classList.add('width-auto');
        panelEl.style.width = `${panelEl.clientWidth}px`;
        select.element.style.width = `${select.element.clientWidth}px`;
        const translateXWidth = select.element.clientWidth - panelEl.clientWidth;
        panelEl.style.transform = `translateX(${translateXWidth}px)`;
        panelEl.classList.remove('width-auto');
      }

      if (this.groupItems && this.expandSelectedGroupItems) {
        this.expandSelectedItems();
      }
    });
  }

  public emitValueChange(newValue: unknown): void {
    let value: TValue | null;
    if (!newValue || (Array.isArray(newValue) && !newValue.length)) {
      value = null;
    } else if (Array.isArray(newValue)) {
      if (this.maxNumOfItems && newValue.length > this.maxNumOfItems) {
        newValue = newValue.slice(this.maxNumOfItems - newValue.length);
      }
      if (this.maxOneGroup && isArray(newValue)) {
        const lastItem = last(newValue);
        if (lastItem?.group) {
          newValue = newValue.filter(item => item.group === lastItem.group) as TValue;
        }
      }
      value = (
        this.valueType === 'value' ? (newValue as any[]).map((row: Record<string, TItem>) => row[this.valueExpr]) : newValue
      ) as TValue;
      this.selectedItems = value;
    } else {
      value = (this.valueType === 'value' ? (newValue as Record<string, TItem>)[this.valueExpr] : newValue) as TValue;
    }

    if (this.isEmissionDelay()) {
      this.valueDebouncer.next(value);
    } else {
      this.changeValue(value);
    }

    if (!this.multiple) {
      this.selectRef.blur();
    }
  }

  protected onItemExpanded(item$: ExtendedItem<TItem>, event?: MouseEvent) {
    if (!item$.group) return;
    this.groupsExpanded[item$.group] = !this.groupsExpanded[item$.group];

    event?.preventDefault();
    event?.stopImmediatePropagation();
  }

  protected onSelectAllClicked(deselect = false, event?: MouseEvent) {
    let newValue: Array<Record<string, ExtendedItem<TItem>>> = [];
    if (!deselect) {
      newValue = this.options;
    }
    this.selectedItems = newValue.map(option => option.value);
    this.emitValueChange(newValue);
    event?.preventDefault();
    event?.stopImmediatePropagation();
  }

  protected onKeyDown = (event: KeyboardEvent) => {
    if (this.disableSelectOnEnter && event.key === 'Enter') {
      return false;
    }
    return true;
  };

  private onValue(value?: TValue): void {
    if (value == undefined) {
      this.selectedItems = this.multiple ? [] : null;
      return;
    }

    const filteredData = this.getSelectedItemsFromItems(value).map((row: Record<string, TItem>) => row[this.valueExpr]);

    if (filteredData.length) {
      this.selectedItems = this.multiple ? filteredData : filteredData[0];
    } else {
      this.selectedItems = this.multiple ? [] : null;
    }
  }

  private debounceValueEmissions(): void {
    if (!this.emissionDelayInMs) return;
    this.valueDebouncer.pipe(debounceTime(this.emissionDelayInMs), takeUntil(this.destroyed)).subscribe((value: TValue | null) => {
      this.valueChange.next(value);
      this.onChange ? this.onChange(value) : null;
    });
  }

  private onForceClear(): void {
    this.emitValueChange(null);
    this.selectedItems = this.multiple ? [] : null;
  }

  private expandSelectedItems(): void {
    uniqBy(this.getSelectedItemsFromItems(this.selectedItems) as Array<Record<string, ExtendedItem<TItem>>>, 'group').forEach(
      (item: Record<string, ExtendedItem<TItem>>) => {
        this.onItemExpanded(item as ExtendedItem<TItem>);
      },
    );
  }

  private getSelectedItemsFromItems(value: unknown): Array<Record<string, TItem>> {
    return this.valueType === 'value'
      ? this.items.filter((item: Record<string, TItem>) => {
          return Array.isArray(value) ? value.includes(item[this.valueExpr]) : item[this.valueExpr] === value;
        })
      : this.items.filter((item: Record<string, TItem>) => {
          return Array.isArray(value)
            ? !!value.find((row: Record<string, TItem>) => row[this.valueExpr] === item[this.valueExpr])
            : item[this.valueExpr] === (value as Record<string, TItem>)[this.valueExpr];
        });
  }

  private isEmissionDelay(): boolean {
    return !!(this.emissionDelayInMs ?? 0);
  }

  private changeValue(value: TValue | null) {
    this.valueChange.next(value);
    this.onChange ? this.onChange(value) : null;
  }
}
