export class IPage {
  program_group_ids?: string;
  name: string;
  npi: string;
  state: string;
  field_admin?: string;
  network_groups?: string[];
  hub_network: number;
  rows_per_page: number;
  last_evaluated_key: any;
}
