import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenantService } from '@services/tenant.service';
import { UrlService } from '@services/url.service';
import { Program } from '@shared/models/program.model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProgramApiService {
  constructor(
    private http: HttpClient,
    private tenantService: TenantService,
    private urlService: UrlService,
  ) {}

  getProgram(tenant: TenantIdType, programId: string): Observable<Program> {
    return this.http.get<Program>(`${this.urlService.PROGRAM_URL}/${tenant}/${programId}`);
  }

  getPrograms(programIds: string[]): Observable<Program[]> {
    const _programIds = programIds.join(',');
    return this.http.get<Program[]>(this.urlService.PROGRAM_URL + '/' + _programIds);
  }

  getAllPrograms(perProduct = false): Observable<Program[]> {
    const currentTenantId = this.tenantService.getCurrentTenantId();
    return this.getAllProgramsForTenant(currentTenantId, perProduct);
  }

  getAllProgramsForTenant(tenant: TenantIdType, perProduct = false): Observable<Program[]> {
    console.log('getAllProgramsForTenant');
    let url = this.urlService.PROGRAM_URL;
    url = `${url}?tenants=${tenant}`;
    console.log(url);
    if (perProduct) {
      url = `${url}&perProduct=1`;
    }
    return this.http.get<Program[]>(url);
  }

  getAllProgramsForTenants(tenants: TenantIdType[]): Observable<Program[]> {
    const programObservables: Array<Observable<Program[]>> = tenants.map(tenant => this.getAllProgramsForTenant(tenant, true));

    return forkJoin(programObservables).pipe(map(allPrograms => allPrograms.flat()));
  }
}
