import { Component } from '@angular/core';
import { CursorPaginatorComponent } from '@shared/components/data-table/cursor-paginator/cursor-paginator.component';

@Component({
  selector: 'app-dashboard-paginator',
  templateUrl: './dashboard-paginator.component.html',
  styleUrls: ['./dashboard-paginator.component.scss'],
})
export class DashboardPaginatorComponent extends CursorPaginatorComponent {
  constructor() {
    super();
  }
}
