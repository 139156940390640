<div class="feedback">
  <p class="feedback__text">
    We're looking to find ways to serve you better. Please help us by taking this quick survey about your overall experience with us as a
    prescription support service.
  </p>

  <p class="feedback__text">How likely is it that you would recommend Monarch's brightscrip&trade; to a friend or colleague?</p>

  <div class="feedback__slider">
    <app-variable-slider minLabel="Not at all likely" maxLabel="Extremely likely" [numberOfSteps]="10" (value)="updateValue($event)">
    </app-variable-slider>
  </div>
</div>
