import { Component, EventEmitter, HostListener, Input, OnChanges, Output, TemplateRef } from '@angular/core';
import { Column } from '@shared/components/data-view/data-view-types';
import {
  DataViewGridCheckedChanged,
  DataViewGridFilterChanged,
  DataViewGridItemClicked,
  DataViewGridSortChanged,
} from '@shared/components/data-view/grid/data-view-grid-events';
import { GridColumn } from '@shared/models/grid-column.model';
import { NgChanges } from '@shared/ng-changes';
import moment from 'moment';

export enum LoadingUI {
  Spinner,
  Placeholder,
  None,
}

@Component({
  selector: 'app-data-view-grid',
  templateUrl: './data-view-grid.component.html',
  styleUrls: ['./data-view-grid.component.scss'],
})
export class DataViewGridComponent<T extends Record<string, any>> implements OnChanges {
  @Input() data: T[] = [];
  @Input() isLoading = false;
  @Input() columns: Column.AppliedDataViewColumn[] = [];
  @Input() templates: Record<string, TemplateRef<any>> = {};
  @Input() checkAble = false;
  @Input() sortAble = false;
  @Input() hasFilters = true;
  @Input() sortOrder: 'asc' | 'desc' = 'asc';
  @Input() sortField = '';
  @Input() loadingUI = LoadingUI.Spinner;
  @Input() loadingRowsCount: number = 30;
  @Input() showLoadingInitOnly = false;
  @Input() activeRowId?: number;
  @Input() smallFilters = false;
  @Input() showFilters = false;
  @Input() resizableColumns = false;
  @Input() isFetchingRows = false;
  @Output() itemClicked = new EventEmitter<DataViewGridItemClicked<T>>();
  @Output() filterChanged = new EventEmitter<DataViewGridFilterChanged>();
  @Output() sortChanged = new EventEmitter<DataViewGridSortChanged>();
  @Output() checkedChanged = new EventEmitter<DataViewGridCheckedChanged<T>>();
  @Output() columnsEdited = new EventEmitter<void>();
  readonly LoadingUI = LoadingUI;
  checkAll = false;
  checkedRows: Array<{ checked: boolean }> = [];

  resizeColumnWidth = 6;
  resizingColumnId?: number = undefined;

  lastWindowWidth: number = window.innerWidth;
  @HostListener('window:resize')
  windowResize() {
    for (const column of this.columns) {
      if (column.width && column.width !== 'auto') column.width *= (window.innerWidth - 320) / (this.lastWindowWidth - 320);
    }
    this.lastWindowWidth = window.innerWidth;
  }

  @HostListener('window:mousemove', ['$event'])
  mouseMove(event: MouseEvent) {
    if (!this.resizableColumns || this.resizingColumnId === undefined) return;
    let precalculatedClientRect;
    for (const element of Array.from(document.getElementsByClassName(`tableColumn${this.resizingColumnId}Item`))) {
      if (!precalculatedClientRect) precalculatedClientRect = (element as HTMLElement).getBoundingClientRect();
      this.columns[this.resizingColumnId].width = Math.max(event.clientX - precalculatedClientRect.x + this.resizeColumnWidth, 30);
    }
    if (window.innerWidth - event.clientX < 5) {
      this.columns[this.resizingColumnId].width = this.columns[this.resizingColumnId].width ?? 0 + 5;
    }
  }

  @HostListener('window:mouseup')
  mouseUp() {
    if (this.resizingColumnId !== undefined) {
      this.resizingColumnId = undefined;
      setTimeout(() => {
        document.body.classList.remove('user-select-none');
        if (window.getSelection()) window.getSelection()!.empty();
      }, 100);
      this.columnsEdited.next();
    }
  }

  onMouseDragBegin(columnId: number) {
    this.resizingColumnId = columnId;
    document.body.classList.add('user-select-none');
  }

  onDoubleClick(columnId: number) {
    this.columns[columnId].width = 'auto';
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.data) {
      this.checkAll = false;
      this.onCheckAll(false);
    }
    if (changes.columns) {
      for (const column of this.columns) {
        if (column.width === undefined) column.width = (window.innerWidth - 340) / this.columns.length;
      }
    }
  }

  get columnWidths() {
    return this.columns.map(c => (!c.width || c.width === 'auto' ? 'min-content' : `${c.width}px`));
  }

  onCheckAll(value: boolean) {
    this.checkedRows = this.data.map(() => {
      return { checked: value };
    });
    this.checkedChanged.next(this.getCheckedItems());
  }

  onCheckItem(value: boolean, index: number) {
    this.checkedRows[index] = { checked: value };
    this.checkedChanged.next(this.getCheckedItems());
  }

  onFilterChanged(value: any, column: Column.AppliedDataViewColumn) {
    if (value && ['date', 'datetime'].includes(column.type ?? '')) {
      this.filterChanged.next({ value: moment(value).format('YYYY-MM-DD'), column });
      return;
    }
    this.filterChanged.next({ value, column });
  }

  onHeaderSortClick(column: Column.AppliedDataViewColumn) {
    if (column.sort === true && this.sortAble) {
      this.setSort(column);
      this.sortChanged.emit({ column, field: this.sortField, order: this.sortOrder });
    }
  }

  onItemClick(item: T, rowId: number, column: Column.AppliedDataViewColumn) {
    this.itemClicked.next({ item, rowId, column });
  }

  getCheckedItems(): T[] {
    const items: T[] = [];

    this.data.forEach((item, index) => {
      if (this.checkedRows[index].checked) {
        items.push(item);
      }
    });

    return items;
  }

  isClearable(column: GridColumn) {
    for (const option of column.filterOptions ?? []) {
      if (option.value === '') return false;
    }
    return true;
  }

  private setSort(column: Column.AppliedDataViewColumn) {
    if (column.field !== this.sortField) {
      this.sortField = column.field;
      this.sortOrder = 'asc';
    } else if (this.sortOrder === 'asc') {
      this.sortOrder = 'desc';
    } else {
      this.sortOrder = 'asc';
    }
  }
}
