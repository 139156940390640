import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { isSelectListItem, SelectListItem } from '@shared/models/select-list-item';
import Utils from '@shared/providers/utils';
import { DropdownService } from '@services/dropdown.service';
import { NgChanges } from '@shared/ng-changes';

type Modifier = 'mw-200' | 'mw-400' | 'font-small';

@Component({
  selector: 'app-multi-select-with-slider-filter',
  templateUrl: './multi-select-with-slider-filter.component.html',
  styleUrls: ['./multi-select-with-slider-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectWithSliderFilterComponent implements OnChanges {
  static readonly VALUES_NOT_ARRAY_ERROR = 'Values is not array';
  static readonly VALUES_NOT_LIST_ITEM_ERROR = 'Values does not contain SelectListItems';

  @Input() values: SelectListItem[] | any[] = [];
  @Input() valueOptions: SelectListItem[] = [];
  @Input() useSelectListItemValue = false;
  @Input() exclusiveOption: SelectListItem | any; // should match type of #values
  @Input() filterText: string;
  @Input() filter: () => boolean;
  @Input() set modifiers(modifiers: Modifier[]) {
    this.onModifiers(modifiers);
  }

  @Output() valuesChange = new EventEmitter<SelectListItem[] | any[]>();

  filterActive = false;
  hasValueOptions = false;
  _modifiers: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private dropdownService: DropdownService,
  ) {}

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.valueOptions) {
      this.hasValueOptions = Utils.isNonEmptyArray(changes.valueOptions.currentValue);
    }
    if (changes.useSelectListItemValue && changes.useSelectListItemValue.currentValue) {
      this.transformValuesToSelectListItemValues();
    }

    // Ensure dropdown service has the first value, if it exists
    if (changes.values && changes.values.currentValue && !changes.values.previousValue) {
      this.syncDropdownServiceWithNewValues(changes.values.currentValue);
    }

    this.cdr.detectChanges();
  }

  onModifiers(modifiers: Modifier[]): void {
    this._modifiers = Utils.getModifiedStringArrayAsString(modifiers, 'multi-select--', 'prepend');
  }

  transformValuesToSelectListItemValues(): void {
    if (this.values && Utils.isNonEmptyArray(this.values) && isSelectListItem(this.values[0])) {
      const TRANSFORMED_VALUES = (this.values as SelectListItem[]).map(value => value.value);
      this.emitValuesChange(TRANSFORMED_VALUES);
    }
  }

  private emitValuesChange(newValues: SelectListItem[] | any[]): void {
    this.valuesChange.emit(newValues);
    this.syncDropdownServiceWithNewValues(newValues);
  }

  private syncDropdownServiceWithNewValues(newValues: SelectListItem[] | any[]): void {
    let labels = [];
    if (this.useSelectListItemValue) {
      labels = this.valueOptions.filter(option => newValues.includes(option.value)).map(option => option.name);
    } else {
      labels = (newValues as SelectListItem[]).map(newValue => newValue.name);
    }
    this.dropdownService.activeOptionLabels.next(labels);
  }

  updateValue(addToValues: boolean, option: SelectListItem): void {
    if (!this.values) {
      return;
    }

    let valuesCopy = [...this.values];

    if (addToValues) {
      valuesCopy = this.addValue(valuesCopy, this.getValueForOption(option));
    } else {
      valuesCopy = this.removeValue(valuesCopy, this.getValueForOption(option));
    }

    this.emitValuesChange(valuesCopy);
  }

  getValueForOption(option: SelectListItem | any): SelectListItem | any {
    return this.useSelectListItemValue ? option.value : option;
  }

  addValue(values: SelectListItem[] | any[], option: SelectListItem | any): SelectListItem | any {
    if (this.isValueExclusiveOption(option) || this.doesValuesContainExclusiveOption()) {
      values = [option];
    } else {
      values.push(option);
    }
    return values;
  }

  removeValue(values: SelectListItem[] | any[], option: SelectListItem | any): SelectListItem | any {
    let valueIndex: number;
    if (this.useSelectListItemValue) {
      valueIndex = values.findIndex(value => value === option);
    } else {
      valueIndex = values.findIndex(value => value.value === option.value);
    }

    if (valueIndex >= 0) {
      values.splice(valueIndex, 1);
    }

    return values;
  }

  isValueExclusiveOption(option: SelectListItem): boolean {
    if (!this.exclusiveOption || !option) {
      return false;
    } else if (this.useSelectListItemValue) {
      return this.exclusiveOption === option;
    } else {
      return this.exclusiveOption.value === option.value;
    }
  }

  doesValuesContainExclusiveOption(): boolean {
    if (!this.exclusiveOption) {
      return false;
    } else if (this.useSelectListItemValue) {
      return this.values.some(valueItem => valueItem === this.exclusiveOption);
    } else {
      return this.values.some(valueItem => valueItem.value === this.exclusiveOption.value);
    }
  }

  isOptionSelected(option: SelectListItem): boolean {
    if (this.useSelectListItemValue) {
      return option && Utils.isNonEmptyArray(this.values) && this.values.some(valueOption => valueOption === option.value);
    } else {
      return option && Utils.isNonEmptyArray(this.values) && this.values.some(valueOption => valueOption.value === option.value);
    }
  }
}
