import { inject, Injectable, InjectionToken, TemplateRef, Type } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { NgbModal, NgbModalOptions, NgbModalRef, NgbOffcanvas, NgbOffcanvasOptions, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';

export type DialogTemplateRef = Omit<NgbModalRef, 'componentInstance'>;

export type DialogRef<T> = Omit<NgbModalRef, 'componentInstance'> & {
  componentInstance: T;
};

export type SlideoutRef<T> = Omit<NgbOffcanvasRef, 'componentInstance'> & {
  componentInstance: T;
};

const DATA = { value: {} };
export const DIALOG_DATA: InjectionToken<Record<string, any>> = new InjectionToken('DIALOG_DATA', {
  providedIn: 'any',
  factory: () => cloneDeep(DATA.value),
});

@Injectable({ providedIn: 'root' })
export class DialogService {
  private readonly modalService = inject(NgbModal);
  private readonly slideoutService = inject(NgbOffcanvas);

  dismissAllModals(reason?: string) {
    this.modalService.dismissAll(reason);
  }

  dismissSlideout(reason?: string) {
    this.slideoutService.dismiss(reason);
  }

  hasOpenModals() {
    return this.modalService.hasOpenModals();
  }

  hasOpenSliedouts() {
    return this.slideoutService.hasOpenOffcanvas();
  }

  openModal<T>(component: Type<T>, options?: NgbModalOptions, data: Record<string, any> = {}): DialogRef<T> {
    DATA.value = data;
    return this.modalService.open(component, options);
  }

  openSlideout<T>(component: Type<T>, options?: NgbOffcanvasOptions, data: Record<string, any> = {}): SlideoutRef<T> {
    DATA.value = data;
    return this.slideoutService.open(component, options);
  }

  openTemplateModal<T>(component: TemplateRef<T>, options?: NgbModalOptions, data: Record<string, any> = {}): DialogTemplateRef {
    DATA.value = data;
    return this.modalService.open(component, options);
  }
}
