import { Pipe, PipeTransform } from '@angular/core';
import type { HLJSApi } from 'highlight.js';

let hljs: HLJSApi;

@Pipe({
  name: 'highlightCode',
})
export class HighlightCodePipe implements PipeTransform {
  async transform(codeBlock: string, language = 'json') {
    await this.loadHighlight();
    const codeBlockHighlighted = hljs.highlight(codeBlock, { language, ignoreIllegals: true }).value;
    return '<code class="hljs">' + codeBlockHighlighted + '</code>';
  }

  async loadHighlight() {
    if (!hljs) {
      hljs = (await import('highlight.js')).default;
    }
  }
}
