<div *ngIf="_numberOfSteps" class="steps__step-img-container">
  <svg class="steps__step-img" xmlns="http://www.w3.org/2000/svg">
    <circle
      *ngFor="let circle of circles"
      class="steps__step-circle"
      [attr.cx]="circle.centerX"
      [attr.cy]="circle.centerY"
      [attr.r]="circle.radius"
      [class.steps__step-circle--active]="circle.isActive"
    ></circle>

    <text *ngFor="let number of numbers" class="steps__step-text" [attr.x]="number.x" [attr.y]="number.y">{{ number.value }}</text>

    <line
      *ngFor="let line of lines"
      class="steps__step-line"
      [attr.x1]="line.leftX"
      [attr.y1]="line.leftY"
      [attr.x2]="line.rightX"
      [attr.y2]="line.rightY"
      [class.steps__step-line--active]="line.isActive"
    ></line>
  </svg>
</div>
