import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-placeholder, tbody[tablePlaceholder]',
  templateUrl: './table-placeholder.component.html',
  styleUrls: ['./table-placeholder.component.scss'],
})
export class TablePlaceholderComponent implements OnInit {
  @Input()
  public rows = 1;

  @Input()
  public cols = 1;
  public rowsArray: number[];
  public colsArray: number[];

  constructor() {}

  public ngOnInit(): void {
    this.generate();
  }

  private generate() {
    this.rowsArray = Array.from(Array(this.rows).keys());
    this.colsArray = Array.from(Array(this.cols).keys());
  }
}
