import { Component, OnInit, OnDestroy } from '@angular/core';
import { WindowService } from '@services/window.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: ['./scrollbar.component.scss'],
})
export class ScrollbarComponent implements OnInit, OnDestroy {
  isMobile = false;
  isTablet = false;

  private componentDestroyed = new Subject<void>();

  constructor(private windowService: WindowService) {}

  ngOnInit() {
    this.trackDeviceType();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  trackDeviceType(): void {
    this.windowService.isTablet.pipe(takeUntil(this.componentDestroyed)).subscribe(isTablet => (this.isTablet = isTablet));

    this.windowService.isMobile.pipe(takeUntil(this.componentDestroyed)).subscribe(isMobile => (this.isMobile = isMobile));
  }
}
