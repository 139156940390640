import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CopayCodeRequestApiService } from '@services/copay-code-request-api.service';
import { ProgramUtilityService } from '@services/program-utility.service';
import { TenantService } from '@services/tenant.service';
import { CodeDispenseMethod } from '@shared/models/code-dispense-method.type';
import { CodeRequestInitiationMethod } from '@shared/models/code-request-initiation-method.type';
import { CopayCodeRequestStatus } from '@shared/models/copay-code-request-status.type';
import { ICopayCodeRequest } from '@shared/models/copay-code-request.model';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import Utils from '@shared/providers/utils';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { finalize, share, switchMap, takeUntil, tap } from 'rxjs/operators';

type StateType =
  | 'start'
  | 'update'
  | 'updateCodes'
  | 'consignmentCheck'
  | 'sendToConsignment'
  | 'dispense'
  | 'redispense'
  | 'download'
  | 'complete';

@Injectable({
  providedIn: 'root',
})
export class CopayCodeRequestStateService implements OnDestroy {
  currentCodeRequest = new BehaviorSubject<ICopayCodeRequest | undefined>(undefined);

  currentState?: StateType;
  isCorrectStateToSendRequest = true;

  isStarting = false;
  $starting = new BehaviorSubject<boolean>(false);

  readonly ACCEPTABLE_PREVIOUS_STATES = new Map<StateType, StateType[]>([
    ['start', ['complete']],
    ['updateCodes', ['start', 'update']],
    ['consignmentCheck', ['start', 'update']],
    ['sendToConsignment', ['consignmentCheck']],
    ['dispense', ['start', 'update', 'complete', 'consignmentCheck']],
    ['redispense', ['start', 'update']],
    ['download', ['complete']],
    ['update', ['start', 'dispense', 'update', 'consignmentCheck', 'complete']],
    ['complete', ['start', 'dispense', 'update', 'updateCodes', 'download', 'consignmentCheck']],
  ]);

  private currentTenantId: TenantIdType;
  private oldTenantId: TenantIdType;
  private serviceDestroyed = new Subject<void>();

  constructor(
    private authService: AuthService,
    private copayCodeRequestApiService: CopayCodeRequestApiService,
    private programUtilityService: ProgramUtilityService,
    private tenantService: TenantService,
  ) {
    this.currentState = 'complete';
    this.resetOnAuthChange();
  }

  ngOnDestroy() {
    this.serviceDestroyed.next();
    this.serviceDestroyed.complete();
  }

  private resetOnAuthChange(): void {
    this.authService.authStatusChanged.pipe(takeUntil(this.serviceDestroyed)).subscribe(() => this.reset());
  }

  public setUpdateState(): void {
    this.currentState = 'update';
  }

  public setConsignmentCheckState(): void {
    this.currentState = 'consignmentCheck';
  }

  reset(): void {
    console.log('Reset code request...');
    this.currentCodeRequest.next(undefined);
    this.currentState = undefined;
    this.isCorrectStateToSendRequest = true;
    this.setStarting(false);
  }

  start(
    initiationMethod: CodeRequestInitiationMethod,
    ncpdp: string,
    callStart?: Date,
    copayCodeRequest?: Partial<ICopayCodeRequest>,
  ): Observable<any> {
    this.setStarting(true);
    const currentUser = this.authService.getCurrentUser();
    console.log('Creating request...');
    const req: Record<string, any> = {
      ncpdp: ncpdp,
      username: currentUser?.username,
      request_status: 'Starting',
      initiation_method: initiationMethod,
      program: ProgramUtilityService.UNDEFINED_PROGRAM_ID,
    };
    if (callStart) {
      req['call_start'] = callStart;
    }
    if (copayCodeRequest) {
      Object.assign(req, copayCodeRequest);
    }
    this.setCurrentTenantId();
    console.log(`current tenant id: ${this.currentTenantId}`);
    return this.sendCopayCodeRequest('start', req as Partial<ICopayCodeRequest>, true, this.currentTenantId);
  }

  private setStarting(isStarting: boolean): void {
    this.isStarting = isStarting;
    this.$starting.next(isStarting);
  }

  private setCurrentTenantId(): void {
    this.oldTenantId = this.currentTenantId;
    this.currentTenantId = this.tenantService.getCurrentTenantId();
  }

  completeExistingCodeRequestWithoutSavingRequest(copayCodeRequestFieldsToUpdate?: Partial<ICopayCodeRequest>, useOldTenant = false): void {
    const lastCodeRequest = this.currentCodeRequest.getValue() || {};
    if (copayCodeRequestFieldsToUpdate) {
      Object.assign(lastCodeRequest, copayCodeRequestFieldsToUpdate);
    }
    const tenantId = useOldTenant ? this.oldTenantId : this.currentTenantId;
    this.sendCopayCodeRequest('complete', this.getUpdatedCodeRequest(lastCodeRequest, 'Completed'), false, tenantId).subscribe();
  }

  private sendCopayCodeRequest(
    state: StateType,
    copayCodeRequest: Partial<ICopayCodeRequest>,
    updateState = true,
    tenantId?: TenantIdType,
  ): Observable<any> {
    this.checkRequestCanBeSent(state);
    this.resetOnStartAndOnDownload(state);
    const ensuredCopayCodeRequest = this.ensureUsernameField(copayCodeRequest);
    const copayCodeRequest$ = this._sendRequest(ensuredCopayCodeRequest, tenantId);
    this.subscribeAndUpdate(copayCodeRequest$, state, updateState);
    return copayCodeRequest$;
  }

  resetOnStartAndOnDownload(wantedState: StateType): void {
    if (wantedState === 'start' || wantedState === 'download') {
      this.reset();
    }
  }

  private checkRequestCanBeSent(wantedState: StateType): void {
    this.isCorrectStateToSendRequest = this.ACCEPTABLE_PREVIOUS_STATES.get(wantedState)?.includes(this.currentState as StateType) ?? false;
    if (wantedState === 'start' && !this.isCorrectStateToSendRequest) {
      this.completeExistingCodeRequestWithoutSavingRequest(undefined, true);
      this.isCorrectStateToSendRequest = true;
    }
    console.log(`this.isCorrectStateToSendRequest: ${this.isCorrectStateToSendRequest}`);
  }

  private ensureUsernameField(copayCodeRequest: Partial<ICopayCodeRequest>): Partial<ICopayCodeRequest> {
    const _copayCodeRequest = Utils.copyObject(copayCodeRequest);

    const CURRENT_USER = this.authService.getCurrentUser();
    if (CURRENT_USER && CURRENT_USER.username) {
      _copayCodeRequest.username = CURRENT_USER.username;
    }

    return _copayCodeRequest;
  }

  private _sendRequest(copayCodeRequest: Partial<ICopayCodeRequest>, tenantId?: TenantIdType): Observable<any> {
    return this.isCorrectStateToSendRequest
      ? this.copayCodeRequestApiService.updateCodeRequest(copayCodeRequest, tenantId).pipe(share())
      : of(null);
  }

  private subscribeAndUpdate(copayCodeRequest$: Observable<any>, state: StateType, updateState: boolean): void {
    copayCodeRequest$.pipe(tap(() => this.checkIsStarting(state))).subscribe(result => {
      if (result && updateState) {
        this.currentState = state;
        this.currentCodeRequest.next(result);
      }
    });
  }

  private checkIsStarting(state: StateType): void {
    if (state === 'start') {
      this.setStarting(false);
    }
  }

  checkEligibility(
    copayCodeRequestFieldsToUpdate: Partial<ICopayCodeRequest> = {},
    requestStatus: CopayCodeRequestStatus = 'Check Consignment',
    state: StateType = 'consignmentCheck',
  ): Observable<any> {
    return this.sendCopayCodeRequest(
      state,
      this.getUpdatedCodeRequest(copayCodeRequestFieldsToUpdate, requestStatus),
      true,
      this.currentTenantId,
    );
  }

  sendToConsignment(
    copayCodeRequestFieldsToUpdate: Partial<ICopayCodeRequest> = {},
    requestStatus: CopayCodeRequestStatus = 'Sent to Consignment',
    state: StateType = 'sendToConsignment',
  ): Observable<any> {
    return this.sendCopayCodeRequest(
      state,
      this.getUpdatedCodeRequest(copayCodeRequestFieldsToUpdate, requestStatus),
      true,
      this.currentTenantId,
    );
  }

  dispense(
    dispenseMethod: CodeDispenseMethod,
    copayCodeRequestFieldsToUpdate?: Partial<ICopayCodeRequest>,
    requestStatus: CopayCodeRequestStatus = 'Dispensing',
    doCompleteCodeRequest = false,
    state: StateType = 'dispense',
  ): Observable<any> {
    const copayCodeRequestFieldsToUpdateCopy = copayCodeRequestFieldsToUpdate || {};
    Object.assign(copayCodeRequestFieldsToUpdateCopy, { dispense_method: dispenseMethod });
    const updateState = requestStatus !== 'Resending';

    if (doCompleteCodeRequest) {
      return this.sendCopayCodeRequest(
        state,
        this.getUpdatedCodeRequest(copayCodeRequestFieldsToUpdateCopy, requestStatus),
        updateState,
        this.currentTenantId,
      ).pipe(switchMap(dispenseCodeRequestResult => this.complete(dispenseCodeRequestResult)));
    }
    return this.sendCopayCodeRequest(
      state,
      this.getUpdatedCodeRequest(copayCodeRequestFieldsToUpdateCopy, requestStatus),
      updateState,
      this.currentTenantId,
    );
  }

  private getUpdatedCodeRequest(
    copayCodeRequestFieldsToUpdate: Partial<ICopayCodeRequest>,
    requestStatus: CopayCodeRequestStatus,
  ): ICopayCodeRequest {
    console.log('>>> CURRENT currentCodeRequest');
    console.log(this.currentCodeRequest.getValue());

    const currentCodeRequestCopy = Utils.copyObject(this.currentCodeRequest.getValue()) as ICopayCodeRequest;
    Object.assign(currentCodeRequestCopy, copayCodeRequestFieldsToUpdate);
    currentCodeRequestCopy.request_status = requestStatus;

    console.log('>>> FINAL copay code request');
    console.log(currentCodeRequestCopy);
    return currentCodeRequestCopy;
  }

  redispense(copayCodeRequestFieldsToUpdate = {} as Partial<ICopayCodeRequest>): Observable<any> {
    Object.assign(copayCodeRequestFieldsToUpdate, {
      redispense: 1,
    });
    return this.sendCopayCodeRequest(
      'redispense',
      this.getUpdatedCodeRequest(copayCodeRequestFieldsToUpdate, 'Updating'),
      true,
      this.currentTenantId,
    );
  }

  redispenseWithStartRequest(
    initiationMethod: CodeRequestInitiationMethod = 'Portal',
    ncpdp: string,
    copayCodeRequestFieldsToUpdate?: Partial<ICopayCodeRequest>,
  ): Observable<any> {
    return this.start(initiationMethod, ncpdp, undefined, copayCodeRequestFieldsToUpdate).pipe(
      switchMap(startingCodeRequest => this.redispense()),
    );
  }

  download(
    dispenseMethod: CodeDispenseMethod,
    copayCodeRequestFieldsToUpdate?: Partial<ICopayCodeRequest>,
    ncpdp?: string,
    requestStatus: CopayCodeRequestStatus = 'Downloading',
    initiationMethod: CodeRequestInitiationMethod = 'Portal',
    doCompleteCodeRequest = true,
  ): Observable<any> {
    let _copayCodeRequestFieldsToUpdate = Utils.copyObject(copayCodeRequestFieldsToUpdate);
    if (!_copayCodeRequestFieldsToUpdate) _copayCodeRequestFieldsToUpdate = {};
    Object.assign(_copayCodeRequestFieldsToUpdate, {
      ncpdp: ncpdp,
      request_status: requestStatus,
      dispense_method: dispenseMethod,
      initiation_method: initiationMethod,
    });
    this.reset();
    this.setCurrentTenantId();
    return this.dispense(dispenseMethod, _copayCodeRequestFieldsToUpdate, requestStatus, doCompleteCodeRequest, 'download');
  }

  updateCodes(copayCodeRequestFieldsToUpdate: Partial<ICopayCodeRequest> = {}): Observable<any> {
    return this.sendCopayCodeRequest(
      'updateCodes',
      this.getUpdatedCodeRequest(copayCodeRequestFieldsToUpdate, 'Updating Codes'),
      true,
      this.currentTenantId,
    );
  }

  update(copayCodeRequestFieldsToUpdate: Partial<ICopayCodeRequest>): Observable<any> {
    this.setCurrentTenantId();
    return this.sendCopayCodeRequest(
      'update',
      this.getUpdatedCodeRequest(copayCodeRequestFieldsToUpdate, 'Updating'),
      true,
      this.currentTenantId,
    );
  }

  complete(copayCodeRequestFieldsToUpdate: Partial<ICopayCodeRequest> = {}, reset = false): Observable<any> {
    return this.sendCopayCodeRequest(
      'complete',
      this.getUpdatedCodeRequest(copayCodeRequestFieldsToUpdate, 'Completed'),
      true,
      this.currentTenantId,
    ).pipe(
      finalize(() => {
        if (reset) {
          this.reset();
        }
      }),
    );
  }

  getPatientResponsibilityAmount(couponRequest: ICopayCodeRequest) {
    const patientAmounts = couponRequest.cob_info?.patient_responsibility_amounts?.[0];
    if (patientAmounts) {
      return parseInt(patientAmounts.amount);
    }
  }

  isRequestForConsignmentHub(codeRequest: Partial<ICopayCodeRequest>, pharmacy: Pick<IPharmacy, 'npi'>): boolean {
    return !!codeRequest.consignment_pharmacy_npi && codeRequest.consignment_pharmacy_npi === pharmacy.npi;
  }
}
