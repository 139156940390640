import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { SelectListItem } from '@shared/models/select-list-item';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss'],
})
export class SearchDropdownComponent implements OnChanges {
  @Input() defaultSelectedValue = '';
  @Input() selectedItem: SelectListItem;
  @Input() placeholder = '';
  @Output() selectionCleared = new EventEmitter<void>();
  @Output() search = new EventEmitter<string>();

  valueToSearch = '';
  isExpanded = false;
  searching = false;

  constructor() {}

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.defaultSelectedValue && changes.defaultSelectedValue.currentValue) {
      this.selectedItem = { name: this.defaultSelectedValue, value: this.defaultSelectedValue };
    }
    if (changes.selectedItem && changes.selectedItem.currentValue) {
      this.searching = false;
      this.isExpanded = false;
    }
  }

  toggleExpand(event: MouseEvent) {
    // Don't close if click was inside pharamcy search container.
    const target = event.target as Element;
    if (target.closest('.search-dropdown__contents')) {
      return;
    }
    this.isExpanded = !this.isExpanded;
  }

  closePopoverOnOutsideClick(event: Element) {
    // Don't close if click was on pharamcy dropdown as it handles opening and closing the popover on it's own.
    if (event.classList.contains('js-search-dropdown-label') || event.classList.contains('js-search-dropdown-caret')) {
      return;
    }
    this.isExpanded = false;
  }

  reset() {
    this.valueToSearch = '';
    if (this.selectedItem.value !== this.defaultSelectedValue) {
      this.selectedItem = { name: this.defaultSelectedValue, value: this.defaultSelectedValue };
      this.selectionCleared.emit();
    }
  }

  searchValue() {
    if (this.valueToSearch) {
      this.searching = true;
      this.search.emit(this.valueToSearch);
    }
  }
}
