<div class="program-group-list">
  <label class="program-group-list__dropdown-label" for="program-group-dropdown"> Program Group </label>

  <div class="program-group-list__dropdown">
    <app-dropdown
      id="program-group-dropdown"
      [(dropdownModel)]="programGroupListItem"
      (dropdownModelChange)="retrieveProgramsForCurrentProgramGroup()"
      [dropdownOptions]="allProgramGroupsListItems"
      [disabled]="disabled"
    >
    </app-dropdown>
  </div>

  <div *ngIf="showTable" class="program-group-list__program-group-categories-shown">
    <span class="program-group-list__program-group-categories-shown-label">
      {{ showAllPrograms ? 'Showing all programs for ' + currentTenantName : 'Showing enrolled programs and default programs' }}
    </span>

    <button type="button" class="button button--clear" (click)="toggleShowAllPrograms()" data-cy="allProgramsBtn">
      {{ showAllPrograms ? 'Show default and enrolled programs' : 'Show all programs' }}
    </button>
  </div>

  <app-table *ngIf="showTable" [data]="tableData" [useShortColumnLabels]="true" (action)="onAction($event)"> </app-table>
</div>
