import { Component, Input, OnChanges } from '@angular/core';
import { StatusIconType } from '@shared/models/status-icon.type';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
})
export class StatusIconComponent implements OnChanges {
  @Input() status: StatusIconType;

  isStatusGood = false;
  isStatusBad = false;
  isStatusMaybeGood = false;
  isStatusMaybeBad = false;

  ngOnChanges(changes: NgChanges<this>) {
    if (changes && changes.status) {
      this.isStatusGood = this.status === 'good';
      this.isStatusBad = this.status === 'bad';
      this.isStatusMaybeGood = this.status === 'maybe-good';
      this.isStatusMaybeBad = this.status === 'maybe-bad';
    }
  }
}
