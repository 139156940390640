import gql from 'graphql-tag';

export const GetConsignmentPharmacyForTerminalQuery = gql`
  query GetConsignmentPharmacyForTerminal($npi: String!) {
    pharmacy(npi: $npi) {
      name
      npi
      ncpdp
      address {
        city
        state
        zip
        lineOne
        lineTwo
      }
      contact {
        phoneNumber
      }
    }
  }
`;
