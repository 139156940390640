<div class="app-table" [class.app-table--loading]="isLoading" [ngClass]="tableClassModifiers" *ngIf="filteredRows !== undefined">
  <div *ngIf="isLoading" class="loader spin-me" [class.loader--data-filled]="filteredRows !== undefined"></div>

  <div *ngIf="filteredRows !== undefined" class="app-table__headers-container">
    <div
      *ngFor="let header of data.headers; index as headerIndex; first as isFirst"
      class="app-table__header"
      [class.app-table__header--short]="useShortColumnLabels"
      [class.app-table__header--filterable]="allowFiltering"
      [ngStyle]="header?.styles ?? {}"
      data-cy="tableHeader"
    >
      <span> {{ header?.name }} </span>
      <div
        *ngIf="allowFiltering"
        class="app-table__header-filter"
        [class.app-table__header-filter--first]="isFirst"
        [ngStyle]="header?.filterStyles ?? {}"
        data-cy="headerFilter"
      >
        <app-text-control
          *ngIf="header.allowFilter && header.filterType === TableHeaderFilterType.Text"
          [clearable]="true"
          [reset]="numberOfFilterResets"
          [emissionDelayInMs]="500"
          [modifiers]="header?.filterModifiers ?? []"
          (valueChange)="onFilterChange($event, headerIndex)"
        >
        </app-text-control>
        <app-select-control
          *ngIf="header.allowFilter && header.filterType === TableHeaderFilterType.Dropdown"
          [valueOptions]="header.filterItems ?? []"
          [isTableFilter]="true"
          (valueChange)="onFilterChange($event, headerIndex)"
          [allowMultipleValues]="false"
          placeholder="All"
        ></app-select-control>
        <app-input-date-picker
          *ngIf="header.allowFilter && header.filterType === TableHeaderFilterType.DatePicker"
          [readonly]="true"
          (valueChange)="onFilterChange($event, headerIndex)"
        ></app-input-date-picker>
        <!--                <ng-container *ngIf="header.allowFilter && header.filterType == TableHeaderFilterType.DatePicker">-->
        <!--                <input-->
        <!--                  ngbDatepicker-->
        <!--                  #d="ngbDatepicker"-->
        <!--                  class="form-control"-->
        <!--                  container="body"-->
        <!--                  (click)="d.toggle()"-->
        <!--                  (dateSelect)="onDateFilterChange($event, headerIndex)"-->
        <!--                ></ng-container>-->
      </div>
    </div>

    <div
      *ngIf="isEditable && Utils.isNonEmptyArray(data?.rows)"
      class="app-table__header app-table__header--edit"
      [class.app-table__header--filterable]="allowFiltering"
    >
      <div *ngIf="allowFiltering && showClearButton" class="app-table__header-filter app-table__header-filter--last">
        <button type="button" class="button button--default" (click)="filterRows(true)" data-cy="clearBtn">CLEAR</button>
      </div>
    </div>
    <div *ngIf="!isEditable && allowFiltering && showClearButton" class="app-table__header-filter app-table__header-filter--last">
      <button type="button" class="button button--default" (click)="filterRows(true)">CLEAR</button>
    </div>
  </div>

  <div class="app-table__rows" [class.app-table__rows--filterable]="allowFiltering">
    <div class="app-table__scrollbar-container" [class.h-100]="(!usePagination || !showPageControls) && !useApiPagination">
      <perfect-scrollbar>
        <div
          *ngIf="!showInitialDataText && !isLoading && emptyDataText && (!filteredRows || filteredRows.length === 0)"
          class="app-table__empty-table-icon-container"
        >
          <img *ngIf="showEmptyDataIcon" class="app-table__empty-table-icon" [src]="EMPTY_TABLE_ICON" />
          <p class="app-table__empty-table-text" data-cy="emptyTableText">{{ emptyDataText }}</p>
        </div>
        <div
          *ngIf="showInitialDataText && !isLoading && initialDataText"
          class="app-table__empty-table-icon-container"
          data-cy="initialDataText"
        >
          <p class="app-table__empty-table-text">{{ initialDataText }}</p>
        </div>
        <div *ngFor="let group of data.groups">
          <div class="app-table__group-header-row" (click)="setCurrentGroup(group.value)" *ngIf="group.value.toString() !== DEFAULT_GROUP">
            <ng-container
              [ngTemplateOutlet]="displayValue"
              [ngTemplateOutletContext]="{
                tableValue: group,
                styleModifier: 'app-table__value--group-row',
              }"
            >
            </ng-container>
            <i class="app-table__group-header-arrow material-icons" style="margin-left: auto">
              {{ group.value.toString() !== currentGroup ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
            </i>
          </div>

          <div
            *ngFor="
              let row of filterRowsByGroup(filteredRows, group.value) | paginate: pagination;
              index as rowIndex;
              odd as isOdd;
              even as isEven
            "
            class="app-table__row"
            [class.app-table__row--white-bg]="reverseBackgroundColor ? isOdd : isEven"
            [class.app-table__row--clickable]="isRowClickable"
            (click)="emitRowClick(row)"
          >
            <div *ngIf="showDataRow(row)">
              <div
                class="app-table__not-editting-row app-table__not-editting-row--{{ rowModifier }}"
                [class.app-table__not-editting-row--selected]="showEdit(rowIndex)"
                data-cy="appTableRow"
              >
                <div *ngFor="let entry of row.entries" class="app-table__entry" [ngStyle]="entry?.styles ?? {}">
                  <ng-container *ngIf="isValuesArray(entry.values)">
                    <ng-container *ngFor="let value of entry.values">
                      <ng-container
                        [ngTemplateOutlet]="displayValue"
                        [ngTemplateOutletContext]="{
                          tableValue: value,
                          tableRow: row,
                          styleModifier: '',
                        }"
                      >
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!isValuesArray(entry.values)">
                    <ng-container
                      [ngTemplateOutlet]="displayValue"
                      [ngTemplateOutletContext]="{
                        tableValue: entry.values,
                        tableRow: row,
                        styleModifier: '',
                      }"
                    >
                    </ng-container>
                  </ng-container>
                </div>

                <div
                  *ngIf="isEditable"
                  class="app-table__entry app-table__entry--edit"
                  [class.app-table__entry--filterable]="allowFiltering"
                  (click)="editRow(row, rowIndex)"
                  data-cy="edit"
                >
                  <span class="app-table__value app-table__value--edit" [class.app-table__value--filterable]="allowFiltering">
                    <img *ngIf="isSavable" class="app-table__edit-icon" src="assets/icons/edit.png" />
                    {{ editButtonLabel }}
                  </span>
                </div>
              </div>

              <div @visibleHidden @openCollapse *ngIf="showEdit(rowIndex)" class="app-table__edit-container">
                <!-- User defined edit/more info component -->
                <ng-content></ng-content>

                <div class="app-table__edit-btns-container">
                  <button class="button button--default" (click)="closeEdit(row)" data-cy="closeBtn">CLOSE</button>
                  <button *ngIf="isSavable" class="button button--primary" (click)="saveRow(row)" data-cy="saveBtn">SAVE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="bodyTemplate">
          <ng-container *ngTemplateOutlet="bodyTemplate"> </ng-container>
        </ng-container>
      </perfect-scrollbar>
    </div>

    <div
      *ngIf="(usePagination && showPageControls) || useApiPagination"
      class="app-table__pagination-container"
      [class.d-block]="useApiPagination"
    >
      <app-cursor-paginator #apiPaginator [class.d-none]="!useApiPagination || !data?.rows"> </app-cursor-paginator>
      <ng-container *ngIf="!useApiPagination">
        <span class="app-table__number-of-items-being-shown" data-cy="totalOfRecords">
          Showing {{ pagination?.numberOfFirstItemBeingShown }} to {{ pagination?.numberOfLastItemBeingShown }} of
          {{ filteredRows?.length }}
        </span>

        <div class="app-table__pagination-controls-container">
          <app-select-control
            class="app-table__rows-per-page"
            label="Rows per page:"
            labelSide="left"
            [useTransparentBackground]="true"
            [isForPaginator]="true"
            [modifiers]="['mw-65']"
            [(value)]="pagination.itemsPerPage"
            (valueChange)="onRowsPerPageChange($event)"
            [valueOptions]="itemsPerPageOptions"
          >
          </app-select-control>

          <pagination-controls class="app-table__pagination-controls" previousLabel="" nextLabel="" (pageChange)="onPageChange($event)">
          </pagination-controls>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Templates -->

<ng-template #tableRows> </ng-template>

<ng-template #displayValue let-tableValue="tableValue" let-tableRow="tableRow" let-styleModifier="styleModifier">
  <ng-container *ngIf="isValueImageType(tableValue)">
    <img
      class="app-table__value {{ styleModifier }}"
      [ngClass]="tableValue.styleClasses"
      [src]="tableValue.value"
      [ngStyle]="tableValue.styles"
    />
  </ng-container>

  <ng-container *ngIf="isValueTextType(tableValue)">
    <span
      class="app-table__value {{ styleModifier }}"
      [class.app-table__value--no-overflow]="makeColumnOverflowEllipsis"
      [ngClass]="tableValue.styleClasses"
      [ngStyle]="tableValue.styles"
    >
      {{ tableValue.value }}
    </span>
  </ng-container>

  <ng-container *ngIf="isValueButtonType(tableValue)">
    <button
      data-cy="actionBtn"
      class="app-table__value {{ styleModifier }}"
      [ngClass]="tableValue.styleClasses"
      [ngStyle]="tableValue.styles"
      (click)="emitAction(tableValue.value, tableRow)"
    >
      {{ tableValue.value }}
    </button>
  </ng-container>

  <ng-container *ngIf="isValueCheckmarkType(tableValue)">
    <app-square-checkmark
      [(value)]="tableValue.value"
      [ngClass]="tableValue.styleClasses"
      [ngStyle]="tableValue.styles"
      (valueChange)="emitAction(tableValue.value, tableRow)"
    >
    </app-square-checkmark>
  </ng-container>

  <ng-container *ngIf="isValueTextFormType(tableValue)">
    <input
      type="text"
      [(ngModel)]="tableValue.value"
      class="app-table__value"
      [ngClass]="tableValue.styleClasses"
      [ngStyle]="tableValue.styles"
      (change)="emitAction(tableValue.value, tableRow)"
    />
  </ng-container>

  <ng-container *ngIf="isValueNumberFormType(tableValue)">
    <input
      type="number"
      [(ngModel)]="tableValue.value"
      class="app-table__value"
      [ngClass]="tableValue.styleClasses"
      [ngStyle]="tableValue.styles"
      (change)="emitAction(tableValue.value, tableRow)"
      data-cy="tableNumber"
    />
  </ng-container>

  <ng-container
    *ngIf="isValueTemplateType(tableValue)"
    [ngTemplateOutlet]="tableValue.template"
    [ngTemplateOutletContext]="{ tableValue, tableRow }"
  ></ng-container>
</ng-template>
