import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISupportRequest } from '@shared/models/support-request.model';
import { HttpClient, HttpContext } from '@angular/common/http';
import { UrlService } from '@services/url.service';
import { AUTH_REQUIRED } from '@app/tokens';

@Injectable({
  providedIn: 'root',
})
export class SupportRequestsService {
  constructor(
    private httpClient: HttpClient,
    private urlService: UrlService,
  ) {}

  submitRequest(request: Partial<ISupportRequest>, authRequired: boolean = true): Observable<any> {
    return this.httpClient.put<any>(this.urlService.SUPPORT_REQUESTS_URL, request, {
      context: new HttpContext().set(AUTH_REQUIRED, authRequired),
    });
  }

  getHelpTopics() {
    const helpTopics = [];
    helpTopics.push('How do I dispute an insurance response?');
    helpTopics.push('How do I use a coupon instead of consignment?');
    helpTopics.push('Missing or cancel consignment ID');
    helpTopics.push('The site is not working properly');
    helpTopics.push('How do I add a user to my pharmacy?');
    helpTopics.push('I need to reset my password');
    helpTopics.push('Other feedback');

    return helpTopics;
  }
}
