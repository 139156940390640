<div class="vh-100 overflow-auto">
  <div class="offcanvas-header">
    <h3 id="offcanvasRightLabel">Views gallery</h3>
    <button (click)="activeOffcanvas.dismiss()" type="button" class="btn-close text-reset" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ng-container *ngIf="pinnedViews.length > 0">
      <h4>Pinned</h4>
      <div class="card viewContainer" cdkDropList (cdkDropListDropped)="viewDropped($event)">
        <div cdkDrag *ngFor="let view of pinnedViews" class="view draggable">
          <div class="d-flex align-items-center">
            <chitin-icon class="pe-3" icon="fa-solid fa-bars" />
            <h5 class="mb-0">{{ view.label }}</h5>
          </div>
          <div class="d-flex gap-1">
            <chitin-button (clicked)="viewTogglePinned(view)" icon="fa-solid fa-thumbtack" label="" iconPosition="center" />
          </div>
        </div>
      </div>
    </ng-container>
    <h4>Default</h4>
    <div class="card viewContainer">
      <div *ngFor="let view of defaultViews" class="view">
        <h5 class="mb-0">{{ view.label }}</h5>
        <div class="d-flex gap-1">
          <chitin-button
            (clicked)="viewTogglePinned(view)"
            [icon]="(view.isPinned ? 'fa-solid' : 'fa-regular') + ' fa-thumbtack'"
            label=""
            iconPosition="center"
          />
        </div>
      </div>
    </div>
    <ng-container *ngIf="customViews.length > 0">
      <h4>My Views</h4>
      <div class="card viewContainer">
        <div *ngFor="let view of customViews; let i = index" class="view">
          <h5 class="mb-0">{{ view.label }}</h5>
          <div class="d-flex gap-1">
            <chitin-button
              (clicked)="viewTogglePinned(view)"
              [icon]="(view.isPinned ? 'fa-solid' : 'fa-light') + ' fa-thumbtack'"
              label=""
              iconPosition="center"
            />
            <chitin-button (clicked)="viewDeleted(view, i)" icon="fa-solid fa-xmark" label="" iconPosition="center" />
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
