import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { SelectListItem } from '@shared/models/select-list-item';
import { NgChanges } from '@shared/ng-changes';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() dropdownOptions: SelectListItem[];
  @Input() dropdownModel: SelectListItem | any;
  @Input() disabled = false;
  @Input() useThinArrow = false;
  @Input() useSelectListItemValue = false;
  @Input() height: number;

  @Output() dropdownModelChange = new EventEmitter<SelectListItem | any>();

  value: any;

  ngOnInit() {
    if (this.dropdownModel) {
      this.value = this.dropdownModel;
    }
  }

  ngOnChanges(changes: NgChanges<this>) {
    if (changes.dropdownModel) {
      this.value = this.dropdownModel;
    }
  }

  getSelectedValue() {
    if (!this.value) {
      return undefined;
    }
    if (this.useSelectListItemValue) {
      const selectedItem = this.findListItemByValue(this.value);
      return selectedItem ? selectedItem.name : undefined;
    }
    return (this.value as SelectListItem).name;
  }

  private findListItemByValue(value: any): SelectListItem | undefined {
    return this.dropdownOptions.find(option => option.value === value);
  }

  emitModelChange(dropdownOption: SelectListItem): void {
    this.value = this.useSelectListItemValue ? dropdownOption.value : dropdownOption;
    this.dropdownModelChange.emit(this.value);
  }
}
