<div class="radio-btns" [class.radio-btns--is-tokenized-login]="isTokenLogin">
  <div
    *ngFor="let item of items; first as isFirst; index as index"
    class="radio-btns__container"
    [class.selected]="item === selectedRadioItem"
    [class.radio-btns__container--margin]="!isFirst && !verticalLayout"
    [class.radio-btns__container--vertical-layout]="verticalLayout"
  >
    <input
      type="radio"
      class="radio-btns__radio"
      [id]="id + index"
      [name]="name"
      [value]="item"
      [disabled]="disabled"
      [(ngModel)]="selectedRadioItem"
      (ngModelChange)="emitChange()"
    />
    <label class="radio-btns__label" [class.checked]="item === selectedRadioItem" [for]="id + index"> {{ item.name }} </label>
  </div>
</div>
