import { Component, OnInit } from '@angular/core';
import { WindowService } from '@services/window.service';

@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.scss'],
})
export class UserAgreementComponent implements OnInit {
  isMobile = false;
  isTablet = false;

  constructor(private windowService: WindowService) {}

  ngOnInit() {
    this._checkDeviceType();
  }

  private _checkDeviceType(): void {
    this.windowService.isMobile.subscribe(newIsMobile => {
      this.isMobile = newIsMobile;
    });

    this.windowService.isTablet.subscribe(newIsTablet => {
      this.isTablet = newIsTablet;
    });
  }
}
