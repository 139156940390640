import { SharedPatientAssistancePharmacyPaymentStatementAggregatedStatusType } from '@libs/shared/domain/patient-assistance/pharmacy/payment-statement/shared-patient-assistance-pharmacy-payment-statement-aggregated-status.type';

export const PHARMACY_PAYMENT_STATEMENT_AGGREGATED_STATUSES: Record<
  SharedPatientAssistancePharmacyPaymentStatementAggregatedStatusType,
  string
> = {
  paid: 'Paid',
  unpaid: 'Unpaid',
  closed: 'Closed',
  paidAdjusted: 'Paid - Adjusted',
  unpaidAdjusted: 'Unpaid - Adjusted',
  closedAdjusted: 'Closed - Adjusted',
};
