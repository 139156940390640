import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, start: number, end?: number, suffix = '...'): string {
    if (typeof value !== 'string') {
      return value;
    }

    const sliced = value.slice(start, end);
    const trail = value.length > sliced.length ? suffix : '';

    return `${sliced}${trail}`;
  }
}
