import gql from 'graphql-tag';

export const CreatePharmacyMutation = gql`
  mutation CreatePharmacy($data: CreatePharmacyInput!) {
    createPharmacy(data: $data) {
      ncpdp
      npi
      name
      pbaEnrollment {
        systemVendor {
          id
        }
      }
      contact {
        email
      }
      softwareInstructionsSentAt
      paymentInfo {
        bankAccountNumber
        bankRoutingNumber
      }
    }
  }
`;
