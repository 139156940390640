import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppStateService } from '@services/app-state.service';
import { AuthService } from '@services/auth.service';
import { LogService } from '@services/log.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
})
export class SignOutComponent {
  constructor(
    public modal: NgbActiveModal,
    private authService: AuthService,
    private Log: LogService,
    private appStateService: AppStateService,
  ) {}

  onLogout(): void {
    this.authService.logout('AppSignOut');
    this.appStateService.resetApplicationState();
    this.modal.close();
  }
}
