import gql from 'graphql-tag';

export const GetPharmacyScorecardInfoQuery = gql`
  query GetPharmacyScorecardInfo($ncpdp: String!) {
    pharmacy(ncpdp: $ncpdp) {
      name
      npi
      enrollments {
        tenant {
          id
        }
        businessRegionId
      }
    }
  }
`;
