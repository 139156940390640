<div class="app-table__pagination-container">
  <div class="app-table__number-of-items-being-shown" data-cy="consignmentNumberPaginator">
    <ng-content></ng-content>
    <span *ngIf="length > 0">Showing {{ startIndex + 1 }} to {{ endIndex }} of {{ length }}</span>
    <span *ngIf="length < 1">Showing 0</span>
  </div>

  <div class="app-table__pagination-controls-container">
    <div class="app-table__rows-per-page">
      <div class="select-control select-control--horizontal">
        <span class="select-control__label select-control__label--horizontal">Rows per page:</span>
        <div class="select-control__control-container select-control__control-container--horizontal">
          <ng-select
            class="select-control__control ng-select-transparent-background"
            data-cy="rowsPerPageSelect"
            dropdownPosition="top"
            [(ngModel)]="pageSize"
            [items]="pageSizeOptions"
            [clearable]="false"
            [searchable]="false"
            [disabled]="loading"
            (change)="onPageSizeChange($event)"
          >
          </ng-select>
        </div>
      </div>
    </div>

    <div class="app-table__pagination-controls">
      <pagination-controls style="display: none"></pagination-controls>
      <ul class="ngx-pagination">
        <li class="pagination-previous" [class.disabled]="isPrevDisabled">
          <span *ngIf="isPrevDisabled"><span class="show-for-sr">page</span></span>
          <a *ngIf="!isPrevDisabled" (click)="prev()" tabindex="0">
            <span class="show-for-sr">page</span>
          </a>
        </li>

        <li class="current">
          <span class="show-for-sr">You're on page</span>
          <span>{{ pageIndex + 1 }}</span>
        </li>

        <li class="pagination-next" [class.disabled]="isNextDisabled">
          <span *ngIf="isNextDisabled"><span class="show-for-sr">page</span></span>
          <a *ngIf="!isNextDisabled" (click)="next()" tabindex="0">
            <span class="show-for-sr">page</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
