import { EventEmitter, HostListener, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  public readonly breakpoints = {
    xxl: 1920,
    xl: 1680,
    lg: 1440,
    md: 1024,
    sm: 768,
    xs: 450,
  };

  height: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  width: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  isMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isTablet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isDesktop: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Output() resize = new EventEmitter<void>();

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    const height = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight,
    );

    const width = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth,
    );

    this.setSize(height, width);
  }

  constructor(private deviceService: DeviceDetectorService) {
    this.getScreenSize();
  }

  setSize(height: number, width: number): void {
    if (height !== this.getHeight() || width !== this.getWidth()) {
      this.setHeight(height, false);
      this.setWidth(width, false);

      this.triggerResizeEvent();
      this.checkDeviceType();
    }
  }

  triggerResizeEvent() {
    this.resize.emit();
  }

  getHeight(): number {
    return this.height.getValue();
  }

  getWidth(): number {
    return this.width.getValue();
  }

  private setHeight(newHeight: number, triggerResize = true): void {
    this.height.next(newHeight);
    if (triggerResize) {
      this.triggerResizeEvent();
    }
  }

  private setWidth(newWidth: number, triggerResize = true): void {
    this.width.next(newWidth);
    if (triggerResize) {
      this.triggerResizeEvent();
    }
  }

  private checkDeviceType(): void {
    this.isMobile.next(this.deviceService.isMobile());
    this.isTablet.next(this.deviceService.isTablet());
    this.isDesktop.next(this.deviceService.isDesktop());
  }
}
