import { Component, Input } from '@angular/core';

type LoadingSpinnerStyleModifier = 'default' | 'small' | 'button-120-px' | 'small-and-relative' | 'small-and-centered';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  static readonly BLOCK_CLASS_NAME = 'loading-spinner';
  static readonly DEFAULT_MODIFIER: LoadingSpinnerStyleModifier = 'default';

  completeBlockModifier: string;

  @Input() isLoading = false;
  @Input() loadingText = '';
  @Input() set modifier(modifier: LoadingSpinnerStyleModifier) {
    this.setCompleteBlockModifierFromModifierName(modifier);
  }

  constructor() {
    this.setCompleteBlockModifierFromModifierName(LoadingSpinnerComponent.DEFAULT_MODIFIER);
  }

  private setCompleteBlockModifierFromModifierName(modifierName: LoadingSpinnerStyleModifier): void {
    this.completeBlockModifier = LoadingSpinnerComponent.BLOCK_CLASS_NAME + '--' + modifierName;
  }
}
