<div class="bs-scrollbar" [class.bs-scrollbar--scroll-overflow]="isMobile || isTablet">
  <perfect-scrollbar *ngIf="!isMobile && !isTablet">
    <ng-container [ngTemplateOutlet]="content"> </ng-container>
  </perfect-scrollbar>

  <ng-container *ngIf="isMobile || isTablet" [ngTemplateOutlet]="content"> </ng-container>
</div>

<ng-template #content>
  <ng-content> </ng-content>
</ng-template>
