import { Pipe, PipeTransform } from '@angular/core';

export type Trend = 'up' | 'down' | 'same';

export function getTrend(current: number, previous: number): Trend {
  let result: Trend;

  if (current > previous) {
    result = 'up';
  } else if (current < previous) {
    result = 'down';
  } else {
    result = 'same';
  }

  return result;
}

@Pipe({
  name: 'trendIcon',
  pure: true,
})
export class TrendIconPipe implements PipeTransform {
  transform(value: Trend): string {
    let result: string;

    if (value === 'up') {
      result = 'fa fa-arrow-up-right';
    } else if (value === 'down') {
      result = 'fa fa-arrow-down-right';
    } else {
      result = 'fa fa-arrow-right';
    }

    return result;
  }
}
