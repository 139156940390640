import { DateRangePreset } from '@shared/models/date-range-preset';
import { isDate, isEmpty, isObject } from 'lodash';

export interface DateRange {
  from: Date;
  to: Date;
  preset?: DateRangePreset;
  presetDisplayValue?: string;
}

export function isDateRange(value: unknown): value is DateRange {
  return isObject(value) && !isEmpty(value) && 'from' in value && 'to' in value && isDate(value.from) && isDate(value.to);
}
