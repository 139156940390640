import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { tap } from 'rxjs/operators';
import Utils from '@shared/providers/utils';
import { PharmacyApiService } from '@services/pharmacy-api.service';
import { IStateService } from '@shared/models/state-service.model';
import { LogService } from '@services/log.service';
import { StateServiceName } from '@shared/models/state-service-name.enum';
import { IUser } from '@shared/models/user.model';
import { PharmacyList } from '@shared/models/pharmacy.list.model';
import { CouponRequestStateService } from '@services/coupon-request-state.service';
import { textSpanIntersectsWithTextSpan } from 'typescript';

@Injectable({
  providedIn: 'root',
})
export class PharmacyStateService implements IStateService {
  name = StateServiceName.PHARMACY;

  userPharmacies = new BehaviorSubject<IPharmacy[]>([]);

  constructor(
    private pharmacyApiService: PharmacyApiService,
    private couponRequestStateService: CouponRequestStateService,
    private Log: LogService,
  ) {}

  reset() {
    this.userPharmacies.next([]);
  }

  setFromCurrentUser(currentUser: IUser): void {
    const isCouponRequestInProgress = this.couponRequestStateService.isCouponRequestInProgress();
    const couponRequestPharmacies = this.couponRequestStateService.userPharmacies.getValue();
    console.log(`isCouponRequestInProgress: ${isCouponRequestInProgress}`);
    console.log(couponRequestPharmacies);
    if (isCouponRequestInProgress && Utils.isNonEmptyArray(couponRequestPharmacies)) {
      this.userPharmacies.next(couponRequestPharmacies);
    } else if (currentUser && Utils.isNonEmptyArray(currentUser.ncpdps)) {
      this.getUsersPharmacies().subscribe();
    }
  }

  setFromObservable<T>(observable: Observable<T>): Observable<T> {
    return (observable as any).pipe(
      tap(newPharmacies => {
        if (Utils.isNonEmptyArray(newPharmacies)) {
          this.userPharmacies.next(newPharmacies as IPharmacy[]);
        } else if (newPharmacies && (newPharmacies as IPharmacy).ncpdp) {
          this.userPharmacies.next([newPharmacies as IPharmacy]);
        } else if (Utils.isNonEmptyArray((newPharmacies as PharmacyList).items)) {
          this.userPharmacies.next((newPharmacies as PharmacyList).items);
        }
      }),
    );
  }

  contains(pharmacy: IPharmacy): boolean {
    if (!pharmacy || !pharmacy.ncpdp) {
      this.Log.errorWithAction('Undefined pharmacy or pharmacy without ncpdp', 'pharmacy-state-contains');
      return false;
    }

    const pharmacies = this.userPharmacies.getValue();
    if (!Utils.isNonEmptyArray(pharmacy) || !pharmacies[0]) {
      return false;
    }

    return pharmacies.some(usersOrCallersPharmacy => usersOrCallersPharmacy.ncpdp === pharmacy.ncpdp);
  }

  update(updatedPharmacy: IPharmacy): void {
    if (!updatedPharmacy || !updatedPharmacy.ncpdp) {
      this.Log.errorWithAction('Undefined pharmacy or pharmacy without ncpdp', 'pharmacy-state-update-existing');
      return;
    }

    const currentUsersOrCallersPharmacies = this.userPharmacies.getValue();
    if (currentUsersOrCallersPharmacies) {
      const pharmacyToUpdateIndex = currentUsersOrCallersPharmacies.findIndex(
        userOrCallerPharmacy => userOrCallerPharmacy.ncpdp === updatedPharmacy.ncpdp,
      );
      currentUsersOrCallersPharmacies.splice(pharmacyToUpdateIndex, 1, updatedPharmacy);
      this.userPharmacies.next(currentUsersOrCallersPharmacies);
    }
  }

  getPharmacyByNcpdp(ncpdp: string, getProgramInfo = false): Observable<IPharmacy> {
    return this.setFromObservable(this.pharmacyApiService.getPharmacyByNcpdp(ncpdp, getProgramInfo));
  }

  getPharmacyBatchByNcpdp(ncpdps: string[]): Observable<PharmacyList> {
    return this.setFromObservable(this.pharmacyApiService.getPharmacyBatchByNcpdp(ncpdps));
  }

  getUsersPharmacies(): Observable<IPharmacy[]> {
    return this.setFromObservable(this.pharmacyApiService.getUserPharmacyList());
  }

  savePharmacy(pharmacy: IPharmacy): Observable<any> {
    if (this.contains(pharmacy)) {
      this.update(pharmacy);
    }

    return this.pharmacyApiService.savePharmacy(pharmacy);
  }
}
