<div class="input-group">
  <input
    [(ngModel)]="date"
    ngbDatepicker
    #dp="ngbDatepicker"
    container="body"
    (dateSelect)="dateSelect()"
    [footerTemplate]="dpFooterTemplate"
    [outsideDays]="outsideDays"
    [minDate]="minDate"
    [maxDate]="maxDate"
    class="hidden-control"
  />
  <input [value]="textValue" [readOnly]="true" (click)="dp.toggle()" [placeholder]="placeholder" class="form-control" />
  <button class="btn btn-outline-light" [disabled]="disabled" (click)="dp.toggle()">
    <i class="material-icons md-inline">calendar_today</i>
  </button>
  <ng-container *ngIf="timepicker">
    <button class="btn btn-outline-light" [disabled]="disabled" [ngbPopover]="timePicker" autoClose="outside" placement="bottom">
      <i class="material-icons md-inline">access_time</i>
    </button>
  </ng-container>
</div>

<ng-template #dpFooterTemplate>
  <button *ngIf="showReset" class="btn btn-link btn-sm m-2 float-left" (click)="reset()">Reset</button>
  <button *ngIf="showToday" class="btn btn-link btn-sm m-2 float-right" (click)="setToday()">Today</button>
</ng-template>

<ng-template #timePicker>
  <ngb-timepicker [(ngModel)]="time" (ngModelChange)="timeSelect()" [meridian]="true" size="small"></ngb-timepicker>
</ng-template>
