import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { IPharmacy } from '@shared/models/pharmacy.model';

@Component({
  selector: 'app-all-pharmacies-select',
  templateUrl: './all-pharmacies-select.component.html',
  styleUrls: ['./all-pharmacies-select.component.scss'],
})
export class AllPharmaciesSelectComponent {
  @Input()
  currentPharmacy?: IPharmacy;

  readonly search = new Subject<string>();

  @Output()
  readonly changed = new EventEmitter<IPharmacy>();

  onChange(pharmacy: any) {
    this.changed.emit(pharmacy);
  }
}
