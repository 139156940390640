<svg #sliderContainer class="var-slider" viewBox="0 0 100 16" xmlns="http://www.w3.org/2000/svg">
  <text *ngIf="minLabel" class="var-slider__text" [attr.x]="0" [attr.y]="3">{{ minLabel }}</text>
  <circle class="var-slider__circle" [attr.cx]="STARTING_CENTER_X" [attr.r]="3" [attr.cy]="Y"></circle>

  <line class="var-slider__line" [attr.x1]="9" [attr.y1]="Y" [attr.x2]="91" [attr.y2]="Y"></line>

  <text *ngIf="maxLabel" class="var-slider__text" [attr.x]="100" [attr.y]="3" text-anchor="end">{{ maxLabel }}</text>
  <circle class="var-slider__circle" [attr.cx]="ENDING_CENTER_X" [attr.r]="3" [attr.cy]="Y"></circle>

  <filter id="dropshadow" height="130%">
    <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
    <!-- stdDeviation is how much to blur -->
    <feOffset dx="2" dy="2" result="offsetblur" />
    <!-- how much to offset -->
    <feComponentTransfer>
      <feFuncA type="linear" slope="0.5" />
      <!-- slope is the opacity of the shadow -->
    </feComponentTransfer>
    <feMerge>
      <feMergeNode />
      <!-- this contains the offset blurred image -->
      <feMergeNode in="SourceGraphic" />
      <!-- this contains the element that the filter is applied to -->
    </feMerge>
  </filter>
  <g (mousedown)="onMouseDown($event)">
    <circle class="var-slider__users-circle" [attr.cx]="getCircleX()" [attr.r]="5" [attr.cy]="Y"></circle>
    <text class="var-slider__text var-slider__text--value" [attr.x]="getCircleX()" [attr.y]="Y + 1.25" text-anchor="middle">
      {{ _value }}
    </text>
  </g>
</svg>
