import { Component, Directive, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'perfect-scrollbar',
  template: `<ng-content></ng-content>`,
})
export class PerfectScrollbarComponent {}

@Directive({
  selector: '[perfectScrollbar]',
})
export class PerfectScrollbarDirective {
  @Input() perfectScrollbar: unknown;
  @HostBinding('class')
  elementClass = 'perfect-scrollbar';
}
