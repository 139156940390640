<div class="pageWrapper bg-light">
  <div class="prescriberForm bg-incyte-white">
    <div class="w-100 d-flex align-items-center">
      <img src="/assets/images/logos/opzelura.svg" class="w-auto opzeluraLogo" />
      <div class="dividerStart">
        <h1 class="mb-0">
          <div class="font-gobold text-incyte-coral">COMMERCIAL ACCESS PROGRAM</div>
          <div class="font-gobold text-incyte-navy">ENROLLMENT FORM</div>
        </h1>
      </div>
    </div>
    <p class="text-center mb-3" style="margin-top: 2.5rem">
      Please complete this form in its entirety and click “submit” at the bottom of this page.
    </p>
    <div class="formSection mb-3">
      <div class="header">
        <div class="sectionNumber bg-incyte-coral">1</div>
        <div class="sectionTitle bg-incyte-navy">Pharmacy information</div>
      </div>
      <div class="body">
        <div class="mb-2">
          <prescriber-form-input label="Pharmacy Name" [(model)]="model.pharmacy_name"></prescriber-form-input>
        </div>
        <div class="d-flex">
          <prescriber-form-input label="Address" [(model)]="model.pharmacy_address" style="width: 55%"></prescriber-form-input>
          <prescriber-form-input label="City" [(model)]="model.pharmacy_city" style="width: 25%"></prescriber-form-input>
          <prescriber-form-input label="State" [(model)]="model.pharmacy_state" style="width: 13%"></prescriber-form-input>
          <prescriber-form-input label="ZIP" [(model)]="model.pharmacy_zip" style="width: 17%"></prescriber-form-input>
        </div>
      </div>
    </div>
    <div class="formSection mb-3">
      <div class="header">
        <div class="sectionNumber bg-incyte-coral">2</div>
        <div class="sectionTitle bg-incyte-navy">Patient information</div>
      </div>
      <div class="body">
        <div class="d-flex">
          <prescriber-form-input label="Full Name" [(model)]="model.patient_name" style="width: 70%"></prescriber-form-input>
          <prescriber-form-input label="Date of Birth" [(model)]="model.patient_dob" type="date" style="width: 30%"></prescriber-form-input>
        </div>
      </div>
    </div>
    <div class="formSection mb-3">
      <div class="header">
        <div class="sectionNumber bg-incyte-coral">3</div>
        <div class="sectionTitle bg-incyte-navy">Prescriber information</div>
      </div>
      <div class="body">
        <div class="d-flex mb-2">
          <prescriber-form-input label="Full Name" [(model)]="model.prescriber_name" style="width: 70%"></prescriber-form-input>
          <prescriber-form-input label="NPI Number" [(model)]="model.prescriber_npi" style="width: 30%"></prescriber-form-input>
        </div>
        <div class="mb-2">
          <prescriber-form-input label="Practice Name" [(model)]="model.prescriber_practice_name"></prescriber-form-input>
        </div>
        <div class="d-flex mb-2">
          <prescriber-form-input label="Address" [(model)]="model.prescriber_address" style="width: 55%"></prescriber-form-input>
          <prescriber-form-input label="City" [(model)]="model.prescriber_city" style="width: 25%"></prescriber-form-input>
          <prescriber-form-input label="State" [(model)]="model.prescriber_state" style="width: 13%"></prescriber-form-input>
          <prescriber-form-input label="ZIP" [(model)]="model.prescriber_zip" style="width: 17%"></prescriber-form-input>
        </div>
        <div class="d-flex mb-2">
          <prescriber-form-input
            label="Practice Contact Name (optional)"
            [(model)]="model.prescriber_contact_name"
            style="width: 70%"
          ></prescriber-form-input>
          <prescriber-form-input label="Phone" type="tel" [(model)]="model.prescriber_phone" style="width: 30%"></prescriber-form-input>
        </div>
        <div class="d-flex">
          <prescriber-form-input
            label="Best time to call"
            style="width: 70%"
            type="option"
            [(model)]="model.prescriber_best_time_to_call"
            [options]="timeOptions"
            [allowCustomValue]="true"
          ></prescriber-form-input>
          <prescriber-form-input label="Fax" type="tel" [(model)]="model.prescriber_fax" style="width: 30%"></prescriber-form-input>
        </div>
      </div>
    </div>
    <div class="formSection mb-3">
      <div class="header">
        <div class="sectionNumber bg-incyte-coral">4</div>
        <div class="sectionTitle bg-incyte-navy">ICD-10 code</div>
      </div>
      <div class="body bg-incyte-coral-125">
        <prescriber-form-input
          label="Primary Diagnosis ICD-10 Code(s)"
          labelStyles="font-weight: 600"
          type="option"
          [(model)]="model.icd10"
          (modelChange)="onIcd10Selected($event.value)"
          [options]="icd10Options"
          [allowCustomValue]="true"
        ></prescriber-form-input>
      </div>
    </div>
    <div class="formSection mb-3">
      <div class="header">
        <div class="sectionNumber bg-incyte-coral">5</div>
        <div class="sectionTitle bg-incyte-navy">Prescriber Declaration</div>
      </div>
      <div class="body">
        <div class="mb-2">
          <span class="fw-bold">By my signature, I certify that</span> I have obtained any and all authorizations and consents from the
          patient or the patient’s authorized personal representative necessary under HIPAA and state law to release protected health
          information, including that contained on this form, to Incyte and its employees or agents for purposes relating to Incyte’s
          patient support programs.
        </div>
        <div class="d-flex declarationsWrapper mb-3">
          <div class="inputWrapper" [ngClass]="{ hasError: model.l20_declaration.error, isDisabled: model.icd10.value === 'l80' }">
            <div class="text-incyte-coral text-uppercase fw-bold" style="font-size: 1rem; margin-top: 0.5rem">
              For patients with atopic dermatitis
            </div>
            <div class="d-flex">
              <input
                type="checkbox"
                class="checkboxInput flex-shrink-0"
                id="l20_declaration"
                [(ngModel)]="model.l20_declaration.value"
                (ngModelChange)="model.l20_declaration.error = undefined"
                [disabled]="model.icd10.value === 'l80'"
              />
              <label
                class="checkboxLabel"
                [ngClass]="{ 'text-danger': model.l20_declaration.error }"
                style="margin-top: 0.2rem"
                for="l20_declaration"
              >
                <span class="fw-bold">By checking this box, I certify</span>
                <span>
                  my patient has mild to moderate atopic dermatitis, has tried and failed a topical prescription therapy, and the Prior
                  Authorization (PA) was denied.
                </span>
              </label>
            </div>
            <div *ngIf="model.l20_declaration.error" class="fieldError d-block text-danger">
              <i class="fa-regular fa-circle-exclamation"></i> {{ model.l20_declaration.error }}
            </div>
          </div>
          <div class="inputWrapper" [ngClass]="{ hasError: model.l80_declaration.error, isDisabled: model.icd10.value === 'l20' }">
            <div class="text-incyte-coral text-uppercase fw-bold" style="font-size: 1rem; margin-top: 0.5rem">
              For patients with nonsegmental vitiligo
            </div>
            <div class="d-flex">
              <input
                type="checkbox"
                class="checkboxInput flex-shrink-0"
                id="l80_declaration"
                [(ngModel)]="model.l80_declaration.value"
                (ngModelChange)="model.l80_declaration.error = undefined"
                [disabled]="model.icd10.value === 'l20'"
              />
              <label
                class="checkboxLabel"
                [ngClass]="{ 'text-danger': model.l80_declaration.error }"
                style="margin-top: 0.2rem"
                for="l80_declaration"
              >
                <span class="fw-bold">By checking this box, I certify</span>
                <span> my patient has nonsegmental vitiligo and that the Prior Authorization (PA) was denied. </span>
              </label>
            </div>
            <div *ngIf="model.l80_declaration.error" class="fieldError d-block text-danger">
              <i class="fa-regular fa-circle-exclamation"></i> {{ model.l80_declaration.error }}
            </div>
          </div>
        </div>
        <div *ngIf="model.declaration.error" class="d-block text-danger">
          <i class="fa-regular fa-circle-exclamation"></i> You must choose one of the declarations!
        </div>
        <div class="d-flex align-items-center mt-3">
          <div
            class="inputWrapper"
            [ngClass]="{ hasError: model.pa_denial_reason.value !== 'Other' && model.pa_denial_reason.error }"
            style="width: 70%; margin-top: -0.25rem"
          >
            <select
              required
              name="paDenialReason"
              class="selectInput"
              [(ngModel)]="model.pa_denial_reason.value"
              (ngModelChange)="model.pa_denial_reason.error = undefined"
            >
              <option selected value="" disabled>PA Denial Reason</option>
              <option *ngFor="let reason of reasons" value="{{ reason }}">{{ reason }}</option>
            </select>
            <div class="fieldError text-danger"><i class="fa-regular fa-circle-exclamation"></i> {{ model.pa_denial_reason.error }}</div>
          </div>
          <prescriber-form-input
            label="PA Denial Date"
            [(model)]="model.pa_denial_date"
            style="width: 30%"
            class="textInputWrapper pl-5"
            type="date"
          ></prescriber-form-input>
        </div>
        <div
          class="d-flex mt-2 inputWrapper"
          *ngIf="model.pa_denial_reason.value === 'Other'"
          [ngClass]="{ hasError: model.pa_denial_reason.error }"
        >
          <div class="inputLabel" [ngClass]="{ 'text-danger': model.pa_denial_reason.error }"></div>
          <div class="flex-grow-1 textInputWrapper">
            <input
              type="text"
              placeholder="Other PA Denial Reason"
              class="textInput"
              [(ngModel)]="model.pa_denial_reason.valueCustom"
              (ngModelChange)="model.pa_denial_reason.error = undefined"
            />
          </div>
          <div class="fieldError text-danger"><i class="fa-regular fa-circle-exclamation"></i> {{ model.pa_denial_reason.error }}</div>
        </div>
        <div class="d-flex mt-3 justify-content-center align-items-start">
          <div class="flex-grow-1" style="margin-top: 7rem">
            <prescriber-form-input
              class="d-block mt-3 mb-4"
              label="Date of signature"
              type="date"
              [model]="model.signature_date"
              [disabled]="true"
            ></prescriber-form-input>
          </div>
          <div class="position-relative" style="width: fit-content">
            <signature-pad
              signatureTitle="Prescriber Signature"
              [signatureSubtitle]="model.signature_date.value"
              (signatureChanged)="onSignatureChanged($event)"
            ></signature-pad>
            <div
              *ngIf="model.signature_base64.error"
              class="fieldError d-block text-danger"
              style="top: 1rem; left: 1rem; pointer-events: none"
            >
              <i class="fa-regular fa-circle-exclamation"></i> {{ model.signature_base64.error }}
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="flex-grow-1 d-flex justify-content-center align-items-center text-incyte-coral fw-bold">
          For questions call 815-853-1348 or email support&#64;brightscrip.com
        </div>
        <button class="bg-incyte-light-blue submitBtn" (click)="onSubmit()">
          <div *ngIf="isSubmitting" class="spinner-border spinner-border-sm text-dark mx-3" role="status">
            <span class="sr-only">Submitting...</span>
          </div>
          <span *ngIf="!isSubmitting">SUBMIT</span>
        </button>
      </div>
    </div>
  </div>
</div>
