import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pageTotal',
})
export class PageTotalPipe implements PipeTransform {
  transform(total: number, pageSize: number): string {
    return `Showing ${total === 0 ? 0 : 1} - ${total > pageSize ? pageSize : total} of ${total} items.`;
  }
}
