import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '@services/url.service';
import { IProgramGroup } from '@shared/models/program-group.model';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProgramGroupApiService {
  constructor(
    private http: HttpClient,
    private urlService: UrlService,
  ) {}

  /**
   * @deprecated
   */
  getAllProgramGroups(): Observable<IProgramGroup[]> {
    return this.http.get<IProgramGroup[]>(this.urlService.PROGRAM_GROUP_URL);
  }

  getAllProgramGroupsForTenant(tenant: string): Observable<IProgramGroup[]> {
    let url = this.urlService.PROGRAM_GROUP_URL;
    url = url.replace('{tenant}', tenant);
    return this.http.get<IProgramGroup[]>(url);
  }

  getAllProgramGroupsForTenants(tenants: string[]): Observable<Record<string, IProgramGroup[]>> {
    const result: Record<string, IProgramGroup[]> = {};
    const requests = tenants.map(tenant => this.getAllProgramGroupsForTenant(tenant));
    return forkJoin(requests).pipe(
      map(results => {
        tenants.forEach((tenant, index) => (result[tenant] = results[index]));
        return result;
      }),
    );
  }
}
