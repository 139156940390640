import { Injectable } from '@angular/core';
import { RetailStateService } from '@app/retail/providers/retailState.service';
import { PharmacyStateService } from '@services/pharmacy-state.service';
import { ProgramGroupStateService } from '@services/program-group-state.service';
import { ProgramStateService } from '@services/program-state.service';
import { TenantService } from '@services/tenant.service';
import { UserStoreService } from '@services/user-store.service';
import { UserService } from '@services/user.service';
import { IUser } from '@shared/models/user.model';

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  constructor(
    private userStoreService: UserStoreService,
    private userService: UserService,
    private tenantService: TenantService,
    private programStateService: ProgramStateService,
    private retailStateService: RetailStateService,
    private programGroupStateService: ProgramGroupStateService,
    private pharmacyStateService: PharmacyStateService,
  ) {}

  /**
   * @deprecated
   */
  resetApplicationState(user?: IUser): void {
    if (user) {
      this.userService.reset();
      this.userService.setSelectedUser(user);
    }
    this.userStoreService.resetStore();
    this.retailStateService.reset();
    this.pharmacyStateService.reset();
    this.programStateService.reset();
    this.programGroupStateService.reset();
    this.tenantService.reset();
  }
}
