<div class="d-flex gap-3 rangeInputWrapper align-items-center">
  <chitin-form-input
    #inputFieldToFocus
    placeholder="From"
    type="number"
    [value]="value ? (value.from ?? null) : null"
    (valueChange)="onFromChange($event)"
    [size]="size"
    [isDisabled]="isDisabled || readonly || isMinDisabled"
    [attr.id]="inputId"
    [clearable]="clearable ?? false"
    [prefix]="prefix"
    [suffix]="suffix"
    (blur)="onBlur()"
  />
  @if (mode === 'fullsize') {
    <div>-</div>
  }
  <chitin-form-input
    placeholder="To"
    type="number"
    [value]="value ? (value.to ?? null) : null"
    (valueChange)="onToChange($event)"
    [size]="size"
    [isDisabled]="isDisabled || readonly || isMaxDisabled"
    [attr.id]="inputId"
    [clearable]="clearable ?? false"
    [prefix]="prefix"
    [suffix]="suffix"
    (blur)="onBlur()"
  />
</div>
