import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { isString } from 'lodash';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-formly-field-select',
  template: `
    <chitin-select-old
      [formControl]="formControl"
      [ngClass]="{ 'is-invalid': !formControl.valid && formControl.touched && !field.props?.disabled }"
      [name]="fieldName"
      [size]="field.props?.size ?? 'medium'"
      [valueExpr]="field.props?.valueExpr ?? 'value'"
      [displayExpr]="field.props?.displayExpr ?? 'name'"
      [items]="items"
      [itemsStream]="itemsStream"
      [multiple]="field.props?.multiple"
      [placeholder]="field.props?.placeholder ?? ''"
      [isDisabled]="field.props?.disabled ?? false"
      [readonly]="field.props?.readonly ?? false"
      [clearable]="field.props?.clearable"
      [searchable]="field.props?.searchable ?? 'smart'"
      [displayNumberOfSelected]="true"
      [forceClear]="field.props?.forceClear"
      [autoWidth]="field.props?.autoWidth ?? true"
      [dropdownRtl]="field.props?.dropdownRtl ?? false"
    ></chitin-select-old>
  `,
})
export class FormlyFieldSelectComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  items: any[] = [];
  itemsStream?: Observable<any[]>;
  fieldName: string;
  fieldOptions?: any[] | Observable<any[]>;
  fieldSubscription?: Subscription;
  optionsSubscription?: Subscription;

  ngOnInit() {
    this.setOptions();
    if (this.field.options?.fieldChanges)
      this.fieldSubscription = this.field.options.fieldChanges.subscribe(() => {
        this.setOptions();
      });
  }

  ngOnDestroy() {
    this.fieldSubscription?.unsubscribe();
    this.optionsSubscription?.unsubscribe();
  }

  private setOptions() {
    this.fieldName = isString(this.field.key) ? this.field.key : '';
    this.optionsSubscription?.unsubscribe();
    this.fieldOptions = this.field.props?.options;
    if (Array.isArray(this.fieldOptions)) {
      this.items = this.fieldOptions;
    } else if (this.fieldOptions) {
      this.itemsStream = this.fieldOptions;
    }
  }
}
