import { AfterViewInit, Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { BaseComponent } from '@shared/components/base-component';
import { Navlink } from '@shared/models/navlink.model';
import { Tenant } from '@shared/models/tenant.model';
import { IUser } from '@shared/models/user.model';

export interface TopbarLink extends Navlink {
  messageIcon?: string;
  message?: string;
}

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent extends BaseComponent implements AfterViewInit {
  @Output() public sidebarClose = new EventEmitter<void>();
  @Output() public tenantChange = new EventEmitter<Tenant>();
  @Input() currentTenant?: Tenant;
  @Input() tenants: Tenant[] = [];
  @Input() userLinks: TopbarLink[] = [];
  @Input() tenantsDisabled = false;
  @Input() topbarExpanded: boolean;
  @Input() homeLink?: Navlink;

  currentUserWarning = false;
  // TODO: This should be determined by comparing the userInfoLastConfirmed date and current date

  public currentUser?: IUser = undefined;

  public constructor(
    private authService: AuthService,
    private userService: UserService,
    private renderer: Renderer2,
  ) {
    super();
    this.currentUser = this.authService.getCurrentUser();
  }

  ngAfterViewInit() {
    this.subscribe(this.userService.userChanged, () => {
      this.currentUser = this.authService.getCurrentUser();
    });
  }

  logoutClick() {
    this.authService.logout('AppSignOut');
  }

  public setTheme(theme: string) {
    this.renderer.setAttribute(document.body, 'data-bs-theme', theme);
  }
}
