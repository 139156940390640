import { Component, Input, TemplateRef } from '@angular/core';
import { CursorPaginatorComponent } from '@app/shared/components/data-table/cursor-paginator/cursor-paginator.component';

@Component({
  selector: 'app-infiniscroll-cursor-paginator',
  templateUrl: './infiniscroll-cursor-paginator.component.html',
  styleUrl: './infiniscroll-cursor-paginator.component.scss',
})
export class InfiniscrollCursorPaginatorComponent extends CursorPaginatorComponent {
  @Input() detailTemplate?: TemplateRef<any>;
  Math = Math;
}
