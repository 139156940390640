<perfect-scrollbar *ngIf="!isMobile && !isTablet">
  <ng-container [ngTemplateOutlet]="agreement"></ng-container>
</perfect-scrollbar>

<ng-container *ngIf="isMobile || isTablet" [ngTemplateOutlet]="agreement"></ng-container>

<!-- Templates -->

<ng-template #agreement>
  <div class="content">
    <p><strong>Program Guidelines &amp; Reminders for HorizonCares&trade; Pharmacy Staff</strong></p>

    <p>
      Government healthcare program beneficiaries, including those enrolled in Medicare, Medicaid, Tricare, and Workers’ Compensation, may
      not participate in HorizonCares&trade;. Your pharmacy is responsible for taking measures to help ensure such patients are excluded
      from participation in HorizonCares&trade;.
    </p>

    <p>
      Your pharmacy is prohibited from: 1) Engaging in steering, switching, or conversion practices. The HorizonCares&trade; Program is not
      intended to induce a pharmacist to switch any prescriptions; 2) Using dummy scripts; and 3) Sharing patient information, of any kind,
      with Horizon employees, including your HorizonCares&trade; contacts.
    </p>

    <p><strong>HorizonCares&trade; Program</strong></p>

    <p>
      <strong>A Patient Support Program for Commercially Insured Patients*:</strong> Through non-retail and retail pharmacies,
      HorizonCares&trade; lowers patient out-of-pocket costs while improving medication compliance for eligible&dagger;, commercially
      insured patients. As such, the pharmacy should not direct the HorizonCares&trade; program towards specific patient populations (e.g.,
      low income, patients over the age of 65).
    </p>

    <p>
      <i
        >*Patients whose medications are covered by government healthcare programs, such as Medicare, Medicaid, Tricare, and Workers’
        Compensation, may not participate in HorizonCares&trade;.</i
      >
    </p>

    <p>
      <strong>Among other things, your pharmacy is responsible for:</strong> 1) Ensuring that all pharmacy employees involved in the
      HorizonCares&trade; Program complete the mandatory compliance onboarding training; and 2) Taking measures to help ensure that
      government healthcare program beneficiaries, including those covered by Medicare, Medicaid, Tricare, and Workers’ Compensation, are
      excluded from participation in the HorizonCares&trade; Program.
    </p>

    <p>
      <strong>Prohibited Pharmacy Activities:</strong> 1) Promoting the Program as a product discount or positioning it in a manner that
      implies value to HCPs; 2) Engaging in any activities or doing anything that will create the appearance that it is “steering,”
      “switching,” or “converting” prescriptions to Horizon’s products; 3) Using dummy scripts (any script that contains a patient, real or
      fictitious, that does not need the prescription); or 4) Sharing any patient information with Horizon employees.
    </p>

    <p>
      <strong>Terms and Conditions:</strong> &dagger; To redeem a HorizonCares&trade; offer, you must have a valid commercial prescription.
      The offer may not be redeemed for CASH. By using an offer, you are certifying that the patient meets the eligibility criteria
      (commercial beneficiary) and you will comply with the following Terms and Conditions: Offer cannot be combined with any other rebate
      or coupon, free trial, or similar offer for the specified prescription. Not valid for prescriptions reimbursed, in whole or in part,
      by Medicaid, Medicare, VA, DOD, TriCare or other federal or state programs (including state prescription drug programs). Offer good
      only in the United States at participating retail pharmacies for eligible Horizon products. Offer not valid where otherwise prohibited
      by law, for example by applicable state law prohibiting co-pay cards. Absent a change in Massachusetts law, offer not valid in
      Massachusetts after July 1, 2020. Horizon reserves the right to rescind, revoke, or amend the offer without notice. The selling,
      purchasing, trading, or counterfeiting of this offer is prohibited by law. This card is not insurance and is not intended to
      substitute for insurance. Participating patients and pharmacists understand and agree to comply with all Terms and Conditions of the
      offer. Patients must be 18 or older.
    </p>
  </div>
</ng-template>
