export enum IconPath {
  HoverNavLinkShadow = '/assets/icons/hover-navlink-shadow.svg',
  HoverNavLinkBorder = '/assets/icons/hover-navlink-border.svg',
  LockNavLink = '/assets/icons/lock-navlink.svg',
  DataLock = '/assets/icons/data-lock-icon.svg',
  CashCard = '/assets/icons/cash-card-icon.svg',
  ActiveNavLinkShadow = '/assets/icons/active-navlink-shadow.svg',
  ActiveNavLinkBorder = '/assets/icons/active-navlink-border.svg',
  ActiveNavLinkBorderHorizontal = '/assets/icons/active-navlink-border-horizontal.svg',
  Docx = '/assets/icons/docx-icon.svg',
  Pdf = '/assets/icons/pdf-icon-white.svg',
  Pptx = '/assets/icons/pptx-icon.svg',
  LockedShield = '/assets/icons/locked-shield.svg',
  Fail = '/assets/icons/fail-icon.svg',
  FailGray = '/assets/icons/fail-icon-gray.svg',
  FailGold = '/assets/icons/fail-icon-gold.svg',
  Warning = '/assets/icons/warning-icon.svg',
  WarningSmall = '/assets/icons/warning-icon-small.svg',
  Search = '/assets/icons/search-icon.svg',
  NoSearchResults = '/assets/icons/search-icon-fail.svg',
  Lock = '/assets/icons/lock.svg',
  UpArrow = '/assets/icons/up-arrow.png',
  DownArrow = '/assets/icons/down-arrow.png',
  ArrowBack = '/assets/icons/arrow-back-adjusted.svg',
  Data = '/assets/icons/data-icon.svg',
  DataBlack = '/assets/icons/data-icon-black.svg',
  GreenCheck = '/assets/icons/green-check.svg',
  GreenCheckNarrow = '/assets/icons/green-check-narrow.svg',
  RedCheck = '/assets/icons/red-check.svg',
  Consignment = '/assets/icons/consignment-icon.svg',
  ConsignmentSpoke = '/assets/icons/consignment-spoke-icon.svg',
  CopyClipboard = '/assets/icons/copy-clipboard.svg',
  Coupon = '/assets/icons/coupon-icon.svg',
  InsuranceAlone = '/assets/icons/insurance-alone-icon.svg',
  Info = '/assets/icons/info-icon.svg',
  LogoLight = '/assets/icons/brightscrip-logo-light.png',
  LogoDark = '/assets/icons/brightscrip-logo-dark.png',
  Prescriber = '/assets/icons/prescriber.svg',
  LightCircleWithShadow = '/assets/icons/light-circle-with-shadow.svg',
  Home = '/assets/icons/navigation-home.svg',
  SolidRightArrow = '/assets/icons/solid-right-arrow.svg',
  RefreshDefault = '/assets/icons/refresh-icon-default.svg',
  ExpandDefault = '/assets/icons/expand-icon-default.svg',
  DateDefault = '/assets/icons/date-icon-default.svg',
  Eligible = '/assets/icons/eligible.svg',
  File = '/assets/icons/file.svg',
  PatientConsent = '/assets/icons/patient-consent.svg',
  ReimburseSquare = '/assets/icons/reimburse-square.svg',
  SupportTicket = '/assets/icons/support-ticket.svg',
  Surveillance = '/assets/icons/surveillance.svg',
  LeftCurvedGradientBorder = '/assets/icons/left-curved-gradient-border.svg',
  RightCurvedGradientBorder = '/assets/icons/right-curved-gradient-border.svg',
  DefaultGlowCircle = '/assets/icons/default-glow-circle.svg',
  DefaultGlowCircleSmall = '/assets/icons/default-glow-circle-small.svg',
  GreenGlowCircle = '/assets/icons/green-glow-circle.svg',
  GreenGlowCircleSmall = '/assets/icons/green-glow-circle-small.svg',
  SmallCircleActive = '/assets/icons/small-circle-active.svg',
  MessageIcon = '/assets/icons/message-anticon.png',
  MobileMenu = '/assets/icons/mobile-menu.svg',
  MobileMenuClose = '/assets/icons/close-anticon.svg',
  Download = '/assets/icons/download.svg',
  Expired = '/assets/icons/expired-icon.svg',
  Errored = '/assets/icons/errored-icon.svg',
  StopWatch = '/assets/icons/stopwatch.svg',
  PaTab = '/assets/icons/pa-tab.svg',
  DataAgreement = '/assets/icons/data-agreement.svg',
  Calendar = '/assets/icons/calendar-icon.svg',
  CalendarSmall = '/assets/icons/calendar-icon-small.svg',
  Phone = '/assets/icons/phone-icon.svg',
  PhoneSmall = '/assets/icons/phone-icon-small.svg',
  Fax = '/assets/icons/fax-icon.svg',
  FaxSmall = '/assets/icons/fax-icon-small.svg',
  Hourglass = '/assets/icons/hourglass.png',
  PatientMemberIdIcon = '/assets/icons/patient-member-id-icon.svg',
  PatientMemberIdIconSmall = '/assets/icons/patient-member-id-icon-small.svg',
  PatientNameIcon = '/assets/icons/patient-name-icon.svg',
  PatientNameIconSmall = '/assets/icons/patient-name-icon-small.svg',
  PatientSexIcon = '/assets/icons/patient-sex-icon.svg',
  PatientSexIconSmall = '/assets/icons/patient-sex-icon-small.svg',
  PatientAddressIcon = '/assets/icons/patient-address-icon.svg',
  PatientAddressIconSmall = '/assets/icons/patient-address-icon-small.svg',
  Transmission = '/assets/icons/transmission-icon.svg',
  AttestationCheck = '/assets/icons/attestation-check.svg',
  AttestationCheckSmall = '/assets/icons/attestation-check-small.svg',
  Copy = '/assets/icons/copy-icon.svg',
  ThumbsUp = '/assets/icons/thumbs-up.png',
  Stars = '/assets/icons/stars.svg',
  TargetBullseye = '/assets/icons/target-bullseye.svg',
  TargetMiss = '/assets/icons/target-miss.svg',
}
