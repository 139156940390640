import { Component, Input, ViewChild, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-variable-slider',
  templateUrl: './variable-slider.component.html',
  styleUrls: ['./variable-slider.component.scss'],
})
export class VariableSliderComponent {
  @Input() minLabel: string;
  @Input() maxLabel: string;
  @Input() set numberOfSteps(steps: number) {
    this.steps = steps;
    this.updateValue(steps);
  }

  @Output() value = new EventEmitter<number>();

  steps = 2;

  isDragging = false;
  private xAtMouseDown: number;

  _value = 1;

  private VIEW_BOX_WIDTH = 100; // from html file
  STARTING_CENTER_X = 6;
  ENDING_CENTER_X = 94;
  STARTING_ENDING_RADIUS = 3;
  Y = 9;

  @ViewChild('sliderContainer', { static: true }) sliderContainer: ElementRef;

  @HostListener('document:mouseup', ['$event', '$event.target'])
  handleMouseUp(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }

    this.isDragging = false;
  }

  @HostListener('document:mousemove', ['$event', '$event.target'])
  handleMouseMove(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }

    if (this.isDragging) {
      if (event.clientX > this.xAtMouseDown + this.getSvgWidthBetweenStepsInPixels() && this.canValueIncrease()) {
        this.updateValue(this._value + 1);
        this.xAtMouseDown = event.clientX;
      } else if (event.clientX < this.xAtMouseDown - this.getSvgWidthBetweenStepsInPixels() && this.canValueDecrease()) {
        this.updateValue(this._value - 1);
        this.xAtMouseDown = event.clientX;
      }
    }
  }

  constructor() {}

  getCircleX(): number {
    return this.getSvgWidthBetweenSteps() * (this._value - 1) + this.STARTING_CENTER_X;
  }

  private getSvgWidthBetweenSteps(): number {
    return this.getSvgWidthWithOffset() / (this.steps - 1);
  }

  private getSvgWidthWithOffset(): number {
    // The steps are based off of the starting and ending circle's center x values.
    // So the effective width needs to account for the offsets to the center x's.
    return this.VIEW_BOX_WIDTH - this.STARTING_CENTER_X - (this.VIEW_BOX_WIDTH - this.ENDING_CENTER_X);
  }

  private getSvgWidthBetweenStepsInPixels(): number {
    return this.getEffectiveWidthInPixels() / this.steps;
  }

  private getEffectiveWidthInPixels(): number {
    return this.getEffectiveWidthRatio() * this.sliderContainer.nativeElement.clientWidth;
  }

  private getEffectiveWidthRatio(): number {
    return this.getSvgWidthWithOffset() / this.VIEW_BOX_WIDTH;
  }

  private canValueDecrease(): boolean {
    return this._value > 1;
  }

  private canValueIncrease(): boolean {
    return this._value < this.steps;
  }

  onMouseDown($event: MouseEvent): void {
    this.isDragging = true;
    this.xAtMouseDown = $event.clientX;
  }

  private updateValue(newVal: number): void {
    this._value = newVal;
    this.value.emit(newVal);
  }
}
