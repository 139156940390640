import gql from 'graphql-tag';

export const GetProductQuery = gql`
  query GetProduct($tenantId: String!, $ndc: String!) {
    tenant(id: $tenantId) {
      product(ndc: $ndc) {
        _id
        brand
        dosage
        howSupplied
        name
        ndc
        ndc9
        wac
      }
    }
  }
`;
