import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component';
import Utils from '@shared/providers/utils';
import { editor } from 'monaco-editor';
import { DiffEditorModel } from 'ngx-monaco-editor-v2';
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;

@Component({
  selector: 'app-code-diff-viewer',
  templateUrl: './code-diff-viewer.component.html',
})
export class CodeDiffViewerComponent<T> extends BaseComponent {
  readonly defaultOptions: IStandaloneEditorConstructionOptions = {
    language: 'json',
    minimap: { enabled: false },
    scrollbar: { alwaysConsumeMouseWheel: false },
    scrollBeyondLastLine: false,
    automaticLayout: true,
  };
  codeOptions: IStandaloneEditorConstructionOptions = {};
  protected originalModel: DiffEditorModel = {
    code: '{}',
    language: 'json',
  };
  protected modifiedModel = {
    code: '{}',
    language: 'json',
  };
  @Input() modelType: 'json' | 'string' = 'json';
  @Input() cssClass: string | string[] | Record<string, unknown> = {};
  @Input() cssStyle: Record<string, unknown> = {};
  @Output() modifiedCodeChange = new EventEmitter<T | null>();
  @Output() jsonParserErrorChange = new EventEmitter<string | undefined>();

  constructor() {
    super();
    this.codeOptions = Object.assign({}, this.defaultOptions);
  }

  @Input()
  set originalCode(code: T | string | null) {
    this.originalModel = {
      ...this.originalModel,
      code: this.formatCode(code),
    };
  }

  @Input()
  set modifiedCode(code: T | string | null) {
    this.modifiedModel = {
      ...this.modifiedModel,
      code: this.formatCode(code),
    };
  }

  get options() {
    return this.codeOptions;
  }

  @Input()
  set options(options: IStandaloneEditorConstructionOptions) {
    this.codeOptions = Object.assign({}, this.defaultOptions, options);
    if (options.language) {
      this.originalModel.language = options.language;
      this.modifiedModel.language = options.language;
    }
  }

  format() {
    this.originalCode = this.formatCode(this.originalModel.code);
    this.modifiedCode = this.formatCode(this.modifiedModel.code);
  }

  private formatCode(code: T | string | null): string {
    if (code === null) {
      return JSON.stringify(null, null, 2);
    }
    if (typeof code === 'object') {
      const formattedCode = Utils.sortObjectKeysDeep(code);
      return JSON.stringify(formattedCode, null, 2);
    }
    const parsedCode = JSON.parse(code as string);
    const formattedCode = Utils.sortObjectKeysDeep(parsedCode);
    return JSON.stringify(formattedCode, null, 2);
  }

  protected onEditorInit(editor: editor.IStandaloneDiffEditor) {
    if (!editor) {
      return;
    }
    editor.getModifiedEditor().onDidChangeModelContent(() => {
      const newCode = editor.getModel()?.modified.getValue();
      if (!newCode) {
        this.modifiedCodeChange.next(null);
        return;
      }
      let jsonParserErrror: string | undefined = undefined;
      try {
        const parsedNewCode = JSON.parse(newCode) as T;
        this.modifiedCodeChange.next(parsedNewCode);
      } catch (err: unknown) {
        if (err instanceof SyntaxError) {
          jsonParserErrror = err.message;
        }
      }
      this.jsonParserErrorChange.next(jsonParserErrror);
    });
  }
}
