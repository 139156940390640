import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { IProgramGroup } from '@shared/models/program-group.model';
import { ProgramGroupApiService } from '@services/program-group-api.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { IStateService } from '@shared/models/state-service.model';
import { StateServiceName } from '@shared/models/state-service-name.enum';
import { Tenant } from '@shared/models/tenant.model';
import { IUser } from '@shared/models/user.model';
import { TenantIdType } from '@shared/models/tenant-id.type';

@Injectable({
  providedIn: 'root',
})
export class ProgramGroupStateService implements IStateService {
  name = StateServiceName.PROGRAM_GROUP;
  loading = new BehaviorSubject<boolean>(false);

  allProgramGroupsForTenant = new BehaviorSubject<IProgramGroup[]>([]);

  private newUpdateRequest = new Subject<void>();

  constructor(private programGroupApiService: ProgramGroupApiService) {}

  get isLoading(): boolean {
    return this.loading.getValue();
  }

  reset() {
    this.allProgramGroupsForTenant.next([]);
  }

  setFromCurrentTenant(currentTenant: Tenant): void {
    if (currentTenant) {
      this.getAllProgramGroups().subscribe();
    }
  }

  setFromCurrentUser(currentUser: IUser): void {
    if (currentUser && currentUser.tenants) {
      this.getAllProgramGroupsForTenants(currentUser.tenants).subscribe();
    }
  }

  setOnTenantChange(newTenant: Tenant): void {
    this.setFromCurrentTenant(newTenant);
  }

  setFromObservable(observable: Observable<IProgramGroup[]>): Observable<IProgramGroup[]> {
    return observable.pipe(takeUntil(this.newUpdateRequest)).pipe(
      tap(newProgramGroups => {
        this.allProgramGroupsForTenant.next(newProgramGroups);
        this.loading.next(false);
      }),
    );
  }

  getAllProgramGroups(): Observable<IProgramGroup[]> {
    this.cancelExistingRequests();
    this.loading.next(true);
    return this.setFromObservable(this.programGroupApiService.getAllProgramGroups());
  }

  getAllProgramGroupsForTenants(tenants: TenantIdType[]): Observable<IProgramGroup[]> {
    this.cancelExistingRequests();
    this.loading.next(true);

    const programGroupObservables: Array<Observable<IProgramGroup[]>> = [];

    tenants.forEach(tenant => {
      const programObservable = this.programGroupApiService.getAllProgramGroupsForTenant(tenant);
      programGroupObservables.push(programObservable);
    });

    const combinedProgramObservables = forkJoin(programGroupObservables).pipe(map(allProgramGroups => allProgramGroups.flat()));
    return this.setFromObservable(combinedProgramObservables);
  }

  private cancelExistingRequests(): void {
    if (this.isLoading) {
      this.newUpdateRequest.next();
    }
  }
}
